import React, { useEffect, useState } from 'react'
import Paper from '@material-ui/core/Paper';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Table from './table';
import SelectPriceLevel from './selectPriceLevel';
import { Counter } from './styled';
import { getSpreadsheetApp, getSellingPriceLevelList, getUser } from './api';
import SelectFood from './selectFood';
import { localStorageContact, localStorageEmployee, setFirstVisit, deleteAfter24Hours } from './local-storage';
import Heading from './Heading';
import './style.css'
const foodTypeAll = ["ก๋วยเตี๋ยวน้ำใส", "ก๋วยเตี๋ยวเย็นตาโฟ", "ก๋วยเตี๋ยวต้มยำ", "เย็นตาโฟต้มยำ", "ก๋วยเตี๋ยวแห้ง", "เย็นตาโฟแห้ง", "ต้มยำแห้ง", "เย็นตาโฟต้มยำแห้ง", "ก๋วยเตี๋ยวเรือชามเล็ก", "ก๋วยเตี๋ยวเรือชามใหญ่", "ลูกชิ้นลวกจิ้ม"]
const priceLevelAll = ["A", "B", "C", "D", "E", "F", "G", "H"]
 


function CostAruneeNoodles() {

    const [data, setData] = useState([])
    const [priceLevel, setPriceLevel] = useState(localStorage.getItem("rawMaterial") ? JSON.parse(localStorage.getItem("rawMaterial")).priceLevel : priceLevelAll[0])
    const [userType, setUserType] = useState("")
    const [loading, setLoading] = useState(true)
    // ประเภทของอาหาร
    const [foodType, setFoodType] = useState(localStorage.getItem("rawMaterial") ? JSON.parse(localStorage.getItem("rawMaterial")).foodType : foodTypeAll[0])


   
      
     
    

    useEffect(() => {

        setFirstVisit();
        deleteAfter24Hours("rawMaterial")
     

        setLoading(true) 
        Promise.all([getUser(), getSpreadsheetApp(),  getSellingPriceLevelList()]).then((res) => {
            const [user, spreadsheetApp,  sellingPriceLevelList] = res
            // spreadsheetApp --> ถ้าไม่ productID ให้เป็น 0
            console.log(user)
            setUserType(user.userType)
            const spreadsheetAppMap = spreadsheetApp.map((item, index) => {
                return {
                    ...item,
                    key: index,
                    productID: item.productID ? Number(item.productID ): 0,
                    // 17 column แรกเป็นสีแดง ตั้งแต่ 0 - 16
                    isRed: index < 17 ? true : false,
                    quantityRecipe: null,
                    price: null,
                }
            })

            console.log("spreadsheetAppMap", spreadsheetAppMap)
            console.log("sellingPriceLevelList", sellingPriceLevelList)
          
            if(user.userType === "CONTACT"){


                const spreadsheetAppFilter = spreadsheetAppMap.map((item) => {
                    const sellingPrice =  sellingPriceLevelList.filter((price) => { return Number(price.productID) === Number(item.productID) } )[0]?.price || 0;
                    const formula = item[foodType] !== "" && item.productID > 0 ? item.purchasePrice * item.multiplyFactor * item[foodType] * sellingPrice  : item.purchasePrice * item.multiplyFactor * item[foodType];
                          
                    return {
                        ...item,
                        quantityRecipe: item[foodType] || 0,
                        price: Number(formula.toFixed(2)).toLocaleString(),
                        sellingPrice: sellingPrice,
                    }
                })

          
            
            
                const localStorageData = {
                    userType: "CONTACT",
                    id: user?.customerID,
                    foodType: foodType,
                    data: foodTypeAll.map((x) => {
                    
                        return {
                                 foodType: x,
                                 rawMaterial: spreadsheetAppFilter
                            }
                    })
                }


                console.log("localStorageData", localStorageData)
                 
                localStorageContact(localStorageData, setData, setLoading, foodType)

            } else if(user.userType === "EMPLOYEE"){

                const data = priceLevelAll.map((e) => { 
                    return {
                        priceLevel: e,
                        rawMaterial: foodTypeAll.map((x) => {

                            const product = spreadsheetAppMap.map((item) => { 
                                // 5.1 if productID > 0 ? cost = purchasePrice * multiplierFactor * quantityRecipe * price : cost = purchasePrice * multiplyFactor * quantityRecipe 
                                const sellingPrice = sellingPriceLevelList.filter((price) => { 
                                    return e === price.priceSymbol && Number(price.productID) === Number(item.productID) 
                                } )[0]?.price || 0;
                                const formula = item[foodType] !== "" && item.productID > 0 ? item.purchasePrice * item.multiplyFactor * item[foodType] * sellingPrice  : item.purchasePrice * item.multiplyFactor * item[foodType];
                                
                                return {
                                    ...item,
                                    quantityRecipe: item[foodType] || 0,
                                    price: Number(formula.toFixed(2)).toLocaleString(),
                                    sellingPrice: sellingPrice,
                                    
                                }
                            })

                        

                            return {
                                foodType: x,
                                  product: product
                            }
                        })

                    }
                })


     

                const localStorageData = {
                    userType: "EMPLOYEE",
                    id: user?.userID,
                    priceLevel: priceLevel,
                    foodType: foodType,
                    data: data

                }

                console.log("localStorageData", localStorageData)
                localStorageEmployee(localStorageData, setData, setLoading, foodType, priceLevel)


        
                        
            }

            

            
        })
    }, [priceLevel, foodType])

    const handleClose = (event, reason) => {
        if (reason && reason === "backdropClick") 
            return;
    }
    
 
  return (
    <Counter>


    

        <div className="main">
        <Paper className="Table-costAruneeNoodles">
        <Heading/>

         <div className='d-flex justify-content-between align-items-baseline mt-2' style={{ marginLeft: '.5rem', marginRight: '.5rem' }}>
            <SelectFood foodType={foodType} setFoodType={setFoodType} foodTypeAll={foodTypeAll} setData={setData}/>

            { userType === "EMPLOYEE" &&  <SelectPriceLevel priceLevel={priceLevel} setPriceLevel={setPriceLevel} priceLevelAll={priceLevelAll}/> }


         </div>
     
                
           <div style={{
                paddingLeft: '.3rem',
                paddingRight: '.3rem',
                paddingBottom: '.5rem',
           }}>
            <Table data={data} setData={setData}  priceLevel={priceLevel} loading={loading} foodType={foodType} userType={userType}/>
           </div>
        </Paper>
        </div>



        <Backdrop
            onClose={handleClose}
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Counter>
  )
}

export default CostAruneeNoodles