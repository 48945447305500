import styled from "styled-components";

export const SectionShopDeta = styled.section`  
  padding-top: 100px;
  padding-bottom: 90px;
  border-radius: 8px;
  position: relative;
  margin: 12px;
  background-color: #FFE4E1;
  margin: 0;
  
  @media (max-width: 767px) {
    
    padding-bottom: 50px;
  }
  span  {
    display: inline-block;
    text-decoration: none;
    transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
  }
  /* Section Title */
  .section-title {
    text-align: center;
   margin-bottom: 0;
    padding: 0px 300px;
    position: relative;
    z-index: 5;
    position: relative;
  }

  .section-title h2 {
    font-size: 30px;
    line-height: 35px;
    text-transform: capitalize;
    position: relative;
    font-weight: 800;
    padding-bottom: 20px;
    margin-bottom: 20px;
    position: relative;
  }

  .section-title h2::before {
    position: absolute;
    left: 50%;
    bottom: 0;
    content: "";
    background-color: #f07;
    height: 2px;
    width: 50px;
    margin-left: -25px;
    border-radius: 2px;
  }

  .section-title p {
    font-size: 14px;
  }

  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .section-title {
      padding: 0px 250px;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 991px) {
   
    padding-bottom: 60px;
    .section-title {
      padding: 0px 20px;
      margin-bottom: 30px;
    }

    .section-title h3 {
      font-size: 14px;
    }

    .section-title h2 {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 20px;
    }

    .section-title p {
      font-size: 14px;
    }
  }

  @media (max-width: 767px) {
    .section-title {
      padding: 0px 10px;
      margin-bottom: 25px;
    }

    .section-title h3 {
      font-size: 14px;
    }

    .section-title h2 {
      font-size: 20px;
      line-height: 30px;
      margin-bottom: 18px;
    }

    .section-title p {
      font-size: 14px;
    }
  }

  .section-title.align-right {
    padding: 0;
    padding-left: 600px;
  }

  .section-title.align-right h2:before {
    display: none;
  }

  .section-title.align-right h2:after {
    position: absolute;
    right: 0;
    bottom: -1px;
    height: 2px;
    width: 50px;
    background: #f07;
    content: "";
  }

  .section-title.align-left {
    padding: 0;
    padding-right: 600px;
  }

  .section-title.align-left h2:before {
    left: 0;
    margin-left: 0;
  }

  /* Single Product*/
  .single-product {
     
    border-radius: 8px;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
    margin-top: 30px;
    -webkit-box-shadow: 0px 0px 20px #00000012;
    box-shadow: 0px 0px 20px #00000012;
    padding: 8px;
    background: #fff;
  }

  .single-product .product-image {
    overflow: hidden;
    position: relative;
  }
  .heart-top{
    position: absolute;
    z-index: 1;
    right: 10px;
    top: 10px;
  }

  .single-product .product-image .sale-tag {
    background: #f73232;
    border-radius: 2px;
    font-size: 12px;
    color: #fff;
    font-weight: bold;
    position: absolute;
    top: 0;
    padding: 5px 10px;
    left: 0;
    z-index: 22;
  }

  .single-product .product-image .new-tag {
    background: #f07;
    border-radius: 2px;
    font-size: 12px;
    color: #fff;
    font-weight: bold;
    position: absolute;
    top: 0;
    padding: 5px 10px;
    left: 0;
    z-index: 22;
  }

  .single-product .product-image .button {
    display: flex;
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    bottom: -60px;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
    opacity: 0;
    visibility: hidden;
  }

  .single-product .product-image .button .btn { 
     transition: 0.2s;
    padding: 12px 20px;
    font-size: 13px;
    font-weight: 600;
    width: 140px;
  }
.single-product .product-image .button .btn:active {
    
    transform: scale(0.95);
  }

  .single-product .product-image img {
    width: 100%;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
     height: 100%;
     @media screen and (max-width: 767px) {
       height:auto;
     }
  }

 

 

  .single-product .product-info {
    padding: 20px;
    background-color: #fff;
  }

  .single-product .product-info .category {
    color: #888;
    font-size: 13px;
    display: block;
    margin-bottom: 2px;
  }

  .single-product .product-info .title span {
    font-size: 20px;
    
    color: #081828;
    cursor: pointer;
  }

  @media only screen and (min-width: 768px) and (max-width: 991px),
    (max-width: 767px) {
    .single-product .product-info .title span {
      font-size: 20px;
    }
  }

  .single-product .product-info .title span:hover {
    color: #f07;
  }

  .single-product .product-info .review {
    margin-top: 5px;
    padding-left: 0;
    margin-bottom: 0;
  }

  .single-product .product-info .review li {
    display: inline-block;
  }

  .single-product .product-info .review li i {
    color: #fecb00;
    font-size: 13px;
  }

  .single-product .product-info .review li span {
    display: inline-block;
    margin-left: 4px;
    color: #888;
    font-size: 13px;
  }

  .single-product .product-info .price {
 
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .single-product .product-info .price .lni-heart {
    
    color: #888;
    cursor: pointer;
    transition: all 0.2s ease;
    &:hover {
      color: #f50057;
       
    }
  }
  .single-product .product-info .price .lni-heart-filled {
     color: #f50057 !important;
         cursor: pointer;
  }


  .single-product .product-info .price span {
    font-size: 15px;
    
    display: inline-block;
  }

  .single-product .product-info .price .discount-price {
    margin: 0;
    color: #aaaaaa;
    text-decoration: line-through;
    font-weight: normal;
    margin-left: 10px;
    font-size: 14px;
    display: inline-block;
  }

  .button .btn {
    display: inline-block;
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 600;
    padding: 13px 30px;
    background-color: #f07;
    color: #fff;
    border: none;
    -webkit-transition: 0.2s;
    transition: 0.2s;
    border-radius: 3px;
    position: relative;
    z-index: 1;
    margin-right: 7px;
    overflow: hidden;
  }

  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .button .btn {
      padding: 12px 25px;
      font-size: 14px;
      font-weight: 500;
    }
  }

  @media (max-width: 767px) {
    .button .btn {
      padding: 12px 25px;
      font-size: 14px;
      font-weight: 500;
    }
  }

  .button .btn i {
    display: inline-block;
    margin-right: 5px;
  }

  .button .btn:last-child {
    margin: 0;
  }

  .button .btn:hover {
    color: #fff;
    background-color: #f50057;
    -webkit-box-shadow: 0px 4px 4px #0000000f;
    box-shadow: 0px 4px 4px #0000000f;
  }

  .button .btn-alt {
    background-color:  #f07 !important;
    color: #fff !important;
  }

  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .button .btn-alt {
      padding: 12px 25px;
      font-size: 14px;
      font-weight: 500;
    }
  }

  @media (max-width: 767px) {
    .button .btn-alt {
      padding: 12px 25px;
      font-size: 14px;
      font-weight: 500;
    }
  }

  .button .btn-alt:hover {
    background-color: #f07 !important;
    color: #fff !important;
  }
  
 
`;