import styled from "styled-components";

export const Navbar = styled.div`
  .header {
  width: 100%;
  background: #fff;
  position: relative;
  width: 100%;
 
}

 
.header .header-middle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 15px 0;
  border-bottom: 1px solid #da1884;
  position: fixed;
  width: 100%;
  background-color: #da1884;
  z-index: 10;
 
 
}

@media (max-width: 767px) {
  .header .header-middle .d-xs-none {
    display: none;
  }
}

.header .middle-right-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: end;
}

@media (max-width: 767px) {
  .header .middle-right-area {
    -webkit-box-pack: right;
    -ms-flex-pack: right;
    justify-content: right;
    float: right;
  }
} 

.header .navbar-cart {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: end;
}

.header .navbar-cart .wishlist {
  margin-right: 5px;

}

.header .navbar-cart .wishlist span {
   cursor: pointer;
  height: 40px;
  width: 40px;
  line-height: 40px;
  display: inline-block;
  border-radius: 50%;
  border: 1px solid #eee;
  color: #555;
  font-size: 18px;
  text-align: center;
  position: relative;
  transition: 0.2s;
  color: #fff;
}

.header .navbar-cart .wishlist span:hover {
  color: #fff;
  background-color: #f07;
  border-color: transparent;
}

.header .navbar-cart .wishlist span:after {
   transform: scale(0.95);
}

.header .navbar-cart .wishlist span .total-items {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -6px;
  top: -5px;
  height: 19px;
  width: 19px;
  line-height: 19px;
  background-color: #081828;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  border-radius: 50%;
}

.header .navbar-cart .cart-items {
  position: relative;
}

.header .navbar-cart .cart-items:hover .main-btn {
  color: #fff;
  background-color: #f07;
  border-color: transparent;
}

.header .navbar-cart .cart-items .main-btn {
  cursor: pointer;
  height: 40px;
  width: 40px;
  line-height: 40px;
  display: inline-block;
  border-radius: 50%;
  border: 1px solid #eee;
  color: #fff;
  font-size: 18px;
  text-align: center;
  
  position: relative;
}
 

.header .navbar-cart .cart-items .main-btn .total-items {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -6px;
  top: -5px;
  height: 19px;
  width: 19px;
  line-height: 19px;
  background-color: #081828;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  border-radius: 50%;
}

.header .navbar-cart .cart-items:hover .shopping-item {
  opacity: 1;
  visibility: visible;
   overflow: auto;
  height: 350px;
  
  
}

.header .navbar-cart .cart-items .shopping-item {
  position: absolute;
  top: 39px;
  right: 0;
  width: 350px;
  background: #fff;
  padding: 0 25px;
 
  z-index: 99;
  border-radius: 4px;
  -webkit-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.137);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.137);
  opacity: 0;
  visibility: hidden;
}
 
.header .navbar-cart .cart-items .shopping-item .dropdown-cart-header {
      padding-bottom: 12px;
  
    padding-left: 0px;
    padding-top: 15px;
    padding-right: 5px;
    z-index: 9;
    background-color: #ffff;
    position: fixed;
    width: 300px;
    border-bottom: 1px solid #e6e6e6;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.header .navbar-cart .cart-items .shopping-item .dropdown-cart-header span {
  text-transform: capitalize;
  color: #222;
  font-size: 14px;
  font-weight: 600;
  
}
.header .navbar-cart .cart-items .shopping-item .dropdown-cart-header span a{
  text-decoration: none ;
}
.header .navbar-cart .cart-items .shopping-item .dropdown-cart-header span {
  float: right;
  text-transform: capitalize;
  color: #222;
  font-size: 14px;
  font-weight: 600;
 
}

.header .navbar-cart .cart-items .shopping-item .dropdown-cart-header span:hover {
  color: #f07;
}
.header .navbar-cart .cart-items .shopping-item .shopping-list{
  margin-top: 65px;
  padding: 0;
  margin-bottom: 100px;
}
.header .navbar-cart .cart-items .shopping-item .shopping-list li ul{
   padding-top: 60px;
    padding-bottom: 60px;
}
  
.header .navbar-cart .cart-items .shopping-item .shopping-list li {
  overflow: hidden;
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 15px;
  margin-bottom: 15px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.header .navbar-cart .cart-items .shopping-item .shopping-list li .remove {
  position: absolute;
  right: 0;
  top: 0;
  height: 18px;
  width: 18px;
  line-height: 16px;
  text-align: center;
  background: #fff;
  color: #222;
  cursor: pointer;
  font-size: 8px;
  padding-left: 1px;
}

.header .navbar-cart .cart-items .shopping-item .shopping-list li .remove:hover {
 
  color: red;
   
}

.header .navbar-cart .cart-items .shopping-item .shopping-list li .cart-img-head {
  width: 40%;
}

@media (max-width: 767px) {
  .header .navbar-cart .cart-items .shopping-item .shopping-list li .cart-img-head {
    width: 30%;
    margin-right: 12px;
  }
}

.header .navbar-cart .cart-items .shopping-item .shopping-list li .cart-img {
  border: 1px solid #ededed;
  overflow: hidden;
  height: 80px;
  width: 80px;
  border-radius: 4px;
  float: left;
  margin-right: 20px;
}

@media (max-width: 767px) {
  .header .navbar-cart .cart-items .shopping-item .shopping-list li .cart-img {
    height: 60px;
    width: 60px;
    margin-right: 10px;
  }
}

.header .navbar-cart .cart-items .shopping-item .shopping-list li .content {
  padding-right: 25px;
  width: 60%;
}

@media (max-width: 767px) {
  .header .navbar-cart .cart-items .shopping-item .shopping-list li .content {
    width: 70%;
  }
}

.header .navbar-cart .cart-items .shopping-item .shopping-list li .content h4 {
  margin-bottom: 5px;
  line-height: 22px;
}

.header .navbar-cart .cart-items .shopping-item .shopping-list li .content h4 a {
  font-size: 14px;
  font-weight: 600;
  color: #081828;
}

@media (max-width: 767px) {
  .header .navbar-cart .cart-items .shopping-item .shopping-list li .content h4 a {
    font-size: 13px;
  }
}

.header .navbar-cart .cart-items .shopping-item .shopping-list li .content h4 a:hover {
  color: #f07;
}

.header .navbar-cart .cart-items .shopping-item .shopping-list li .content .quantity {
  line-height: 22px;
  font-size: 14px;
  margin-bottom: 0;
}

.header .navbar-cart .cart-items .shopping-item .bottom {
 
     text-align: center;
    position: fixed;
    width: 300px;
    padding: 7px;
    z-index: 9;
    background-color: #fff;
    top: 314px;
}

.header .navbar-cart .cart-items .shopping-item .bottom .total {
 
  overflow: hidden;
  display: block;
  padding-bottom: 10px;
}

.header .navbar-cart .cart-items .shopping-item .bottom .total span {

  text-transform: capitalize;
  color: #222;
  font-size: 14px;
  font-weight: 600;
  float: left;
}

.header .navbar-cart .cart-items .shopping-item .bottom .total .total-amount {
  float: right;
  font-size: 14px;
}

.header .navbar-cart .cart-items .shopping-item .bottom .button {
    display: flex;
  width: 100%;
}

.header .navbar-cart .cart-items .shopping-item .bottom .button .btn {
  width: 100%;
  background-color: #f07;
  color: #fff;
 transition: 0.2s;
}

.header .navbar-cart .cart-items .shopping-item .bottom .button .btn:active{
   transform: scale(0.95);
}


.header .navbar-cart .cart-items .shopping-item .bottom .button .btn:hover {
  background-color: #f50057;
}

 
@media (max-width: 767px) {
  .header .main-menu-search {
    display: none;
  }
}

.header .main-menu-search .navbar-search {
  position: relative;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.header .main-menu-search .select-position {
  position: relative;
}

.header .main-menu-search .select-position:after {
  border-bottom: 1.5px solid #081828;
  border-right: 1.5px solid #081828;
  content: '';
  display: block;
  height: 8px;
  margin-top: -4px;
  pointer-events: none;
  position: absolute;
  right: 15px;
  top: 50%;
  -webkit-transform-origin: 66% 66%;
  transform-origin: 66% 66%;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  width: 8px;
}

.header .main-menu-search .select-position select {
  height: 45px;
  border: 1px solid #e2e2e2;
  border-right: 0px;
  padding: 0 20px;
  background: transparent;
  border-radius: 4px 0 0 4px;
  position: relative;
  padding-right: 30px;
  -moz-appearance: none;
  appearance: none;
  -webkit-appearance: none;
  font-size: 14px;
}

.header .main-menu-search .select-position select:focus {
  text-decoration: none;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.header .main-menu-search .search-input {
  width: 100%;
}

.header .main-menu-search .search-input input {
  width: 100%;
  height: 45px;
  border: 1px solid #e2e2e2;
  background-color: #fff;
  color: #000;
  border-radius: 0;
  padding: 0 15px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  font-size: 14px;
}

.header .main-menu-search .search-btn button {
  background-color: #f07;
  color: #fff;
  width: 45px;
  height: 45px;
  padding: 0;
  font-size: 20px;
  border: 0;
  border-radius: 0 4px 4px 0;
  margin-left: -2px;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  font-size: 18px;
}

.header .main-menu-search .search-btn button:hover {
  color: #fff;
  background-color: #081828;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header .mobile-menu-btn .toggler-icon {
    background-color: #081828;
  }

  .header .button {
    margin: 0 !important;
  }

  .header .nav-inner {
    display: block !important;
    padding: 18px 0;
  }

  .header .navbar-collapse {
    position: absolute;
    top: 46px !important;
    left: 0;
    width: 100%;
    background-color: #fff;
    z-index: 9;
    -webkit-box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.1);
    padding: 10px 20px;
    max-height: 350px;
    overflow-y: scroll;
    border-top: 1px solid #eee;
    border-radius: 6px;
  }

  .header .navbar .navbar-nav .nav-item span:hover {
    color: #f07 !important;
  }

  .header .navbar .navbar-nav .nav-item span.active {
    color: #f07 !important;
  }

  .header .navbar-nav .nav-item {
    margin: 0;
  }

  .header .navbar-nav .nav-item:hover span {
    color: #f07;
  }

  .header .navbar-nav .nav-item span {
    padding: 12px 16px !important;
  }

  .header .navbar-nav .nav-item span::before {
    display: none;
  }

  .header .navbar-nav .nav-item .sub-menu {
    position: static;
    width: 100%;
    opacity: 1;
    visibility: visible;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding: 0;
    border: none;
    margin-left: 15px;
    margin-right: 15px;
  }

  .header .navbar-nav .nav-item .sub-menu .nav-item span {
    padding: 12px 12px;
  }

  .header .navbar-nav .nav-item .sub-menu .nav-item span:hover {
    color: #f07 !important;
  }

  .header .navbar-nav .nav-item span {
    color: #051441;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 10px 0;
  }

  .header .navbar-nav .nav-item span::after {
    opacity: 1;
    visibility: visible;
  }

  .header .navbar-nav .nav-item .sub-menu li.active {
    background: #fff !important;
    color: #f07 !important;
  }

  .header .navbar-nav .nav-item .sub-menu .nav-item {
    margin: 0 !important;
  }

  .header .navbar-nav .nav-item .sub-menu .nav-item span {
    padding: 10px 12px !important;
  }

  .header .navbar-nav .nav-item .sub-menu li:hover {
    background: #fff !important;
    color: #f07 !important;
  }

  .header .navbar-nav .nav-item span {
    font-size: 14px;
  }

  .header .navbar-nav .nav-item span:hover {
    color: #f07;
  }
}

@media (max-width: 767px) {
  .header .mobile-menu-btn .toggler-icon {
    background-color: #081828;
  }

  .header .button {
    margin: 0 !important;
  }

  .header .nav-inner {
    display: block !important;
    padding: 18px 0;
  }

  .header .navbar-collapse {
    position: absolute;
    top: 46px !important;
    left: 0;
    width: 100%;
    background-color: #fff;
    z-index: 9;
    -webkit-box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.1);
    padding: 10px 20px;
    max-height: 350px;
    overflow-y: scroll;
    border-top: 1px solid #eee;
    border-radius: 6px;
  }

  .header .navbar .navbar-nav .nav-item span:hover {
    color: #f07 !important;
  }

  .header .navbar .navbar-nav .nav-item span.active {
    color: #f07 !important;
  }

  .header .navbar-nav .nav-item {
    margin: 0;
  }

  .header .navbar-nav .nav-item:hover span {
    color: #f07;
  }

  .header .navbar-nav .nav-item span {
    padding: 12px 16px !important;
  }

  .header .navbar-nav .nav-item span::before {
    display: none;
  }

  .header .navbar-nav .nav-item .sub-menu {
    position: static;
    width: 100%;
    opacity: 1;
    visibility: visible;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding: 0;
    border: none;
    margin-left: 15px;
    margin-right: 15px;
  }

  .header .navbar-nav .nav-item .sub-menu .nav-item span {
    padding: 12px 12px;
  }

  .header .navbar-nav .nav-item .sub-menu .nav-item span:hover {
    color: #f07 !important;
  }

  .header .navbar-nav .nav-item span {
    color: #051441;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 10px 0;
  }

  .header .navbar-nav .nav-item span::after {
    opacity: 1;
    visibility: visible;
  }

  .header .navbar-nav .nav-item .sub-menu li.active {
    background: #fff !important;
    color: #f07 !important;
  }

  .header .navbar-nav .nav-item .sub-menu .nav-item {
    margin: 0 !important;
  }

  .header .navbar-nav .nav-item .sub-menu .nav-item span {
    padding: 10px 12px !important;
  }

  .header .navbar-nav .nav-item .sub-menu li:hover {
    background: #fff !important;
    color: #f07 !important;
  }

  .header .navbar-nav .nav-item span {
    font-size: 14px;
  }

  .header .navbar-nav .nav-item span:hover {
    color: #f07;
  }
}

.header .nav-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.header .mega-category-menu {
  position: relative;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-right: 1px solid #eee;
  margin-right: 40px;
  padding-right: 112px;
  cursor: pointer;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header .mega-category-menu {
    padding-right: 35px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header .mega-category-menu {
    display: none;
  }
}

@media (max-width: 767px) {
  .header .mega-category-menu {
    display: none;
  }
}

.header .mega-category-menu .cat-button {
  display: inline-block;
  color: #081828;
  font-size: 15px;
  font-weight: 600;
}

.header .mega-category-menu .cat-button i {
  display: inline-block;
margin-right: 12px;
font-size: 20px;
position: relative;
top: 2px;
}

.header .mega-category-menu:hover .sub-category {
  opacity: 1;
  visibility: visible;
  top: 61px;
}

.header .mega-category-menu .sub-category {
  position: absolute;
  left: 0;
  top: 68px;
  width: 241px;
  height: auto;
  border: 1px solid #eee;
  background-color: #fff;
  opacity: 0;
  visibility: hidden;
  border-radius: 0;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  padding: 15px 0;
}

.header .mega-category-menu .sub-category li {
  display: block;
  position: relative;
}

.header .mega-category-menu .sub-category li:hover .inner-sub-category {
  opacity: 1;
  visibility: visible;
  top: 0;
}

.header .mega-category-menu .sub-category li .inner-sub-category {
  padding: 15px 0;
  position: absolute;
  top: 8px;
  width: 240px;
  height: auto;
  border: 1px solid #eee;
  background-color: #fff;
  border-radius: 0;
  left: 239px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.header .mega-category-menu .sub-category li .inner-sub-category li {
  display: block;
  position: relative;
}

.header .mega-category-menu .sub-category li .inner-sub-category li:last-child {
  margin: 0;
}

.header .mega-category-menu .sub-category li .inner-sub-category li span {
  color: #081828;
  text-transform: capitalize;
  font-size: 14px;
  position: relative;
  width: 100%;
  padding: 8px 30px;
}

.header .mega-category-menu .sub-category li .inner-sub-category li span:hover {
  color: #f07;
}

.header .mega-category-menu .sub-category li:last-child {
  margin: 0;
}

.header .mega-category-menu .sub-category li span {
  color: #081828;
  text-transform: capitalize;
  font-size: 14px;
  position: relative;
  width: 100%;
  padding: 8px 30px;
}

.header .mega-category-menu .sub-category li span i {
  font-size: 10px;
  float: right;
  position: relative;
  top: 6px;
}

.header .mega-category-menu .sub-category li span:hover {
  color: #f07;
}

.header .nav-social {
  float: right;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (max-width: 767px) {
  .header .nav-social {
    position: relative;
    margin-top: -50px;
  }
}

.header .nav-social .title {
  font-size: 14px;
  font-weight: 600;
  color: #081828;
  display: inline-block;
  margin-right: 15px;
}

@media (max-width: 767px) {
  .header .nav-social .title {
    display: none;
  }
}

.header .nav-social ul li {
  display: inline-block;
  margin-right: 5px;
}

.header .nav-social ul li:last-child {
  margin: 0;
}

.header .nav-social ul li span {
  height: 35px;
  width: 35px;
  line-height: 35px;
  text-align: center;
  display: block;
  border: 1px solid #eee;
  border-radius: 50%;
  font-size: 13px;
  color: #555;
}

.header .nav-social ul li span:hover {
  color: #fff;
  background-color: #f07;
  border-color: transparent;
}

.header .navbar-brand {
  margin: 0;
  font-size: 20px;
  
  color: #fff;
  font-weight: bold;
}

@media (max-width: 767px) {
  .header .navbar-brand {
    display: block;
    text-align: left;
    padding: 0;
  }
}

.header .navbar-brand img {
  width: 200px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header .navbar-brand img {
    width: 190px;
  }
}

@media (max-width: 767px) {
  .header .navbar-brand img {
    width: 180px;
  }
}

.header.sticky .navbar-brand .white-logo {
  opacity: 0;
  visibility: hidden;
}

.header.sticky .navbar-brand .dark-logo {
  opacity: 1;
  visibility: visible;
}

.header.sticky .button .btn {
  background-color: #f07;
  color: #fff;
  border-color: transparent;
}

.header.sticky .button .btn:hover {
   background-color: #f50057;
  color: #fff;
}

.sticky {
  position: fixed;
  z-index: 99;
  background-color: #fff;
  -webkit-box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.05);
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  top: 0;
  width: 100%;
}

.navbar-expand-lg .navbar-nav {
  margin: 0;
  margin-left: 0 !important;
  margin-right: auto !important;
}

.header .navbar .navbar-nav .nav-item span.active {
  color: #f07;
}

.sticky .navbar .navbar-nav .nav-item span.active {
  color: #f07;
}

.sticky .navbar .mobile-menu-btn .toggler-icon {
  background: #333;
}

/*===== NAVBAR =====*/
.navbar-area {
  width: 100%;
  z-index: 99;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  padding: 0;
  border-bottom: 1px solid #eee;
}

.navbar-area.sticky {
  position: fixed;
  z-index: 99;
  background: #f07;
  -webkit-box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.05);
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  background: #fff;
  padding: 0px 0;
}

.navbar {
  padding: 0;
  position: relative;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.navbar-brand {
  padding-left: 0;
  border-radius: 0;
}

.mobile-menu-btn {
  padding: 0px;
}

.mobile-menu-btn:focus {
  text-decoration: none;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.mobile-menu-btn .toggler-icon {
  width: 30px;
  height: 2px;
  background-color: #222;
  display: block;
  margin: 5px 0;
  position: relative;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.mobile-menu-btn.active .toggler-icon:nth-of-type(1) {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 7px;
}

.mobile-menu-btn.active .toggler-icon:nth-of-type(2) {
  opacity: 0;
}

.mobile-menu-btn.active .toggler-icon:nth-of-type(3) {
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
  top: -7px;
}

.navbar-nav .nav-item {
  z-index: 1;
  position: relative;
  margin-right: 40px;
}

.navbar-nav .nav-item:last-child {
  margin-right: 0 !important;
}

.navbar-nav .nav-item:hover span {
  color: #f07;
}

.sticky .navbar-nav .nav-item:hover span {
  color: #f07;
}

.navbar-nav .nav-item span {
  font-size: 14px;
  color: #081828;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  position: relative;
  padding: 20px 0;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: 600;
  transition: all 0.3s ease-out 0s;
  position: relative;
  text-transform: capitalize;
}

.navbar-nav .nav-item:hover .dd-menu::before {
  border-bottom-color: #f07;
  border-right-color: #f07;
}

.navbar-nav .nav-item span.active {
  color: #f07;
}

.navbar-nav .nav-item span.dd-menu::after {
  content: "\ea5e";
  font: normal normal normal 1em/1 "LineIcons";
  font-size: 1em;
  position: absolute;
  right: -20px;
  font-size: 12px;
  top: 50%;
  margin-left: 5px;
  margin-top: -4px;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  height: 10px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .navbar-nav .nav-item span.dd-menu::after {
    right: -15;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .navbar-nav .nav-item span.dd-menu::after {
    top: 18px;
    right: 0;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .navbar-nav .nav-item span.collapsed::after {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

.navbar-nav .nav-item:hover>.sub-menu {
  top: 100%;
  opacity: 1;
  visibility: visible;
}

.navbar-nav .nav-item:hover>.sub-menu .sub-menu {
  left: 100%;
  top: 0;
}

.navbar-nav .nav-item .sub-menu {
  padding: 30px;
  min-width: 240px;
  background: #fff;
  border: 1px solid #eee;
  position: absolute;
  top: 110% !important;
  left: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  border-radius: 0 0 4px 4px;
}

.navbar-nav .nav-item:hover .sub-menu {
  top: 100% !important;
}

.navbar-nav .nav-item .sub-menu .nav-item {
  width: 100%;
  margin-bottom: 15px;
}

.navbar-nav .nav-item .sub-menu .nav-item:last-child {
  margin: 0;
}

.navbar-nav .nav-item .sub-menu .nav-item span {
  padding: 0;
  color: #888;
  display: block;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  position: relative;
  z-index: 1;
  border-radius: 4px;
  overflow: hidden;
  -webkit-transition: all 0.1s ease;
  transition: all 0.1s ease;
}

.navbar-nav .nav-item .sub-menu .nav-item span:hover {
  color: #f07;
}

.navbar-nav .nav-item .sub-menu.left-menu {
  left: -100%;
}

.navbar-nav .nav-item .sub-menu.collapse:not(.show) {
  display: block;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .navbar-nav .nav-item .sub-menu.collapse:not(.show) {
    display: none;
  }
}

.navbar-nav .nav-item .sub-menu>li {
  display: block;
  margin-left: 0;
}

.navbar-nav .nav-item .sub-menu>li:last-child {
  border: none;
}

.navbar-nav .nav-item .sub-menu>li.active>span {
  color: #f07 !important;
}

.navbar-nav .nav-item .sub-menu>li>span {
  font-weight: 400;
  display: block;
  padding: 12px 15px;
  font-size: 14px;
  color: #222;
  font-weight: 400;
}

.navbar-nav .nav-item .sub-menu>li:first-child span {
  border: none;
}

.add-list-button {
  display: inline-block;
  margin-left: 10px;
}

.add-list-button .btn i {
  font-size: 14px;
  display: inline-block;
  margin-right: 5px;
}
  `