import React, { useState } from 'react'
import { ItemDetail3, BittonGreen, } from './style.module';
import Button from '@material-ui/core/Button';
import swal from "@sweetalert/with-react";
import './style.css';
import apiService from "../../api/apiService";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import { styled } from '@mui/material/styles';
 
import CircularProgress from '@mui/material/CircularProgress';
 
import Backdrop from '@mui/material/Backdrop';


const BoxButton = styled(Box)(({ theme }) => ({
  width: 500,
  margin: 0,
  marginTop: 1,
  marginBottom: 2,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  button: {
    width: '80%',
    borderRadius: '10px !important',
    cursor: 'pointer !important',
  },
  '@media(max - width: 768px)': {
    width: '100%',
  },
}));
 

function MoneyDetail3({loading1, loading2, loading3, returnProduct, receiptAmount1, receiptAmount2, showButton, setShowButton, orderCancel }) {
  const service = apiService();
  const { shipperID, date } = useParams();
  const [open, setOpen] = useState(false);


  const postApi = () => {
      console.log("receiptAmount1", receiptAmount1.toString());
      console.log("receiptAmount2", receiptAmount2.toString());
      setOpen(true);
   
    if (returnProduct.length > 0) {
    
      async function CheckoutForReturn () {

        return await service.post({ url: `/api/v1/order/checkout-for-return`, params: { shippingDate: date, shipperID: shipperID } }).then((res) => {
          return res.data.result;
        })
      }

      // async function cashtransfer() {
       
      //   return await service.post({ url: `/api/v1/financial/cashtransfer`, params: { shipperID: shipperID, receiptAmount: receiptAmount1.toString(), paymentMethod: "cash_ThisPayment" } }).then((res) => {
      //     return res.data.result;
      //   })

      // }
      // async function cashtransfer2() {
         
      //   return await service.post({ url: `/api/v1/financial/cashtransfer`, params: { shipperID: shipperID, receiptAmount: receiptAmount2.toString(), paymentMethod: "cash_PreviousPayment" } }).then((res) => {
      //     return res.data.result;
      //   }) 

      // }

      Promise.all([CheckoutForReturn()]).then((res) => {
        swal({
          title: "สำเร็จ",
          text: "กรุณาคืนสินค้าที่ฝ่ายสต็อค และเคลียร์เงินกับฝ่ายบัญชี",
          icon: "success",
          button: "ตกลง",
        });
        setShowButton(true);
        setOpen(false);

      }).catch((err) => {
        console.log(err);
        setOpen(false);
        swal({
          title: `${err.message}`,
          text: "กรุณาลองใหม่อีกครั้ง",
          icon: "error",
          button: "ตกลง",
        });
      });
     

    // }else{
      
    //   async function cashtransfer() {
     
    //     return await service.post({ url: `/api/v1/financial/cashtransfer`, params: { shipperID: shipperID, receiptAmount: receiptAmount1.toString(), paymentMethod: "cash_ThisPayment" } }).then((res) => {
    //       return res.data.result;
    //     })

    //   }
    //   async function cashtransfer2() {
       
    //     return await service.post({ url: `/api/v1/financial/cashtransfer`, params: { shipperID: shipperID, receiptAmount: receiptAmount2.toString(), paymentMethod: "cash_PreviousPayment" } }).then((res) => {
    //       return res.data.result;
    //     })
    //   }


    //   Promise.all([cashtransfer(), cashtransfer2()]).then((res) => {
    //     swal({
    //       title: "สำเร็จ",
    //       text: "กรุณาคืนสินค้าที่ฝ่ายสต็อค และเคลียร์เงินกับฝ่ายบัญชี",
    //       icon: "success",
    //       button: "ตกลง",
    //     });
    //     setShowButton(true);
    //     setOpen(false);
    //   }).catch((err) => {
    //     console.log(err);
    //     setOpen(false);
    //     swal({
    //       title: `${err.message}`,
    //       text: "กรุณาลองใหม่อีกครั้ง",
    //       icon: "error",
    //       button: "ตกลง",
    //     });
    //   });

    }
  }
  

  const modalCheck = () => {
    swal({
      title: "ยืนยันการเคลียร์เงิน",
      text: "ถ้ากดยืนยันแล้ว จะเปลี่ยนข้อมูลอีกไม่ได้ ท่านจะยืนยันว่าถูกต้องหรือไม่?",
      icon: "warning",
      buttons: ["ยกเลิก", "ยืนยัน"],
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          postApi();
        } 
      });
  }




  const noData = (
    <div className="shadow-sm" style={{
      width: '100%',
      textAlign: 'center',
      padding: '10px 0',
      height: '150px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#fff',
      border: ' 2px solid #eee',


    }}> ไม่มีรายการสินค้าคืน </div> 
  )



  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") 
        return;
}

  
  return (
    <div style={{
      marginTop: '1rem',
    }}>
      <ItemDetail3>
        <div className='shadow-sm ItemDetail_title'>
          5 รายการสินค้าคืน
        </div>

     

        {
          returnProduct.length > 0 ? returnProduct.map((item, index) => {
            return (

              <div className='shadow-sm ItemDetail_filex' key={index}>
                <div className='ItemDetail_filex__title_1'>
                  {item.productNameShort}
                </div>
                <div className='ItemDetail_filex__title_2'>
                  {item.quantity} ชิ้น
                </div>
              </div>

            )
          }) : noData

        }

     

      </ItemDetail3>


        {
          // loading1  loading2  loading3 เป็น false ทุกตัว
          <BittonGreen>
          {showButton === true || orderCancel > 0 ? null : <Button className='btn shadow-sm' onClick={() => modalCheck()} >ยืนยันรายละเอียดทุกอย่างถูกต้อง</Button>}
        </BittonGreen>
        }

     
     
 
<Backdrop
        onClose={handleClose}
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={()=> setOpen(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

    </div>

  )
}

export default MoneyDetail3