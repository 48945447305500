import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';



function SearchableTextField({ value, setValue, searchableOptions, label, error, setError, setSaleID, type }) {

  return (
    <>
    <Autocomplete
    value={value}
    onChange={(event, newValue) => {
        console.log("newValue", newValue);
         setValue(newValue);
         setError(newValue ? null : error)
      
    }}
    freeSolo // ทำให้สามารถเพิ่มค่าที่ไม่มีในรายการได้
    options={searchableOptions} // รายการที่สามารถค้นหาได้
    getOptionLabel={(option) => option.value}

    filterOptions={(options, state) => {
      const inputValue = state.inputValue.toLowerCase();
      return options.filter((option) =>
        option.value.toLowerCase().includes(inputValue)
      );
    }}
    renderInput={(params) => (
      <TextField {...params} 
      label={label} 
      variant="outlined"
      autoFocus 
      fullWidth 
      required   
      margin="normal"
      error={error ? true : false}
     
      sx={{ m:0, backgroundColor: "#dbe9ff", width: "100%" }}
      onChange={(e) => {
        setError(e.target.value ? null : error);
      }}
      />
    )}
  />
  <div style={{color: '#dc3545', fontSize: '0.75rem', marginTop: '0.25rem'}}>{error}</div>
</>
  );
}

export default SearchableTextField;
