import React from 'react'

import QrScanner from 'qr-scanner'; 

 
import jsQR from 'jsqr';



function TestQrCode() {

    

    const [qr1, setQr1] = React.useState(null)
    
    const [qr2, setQr2] = React.useState(null)
    const [qr3, setQr3] = React.useState(null)

    const checkImage1 = (e) => {
 
        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        
        fetch("https://api.qrserver.com/v1/read-qr-code/", {
            method: "POST",
            body: formData,
        }).then((res) => res.json()).then((r) => {
            const resultQR = r[0].symbol[0].data;
            setQr1(resultQR ? resultQR : "error")
            console.log(resultQR)
            alert(resultQR)
        }).catch(err => {
            console.log(err.message)
            setQr1("error")
            alert(err)
        } )

    }

    const checkImage2 = (e) => {
         // อ่าน QR Code จากภาพ QrScanner
            QrScanner.scanImage(e.target.files[0])
            .then(result => {
                console.log(result)
                setQr2(result)
                alert(result)
            })
            .catch(err => {
                console.log(err.message)
                setQr2("error")
                alert(err)
            } )


    }


    const checkImage3 = (e) => {
        // อ่าน QR Code จากภาพ QrReader
        const reader = new FileReader()
        reader.onload = () => {
            const img = new Image()
            img.onload = () => {
                const canvas = document.createElement('canvas')
                const canvasContext = canvas.getContext('2d')
                canvas.width = img.width
                canvas.height = img.height
                canvasContext.drawImage(img, 0, 0, canvas.width, canvas.height)
                const imageData = canvasContext.getImageData(0, 0, canvas.width, canvas.height)
                const code = jsQR(imageData.data, imageData.width, imageData.height)
                if (code) {
                    console.log(code.data)
                    setQr3(code.data)
                    alert(code.data)
                } else {
                    console.log("error")
                    setQr3("error")
                    alert("error")
                }
            }
            img.src = reader.result
        }
        reader.readAsDataURL(e.target.files[0])

    }



 

  return (
    <div className='d-flex justify-content-center align-items-center' style={{height:'100vh'}}>
 

    <div className='d-flex flex-column justify-content-center align-items-center'> 

 <div className='mb-3'>
  <button style={{ display: 'block', width: 120, height: 30,
   backgroundColor: qr1 === "error" ? "red" : qr1 ? "green" : "white",
   color: qr1 === "error" ? "white" : qr1 ? "white" : "black"
 }} onClick={() => {
     document.getElementById('getFile').value = null
     document.getElementById('getFile').click()
     }}> read-qr-code </button>
  <input type="file" id="getFile" style={{display: 'none'}} onChange={checkImage1} />
  </div>


  <div className='mb-3'>
  <button style={{display: 'block', width: 120, height: 30, backgroundColor: qr2 === "error" ? "red" : qr2 ? "green" : "white",  color: qr2 === "error" ? "white" : qr2 ? "white" : "black"}} onClick={() => {
     document.getElementById('getFile').value = null
     document.getElementById('getFile2').click()
     }}>
  QrScanner
  </button>
  <input type="file" id="getFile2" style={{display: 'none'}} onChange={checkImage2} />
  </div>


  <div>
  <button style={{display: 'block', width: 120, height: 30, backgroundColor: qr3 === "error" ? "red" : qr3 ? "green" : "white",  color: qr3 === "error" ? "white" : qr3 ? "white" : "black"}} onClick={() => {
     document.getElementById('getFile').value = null
     document.getElementById('getFile3').click()
     }}>
    jsqr
  </button>
  <input type="file" id="getFile3" style={{display: 'none'}} onChange={checkImage3} />
  </div>

  </div>

 
    </div>
  )
}

export default TestQrCode