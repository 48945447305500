import React from 'react'
import Box from '@mui/material/Box';

import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import FormHelperText from '@mui/material/FormHelperText';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import LaunchIcon from '@mui/icons-material/Launch';
function SalesReportGeolocation({
    error,
    setError,
    salesReportGeolocation,
    setSalesReportGeolocation,
    label,
}) {

  const showError = (error) => {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        setError("ผู้ใช้ปฏิเสธคำขอสำหรับตำแหน่งที่ตั้ง.");
  
        break;
      case error.POSITION_UNAVAILABLE:
        setError("ข้อมูลตำแหน่งไม่สามารถให้ได้.");
  
        break;
      case error.TIMEOUT:
        setError("คำขอเพื่อรับตำแหน่งของผู้ใช้หมดเวลา.");
        break;
      case error.UNKNOWN_ERROR:
        setError("เกิดข้อผิดพลาดที่ไม่รู้จัก.");
        break;
    }
  };
  
  function getLocation() { 
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          console.log("position", position);
          setError(null);
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;
          setSalesReportGeolocation(lat + "," + lng);

        },
        showError,
        {
          enableHighAccuracy: true,
        }
      );
    } else {
      setError("เบราว์เซอร์นี้ไม่รองรับการระบุตำแหน่ง.");
    }
  }
  

  return (
    <Box sx={{  width: "100%" }}>
    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <Box >
        <LoadingButton
        fullWidth
        size="large"
        loadingPosition="start"
        onClick={getLocation}
        variant="outlined"
        color={error ? "error" : "secondary"}
        startIcon={<LocationOnIcon sx={{ fontSize: "2rem", color: error ? "error" : "secondary" }} />}
        sx={{
          textTransform: "none",
          width: "400px",
           '@media only screen and (min-width: 480px) and (max-width: 767px)':{
            width: "350px",
           },
            '@media only screen and (min-width: 320px) and (max-width: 479px)':{
              width: "100%",
            },
            
        }}

      >
        

        {
          salesReportGeolocation ? (
            <Typography variant="h7" component="p" noWrap fullWidth>
            {
              `${salesReportGeolocation}`
            }
          </Typography>
          ) :  <Typography variant="h7" component="p" sx={{ fontSize: "1rem", color:  error ? "error" : "secondary", marginLeft: "3.5rem", marginRight: "3.5rem"
          }} fullWidth>
          { label }  
         </Typography>
        
        }
      </LoadingButton>
      <FormHelperText error={error ? true : false}>
            {error ? error : null}
        </FormHelperText>
        </Box>

        <Box>


        <LaunchIcon sx={{ color: !salesReportGeolocation ? 'error' : 'secondary', fontSize: "2rem" }} onClick={()=>{
          //open new tab https://www.google.com/maps/search/?api=1&query=[lat]%2C[long] 
          const lat = salesReportGeolocation?.split(",")[0] || "";
          const lng = salesReportGeolocation?.split(",")[1] || "";
          window.open(`https://www.google.com/maps/search/?api=1&query=${lat}%2C${lng}`, '_blank').focus();
        }}/>


        {/* <LoadingButton
        fullWidth
        size="large"
        loadingPosition="start"
        onClick={()=>{
          //open new tab https://www.google.com/maps/search/?api=1&query=[lat]%2C[long]
          const lat = salesReportGeolocation?.split(",")[0] || "";
          const lng = salesReportGeolocation?.split(",")[1] || "";
          window.open(`https://www.google.com/maps/search/?api=1&query=${lat}%2C${lng}`, '_blank').focus();
        
        }}
        variant="outlined"
        color={error ? "error" : "secondary"}
        sx={{
          textTransform: "none",
          marginLeft: ".5rem"
        }}
      >
     
 
      </LoadingButton> */}
        </Box>
       </Box>
      
    </Box>
  )
}

export default SalesReportGeolocation