import React, { useState, useEffect } from "react";
import { TitleContent2, ItemDetailCansel, ItemDetail2, ButtonOrange, ButtonBrue, ItemDetailOther } from "./style.module";
import Button from '@material-ui/core/Button';
import { useHistory, useParams } from 'react-router-dom';

import apiService from "../../api/apiService";
import swal from "@sweetalert/with-react";


function MoneyDetail2({setLoading3,  data, setReceiptAmount2, orderCancel, orderOther1, orderOther2, orderOther3 }) {

  const { shipperID, date } = useParams();
  const service = apiService();
  const history = useHistory();
  const [CT, setCT] = useState(0);



  useEffect(() => {
   
    async function getListCashtransfer() {
      return await service.get({ url: `/api/v1/financial/list-cashtransfer?shipperID=${shipperID}&shippingDate=${date}` }).then((res) => {
        return res.data.result;
      })
    }


    async function getListCashtransferByShipper() {
      return await service.get({ url: `/api/v1/order/list-condition-by-shipper?shipperID=${shipperID}&shippingDate=${date}` }).then((res) => {
        return res.data.result;
      })
    }


    Promise.all([getListCashtransfer(), getListCashtransferByShipper()]).then((res) => {
  

      const newCT = res[0].length > 0 ? res[0].filter((item) => { return ["transfer_PreviousPayment", "ATM_PreviousPayment", "depositSlip_PreviousPayment", "cheque_PreviousPayment", "otherTransfer_PreviousPayment"].includes(item.paymentMethod) }).reduce((sum, item) => { return sum + item.receiptAmount }, 0) : 0

      // 16+16.6+16.9+17+20.9+21
      const newTotalCash2 = res[1].filter((item) => { return [16, 16.6, 16.9, 17, 20.9, 21].includes(item.invoiceCondition) }).reduce((sum, item) => sum + item.totalPrice, 0);
      setCT(newCT);
      setReceiptAmount2(newTotalCash2 - newCT < 0 ? 0 : newTotalCash2 - newCT);

      setLoading3(false);
       
    }).catch((err) => {
      console.log(err);
      swal({
        title: `${err.message}`,
        text: "กรุณาลองใหม่อีกครั้ง",
        icon: "error",
        button: "ตกลง",
      });
    })


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  // 16+16.6+16.9+17+20.9+21 ยอดเงินสดทั้งหมด
  const totalCash2 = data.filter((item) => { return [16, 16.6, 16.9, 17, 20.9, 21].includes(item.invoiceCondition)}).reduce((sum, item) => sum + item.totalPrice, 0)

 
  // 14+14.6+15+19 จำนวนออเดอร์ทั้งหมด
  const totalOrder2 = data.filter((item) => { return [14, 14.6, 15, 19].includes(item.invoiceCondition)  }).length
 
  // 15+19 ออเดอร์ที่จ่ายแล้ว
  const totalOrder12 = data.filter((item) => { return [15, 19].includes(item.invoiceCondition) }).length
  // 14 ออเดอร์ที่ยังไม่จ่าย
  const totalOrder14 = data.filter((item) => { return  [14].includes(item.invoiceCondition)}).length

 

  return (

    <div>
      {/* <TitleContent2>
        <h1 className="shadow-sm">2. ลูกค้าที่จ่ายงวดก่อน</h1>
      </TitleContent2> */}

{/* 
      <ItemDetail2>
        <div className='shadow-sm ItemDetail_title'>
          2.1 ลูกค้าจ่ายเป็นเงินสด
        </div>  

        <div className='shadow-sm ItemDetail_filex'>
          <div className='ItemDetail_filex__title_1'>
            ยอดเงินสดทั้งหมด
          </div>
          <div className='ItemDetail_filex__title_2'>
            {totalCash2 ? totalCash2.toLocaleString() : 0} บาท
          </div>
        </div>
        <div className='shadow-sm ItemDetail_filex'>
          <div className='ItemDetail_filex__title_1'>
            สายส่งโอนเข้าบริษัท
          </div>
          <div className='ItemDetail_filex__title_2'>
            {CT ? CT.toLocaleString() : 0} บาท
          </div>
        </div>

        <div className='shadow-sm ItemDetail_filex_bg'>
          <div className='ItemDetail_filex__title_1'>
            เหลือเงินสดต้องชำระ
          </div>
          <div className='ItemDetail_filex__title_2'>
            
            {(totalCash2 - CT) ? totalCash2 - CT < 0 ? 0 : (totalCash2 - CT).toLocaleString() : 0} บาท
          </div>
        </div>
      </ItemDetail2>
      <ButtonBrue>
        <Button 
        className='btn shadow-sm' 
          onClick={() => {
            history.push(`/cashTransferPreviousPayment/${shipperID}/${date}`);
          }}
        >สายส่งแนบสลิปที่โอนเงินเข้าบริษัท</Button>

      </ButtonBrue>


      <ItemDetail2 style={{
        marginTop: '20px'
      }}>
        <div className='shadow-sm ItemDetail_title'>
          2.2 ลูกค้าโอนเงินเข้าบริษัท
        </div>

        <div className='shadow-sm ItemDetail_filex'>
          <div className='ItemDetail_filex__title_1'>
            จำนวนออเดอร์ทั้งหมด
          </div>
          <div className='ItemDetail_filex__title_2'>
         {totalOrder2 ? totalOrder2.toLocaleString() : 0} ออเดอร์
          </div>
        </div>


        <div className='shadow-sm ItemDetail_filex'>
          <div className='ItemDetail_filex__title_1'>
            ออเดอร์ที่จ่ายแล้ว
          </div>
          <div className='ItemDetail_filex__title_2'>
            {totalOrder12 ? totalOrder12.toLocaleString() : 0} ออเดอร์
          </div>
        </div>


        <div className='shadow-sm ItemDetail_filex_bg'>
          <div className='ItemDetail_filex__title_1'>
            ออเดอร์ที่ยังไม่จ่าย
          </div>
          <div className='ItemDetail_filex__title_2'>
            {totalOrder14 ? totalOrder14.toLocaleString() : 0} ออเดอร์
          </div>
        </div>

      </ItemDetail2>

      <ButtonOrange>
        <Button className='btn shadow-sm' onClick={() => history.push(`/checkInvoice/${shipperID}/${date}`)}>ตรวจสอบออเดอร์ที่ยังไม่จ่าย</Button>
      </ButtonOrange>


      <ItemDetailOther style={{
        marginTop: '5rem'
      }} >
        <div className='shadow-sm ItemDetail_title'>
          3 ออเดอร์อื่นๆ
        </div>

        <div className='shadow-sm ItemDetail_title_filex'> 
          ยังไม่ชำระเงิน เพราะยังไม่ถึง due
        </div>

        <div className='shadow-sm ItemDetail_filex'>
          <div className='ItemDetail_filex__title_1'>
            จำนวนออเดอร์
          </div>
          <div className='ItemDetail_filex__title_2'>
            {orderOther1.length ? orderOther1.length.toLocaleString() : 0} ออเดอร์
          </div>
        </div>

        <div className='shadow-sm ItemDetail_filex'>
          <div className='ItemDetail_filex__title_1'>
            จำนวนเงิน
          </div>
          <div className='ItemDetail_filex__title_2'>
            {orderOther1.length ? orderOther1.reduce((a, b) => a + b.totalPrice, 0).toLocaleString() : 0} บาท
          </div>
        </div>


        <div className='shadow-sm ItemDetail_title_filex'>
          ออเดอร์ที่ยกเลิก
        </div>
        <div className='shadow-sm ItemDetail_filex'>
          <div className='ItemDetail_filex__title_1'>
            จำนวนออเดอร์
          </div>
          <div className='ItemDetail_filex__title_2'>
            {orderOther2.length ? orderOther2.length.toLocaleString() : 0} ออเดอร์
          </div>
        </div>

        <div className='shadow-sm ItemDetail_filex'>
          <div className='ItemDetail_filex__title_1'>
            จำนวนเงิน
          </div>
          <div className='ItemDetail_filex__title_2'>
            {orderOther2.length ? orderOther2.reduce((a, b) => a + b.totalPrice, 0).toLocaleString() : 0} บาท
          </div>
        </div>

        <div className='shadow-sm ItemDetail_title_filex'>
          ยอดค้าง เลยกำหนดชำระแล้ว
        </div>
        <div className='shadow-sm ItemDetail_filex'>
          <div className='ItemDetail_filex__title_1'>
            จำนวนออเดอร์
          </div>
          <div className='ItemDetail_filex__title_2'>
            {orderOther3.length ? orderOther3.length.toLocaleString() : 0} ออเดอร์
          </div>
        </div>

        <div className='shadow-sm ItemDetail_filex'>
          <div className='ItemDetail_filex__title_1'>
            จำนวนเงิน
          </div>
          <div className='ItemDetail_filex__title_2'>
            {orderOther3.length ? orderOther3.reduce((a, b) => a + b.totalPrice, 0).toLocaleString() : 0} บาท
          </div>
        </div>

      </ItemDetailOther> */}


      <ItemDetailCansel style={{
        marginTop: '20px'
      }}> 
      
        <div className='shadow-sm ItemDetail_title'>
          4 ออเดอร์ที่ยังไม่ส่ง
        </div>

        <div className='shadow-sm ItemDetail_filex'>
          <div className='ItemDetail_filex__title_1'>
            ออเดอร์ที่ยังไม่ส่ง
          </div>
          <div className='ItemDetail_filex__title_2'>
            {orderCancel ? orderCancel.toLocaleString() : 0} ออเดอร์
          </div>
        </div>
      

      </ItemDetailCansel>

    </div>
  );
}

export default MoneyDetail2;
