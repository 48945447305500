 

import React from 'react'
import API from "../constants/serviceAPI.json";
import swal from "@sweetalert/with-react";
import liff from "@line/liff/dist/lib";
 

function Logout() {

  // heard

  return (
    <div className='d-flex justify-content-center align-items-center' style={{height:'100vh'}}>
    <button className='btn btn-danger'
     onClick={() => {
      document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      localStorage.clear();
      swal({
        title: "สำเร็จ!",
        text: "ออกจากระบบสำเร็จ",
        icon: "success",
        button: "ตกลง",
      }).then((value) => {
          liff.closeWindow();
      });
    }}>
      ออกจากระบบ
    </button>
    
    </div>

  )
}

export default Logout