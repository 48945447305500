import styled from 'styled-components';


const Counter = styled.div`
  background-color: #f9ecff;
  padding-bottom: 3rem;
  padding-top: 1.5rem;
  width: 100%;
  min-height: 100vh;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
 
  .title {
    font-size: 24px;
    font-weight: 600;
    border-bottom: 2px solid #1a1a1a;
    width: 500px;
    text-align: center;
    color: #1a1a1a;
    margin: 0 auto;
     height: auto;
   margin-bottom: 0.5rem;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;

const List = styled.div`
display: flex;
justify-content: center;
  .list {
    width: 500px;
       border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    margin-top: 1rem;
    .list__title {
      font-size: 16px;
       text-align: center;
       color: #1a1a1a;
       background-color: #fff;
       padding: 0.5rem;
       border-bottom: 2px solid #eee;
    }

    .list__detail {
      display: flex;
      justify-content: space-between;
       padding: 0.5rem;
        background-color: #fff;
      .list__detail__title {
        font-size: 16px;
        color: #1a1a1a;
       
      }
    }

    .list__qty {
      display: flex;
      justify-content: flex-end;
      padding: 0.5rem;
      background-color: #f7a4d8;
      border-radius: 0 0 10px 10px;
      .list__qty__title {
        font-size: 16px;
        color: #1a1a1a;
      }
    }



    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;



const Payment = styled.div`
  width: 500px;
  margin: 0 auto;
  margin-top: .7rem;
  border-radius: 10px;
  padding: 1rem;
  .Payment {
    .Payment__title {
      font-size: 18px;
      color: #1a1a1a;
    }
    .Payment__detail {
      font-size: 18px;
      color: #1a1a1a;
      text-align: right;
    }
  }
  @media (max-width: 768px) {
    width: 100%;
  }

`;



const PaymentText = styled.div`
  width: 500px;
  margin: 0 auto;
  margin-top: 2.5rem;
  border-radius: 0px;
  padding: 1rem;
  text-align: center;
  border: 1px solid #9f98a3;
  cursor: pointer;
  background-color: #f9ecff;
  

  .Payment {  
      
    .Payment__title {
      font-size: 16px;
       font-weight: unset;
      color: #1a1a1a;
    }
    .Payment__detail {
      font-size: 16px;
       font-weight: unset;
      color: #1a1a1a;
    }
    .Payment__detail_dank{
      font-size: 16px;
       font-weight: unset;
      color: #1a1a1a;
    }
    .Payment__detail_dank_number{
      font-size: 20px;
  
      margin-bottom: 0.3rem;
      color: #15a251;
    }
    .Payment__detail_coppy{
        font-size: 16px;
       font-weight: unset;
      color: #1a1a1a;
      
    }
  }



  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Upload = styled.div`
  width: 250px;
  height: 350px;
  margin: 0 auto;
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0px;
  background-color: #fff;
  border: 1px solid #9f98a3;
  cursor: pointer;
   
  div{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  &:hover {
    div .Upload_title{
      color: #2652f9;
    }
    div svg{
      color: #2652f9;
    }
  border: 1px solid #2652f9;

  }

  &:active {
     div .Upload_title{
      color: #2652f9;
    }
    div svg{
      color: #2652f9;
    }
  border: 1px solid #2652f9;
  }

  @media (max-width: 768px) {
     width: 200px;
    height: 300px;
  }

 
  }
`;

const Button = styled.div`
  width: 500px;
  margin: 0 auto;
  .Button {
      width: 500px;
  margin: 0 auto; 
  margin-top: 1.5rem;
  border-radius: 10px;
  font-size: 18px;
  padding: 0.4rem;
  background-color: #2652f9;
  border: 1px solid #fff;
  font-weight: 600;
  transition: all 0.3s ease;
  color: #fff;
  @media (max-width: 768px) {
    width: 100%;
  }
 
    
    &:active {
      background-color: #0a3cfc;
      color: #fff;
      border: 1px solid #2652f9;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;


const FileImage = styled.div`
  width: 250px;
  height: 100%;
  margin: 0 auto;
  position: relative;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
    @media (max-width: 768px) {
     width: 200px;
    
    margin-bottom: 1rem;
  }
`

const SwalFooter = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .SwalFooter__title {
    width:100%;
    font-size: 18px;
    color: #1a1a1a;
    border-bottom: 2px solid #eee;
    padding-bottom: 0.5rem;
  }
  .SwalFooter__detail{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 1rem;  
    padding-bottom: 0rem;
    color: #1a1a1a;
    .SwalFooter__detail__icon{
      margin-right: 1rem;
    }
  }
`;

const UploadFileContent = styled.div`
  width: 100%;
  height: 100%;

`;

export {
  Counter,
  List,
  Payment,
  PaymentText,
  Upload,
  Button,
  FileImage,
  SwalFooter,
  UploadFileContent,
}
