import Toastify from 'toastify-js'
import "toastify-js/src/toastify.css"
import swal from "@sweetalert/with-react";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CallIcon from '@mui/icons-material/Call';
import styled from 'styled-components';

import { SwalFooter } from '../../paymentAmount/payment.module'
 
const PaymentText = styled.div`
  width: 300px;
  margin: 0 auto;
  margin-top: 2.5rem;
  border-radius: 0px;
  padding: 1rem;
 
  border: 1px solid #9f98a3;
  
  background-color: #f9ecff;
  

  .Payment {  
      text-align: left;
     
    .Payment__title {
      font-size: 16px;
       font-weight: 800;
      color: #1a1a1a;
    }
    .Payment__detail {
      font-size: 16px;
       font-weight: unset;
      color: #1a1a1a;
    }
    .Payment__detail_dank{
      font-size: 16px;
       font-weight: unset;
      color: #1a1a1a;
    }
    .Payment__detail_dank_number{
      font-size: 20px;
  
      margin-bottom: 0.3rem;
      color: #15a251;
    }
    .Payment__detail_coppy{
        font-size: 16px;
       font-weight: unset;
      color: #1a1a1a;
      
    }
  }



  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const ToastifyError = (message) => {
  Toastify({
    text: message,
    duration: 3000,
    close: false,
    gravity: "top",
    position: "center",
    backgroundColor: "linear-gradient(to right, #ff416c, #ff4b2b)",
    stopOnFocus: false,
  }).showToast();
}

export const ToastifyGreen = (message) => {
  Toastify({
    text: message,
    duration: 3000,
    close: false,
    gravity: "top",
    position: "center",
    backgroundColor: "linear-gradient(to right, #00b09b, #96c93d)",
    stopOnFocus: false,
  }).showToast();
}
export const ToastifyPurple = (message) => {
    Toastify({
      text: message,
      duration: 3000,
      close: false,
      gravity: "top",
      position: "center",
      backgroundColor: "linear-gradient(to right, #6a3093, #a044ff)",
      stopOnFocus: false,
    }).showToast();
  };

export const Toastifys = (message) => {
  Toastify({
    text: message,
    duration: 3000,
    close: false,
    gravity: "top",
    position: "center",
    backgroundColor: "#888",
    stopOnFocus: false,
  }).showToast();
}


export async function copyToClipboard(bank) {
  await navigator.clipboard.writeText(bank);
  if (bank === "347-404461-8") {
    ToastifyPurple("คัดลอกเลขบัญชีธนาคารสำเร็จ");
    document.querySelector(".PaymentText").style.border = "1px solid #6a3093";
    setTimeout(() => {
      document.querySelector(".PaymentText").style.border = "1px solid #9f98a3";
    }, 500);

  } else {
    ToastifyGreen("คัดลอกเลขบัญชีธนาคารสำเร็จ");
    document.querySelector(".PaymentText").style.border = "1px solid #15a251";
    setTimeout(() => {
      document.querySelector(".PaymentText").style.border = "1px solid #9f98a3";
    }, 500);

  }
}


export const swalChange = (state, bank, customerName, phone, lineGroupURL) => {
  if (state === false) {

    const _phone = () => {
      if(phone !== null){
        window.open(`tel:${phone}`)
      }else{
        return null
      }
    }

    const _lineGroupURL = () => {
      if(lineGroupURL !== null){
        window.open(lineGroupURL)
      }else{
        return null
      }
    }




 
    swal({
      //  closeOnClickOutside: false,
      buttons: ["ปิด", "ตกลง"],
      className: 'swal-payment',
      title: "สลิปของท่านไม่ถูกต้อง",
      text: "กรุณาตรวจสอบ และแนบสลิปอีกครั้ง",
      icon: "error",
      content: (
        <div>
         
            <PaymentText
              style={{
                marginTop: 0,
                backgroundColor: '#f9ecff',
                width: '100%',
              }}
              className='shadow-sm'  >
              <div className='Payment'>
                <div className='Payment__title'>
                สาเหตุอาจเกิดจาก
                </div>
                <div className='Payment__detail'>
                1 สลิปปลอม <br />
                2 สลิปถูกใช้งานไปแล้ว <br />
                3 หมายเลขบัญชีไม่ถูกต้อง
                </div>

                 
              </div>
            </PaymentText>

 
          <SwalFooter>
            <div className='SwalFooter__title'>
              หากท่านยังมีปัญหา ให้แจ้งลูกค้าทันทีที่
            </div>

            <div className='SwalFooter__detail' style={{
              marginBottom: '0.3rem',
            }}>
              <div className='SwalFooter__detail__icon'>
                <AccountCircleIcon sx={{
                  fontSize: 35,
                  color: '#1a1a1a',
                }} />
              </div>

              <div className='SwalFooter__detail__title'  >
                {customerName}
              </div>
            </div>

            <div className='SwalFooter__detail' style={{ marginBottom: '0.3rem', }} onClick={_phone}>
              <div className='SwalFooter__detail__icon'>
                <CallIcon
                  sx={{
                    fontSize: 35,
                    color: '#1a1a1a',
                  }} />
              </div>

            
              {phone !== null ? <div className='SwalFooter__detail__title' style={{
                color: '#0a3cfc',
                cursor: 'pointer',
                 

              }}>{phone} </div> : <div className='SwalFooter__detail__title' >
                ไม่มีเบอร์โทร
              </div>}
 
            </div>

            <div className='SwalFooter__detail' onClick={_lineGroupURL}>
              <div className='SwalFooter__detail__icon'>
                 
                <svg xmlns="http://www.w3.org/2000/svg" aria-label="LINE" role="img" viewBox="0 0 512 512" style={{
                  width: '35px',
                  height: '35px',
                }}><rect width={512} height={512} rx="15%" fill="#00B900" /><path d="m443 231c-2 45-21 76-51 103-53 47-137 105-148 96-11-14 21-47-20-52-88-12-155-74-155-147 0-82 85-150 188-150s189 68 186 150z" fill="#fff" /><path d="m371 232h-34m34-36h-36v72h36m-123 0v-72l54 72v-72m-89 72v-72m-66 0v72h37" fill="none" stroke="#00B900" strokeLinecap="round" strokeLinejoin="round" strokeWidth={21} /></svg>


              </div>

              {lineGroupURL !== null ? <div className='SwalFooter__detail__title' style={{
                color: '#0a3cfc',
                cursor: 'pointer',

              }}>
                {lineGroupURL}
              </div> : <div className='SwalFooter__detail__title'>
                ไม่มี Line Group
              </div>}


            </div>
            
          </SwalFooter>


        </div>
      )
    })
  }
}



export const swalSuccess = (title, message) => {
  // title = ท่านชำระเงินถูกต้อง
  // message = คำสั่งซื้อของคุณลูกค้าเรียบร้อยแล้วค่ะ

  swal({
    title: title,
    text: message,
    icon: "success",
    button: "ตกลง",
  });
  


}