import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Card from '../../components/checkout/card';
import Grid from '@mui/material/Grid';
import { useParams } from 'react-router-dom';
import { getUser, getProductList, getSellingPriceLevelList } from '../../components/checkout/api';
import AppBar from '../../components/checkout/appBar';
import Loading from '../../components/checkout/loading';
 

const productType = ["sample"]

export default function Orders() {
  const { shippingDate } = useParams();
  const [productList, setProductList] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [shipperID, setShipperID] = React.useState(0);

  React.useEffect(() => {

    Promise.all([getUser(), getProductList(), getSellingPriceLevelList()]).then((res) => {
      const [user, productList, sellingPriceLevelList] = res;
      const shipperID = user.userID;
      const filterProductList = productList.filter((item) => { return productType.includes(item.productType) })
       // ใส่ราคาให้ product
       const productWithPrice = filterProductList.map((item) => {
        return {
          ...item,
          price: sellingPriceLevelList.filter((priceItem) => { return priceItem.productID === item.productID})[0]?.price || 0,
          // ใส่จำนวนที่สั่งซื้อได้
          quantity: 0,
        }
      })

      setProductList(productWithPrice)
      setShipperID(shipperID)
      setLoading(false)

    });

  }, [shippingDate])

 
  return (
    <React.Fragment>
      <Box
        sx={{
          minHeight: "100vh",
          backgroundColor: `#ffe4e1`,
          paddingBottom: `76px`,
          paddingTop: `0px`,
        }}
      >
        <CssBaseline />

        <AppBar
          productList={productList}
          setProductList={setProductList}
          shippingDate={shippingDate}
          shipperID={shipperID}
          pages={"checkout-sample"}
        />

        <Container>
          {loading ? (
            <Loading pages={"checkout-sample"}/>
          ) : (
            <Grid container spacing={2} sx={{ my: 2, mb: 0, mt: 0 }}>
              {productList.sort((a, b) => { return a.productSequence - b.productSequence}).map((item, index) => {
                return (
                  <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
                    <Card
                      item={item}
                      setProductList={setProductList}
                      productList={productList}
                    />
                  </Grid>
                );
              })}
            </Grid>
          )}
        </Container>
      </Box>
    </React.Fragment>
  );
}
