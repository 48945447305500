/* eslint-disable no-self-compare */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Navbar } from "../../styles/themes/NavBar.module";
import { useSelector, useDispatch } from "react-redux";
import Offcanvas from "react-bootstrap/Offcanvas";
import { SectionShopDeta } from "../../styles/themes/ShopDeta.module";
import { useHistory, useLocation } from "react-router-dom";
import apiService from "../../api/apiService";
import liff from "@line/liff/dist/lib";
import { Paper } from "@material-ui/core";


function NavBar() {
  const dispatch = useDispatch();
  const service = apiService();
  const useShopReducer = useSelector((state) => state.useShopReducer);
  const useCartReducer = useSelector((state) => state.useCartReducer);
  const useNumberOfHeartsReducer = useSelector((state) => state.useNumberOfHeartsReducer);
  const history = useHistory();
  const location = useLocation();
  
 
  const addtocart = (e) => {
    const id = e.รหัสสินค้า;
    const name = e.สินค้า;
    const price = e.ราคา;
    const image = e.รูปภาพ;
    const amount = e.จำนวน;
    const heart = e.heart;
    const Category = e.หมวด;
    const details = e.รายละเอียด;

    if(id === 18 || id === 19){
      //  ให้สินค้า ปลาเส้นทอด (productID = 18) และปลาเส้นขาว (productID = 19) ให้เพิ่มทีละ 5 หน่วย
      if (useCartReducer.length > 0) {

        Promise.all(
          useCartReducer.map((item) => {
            if (item.id === id) {
              dispatch({
                type: "UPDATE_CART_DATA",
                payload: {
                  id: id,
                  รหัสสินค้า: id,
                  สินค้า: name,
                  ราคา: price,
                  รูปภาพ: image,
                  จำนวน: item.จำนวน + amount,
                  ราคารวม: (item.จำนวน + 5) * price,
                  หมวด: Category,
                  heart: heart,
  
                  รายละเอียด: details,
                },
              });
            } else if (item.id !== id) {
              dispatch({
                type: "ADD_CART_DATA",
                payload: {
                  id: id,
                  รหัสสินค้า: id,
                  สินค้า: name,
                  ราคา: price,
                  รูปภาพ: image,
                  จำนวน: 5,
                  ราคารวม: price * 5,
                  หมวด: Category,
                  heart: false,
  
                  รายละเอียด: details,
                },
              });
            }
          })
        );
      } else {
        dispatch({
          type: "ADD_CART_DATA",
          payload: {
            id: id,
            รหัสสินค้า: id,
            สินค้า: name,
            ราคา: price,
            รูปภาพ: image,
            จำนวน: 5,
            ราคารวม: price * 5,
            หมวด: Category,
            heart: false,
            รายละเอียด: details,
          },
        });
      }
    } else {
      if (useCartReducer.length > 0) {

        Promise.all(
          useCartReducer.map((item) => {
            if (item.id === id) {
              dispatch({
                type: "UPDATE_CART_DATA",
                payload: {
                  id: id,
                  รหัสสินค้า: id,
                  สินค้า: name,
                  ราคา: price,
                  รูปภาพ: image,
                  จำนวน: item.จำนวน + amount,
                  ราคารวม: item.ราคารวม + price,
                  หมวด: Category,
                  heart: heart,
  
                  รายละเอียด: details,
                },
              });
            } else if (item.id !== id) {
              dispatch({
                type: "ADD_CART_DATA",
                payload: {
                  id: id,
                  รหัสสินค้า: id,
                  สินค้า: name,
                  ราคา: price,
                  รูปภาพ: image,
                  จำนวน: 1,
                  ราคารวม: price,
                  หมวด: Category,
                  heart: false,
  
                  รายละเอียด: details,
                },
              });
            }
          })
        );
      } else {
        dispatch({
          type: "ADD_CART_DATA",
          payload: {
            id: id,
            รหัสสินค้า: id,
            สินค้า: name,
            ราคา: price,
            รูปภาพ: image,
            จำนวน: 1,
            ราคารวม: price,
            หมวด: Category,
            heart: false,
  
            รายละเอียด: details,
          },
        });
      }
    }
  };



 
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const showfavorite = () => {
    setShow(true);
      
  };






  async function getSellingPrice () {
    const user = await service.get({ url: '/api/v1/selling-price-level/list' });
    return user.data.result
  }


async function getUserID() {
  const user = await service.get({ url: '/api/v1/user' });

  return user.data.result;
}

async function getOrder(userID) {
  const order = await service.get({ url: `/api/v1/order/list-by-customer?customerID=${userID}`});
  return order.data.result;
}

async function getOrderDetail(e) {
  
  const orderID = e.filter((item) => item.orderType  === 'ORDER_LINE' || item.orderType === 'REORDER_LINE').map((item) => item.orderID);
  const orderDetail = await Promise.all(orderID.map(async (item) => {
      const order = await service.get({ url: `/api/v1/order/?orderID=${item}`});
      return order.data.result;
  }));
  return orderDetail;

 
}


useEffect(() => {
const product = service.get({url: `/api/v1/product/list`}).then((res) => {
      return res.data.result.filter((item) => item.productType === "aruneeFishballCustomer")
})

product.then((list) => {

  getSellingPrice().then((price) => {
    getUserID().then((res) => {

      

      dispatch({
        type: "SET_USER",
        payload: res,
      });

      getOrder(res.userID).then((e) => {
        getOrderDetail(e).then((x) => {
          console.log(e)
          console.log(x)
         
          dispatch({
            type: "SET_ORDER_DETAIL",
            payload: x.map((el, i) => {
              const orderType = e.filter((item) => item.orderID === el.orderID)[0].orderType;
              const reorder_line = orderType === "REORDER_LINE"
              
              return {
                key: i,
                orderID: el.orderID,
                orderDate: el.orderDate,
                shippedDate: el.shippedDate,
                orderStatus: el.orderStatus,
                stage: reorder_line ? 3 
                : el.ingStockRequestID === null ? 1 
                : el.orderDetailRecords[0]?.receivedQuantity === null ? 2 
                : 3,
                 

                orderDetailRecords: el.orderDetailRecords.map((item) => {
                  const purchasePrice = price.filter((e) => e.productID === item.productID);

                  return {
                    ...item,
                    orderID: item.orderID,
                    productID: item.productID,
                    purchasePrice: purchasePrice.length > 0 ? purchasePrice[0].price : 0,
                    imageURL: list.filter((x) => x.productID === item.productID).map((x) => x.imageURL),
                    productNameFull: list.filter((x) => x.productID === item.productID).map((x) => x.productNameFull),
                    quantity: item.quantity,
                    receivedQuantity: item.receivedQuantity,
                    totalPrice: price.filter((e) => e.productID === item.productID).length > 0 ? price.filter((e) => e.productID === item.productID)[0].price * item.quantity : 0,
                   
                  }
                }),
              }
            })
          });

          dispatch({
            type: "SET_ORDER_DETAIL_LOADING",
            payload: false,
          });




        }).catch((err) => {
          console.log(err);
          
        })
      }).catch((err) => {
        console.log(err);
      
      })



    }).catch((err) => {
      console.log(err);
     
    });
  })
    
     
 
         
  }).catch((err) => {
    console.log(err);
   });

  

// eslint-disable-next-line react-hooks/exhaustive-deps
}, [])



const minusNum = (e) => {
  const id = e.รหัสสินค้า;
  const name = e.สินค้า;
  const price = e.ราคา;
  const image = e.รูปภาพ;
  const details = e.รายละเอียด;
  const category = e.หมวด;
  const heart = e.heart;

  if(id === 18 || id === 19){
    if (useCartReducer.length > 0) {
      Promise.all(
        useCartReducer.map((item) => {
          if (item.id === id) {
            if (item.จำนวน <= 5 || item.จำนวน - 1 < 0 || item.จำนวน === 1) {
              dispatch({
                type: "SET_CART_DATA",
                payload: useCartReducer.filter((item) => item.id !== id),
              });
            } else {
              dispatch({
                type: "UPDATE_CART_DATA",
                payload: {
                  id: id,
                  รหัสสินค้า: id,
                  สินค้า: name,
                  ราคา: price,
                  รูปภาพ: image,
                  จำนวน: item.จำนวน - 5 < 0 ? 0 : item.จำนวน - 5,
                  ราคารวม:(item.จำนวน - 5) * price,
                  หมวด: category,
                  heart: heart,
                  รายละเอียด: details,
                },
              });
            }
          }
        })
      );
    }
  }else{
    if (useCartReducer.length > 0) {
      Promise.all(
        useCartReducer.map((item) => {
          if (item.id === id) {
            if (item.จำนวน === 1) {
              dispatch({
                type: "SET_CART_DATA",
                payload: useCartReducer.filter((item) => item.id !== id),
              });
            } else {
              dispatch({
                type: "UPDATE_CART_DATA",
                payload: {
                  id: id,
                  รหัสสินค้า: id,
                  สินค้า: name,
                  ราคา: price,
                  รูปภาพ: image,
                  จำนวน: item.จำนวน - 1,
                  ราคารวม: item.ราคารวม - price,
                  หมวด: category,
                  heart: heart,
                  รายละเอียด: details,
                },
              });
            }
          }
        })
      );
    }
  }
};







const setWidth = () => {
 
  if(useCartReducer.reduce((acc, item) => { return item.จำนวน + acc; }, 0) > 99 && useCartReducer.reduce((acc, item) => { return item.จำนวน + acc; }, 0) < 999){
    return '24px'
  }else if(useCartReducer.reduce((acc, item) => { return item.จำนวน + acc; }, 0) > 999 && useCartReducer.reduce((acc, item) => { return item.จำนวน + acc; }, 0) < 9999){
    return '38px'
  }else if(useCartReducer.reduce((acc, item) => { return item.จำนวน + acc; }, 0) > 9999){
    return  '52px'
  }
}



 

const setheart2 = (e) => {
  const getlocalStorage = localStorage.getItem("heart");

  console.log(useShopReducer.find((item) => item.id === e.id));

  if(getlocalStorage){
    const getlocalStorage2 = JSON.parse(getlocalStorage);
    const find = getlocalStorage2.find((item) => item.id === e.id);
    if (find) {
      // ถ้ามีอยู่แล้วให้ลบออก
      const filter = getlocalStorage2.filter((item) => item.id !== e.id);
      localStorage.setItem("heart", JSON.stringify(filter));
        dispatch({ type: "UPDATE_SHOP_DATA", payload: { ...e, heart: false, }, });
    } else {
      // ถ้าไม่มีให้เพิ่มเข้าไป
      localStorage.setItem("heart",JSON.stringify([...getlocalStorage2, { id: e.id }]));
      dispatch({ type: "UPDATE_SHOP_DATA", payload: { ...e, heart: true, }, });
    }
   

  } else {
    localStorage.setItem("heart", JSON.stringify([{ id: e.id }]));
    dispatch({ type: "UPDATE_SHOP_DATA", payload: { ...e, heart: true, }, });
  }
  

};
  return (
    <Navbar>
      <header
        className={`header navbar-area`}
      >
        <Paper className="header-middle " id="_navbar"  elevation={3}>
          <div className="container m-auto">
            <div className="row align-items-center">
              <div className="col-lg-8 col-md-8 col-7 text-start">
                <Link className="navbar-brand " to={{
                  pathname: location.pathname === "/OrderAgain/user" ? "/CancelOrder" : location.pathname === "/CancelOrder" ? "/CancelOrder" : "/",
                  state: location?.state,
                }}>
             
                  {location.pathname === "/CancelOrder" ? "ตรวจรายการสั่งซื้อ" : location.pathname === "/Baskets" ? "ตรวจสอบตะกร้า" : location.pathname === "/OrderAgain/user" ? "สั่งซื้อใหม่" : "สั่งสินค้า" }

                </Link>
              </div>
 
              <div className="col-lg-4 col-md-4 col-5">
                <div className="middle-right-area">
                  <div className="navbar-cart">

                   

                 {
                      location.pathname === "/CancelOrder" || location.pathname === "/OrderAgain/user" ? null : <div className="wishlist">
                        <span onClick={showfavorite}>
                          

                          <i style={{ fontSize: "18px", marginTop: "1.5px" }} className={
                            useShopReducer.filter((item) => item.heart === true).length === 0 ? 'lni lni-heart' : 'lni lni-heart-filled'
                          } />
                          <span className="total-items" id="favorite-total" style={{
                            display: useShopReducer.filter((item) => item.heart === true).length === 0 ? "none" : "block"
                          }}>
                            {useShopReducer.filter((item) => item.heart === true).length.toLocaleString()}   </span>
                        </span>
                      </div>
                 }


                  {
                      location.pathname === "/CancelOrder" || location.pathname === "/OrderAgain/user" ? <div className="cart-items" onClick={() => {
                     liff.closeWindow() 
                        
                      }}>
                        <span className="main-btn" id="basket" >
                       <i className="lni lni-close" />

                        </span>
                     
                      </div> : <div className="cart-items">
                        <span className="main-btn" id="basket" onClick={() => {
                          history.push({
                            pathname: "/Baskets",
                            state: useCartReducer.map((item) => {
                              return {
                                ...item,
                                orderID: location?.state && location?.state[0]?.orderID ? location?.state[0]?.orderID : null,
                                productID: item.id,
                                quantity: item.จำนวน,
                                purchasePrice: item.ราคา,
                                
                              }
                            }),
                          })
                          
                        }}>
                          <i className={useCartReducer.reduce((acc, item) => { return item.จำนวน + acc; }, 0) === 0 ? 'lni lni-cart' : 'lni lni-cart-full'} />
                          <span className="total-items" id="basket-total" style={{
                            display: useCartReducer.reduce((acc, item) => { return item.จำนวน + acc; }, 0) === 0 ? 'none' : 'block',
                            width: setWidth()
                          }}>
                            {
                              useCartReducer.length === 0 ? 0 : useCartReducer.reduce((acc, item) => {
                                return item.จำนวน + acc;
                              }, 0).toLocaleString()
                            }
                          </span>
                        </span>
                       
                      </div>
                  }


                    
 
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Paper>
      </header>
 
      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title style={{
            color: "#000",
            
          }}>สินค้าที่สั่งซื้อบ่อย</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {useShopReducer.filter((item) => { return item.heart === true; }).length > 0 ? (
            useShopReducer.filter((item) => { return item.heart === true; }).map((item, index) => {
              return (
                <div key={index}  className={index === 0 ? "mt-2 mb-1" : "mt-1 mb-1"}>
                <Paper
                  className="cart-list-head p-0"
                  elevation={2}
                  style={{
                    display: "block",
                    
                  }}
                 
                >
                  <div style={{ width: "100%", marginTop: 0 }}>
                    <div
                      style={{
                        display: "flex",
                        background: "rgb(255, 255, 255)",
                        padding: 10,
                      }}
                    >
                      <img
                        className="img-thumbnail"
                        src={item.รูปภาพ} 
                        alt={item.สินค้า} 
                        onClick={() => { history.push({
                          pathname: `/product/${item.id}`,
                          state: location?.state,
                        }) }} 
                        style={{ width: "35%" }}
                      />
                      <div style={{ marginLeft: 10 }}>
                        <h5 className="product-name mb-0">
                        <Link to={{
                          pathname: `/product/${item.id}`,
                          state: location?.state,
                        }} style={{ fontSize: 20 }}>
                  {item.สินค้า}
                </Link>
                        </h5>
                        <p className="product-des">
                          <span style={{ fontSize: 18 }}>
                            <em>ราคา:</em> {item.ราคา} บาท
                          </span>
                        </p>
                        <div className="num-block skin-2 d-flex ">
                          <div
                            className="num-in"
                            style={{ border: "2px solid rgb(204, 204, 204)" }}
                          >
                            <span className="minus dis" onClick={() => minusNum(item)}/>
                            <input
                              type="number"
                              className="in-num"
                              inputMode="numeric"
                              onClick={(e) => { e.target.select() }}
                              value={useCartReducer.filter(item2 => item2.id === item.รหัสสินค้า)[0]?.จำนวน || 0}
                              onChange={(e) => {
                                if (e.target.value === "") {
                                  dispatch({
                                    type: "DELETE_CART_DATA",
                                    payload: {
                                      id: item.รหัสสินค้า,
                                    }
                                  })
                                }
                                if (e.target.value > 0) {
                                  // เช็คว่า useCartReducer มีข้อมูลหรือไม่
                                  if (useCartReducer.filter(item2 => item2.id === item.รหัสสินค้า).length > 0) {
                                    // ถ้ามีข้อมูลให้เอาข้อมูลมาใช้
                                    
                                    dispatch({
                                      type: "UPDATE_CART_DATA",
                                      payload: {
                                        id: item.รหัสสินค้า,
                                        รหัสสินค้า: item.รหัสสินค้า,
                                        สินค้า: item.สินค้า,
                                        ราคา: item.ราคา,
                                        รูปภาพ: item.รูปภาพ,
                                        จำนวน: parseInt(e.target.value),
                                        ราคารวม: item.ราคา * parseInt(e.target.value),
                                        หมวด: item.หมวด,
                                        heart: item.heart,
                                        รายละเอียด: item.รายละเอียด,
                                      }
                                    })
                                  } else {
                                    // ถ้าไม่มีข้อมูลให้เพิ่มข้อมูลใหม่
                                    dispatch({
                                      type: "ADD_CART_DATA",
                                      payload: {
                                        id: item.รหัสสินค้า,
                                        รหัสสินค้า: item.รหัสสินค้า,
                                        สินค้า: item.สินค้า,
                                        ราคา: item.ราคา,
                                        รูปภาพ: item.รูปภาพ,
                                        จำนวน: parseInt(e.target.value),
                                        ราคารวม: item.ราคา * parseInt(e.target.value),
                                        หมวด: item.หมวด,
                                        heart: item.heart,
                                        รายละเอียด: item.รายละเอียด,
                                      }
                                    })
                                  }
                      
                                }
                              }}
                              style={{ 
                                height: 35,
                                fontSize: '1rem'
                              }}
                            />
                            <span className="plus" onClick={() => addtocart(item)}/>
                          </div>
          
          <div className="d-flex justify-content-end align-items-center" style={{marginLeft: 40, marginRight: 10}}>
          {item.heart ? <i className="lni lni-heart-filled" onClick={() => setheart2(item)} style={{ fontSize: "20px", marginTop: "1.5px", color:"red" }} /> :
  <i className="lni lni-heart" onClick={() => setheart2(item)} style={{ fontSize: "20px", marginTop: "1.5px" }} />  
}
          </div>
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </Paper>
                </div>
              )
            })
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              ไม่พบสินค้าที่ถูกใจ
            </div>
          )}


  {
    useShopReducer.filter((item) => { return item.heart === true; }).length > 0  && (
      <div>
                     <div className="button">
                      <Paper elevation={3} className="mt-3" >
                      <button 
                      className="btn btn-alt mt-0"
                      onClick={() => { 
                        history.push({
                          pathname: "/Baskets",
                          state: useCartReducer.map((item) => {
                            return {
                              ...item,
                              productID: item.id,
                              quantity: item.จำนวน,
                              purchasePrice: item.ราคา,
                            }
                          }),
                        }) 
                        setShow(false)
                      }}
                      style={{
                        fontSize: 16,
                        width: "100%",
                        marginTop: "30px",

                      }}>
                        เพิ่มไปยังรถเข็น
                      </button>
                      </Paper>
                      </div>

                      </div>
    )
  } 



        </Offcanvas.Body>
      </Offcanvas>
    </Navbar>
  );
}

export default NavBar;
