export const useCartReducer = (state = [], action) => {
  switch (action.type) {
    case "SET_CART_DATA":
      return action.payload;
    case "ADD_CART_DATA":
      return state.filter(item => item.id !== action.payload.id).concat(action.payload);
    case "DELETE_CART_DATA":
      return  state.length === 1 ? [] : state.filter(item => item.id !== action.payload.id);
    case "UPDATE_CART_DATA":
      return state.map(item => { if (item.id === action.payload.id) { return action.payload; } else { return item; } });
    
    default:
      return state;
  }
}