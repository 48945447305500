import styled from "styled-components";

export const ScroolTop = styled.div`
    width: 50px;
    height: 50px;
    line-height: 40px;
    background: #f07;
    display: -webkit-box;
    display: -ms-flexbox;
    display: none;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 16px;
    color: #fff !important;
    position: fixed;
    bottom: 90px;
    right: 10px;
    z-index: 9;
    cursor: pointer;
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    border-radius: 10px;
   
  `;

