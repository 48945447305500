import React from 'react'
import bg from './6af537cbb99c5465.webp'
function Heading() {
  return (
   
        <div style={{ 
            backgroundImage: `url(${bg})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            width: '100%', 
            minHeight: '200px', 
            position: 'relative',
        }}>


        {/* <div style={{ marginLeft: '1rem', position: 'absolute', top: '65%', transform: 'translateY(-50%)' }}>
          <div className='mb-2'>
                <h1 
                  className='text-heading-AruneeNoodles-1' style={{
                    fontSize: '1.8rem',
                  }}>ตารางคํานวณ</h1>
            </div>

          <div className='mb-2'>
              <h2 className='text-heading-AruneeNoodles-1' style={{
                    fontSize: '1.4rem',
                  }}>
                  ราคาต้นทุน
              </h2>
          </div>

          <div className='mb-2'>
              <h2 className='text-heading-AruneeNoodles-1' style={{
                    fontSize: '1.4rem',
                  }}>
                  ก๋วยเตี๋ยวต่อ 1 ชาม
              </h2>
           </div>
          </div> */}
        
        </div>

   

  )
}

export default Heading