import apiService from "../../api/apiService";
import swal from '@sweetalert/with-react';

const service = apiService();


export async function getMentorCustomerList() {
  return await service.get({ url: `/api/v1/mentor-customer/list` }).then((res) => {
    return res.data.result
  }).catch((err) => {
    console.log(err)
    swal({ title: "Error", text: err?.response?.data?.message || err.message, icon: "error", button: "close" });
  })
}

//user
export async function getUser() {
  return await service.get({ url: `/api/v1/user` }).then((res) => {
    return res.data.result
  }).catch((err) => {
    console.log(err)
    swal({ title: "Error", text: err?.response?.data?.message || err.message, icon: "error", button: "close" });
  })
}

//customer/list
export async function getCustomerList() {
  return await service.get({ url: `/api/v1/customer/list` }).then((res) => {
    return res.data.result
  }).catch((err) => {
    console.log(err)
    swal({ title: "Error", text: err?.response?.data?.message || err.message, icon: "error", button: "close" });
  })
}

///api/v1/order/list-by-shipping-date
export async function getOrderListByShippingDate({ startShippingDate, endShippingDate }) {
  return await service.get({ url: `/api/v1/order/list-by-shipping-date?startDate=${startShippingDate}&endDate=${endShippingDate}` }).then((res) => {
    return res.data.result
  }).catch((err) => {
    console.log(err)
    swal({ title: "Error", text: err?.response?.data?.message || err.message, icon: "error", button: "close" });
  })
}

//product/list 
export async function getProductList() {
  return await service.get({ url: `/api/v1/product/list` }).then((res) => {
    return res.data.result
  }).catch((err) => {
    console.log(err)
    swal({ title: "Error", text: err?.response?.data?.message || err.message, icon: "error", button: "close" });
  })
}

// /api/v1 / order /? orderID = 1
export async function getOrder({ orderID }) {
  return await service.get({ url: `/api/v1/order/?orderID=${orderID}` }).then((res) => {
    return res.data.result
  }).catch((err) => {
    console.log(err)
    swal({ title: "Error", text: err?.response?.data?.message || err.message, icon: "error", button: "close" });
  })
}

//  /api/v1/order/full-detail?orderID=1
export async function getOrderFullDetail({ orderID }) {
  return await service.get({ url: `/api/v1/order/full-detail?orderID=${orderID}` }).then((res) => {
    return res.data.result
  }).catch((err) => {
    console.log(err)
    swal({ title: "Error", text: err?.response?.data?.message || err.message, icon: "error", button: "close" });
  })
}
