import React from 'react'
import Products from '../../components/Products';
import GoTop from '../../components/Button/GoTop';
function Product() {
 
  return (
    <>
    <Products/>
    <GoTop/>
    </>
  )
}

export default Product