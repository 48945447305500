export const useOrderDetail = (state = [], action) => {
    switch (action.type) {
        case "SET_ORDER_DETAIL":
            return action.payload;
        case "ADD_ORDER_DETAIL":
            return [...state, action.payload];
        case "DELETE_ORDER_DETAIL":
            return state.filter(item => item.key !== action.payload);
            
      default:
        return state;
  
    }
  }

  export const useOrderDetailLoading = (state = true, action) => {
    switch (action.type) {
      case "SET_ORDER_DETAIL_LOADING":
        return action.payload;
      default:
        return state;
    }
  }


export const findIndex = (state = 0, action) => {
  switch (action.type) {
    case "SET_FIND_INDEX":
      return action.payload;
    default:
      return state;
  }
}