import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { WatDatePicker } from "thaidatepicker-react";
import dayjs from "dayjs";
import Toastify from "toastify-js";
import apiService from "../../api/apiService";
import swal from '@sweetalert/with-react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from "@mui/material/Box";
import { SweetError } from "../../components/checkout/modal";
import { setLocalStorage } from "../../components/localStorage";

const Counter = styled.div`
  background-color: #ffecf8;
  padding-bottom: 3rem;
  padding-top: 1.5rem;
  width: 100%;
  min-height: 100vh;
  padding-left: 1rem;
  padding-right: 1rem;

  .flex-button{
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    flex-direction: column;
    align-items: center;
    .btn-1, .btn-2{
      width: 500px;
      background-color: #ff00a6;
      padding: .4rem;
      font-size: 16px;
      border-radius: 10px;
      color: #fff;
      border: 2px solid #fff;
      font-weight: 600;
      margin-bottom: 1rem;
      transition: all .1s ease-in-out;
      @media (max-width: 768px) {
        width: 100%;
      }
      &:hover{
        background-color: #e834a9;
      }
      &:active{
        transform: scale(0.95);
      }
    }

  }


    .title {
      font-size: 18px;
      font-weight: 600;
        text-align: center;
        color: #fff;
        background-color: #730000;
        padding: 0.5rem;
        margin: 0 auto;
      width: 500px;
      @media (max-width: 768px) {
        width: 100%;
      }
    }
   
  }
`;

const Date = styled.div`
  width: 500px;
  margin: 0 auto;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  @media (max-width: 768px) {
    width: 100%;
  }
`;


const ToastifyDateNull = () => {
  Toastify({
    text: "กรุณาเลือกวันที่ !",
    duration: 3000,
    close: false,
    gravity: "top",
    position: "center",
    backgroundColor: "#ff0000",
    stopOnFocus: true,
  }).showToast();
}

 
function ClearMoney() {
  const history = useHistory();
  const clearMoneyShipperID = localStorage.getItem("clearMoneyShipperID") || null;
  const clearMoneyDateStart = localStorage.getItem("clearMoneyDateStart") || null;
  const [dateStart, setDateStart] = useState(clearMoneyDateStart || dayjs().format("YYYY-MM-DD"));
  const [loading, setLoading] = useState(true);
  const service = apiService()
  const [shipperID, setShipperID] = useState(null);
  const [employeeList, setEmployeeList] = useState([])
  const handleWatDatePickerChange = (christDate, buddhistDate) => {
    setDateStart(christDate);
    setLocalStorage("clearMoneyDateStart",  christDate);
    
  };
 

  const checkInvoice = () => {
    dateStart === "" || dateStart === undefined || dateStart === null ? ToastifyDateNull() : history.push(`/checkInvoice/${shipperID}/${dateStart}`)
  };
  const checkPayInCash = () => {
    dateStart === "" || dateStart === undefined || dateStart === null ? ToastifyDateNull() : history.push(`/checkPayInCash/${shipperID}/${dateStart}`); 
  };
  const clearMoneyDetail = () => {
    dateStart === "" || dateStart === undefined || dateStart === null ? ToastifyDateNull() : history.push(`/clearMoneyDetail/${shipperID}/${dateStart}`);
  };

  ///api/v1/user/
async function getUser() {
  return await service.get({ url: `/api/v1/user/` }).then((res) => {
      return res.data.result
  }).catch((err) => {
      console.log(err)
      setLoading(false)
      SweetError("api/v1/user/", err.message)
  })
}

///api/v1/route/all-with-shipper
async function getEmployeeList() {
  return await service.get({ url: `/api/v1/route/all-with-shipper` }).then((res) => {
      return res.data.result
  }).catch((err) => {
      console.log(err)
      setLoading(false)
      SweetError("/api/v1/route/all-with-shipper", err.message)
   })
}






  useEffect(() => {

    getUser().then((res) => {
    
    if(['Salesperson', 'Executive', 'Accountant'].includes(res.employeeJobType)){
      getEmployeeList().then((e) => {
        setLoading(false)
        setEmployeeList(e)
        setShipperID(clearMoneyShipperID ? Number(clearMoneyShipperID) : e[0].shipperID)
        
      })
    } else {
      setLoading(false)
      setShipperID(res.userID)
    }

    })


  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);




  return (
    <Counter>
      <div className="title shadow-sm">การเคลียร์เงิน</div>

      {loading ? <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "45vh",
        }}
      >
        <CircularProgress />
      </Box> : (
        <div className="flex-button mt-0">
          <Date className={employeeList.length > 0 ? "" : "mb-4"}>
            <WatDatePicker
              placeholder="วันที่ส่งของ"
              onChange={handleWatDatePickerChange}
              dateFormat={"yyyy-MM-dd"}
              displayFormat={"dd, DD MMMM YY"}
              readOnly={false}
             //maxDate = tomorrow
              maxDate={dayjs().add(1, "day").format("YYYY-MM-DD") }
              value={dateStart}
              className="shadow-sm"

              inputStyle={{
                color: "#081828",
                border: "1px solid #ced4da",
                borderRadius: "10px",
                padding: ".5rem",
                textAlign: "center",
                width: "100%",
                fontSize: "16px",
                fontWeight: "600",
              }}
            />
          </Date>

          {employeeList.length > 0 && (
            <Date className="select-employee mt-0 mb-4">
              <select
                className="form-select"
                onChange={(e) =>{
                  setShipperID(e.target.value)
                  setLocalStorage("clearMoneyShipperID", e.target.value)
                }} 
                defaultValue={shipperID || ""}
                value={shipperID || ""}
                style={{
                  border: "1px solid #ced4da",
                  borderRadius: "10px",
                  padding: ".5rem",
                  textAlign: "center",
                  width: "100%",
                  fontSize: "16px",
                  fontWeight: "600",
                }}
              >
             
                {employeeList.map((e, i) => (
                  <option key={i} value={e.userID}>
                    {e.routeName}
                  </option>
                ))}
              </select>
            </Date>
          )}
          


          <button className="btn-1 shadow-sm" onClick={checkInvoice}>
            1. เช็คความถูกต้องของบิล
          </button>
          <button className="btn-2 shadow-sm" onClick={checkPayInCash}>
              2. เคลียร์ ยอด ที่ยังไม่ถึง กำหนดชำระเงิน และยอดที่เลย กำหนดชำระเงินแล้ว
          </button>
          <button className="btn-1 shadow-sm" onClick={clearMoneyDetail}>
              3. เคลียร์การชำระเงินทั้งหมด
          </button>
        </div>
      )}
    </Counter>
  );
}

export default ClearMoney;
