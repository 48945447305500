import React, { useEffect } from 'react';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from "@mui/material/TextField";


import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Table from './table';
import swal from '@sweetalert/with-react';
import { getMentorCustomerList, getUser, getCustomerList, getOrderListByShippingDate, getProductList, getOrderFullDetail } from './api';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

 
// ตัวเลือกในการกำหนดรูปแบบวันที่เป็น ปี-เดือน-วัน
const options = { year: 'numeric', month: '2-digit', day: '2-digit' };

// ฟังก์ชันเพื่อแปลงปีพุทธศักราชเป็นคริสต์ศักราช
function convertToCE(dateString) {
  const [day, month, year] = dateString.split('/');
  return `${year - 543}-${month}-${day}`;
}

// วันแรกของเดือนนี้
const getFirstDayOfCurrentMonth = convertToCE(
  new Date(new Date().getFullYear(), new Date().getMonth(), 1)
    .toLocaleDateString('th-TH', options)
);

// วันแรกของเดือนที่แล้ว
const getFirstDayOfLastMonth = convertToCE(
  new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1)
    .toLocaleDateString('th-TH', options)
);

// วันสุดท้ายของเดือนที่แล้ว
const getLastDayOfLastMonth = convertToCE(
  new Date(new Date().getFullYear(), new Date().getMonth(), 0)
    .toLocaleDateString('th-TH', options)
);

// วันแรกของสองเดือนที่แล้ว
const getFirstDayOfTwoMonthsAgo = convertToCE(
  new Date(new Date().getFullYear(), new Date().getMonth() - 2, 1)
    .toLocaleDateString('th-TH', options)
);

// วันสุดท้ายของสองเดือนที่แล้ว
const getLastDayOfTwoMonthsAgo = convertToCE(
  new Date(new Date().getFullYear(), new Date().getMonth() - 1, 0)
    .toLocaleDateString('th-TH', options)
);

// วันนี้
const today = convertToCE(
  new Date().toLocaleDateString('th-TH', options)
);



function MentorMonthlySales() {

  const classes = useStyles();
  const [data, setData] = React.useState([]);
  const [open, setOpen] = React.useState(true);

 
  useEffect(() => {
    //2.3.1 เดือนนี้ ให้ยิง order/list-by-shipping-date?startDate="วันแรกของเดือนนี้"&endDate="today"
    // 2.3.1 เดือนนี้ ให้ยิง order / list - by - shipping - date ? startDate = "วันแรกของเดือนนี้" & endDate="today"
    // 2.3.2 เดือนที่แล้ว ให้ยิง order / list - by - shipping - date ? startDate = "วันแรกของเดือนที่แล้ว" & endDate="วันสุดท้ายของเดือนที่แล้ว"
    // 2.3.3 สองเดือนที่แล้ว ให้ยิง order / list - by - shipping - date ? startDate = "วันแรกของสองเดือนที่แล้ว" & endDate="วันสุดท้ายของสองเดือนที่แล้ว"

    Promise.all([getOrderListByShippingDate({ startShippingDate: getFirstDayOfCurrentMonth, endShippingDate: today }),
    getOrderListByShippingDate({ startShippingDate: getFirstDayOfLastMonth, endShippingDate: getLastDayOfLastMonth }),
    getOrderListByShippingDate({ startShippingDate: getFirstDayOfTwoMonthsAgo, endShippingDate: getLastDayOfTwoMonthsAgo }),
    getMentorCustomerList(),
    getUser(),
    getCustomerList(),
    getProductList()
    ]).then((res) => {
      const mentorCustomerList = res[3];
      const user = res[4];
      const customerList = res[5];
      const product = res[6];
      const currentMonthOrderList = res[0];
      const lastMonthOrderList = res[1];
      const twoMonthsAgoOrderList = res[2];
      console.log("api", {
        mentorCustomerList,
        user,
        customerList,
        product,
        currentMonthOrderList,
        lastMonthOrderList,
        twoMonthsAgoOrderList
        
      });

      // 1.3 filter เฉพาะที่ mentorID == userID จากข้อ 1.1 ? เอา studentID มาใช้
      // 1.4 ยิง customer / list แล้ว filter customerID == studentID ในข้อ 1.3 ? เอา invoiceName มาใช้เติมใน column ชื่อลูกศิษย์

      const filteredMentorCustomerList = mentorCustomerList.filter((item) => item.mentorID === user.userID);
      const studentIDList = filteredMentorCustomerList.map((item) => item.studentID);

      const filteredCustomerList = customerList.filter((item) =>  studentIDList.includes(item.customerID));
      console.log("filteredCustomerList", filteredCustomerList);
      
      const a = filteredCustomerList.map((item) => {
        //This month
        const thisMonth = currentMonthOrderList.filter((order) => order.customerID === item.customerID && order.paidDate !== null && order.orderStatus === null);
        //Last month
        const lastMonth = lastMonthOrderList.filter((order) => order.customerID === item.customerID && order.paidDate !== null && order.orderStatus === null);
        // สองเดือนที่แล้ว
        const twoMonthsAgo = twoMonthsAgoOrderList.filter((order) => order.customerID === item.customerID && order.paidDate !== null && order.orderStatus === null);
        return {
          "ชื่อลูกศิษย์": item.invoiceName,
          "เดือนนี้": thisMonth,
          "เดือนที่แล้ว": lastMonth,
          "สองเดือนที่แล้ว": twoMonthsAgo,
          customerID: item.customerID,
          orderID:   [...thisMonth, ...lastMonth, ...twoMonthsAgo].map((order) => order.orderID)
        }
      });
      console.log("a", a);
      
      //ข้อนี้คือการหาว่าสินค้าไหนที่ Mentor จะได้ commission ให้ยิง product/list แล้ว filter productID ที่ mentorCommission == TRUE ? เก็บ productID ไว้ใน
      const b = product.filter((item) => item.mentorCommission === true).map((item) => item.productID);
      console.log("b", b);
     
      Promise.all(a.map((item) => {
        return Promise.all(item.orderID.map((orderID) => {
          return getOrderFullDetail({ orderID });
        })).then((res) => {
          return res.map((order) => {
            const orderID = order.order.orderID;
            // eslint-disable-next-line array-callback-return
            const type = a.filter((item) => item.orderID.includes(orderID)).map((item) => {
              if (item["เดือนนี้"].map((order) => order.orderID).includes(orderID)) {
                return "เดือนนี้";
              } else if (item["เดือนที่แล้ว"].map((order) => order.orderID).includes(orderID)) {
                return "เดือนที่แล้ว";
              } else if (item["สองเดือนที่แล้ว"].map((order) => order.orderID).includes(orderID)) {
                return "สองเดือนที่แล้ว";
              }
            })
            return {
               ...order.order,
               // type ระบุว่าเป็น เดือนนี้, เดือนที่แล้ว หรือสองเดือนที่แล้ว โดยเช็คจาก orderID ว่าตรงกับ orderID ในเดือนไหน
                type: type[0],
            }
          })
        });
      })).then((res) => {
        setOpen(false);
        const resundefined = res.flat().filter((item) => item !== undefined);
        console.log("resundefined", resundefined);
      

     const sumReceivedQuantity = resundefined.map((item) => {
  return {
    ...item,
    sumReceivedQuantity: item.orderDetail
      .filter((detail) => b.includes(detail.productID))
      .reduce((acc, cur) => acc + (cur.receivedQuantity ?? 0), 0)  // Handle null as 0
  };
});


        console.log("sumReceivedQuantity", sumReceivedQuantity);

        
        
        console.log("filteredCustomerList", filteredCustomerList);
        const result = filteredCustomerList.map((item) => {
          // 3.1 ชื่อลูกศิษย์-- -> เอา invoiceName จากข้อ 1.4 มาเติมครับ
          // 3.2 เดือนนี้-- -> เอาผลรวมที่ได้จาก 2.6.1 มาเติมครับ
          // 3.3 เดือนที่แล้ว-- -> เอาผลรวมที่ได้จาก 2.6.2 มาเติมครับ
          // 3.4 สองเดีอนที่แล้ว-- -> เอาผลรวมที่ได้จาก 2.6.3 มาเติมครับ
          return {
            "ชื่อลูกศิษย์": item.invoiceName,
            "เดือนนี้": sumReceivedQuantity.filter((order) => order.customerID === item.customerID && order.type === "เดือนนี้").reduce((acc, cur) => acc + cur.sumReceivedQuantity, 0),
            "เดือนที่แล้ว": sumReceivedQuantity.filter((order) => order.customerID === item.customerID && order.type === "เดือนที่แล้ว").reduce((acc, cur) => acc + cur.sumReceivedQuantity, 0),
            "สองเดือนที่แล้ว": sumReceivedQuantity.filter((order) => order.customerID === item.customerID && order.type === "สองเดือนที่แล้ว").reduce((acc, cur) => acc + cur.sumReceivedQuantity, 0),
            customerID: item.customerID
          }
        })
        //เดือนนี้: 0, เดือนที่แล้ว: 0, สองเดือนที่แล้ว: 0 เอาออก
        .filter((item) => item["เดือนนี้"] !== 0 || item["เดือนที่แล้ว"] !== 0 || item["สองเดือนที่แล้ว"] !== 0);
        console.log("result", result);
        setData(result);
      }).catch((err) => {
        console.log(err);
        swal({ title: "Error", text: err?.response?.data?.message || err.message, icon: "error", button: "close" });
      });




 

    }).catch((err) => {
      console.log(err);
      swal({ title: "Error", text: err?.response?.data?.message || err.message, icon: "error", button: "close" });
    }
    );





  }, []);


  return (
    <Container component="main" maxWidth="md" style={{
      padding: '10px',
    }}>
      <CssBaseline />
      <div className={classes.paper}>

        <Typography component="h1" variant="h5">
          รายได้จากการเป็นอาจารย์ของท่านย้อนหลัง 3 เดือน
        </Typography>


        <Table data={data} loading={open} />

      </div>
      <Backdrop
      
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
         
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
}

export default MentorMonthlySales;