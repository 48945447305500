import React, { useEffect } from 'react'
import Box from "@material-ui/core/Box";
import { styled } from '@material-ui/core/styles';
import Paper from '@mui/material/Paper';
import swal from 'sweetalert';
import { useHistory } from "react-router-dom";
import liff from "@line/liff/dist/lib";
import apiService from "../../../api/apiService";
 

const Button = styled(Paper)({
    backgroundColor: "#1565c0 !important",
     width: "500px",
    color: "#ffff !important",
    fontSize: "20px",
    padding: "15px 20px",
    textAlign: "center",
    borderRadius: "10px !important",
    boxShadow: "none !important",
    border: "1px solid #fff !important",
    fontWeight: "500",
    marginBottom: "10px",
    transition: ".3s !important",
     '&:active': {
        backgroundColor: "#0d6efd !important",
        color: "#fff !important",
        border: "1px solid #0d6efd !important",
        
     },
     '&:hover': {
        backgroundColor: "#0d6efd !important",
        color: "#fff !important",
        border: "1px solid #0d6efd !important",
        }

});


const ButtonL = styled(Paper)({
    backgroundColor: "#fff !important",
     width: "500px",
    color: "#dc3545 !important",
    fontSize: "20px",
    padding: "15px 20px",
    textAlign: "center",
    borderRadius: "10px !important",
    marginTop: "50px",
    
   border: "1px solid !important",
    fontWeight: "500",
    marginBottom: "0px !important",
    transition: ".3s !important",
     '&:active': {
        backgroundColor: "#dc3545 !important",
        color: "#fff !important",
        
     },
     '&:hover': {
        backgroundColor: "#dc3545 !important",
        color: "#fff !important",
       
        }

});


const LogOut = () => {
      swal({
         title: "คุณต้องการออกจากการตั้งค่าใช่หรือไม่?",
         className: "sweet-alert-jobtype-logout",
         icon: "info",
         buttons: ["ยกเลิก", "ตกลง"],
         dangerMode: true,
      })
      .then((willDelete) => {
         if (willDelete) {
            console.log("ออกจากการตั้งค่า");
          
               liff.closeWindow()
            
         }
      });
}  



function JobType() {
   const history = useHistory();
   const service = apiService();
   const [lineGroupID, setLineGroupID] = React.useState("");

   const getLineGroupID = async () => {
      const url = window.location.href;
      
      const setUrl1 = url.split("?liff.state=%3FgroupID%3D")[1];
      const setUrl2 = url.split("?liff.state=%2FgroupID%3D")[1];
      const setUrl3 = url.split("?liff.state=%2F%3FgroupID%3D")[1];
      const setUrl = setUrl1 ? setUrl1 : setUrl2 ? setUrl2 : setUrl3 ? setUrl3 : null;
  
      const endUrl = setUrl?.split("#")[0] 
      console.log("endUrl", endUrl);
      return endUrl
    };



   useEffect(() => {

      getLineGroupID().then((e) => {
         console.log("lineGroupID", e);
     
         if (e) {
            setLineGroupID(e);
            service.get({
               url: "/api/v1/user/"
            }).then((res) => {
               const deniedJobType = [ 'Shipper','CONTACT', '', null, undefined]
               if (deniedJobType.includes(res.data.result.employeeJobType) || deniedJobType.includes(res.data.result.userType)) {
                 swal({
                  title: 'เกิดข้อผิดพลาด',
                  content: 'Forbidden',
                  icon: 'error',
                  closeOnClickOutside: false,
                  buttons: {
                    cancel: "ปิด",
                  },
                }).then((value) => {
                  switch (value) {
                    default:
                       liff.closeWindow()
                       
                      console.log("close");
                  }
                });
               }
            }) 

         } else {
            swal({
               title: 'เกิดข้อผิดพลาด',
               text: 'ไม่พบ lineGroupID',
               icon: 'error',
            }).then((value) => {
               switch (value) {
                  default:
                     liff.closeWindow()
                     
                     console.log("close");
               }
            });
         }
      });

   

  
 
   }, []);

  
  return (
    <Box style={{ display: "flex", justifyContent: "center" }}>
    <div className="container"   id="EditJobType"  style={{ margin: "0 auto", paddingTop: ".75rem",paddingBottom: ".75rem",maxWidth:"500px"}}>
     <div className="row" style={{ margin: "0", width: "100%", position: "relative", }} >
       <div className="col-md-6 border p-4 shadow " style={{ 
        width: "100%", 
                 minHeight: "100vh",
        display: "flex",
        backgroundColor: "#f5f5f5",
        flexDirection: "column",
       
        }}>


       <div className="col-12">
              <h3 className="fs-4  mb-4" style={{
                fontSize: "18px !important",
                color: "#333",
                fontWeight: "bold !important",
                textAlign: "center"
              }}>
               กรุณาเลือกการตั้งค่า
              </h3>
            </div>

            <div className="col-12 d-flex flex-column ">
                <Button 
                  onClick={()=>{
                          history.push(`/CusLinkGID?lineGroupID=${lineGroupID}`);
                  }}
                className="btn w-100 mb-1" elevation={2}>
                ผูก lineGroupId กับ customerId
                </Button>
                <Button
                onClick={()=>{
                  history.push(`/UrlLinkGID?lineGroupID=${lineGroupID}`);
               }}
                 className="btn w-100 mb-1" elevation={2}>
                กรอก lineGroupUrl
                </Button>
                <Button className="btn w-100 " elevation={2} onClick={()=> {
                       history.push(`/EditJobType/JobTypeList?lineGroupID=${lineGroupID}`);
                }}>
                ระบุ jobType
                </Button>

                <ButtonL className="btn w-100 " elevation={0} onClick={()=> LogOut()}>
                ออกจากการตั้งค่า
                </ButtonL>
                
             </div>


             <div className="col-12 d-flex flex-column " style={{
                 position: "absolute",
                    bottom: "0",
                    width: "100%",
                    left: "0",
             }}>
               
             </div>

        </div>
        </div>
        </div>
        </Box>

  )
}

export default JobType