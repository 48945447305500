import React, { useEffect, useState } from "react";
import Navbar from "../../../components/Navbar";
import GoTop from "../../../components/Button/GoTop";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@mui/material/Grid";
import swal from '@sweetalert/with-react';
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import apiService from "../../../api/apiService";
import liff from "@line/liff/dist/lib";
import './style.css';
import { SweetError } from "../../../components/checkout/modal";
import Backdrop from '@mui/material/Backdrop';
 

const TypographyOrderList = styled(Typography)({
  fontSize: "16px",
  fontWeight: "400",
})

const PaperOrderList = styled(Paper)({
  padding: "10px",
  backgroundColor: "#f5f5f5",
  borderRadius: "0",
})

function Index() {
  const useBuyNew = useSelector((state) => state.useBuyNew);
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const service = apiService();
  const [shipperID, setShipperID] = useState(null);
  const [open, setOpen] = useState(false);

  async function postOrderDelete({ orderID }) {
    return await service.post({ url: `/api/v1/order/delete`, params: { orderID : orderID} }).then((res) => {
        return res.data.result
    }).catch((err) => {
        console.log(err)
        SweetError("/api/v1/order/delete", err.message)
   })
}

  console.log(history.location.state.order.map((i, k) => {
    return {
      ...i,
      key: k,
      imageURL: i.imageURL,
      productNameFull: i.productNameFull,
      quantity: 0,
      purchasePrice: parseInt(i.purchasePrice),
      totalPrice: 0,
      quantityMax: parseInt(i.receivedQuantity),
    }
  }));

  useEffect(() => {
   
    dispatch({
      type: "SET_BUY_NEW", payload: history.location.state.order.map((i, k) => {
        return {
          ...i,
          key: k,
          imageURL: i.imageURL,
          productNameFull: i.productNameFull,
          quantity: parseInt(i.receivedQuantity),
          purchasePrice: parseInt(i.purchasePrice),
          totalPrice:  parseInt(i.purchasePrice) * parseInt(i.receivedQuantity),
          quantityMax: parseInt(i.receivedQuantity),
        }
      })
    });

    service.get({ url: `/api/v1/order/?orderID=${history.location.state.orderID}` }).then((res) => {
      setIsLoading(false)
      console.log(res.data.result.shipperID);
      setShipperID(res.data.result.shipperID);

    }).catch((err) => {
      console.log(err);
    })

    

   


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



const PostApi = () =>{

  const ยอดใหม่ = useBuyNew.map((i, k) => {
    return i.totalPrice
  }).reduce((a, b) => {
    return a + b
  }
  )
 
   



  if (ยอดใหม่ === 0){
    swal({
      title: "กรุณาเลือกสินค้า",
      text: "กรุณาเลือกสินค้าที่ต้องการสั่งซื้อ",
      icon: "warning",
      className: "swal-shippr-listOrder",
      buttons: ["ยกเลิก", "ตกลง"],
       
    });
  }else{


    const data = {
      "orderID": history.location.state.orderID,
      "shipperID": shipperID,

      "orderDetail": useBuyNew.filter((i) => i.quantity > 0).map((i) => {
        return {
          "productID": i.productID,
          "quantity": i.quantity,
          "returnPictureURL": ""
        }
      })

    }

    function checkout() {
      setOpen(true);
      service.post({ url: `/api/v1/order/checkout-for-reorder`, params: data }).then((res) => {
        setOpen(false);
        swal({
          text: "ขอบคุณสำหรับคำสั่งซื้อของท่าน",
          icon: "success",
          buttons: ["ยกเลิก", "ตกลง"],
          className: "swal-shippr-listOrder",
          content: (
            <Box>
  
              <Grid container spacing={0}>
                <Grid item xs={6}>
                  <PaperOrderList>
                    <TypographyOrderList>ยอดเดิม</TypographyOrderList>
                  </PaperOrderList>
                </Grid>
                <Grid item xs={6}>
                  <PaperOrderList>
                    <TypographyOrderList>
                      {history.location.state.ยอดเดิม} บาท</TypographyOrderList>
                  </PaperOrderList>
                </Grid>
              </Grid>
  
  
              <Grid container spacing={0}>
                <Grid item xs={6}>
                  <PaperOrderList>
                    <TypographyOrderList>ยอดใหม่</TypographyOrderList>
                  </PaperOrderList>
                </Grid>
                <Grid item xs={6}>
                  <PaperOrderList>
                    <TypographyOrderList>
                      {
                        (
                          useBuyNew.length > 0 ? useBuyNew.map((i, k) => {
                            return i.totalPrice
                          }).reduce((a, b) => {
                            return a + b
                          }
                          ).toLocaleString() : 0
                        ).toLocaleString()
  
                      } บาท</TypographyOrderList>
                  </PaperOrderList>
                </Grid>
              </Grid>
  
  
            </Box>
          )
        }).then((value) => {
          
         
            liff.closeWindow()
          
        })
  
  
      }).catch((err) => {
        console.log(err);
  
        swal({
          title: "เกิดข้อผิดพลาด",
          text: "กรุณาติดต่อเจ้าหน้าที่",
          icon: "error",
          className: "swal-shippr-listOrder",
          buttons: ["ยกเลิก", "ตกลง"],
        }).then((value) => {
         
            liff.closeWindow()
         
        });
  
  
        
       })
    }



    if(history.location.state.CancelOrder === true){
      const orderID = data.orderID
      postOrderDelete({ orderID }).then((res) => {
        console.log(res);
        checkout()
      })
    } else{
      checkout()
    }

      
   



  }
 

 
    
}
 

const handleClose = (event, reason) => {
  if (reason && reason === "backdropClick") 
      return;
}

  return (
    <>
      <Navbar />

      {isLoading === false ? (
        <div>
          <div
            className="shopping-cart-b section fade-in"
            style={{
              padding: "100px 10px 10px 10px",
              backgroundColor: "#fde2df",
              minHeight: "100vh",
            }}>

            <div
              className="container"
              id="Basketscontainer"
              style={{ margin: "0px auto" }}
            >
              {useBuyNew.map((item, index) => {
                return (
                  <div className="cart-list-head" style={{ display: "block", marginBottom: 10, }} key={index}>
                    <div className="cart-single-list" >
                      <div className="row align-items-center  justify-content-center">
                        <div className="col-lg-2 col-md-2 col-12">

                          <img
                            className="img-thumbnail userBuyNew"
                            src={item.imageURL}
                            alt={item.productNameFull}
                            style={{ maxWidth: "100%" }}
                          />

                        </div>
                        <div className="col-lg-2 col-md-3 col-12">
                          <h5 className="product-name mb-0" style={{
                            fontSize: "18px",
                            color: "#333",
                            fontWeight: "500"
                          }}>
                            {item.productNameFull}
                          </h5>
                          <p className="product-des">
                            
                            <span style={{
                              fontSize: "17px",
                            }}>
                              <em>ราคา:</em> {item.purchasePrice ? item.purchasePrice.toLocaleString() : 0} บาท
                            </span>
                          </p>
                        </div>
                        <div className="col-lg-3 col-md-2 col-12">
                          <div className="num-block skin-2 d-flex justify-content-center">
                            <div className="num-in">
                              <span className="minus dis" onClick={() => {
                                if (item.quantity > 0) {
                                  dispatch({
                                    type: "UPDATE_BUY_NEW",
                                    payload: {
                                      ...item,
                                      key: item.key,
                                      imageURL: item.imageURL,
                                      productNameFull: item.productNameFull,
                                      quantity: parseInt(item.quantity - 1),
                                      purchasePrice: parseInt(item.purchasePrice),
                                      totalPrice: parseInt(item.totalPrice - item.purchasePrice),

                                      quantityMax: item.quantityMax
                                    }
                                  });
                                }
                              }} />
                              <input
                                type="number"
                                className="in-num"
                                onClick={(e) => { e.target.select() }}
                                inputMode="numeric"
                                style={{
                                  fontSize: "18px",
                                }}
                                readOnly
                                value={item.quantity ? item.quantity : 0}
                              />
                              <span className="plus" onClick={() => {
                                // item.quantity น้อยกว่าหรือเท่ากับ item.quantityMax
                                if (item.quantity + 1 <= item.quantityMax) {
                                  dispatch({
                                    type: "UPDATE_BUY_NEW",
                                    payload: {
                                      ...item,
                                      key: item.key,
                                      imageURL: item.imageURL,
                                      productNameFull: item.productNameFull,

                                      quantity: parseInt(item.quantity + 1),
                                      purchasePrice: parseInt(item.purchasePrice),
                                      totalPrice: parseInt(item.totalPrice + item.purchasePrice),
                                      quantityMax: item.quantityMax
                                    }
                                  });
                                }

                              }} />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-2 col-md-2 col-12">
                          <p
                            className="mb-0  text-center"
                            style={{ color: "rgb(8, 24, 40)", fontWeight: 500 , fontSize: "17px", }}
                          >
                            รวม {item.totalPrice ? item.totalPrice.toLocaleString() : 0} บาท
                          </p>
                        </div>

                      </div>
                    </div>
                  </div>


                );
              })}



              <div className="row">
                <div className="col-12">
                  <div className="total-amount">
                    <div className="row">
                      <div
                        className="col-lg-4 col-md-6 col-12"
                        style={{ width: "100%" }}
                      >
                        <div className="right" >
                          {useBuyNew.length > 0 ? useBuyNew.map((i, k) => {
                            return (<div key={k}>
                              <ul style={{ listStyleType: "none",  }}>
                                <li style={{ fontSize: "18px", }}>
                                  {i.productNameFull} <span>{i.totalPrice ? i.totalPrice.toLocaleString() : 0
                                  } บาท</span>
                                </li>

                              </ul>
                            </div>)

                          }) : null}
                          <li style={{
                           
                            color: "#081828",
                            fontWeight: "500",
                            listStyleType: "none",
                            fontSize: "18px",
                          }}>
                            ราคารวมทั้งหมด<span style={{
                              display: "inline-block",
                              float: "right"
                            }}>{
                               (
                                  useBuyNew.length > 0 ? useBuyNew.map((i, k) => {
                                    return i.totalPrice
                                  }).reduce((a, b) => {
                                    return a + b
                                  }
                                  ).toLocaleString() : 0
                               ).toLocaleString()

                              } บาท</span>
                          </li>
                          <div className="button">
                            <button className="btn btn-alt" onClick={PostApi} style={{
                              fontSize: "18px",
                            }}>
                              ยืนยันคำสั่งซื้อ
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>) : (<Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100vh",
            backgroundColor: "#fde2df"
          }}
        >
          <CircularProgress />
        </Box>)}

      <GoTop />

      <Backdrop
        onClose={handleClose}
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={()=> setOpen(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

export default Index;
