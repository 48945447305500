 


const localStorageContact = (localStorageData, setData, setLoading, foodType) => {
    const dataState = localStorageData.data.filter((item) => { return item.foodType === foodType })[0]?.rawMaterial || []

      // เช็คว่ามีข้อมูลใน localStorage หรือยัง
      if(localStorage.getItem("rawMaterial")){
        // ถ้ามีข้อมูลใน localStorage ให้เอาข้อมูลใน localStorage มาเก็บไว้ในตัวแปร
        const localStorageRawMaterial = JSON.parse(localStorage.getItem("rawMaterial"))
        // เช็คว่า id ที่เข้ามาตรงกับ id ใน localStorage หรือไม่
        if(localStorageRawMaterial.id === localStorageData.id){ 
            const dataLocalStorage = localStorageRawMaterial.data.filter((item) => { return item.foodType === foodType })[0]?.rawMaterial || []
            const newData = dataLocalStorage.map((item) => {
                return {
                    ...item,
                    quantityRecipe: item.isRed === true ? dataState.filter((item2) => { return item2.key === item.key })[0]?.quantityRecipe || 0 : item.quantityRecipe || 0,
                    price: item.isRed === true ? dataState.filter((item2) => { return item2.key === item.key })[0]?.price || 0 : item.price || 0,
                    sellingPrice: dataState.filter((item2) => { return item2.key === item.key })[0]?.sellingPrice || 0,
                }
        })
            const productRed = newData.filter((item) => { return item.isRed === true && item.quantityRecipe }) 
            const productGreen = newData.filter((item) => { return item.isRed === false})
    

             setData([...productRed, ...productGreen])
           
            setLoading(false)
        }else{
            // ถ้าไม่ตรงกันให้เอาข้อมูลใน localStorage ใหม่มาใส่ในตัวแปร
            localStorage.setItem("rawMaterial", JSON.stringify(localStorageData))
            const productRed = dataState.filter((item) => { return item.isRed === true && item.quantityRecipe }) 
            const productGreen = dataState.filter((item) => { return item.isRed === false})
            setData([...productRed, ...productGreen])
            console.log(dataState)
            setLoading(false)
        }

    }else{
        // ถ้าไม่มีข้อมูลใน localStorage  
        localStorage.setItem("rawMaterial", JSON.stringify(localStorageData))
        const productRed = dataState.filter((item) => { return item.isRed === true && item.quantityRecipe }) 
        const productGreen = dataState.filter((item) => { return item.isRed === false})
        setData([...productRed, ...productGreen])
        setLoading(false)

    }
}


const localStorageEmployee = (localStorageData, setData, setLoading, foodType, priceLevel) => {

    const dataState = localStorageData.data.filter((item) => { return item.priceLevel === priceLevel })[0]?.rawMaterial.filter((item) => { return item.foodType === foodType })[0]?.product || []   
 
      
    // เช็คว่ามีข้อมูลใน localStorage หรือยัง
    if(localStorage.getItem("rawMaterial")){
        // ถ้ามีข้อมูลใน localStorage ให้เอาข้อมูลใน localStorage มาเก็บไว้ในตัวแปร
        const localStorageRawMaterial = JSON.parse(localStorage.getItem("rawMaterial"))
        // เช็คว่า id ที่เข้ามาตรงกับ id ใน localStorage หรือไม่
        if(localStorageRawMaterial.id === localStorageData.id){
            const dataLocalStorage = localStorageRawMaterial.data.filter((item) => { return item.priceLevel === priceLevel })[0]?.rawMaterial.filter((item) => { return item.foodType === foodType })[0]?.product || []
            // อัพเดท  quantityRecipe
         const newData = dataLocalStorage.map((item) => {
                return {
                    ...item,
                    quantityRecipe: item.isRed === true ? dataState.filter((item2) => { return item2.key === item.key })[0]?.quantityRecipe || 0 : item.quantityRecipe || 0,
                    price: item.isRed === true ? dataState.filter((item2) => { return item2.key === item.key })[0]?.price || 0 : item.price || 0,
                    sellingPrice: dataState.filter((item2) => { return item2.key === item.key })[0]?.sellingPrice || 0,
                }
        })


            const productRed = newData.filter((item) => { return item.isRed === true && item.quantityRecipe }) 
            const productGreen = newData.filter((item) => { return item.isRed === false})
        

            setData([...productRed, ...productGreen])
            console.log(newData)
            setLoading(false)


        } else {
            // ถ้าไม่ตรงกันให้เอาข้อมูลใน localStorage ใหม่มาใส่ในตัวแปร
            localStorage.setItem("rawMaterial", JSON.stringify(localStorageData))
            const productRed = dataState.filter((item) => { return item.isRed === true && item.quantityRecipe }) 
            const productGreen = dataState.filter((item) => { return item.isRed === false})

            setData([...productRed, ...productGreen])
            console.log(localStorageData)
            setLoading(false)

        }
    } else {
        // ถ้าไม่มีข้อมูลใน localStorage  
        localStorage.setItem("rawMaterial", JSON.stringify(localStorageData))
        const productRed = dataState.filter((item) => { return item.isRed === true && item.quantityRecipe }) 
        const productGreen = dataState.filter((item) => { return item.isRed === false})
        setData([...productRed, ...productGreen])
        console.log(localStorageData)
        setLoading(false)
        
        
    }
}


function setFirstVisit() {
    if (!localStorage.getItem('firstVisit')) {
      localStorage.setItem('firstVisit', new Date().getTime());
    }
  }
  
  function deleteAfter24Hours(key) {
    const firstVisit = localStorage.getItem('firstVisit');
    
    if (firstVisit) {
      const now = new Date().getTime();
      const hoursSinceFirstVisit = (now - firstVisit) / (1000 * 60 * 60);
      
      if (hoursSinceFirstVisit >= 24) {
        localStorage.removeItem(key);
        localStorage.removeItem('firstVisit');
      }
    }
  }



 


export { localStorageContact, localStorageEmployee, setFirstVisit, deleteAfter24Hours }