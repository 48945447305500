import React, { useEffect, useState } from 'react'
import styled from "styled-components";
import Heading from "./heading";
import Table from "./table";
import { useParams } from "react-router-dom";
import apiService from "../../api/apiService";
import swal from '@sweetalert/with-react';
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { setPaymentType, setPayment, setInvoiceCondition} from '../../components/invoiceCondition';
 

const Counter = styled.div`
  background-color: #ffecf8;
  width: 100%;
  min-height: 100vh;
  }
`;


function CheckPayInCash() {
 
  const { shipperID, date } = useParams();
  const service = apiService()
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])
 

  const [table_1, setTable_1] = useState([]);
  const [table_2, setTable_2] = useState([]);
  const [table_3, setTable_3] = useState([]);


  const getData = () => {
    setLoading(true)
    service.get({ url: `/api/v1/order/list-condition-by-shipper?shipperID=${shipperID}&shippingDate=${date}` }).then((res) => {
      console.log(res.data.result)
      setLoading(false)
      setData(res.data.result)

      const t1 = res.data.result.filter((item) => [
        20, 20.6, 20.9, 38, 39
      ].includes(item.invoiceCondition)).map((item) => {
        return {
          ...item,
          checkbox: item.invoiceCondition === 20 || item.invoiceCondition === 20.6 || item.invoiceCondition === 38  ? false : true,
          backgroundColorRouteOrder: item.shippedDate !== null ? "#ff8ccb" : "#acd3bd",
          backgroundColorCheckbox: item.invoiceCondition === 20 || item.invoiceCondition === 20.6 || item.invoiceCondition === 38  ? "#ffffb8" : "#ffd6e7", // สีเหลือง สีชมพู
          backgroundColorPaymentType: setPaymentType(item.paymentType).backgroundColor,
          payment: setPayment(item.invoiceCondition).text,
          backgroundColorPayment: setPayment(item.invoiceCondition).backgroundColor,
          colorPayment: setPayment(item.invoiceCondition).color,
          backgroundColorDate: setInvoiceCondition(item.invoiceCondition).backgroundColor,

        }
      }).sort((a, b) => a.orderID - b.orderID)

      setTable_1(t1)

        console.log("table_1", t1)
    
        

      setTable_2(  
        res.data.result.filter((item) => [
          16, 16.6, 16.9, 35, 36
        ].includes(item.invoiceCondition)).map((item) => {
          return {
            ...item,
             
            checkbox: item.invoiceCondition === 16 || item.invoiceCondition === 16.6 || item.invoiceCondition === 35  ? false : true,
            backgroundColorRouteOrder: item.shippedDate !== null ? "#ff8ccb" : "#acd3bd",
            backgroundColorCheckbox: item.invoiceCondition === 16 || item.invoiceCondition === 16.6 || item.invoiceCondition === 35 ? "#ffffb8" : "#ffd6e7", // สีเหลือง สีชมพู
            backgroundColorPaymentType: setPaymentType(item.paymentType).backgroundColor,
            payment: setPayment(item.invoiceCondition).text,
            backgroundColorPayment: setPayment(item.invoiceCondition).backgroundColor,
            colorPayment: setPayment(item.invoiceCondition).color,
          }
        
        }).sort((a, b) => a.orderID - b.orderID))


        setTable_3(  
          res.data.result.filter((item) => [
            6, 6.6, 8.6, 8.9, 10, 10.6, 12.6, 13
          ].includes(item.invoiceCondition)).map((item) => {
            return {
              ...item,
               
              checkbox: item.invoiceCondition === 6.6 || item.invoiceCondition === 6 || item.invoiceCondition === 10 || item.invoiceCondition === 10.6  ? false : true,
              backgroundColorRouteOrder: item.shippedDate !== null ? "#ff8ccb" : "#acd3bd",
              backgroundColorCheckbox: item.invoiceCondition === 6.6 || item.invoiceCondition === 6 || item.invoiceCondition === 10 || item.invoiceCondition === 10.6  ? "#ffffb8" : "#ffd6e7", // สีเหลือง สีชมพู
              backgroundColorPaymentType: setPaymentType(item.paymentType).backgroundColor,
              payment: setPayment(item.invoiceCondition).text,
              backgroundColorPayment: setPayment(item.invoiceCondition).backgroundColor,
              colorPayment: setPayment(item.invoiceCondition).color,
            }
          
          }).sort((a, b) => a.orderID - b.orderID))


    }).catch((err) => {
      console.log(err)
      swal({
        title: `${err.message}`,
        text: "กรุณาลองใหม่อีกครั้ง",
        icon: "error",
        button: "ตกลง",
      });
    });




  }


  useEffect(() => {
  
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipperID, date]);




  const props = {
    loading,
    data
  }

  return  (
    <Counter>
     
      <Heading />
     

      {
        loading ? <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100vh",
            backgroundColor: "#ffecf8",
          }}
        >
          <CircularProgress />
        </Box> : <Table props={props} getData={getData} service={service} table_1={table_1} table_2={table_2} table_3={table_3}/>
      }
  
    
    </Counter>
  );
}

export default CheckPayInCash;
