import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./style.css";
import NavBar from "./NavBar";
import Details from "./Details";
import apiService from "../../../api/apiService";
import { getResult } from "./utils";
import {
  convertLatLongToUTM,
  extectLatLongFromGGUrl,
  showError,
} from "../../../helper";
import Production from "../../../constants/serviceAPI.json";
import {
  getListOrderToShip,
  getProductList,
  getSellingPriceLevelList,
  getProofOfPayment,
  getListNotShipped,
  updateOrder,
  getOrder,
  getRouteList
} from "./api";
function OrdersDetails() {
  const { employeeID } = useParams();
  const service = apiService();
  const [OrderSelectedDate, setOrderSelectedDate] = useState(
    new Date().toISOString().slice(0, 10)
  );
  const [customer, setCustomer] = useState([]);

  const [navBar, setNavBar] = useState([]);
  const [orderID, setOrderID] = useState(null);
  const [orderDetailRecords, setOrderDetailRecords] = useState([]);
  const [product, setProduct] = useState([]);
  const [sellingPriceLevel, setSellingPriceLevel] = useState([]);
  const [amountToBePaid, setAmountToBePaid] = useState(0);
  const [shippingStatus, setShippingStatus] = useState(null);
  const [orderToReceiveCash, setOrderToReceiveCash] = useState(null);
  const [orderToReceiveSlip, setOrderToReceiveSlip] = useState(null);
  const [orderStatus, setOrderStatus] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [proofOfPayment, setProofOfPayment] = useState([]);
  const [listNotShipped, setListNotShipped] = useState([]);
  const [getGeoLocation, setGetGeoLocation] = useState(null);
  const [getGeoLocationDefault, setGetGeoLocationDefault] = useState(null);

  const [isDisable, setIsDisable] = useState(null);
  const [orderToReceiveNull, setOrderToReceiveNull] = useState(null);
  const [orderDate, setOrderDate] = useState(null);
 
  const [distance, setDistance] = useState(null);

  const [loadingGeoLocation, setLoadingGeoLocation] = useState(false);
    const [employeeNickname, setEmployeeNickname] = useState("");

  useEffect(() => {
    getRouteList().then((res) => {
      setEmployeeNickname(res.filter((item) => item.employeeID === Number(employeeID))[0]?.employeeNickname);                                      
    })
  }, [employeeID]);
  

  function haversineMeters(lat1, lon1, lat2, lon2) {
    console.log("checkHaversineMeters", {
      lat1,
      lon1,
      lat2,
      lon2,
    });
    setLoadingGeoLocation(true);
    const toRadians = degrees => (degrees * Math.PI) / 180;
    const R = 6371 * 1000; // รัศมีของโลกเป็นเมตร
  
    const dLat = toRadians(lat2 - lat1);
    const dLon = toRadians(lon2 - lon1);
  
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distanceMeters = R * c; // ระยะทางในหน่วยเมตร
    setDistance(distanceMeters ? Math.round(distanceMeters) : null);
     
    
    if(distanceMeters <= 1000){
      console.log("checkHaversineMeters", "อยู่ในพื้นที่");
     
      setIsDisable(false); // อยู่ในพื้นที่
      setLoadingGeoLocation(false);
    } else {
      console.log("checkHaversineMeters", "อยู่นอกพื้นที่");

      setIsDisable(true); // อยู่นอกพื้นที่
      setLoadingGeoLocation(false);

      console.log("checkHaversineMeters", {
        distanceMeters : Math.round(distanceMeters),
      });
    }


  }


  function showResolve(props) {
    const { position, data, getGeoLocation } = props;
    //18.762321011384845,99.005992048816
        console.log("showResolve", {
          position,
          data,
          getGeoLocation,
        });

      const latitude_default = getGeoLocation?.split(",")[0] ? Number(getGeoLocation?.split(",")[0]) : null;
      const longitude_default = getGeoLocation?.split(",")[1] ? Number(getGeoLocation?.split(",")[1]) : null;

      const calculate_latitude = position?.coords?.latitude || null
      const calculate_longitude = position?.coords?.longitude || null
      

      const latitude_user = latitude_default ? latitude_default : calculate_latitude;
      const longitude_user = longitude_default ? longitude_default : calculate_longitude;

      const latitude_customer = data?.split(",")[0] ? Number(data?.split(",")[0]) : null;
      const longitude_customer = data?.split(",")[1] ? Number(data?.split(",")[1]) : null;

      console.log("checkHaversineMeters", { latitude_user, longitude_user });
       setGetGeoLocation(`${calculate_latitude},${calculate_longitude}`); // ตำแหน่งของ user
 
       
      if(latitude_customer && longitude_customer && latitude_user && longitude_user){
        console.log("checkHaversineMeters", {
          latitude_customer,
          longitude_customer,
        });
        haversineMeters(latitude_user, longitude_user, latitude_customer, longitude_customer);
        
      } else {
        console.log("checkHaversineMeters", "err ShowResolve");
        setLoadingGeoLocation(false);
        return;
      }

   
  }



  function getLocation(data, getGeoLocation) { 
    console.log("getGeoLocation", getGeoLocation);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => showResolve({ position, data, getGeoLocation }),
        showError,
        {
          enableHighAccuracy: true,
        }
      );


    } else {
      setLoadingGeoLocation(false);
      alert("Geolocation is not supported by this browser.");
    }
  }

 


  
  function checkButton(orderID) {
   

     Promise.all([getOrder({orderID}), getProductList()]).then((res) => {
          const order = res[0];
          const checkButtonProduct = res[1];
       
          console.log("checkHaversineMeters", order?.getGeoLocation);
          setGetGeoLocationDefault(order?.getGeoLocation);
    
          
    
          // . if orderType == ORDER_SALESPERSON, ORDER_GIFT ? กดได้ : กดไม่ได้
          setDisabled(
            order.orderType === "ORDER_SALESPERSON" ||
            order.orderType === "ORDER_GIFT"
              ? false
              : true
          );
          setOrderStatus(order.orderStatus);
          setOrderToReceiveCash(order.payInCash === true ? true : false);
          setOrderToReceiveSlip(order.payInCash === false ? true : false);
          setOrderToReceiveNull(order.payInCash === null ? true : false);
          setOrderDate(order.orderDate);
         
          setOrderDetailRecords(
            order.orderDetailRecords.map((item) => {
              const productNameFull = checkButtonProduct.filter((productItem) => productItem.productID === item.productID);
              const price = Number(item?.unitPrice) || 0;
              const quantity = item.quantity || 0;
              const receivedQuantity = item.receivedQuantity
                ? item.receivedQuantity
                : quantity;
              return {
                ...item,
                productNameFull: productNameFull ? productNameFull[0]?.productNameFull : "-",
                productSequence: checkButtonProduct.filter((productItem) => productItem.productID === item.productID)[0]?.productSequence,
                price: price,
                totalAmount: Number(receivedQuantity) * Number(price),
                totalPrice: Number(order?.totalPrice || 0),
              };
            })
          );
          
          
     })

  }

  useEffect(() => {
    setShippingStatus(null);
    Promise.all([
      getListOrderToShip({ employeeID, OrderSelectedDate }),
      getProductList(),
      getSellingPriceLevelList(),
      getListNotShipped({ employeeID }),
    ]).then((res) => {

      console.log("listNotShipped", res[3]);
      setListNotShipped(res[3]);
      // เช็คว่ามี customerID หรือไม่
      const listOrderToShip = res[0].filter((item) => item.customerID !== null);
      const product = res[1];
      const sellingPriceLevel = res[2];
      setProduct(product);
      setSellingPriceLevel(sellingPriceLevel);

      console.log(listOrderToShip);

      //  ใช่ listOrderToShip กําหนดสีของ NavBar shippingStatus === "NOT_SHIPPED" ? สีชมพู : shippingStatus === "SHIPPED" || shippingStatus === "SHIPPED_PAIDED" ? สีเขียว : สีชมพู และ เรียงลําดับตาม routeOrder จากน้อยไปมาก
      if (listOrderToShip.length > 0) {
        const orderNavBar = listOrderToShip
          .sort((a, b) => a.orderID - b.orderID)
          .map((item, index) => {
            function getOrderType(orderType) {
              // ให้ orderType ที่เริ่มต้นด้วยตัวอักษร O ---> ORDER, ที่เริ่มต้นด้วยตัวอักษร R ---> REORDER
              if (orderType.startsWith("O")) {
                return "ORDER";
              } else if (orderType.startsWith("R")) {
                return "REORDER";
              }
            }

            const case1Conditions = {
              ingStockRequestID: item.ingStockRequestID,
              shippingStatus: item.shippingStatus,
              orderStatus: item.orderStatus,
              orderType: getOrderType(item.orderType),
            };

            const Status = getResult(case1Conditions);
            console.log("status", {
              case1Conditions,
              status: Status,
            });
            return {
              ...item,
              color: index === 0 ? "blue" : Status,
              defaultColor: Status,
            };
          });

        console.log(orderNavBar);
        setNavBar(orderNavBar);
        setOrderID(orderNavBar[0].orderID);

        setShippingStatus(orderNavBar[0].shippingStatus);
        console.log(orderNavBar[0].shippingStatus);
        checkButton(orderNavBar[0].orderID);

        if (orderNavBar[0].customerID) 
        {
          setLoadingGeoLocation(true);

          service
            .get({
              url: `/api/v1/customer/?customerID=${orderNavBar[0].customerID}`,
            })
            .then((res) => {
              // get customer
              setCustomer(res.data.result);
 
              service.get({ url: `/api/v1/order/?orderID=${orderNavBar[0].orderID}`}).then((order) => {
                getLocation(res.data.result?.deliveryGeoLocation, order.data.result.getGeoLocation);
                setGetGeoLocationDefault(order.data.result?.getGeoLocation);
              })
               
            });

          // 7. เอา customerID ที่ได้จาก order มายิง / api / v1 / financial / customer - info(Payload customerID) แล้วเอา response มาเก็บไว้ใน state ชื่อ customerInfo(เอาไปหา totalOutstandingBalance - advancePayment = ยอดที่ต้องชำระ)
          service
            .get({
              url: `/api/v1/financial/customer-info/?customerID=${orderNavBar[0].customerID}`,
            })
            .then((res) => {
              console.log(res.data.result);
              const totalOutstandingBalance = res.data.result
                .totalOutstandingBalance
                ? res.data.result.totalOutstandingBalance
                : 0;
              const advancePayment = res.data.result.advancePayment
                ? res.data.result.advancePayment
                : 0;
              setAmountToBePaid(totalOutstandingBalance - advancePayment);
            });

          getProofOfPayment({ customerID: orderNavBar[0].customerID }).then(
            (res) => {
              console.log(
                "ProofOfPayment",
                res.filter((item) => item.invoiceID === orderNavBar[0].orderID)
              );
              setProofOfPayment(
                res.filter((item) => item.invoiceID === orderNavBar[0].orderID)
              );
            }
          );
        } else {
          setCustomer([]);
        }

        // /api/v1/order
      } else {
        setNavBar([]);
        setOrderID(null);
        setCustomer([]);
        setOrderDetailRecords([]);
        setAmountToBePaid(0);
        setShippingStatus(null);
      }
    });
  }, [OrderSelectedDate]);

  return (
    <div className="wrapper d-flex align-items-stretch">
      <NavBar
        navBar={navBar}
        setNavBar={setNavBar}
        setOrderID={setOrderID}
        setCustomer={setCustomer}
        setOrderDetailRecords={setOrderDetailRecords}
        product={product}
        sellingPriceLevel={sellingPriceLevel}
        setAmountToBePaid={setAmountToBePaid}
        setShippingStatus={setShippingStatus}
        setOrderToReceiveCash={setOrderToReceiveCash}
        setOrderToReceiveSlip={setOrderToReceiveSlip}
        setOrderToReceiveNull={setOrderToReceiveNull}
        setGetGeoLocation={setGetGeoLocation}
        OrderSelectedDate={OrderSelectedDate}
        setOrderStatus={setOrderStatus}
        setDisabled={setDisabled}
        setProofOfPayment={setProofOfPayment}
        getProofOfPayment={getProofOfPayment}
        checkButton={checkButton}
        setLoadingGeoLocation={setLoadingGeoLocation}
        setIsDisable={setIsDisable}
        customer={customer}
        setGetGeoLocationDefault={setGetGeoLocationDefault}
        getLocation={getLocation}
        getGeoLocationDefault={getGeoLocationDefault}
        employeeID={employeeID}
      />
      <Details
        OrderSelectedDate={OrderSelectedDate}
        setOrderSelectedDate={setOrderSelectedDate}
        orderID={orderID}
        customer={customer}
        isDisable={isDisable}
        orderDetailRecords={orderDetailRecords}
        amountToBePaid={amountToBePaid}
        shippingStatus={shippingStatus}
        orderToReceiveCash={orderToReceiveCash}
        orderToReceiveSlip={orderToReceiveSlip}
        setOrderToReceiveCash={setOrderToReceiveCash}
        setOrderToReceiveSlip={setOrderToReceiveSlip}
        
        orderStatus={orderStatus}
        setNavBar={setNavBar}
        navBar={navBar}
        setShippingStatus={setShippingStatus}
        setOrderStatus={setOrderStatus}
        disabled={disabled}
        product={product}
        employeeID={employeeID}
        proofOfPayment={proofOfPayment}
        listNotShipped={listNotShipped}
        updateOrder={updateOrder}
        setDisabled={setDisabled}
        setOrderDetailRecords={setOrderDetailRecords}
        checkButton={checkButton}
        getGeoLocation={getGeoLocation}
        orderToReceiveNull={orderToReceiveNull}
        orderDate={orderDate}
       
        distance={distance}
      
        getGeoLocationDefault={getGeoLocationDefault}
        getLocation={getLocation}
        loadingGeoLocation={loadingGeoLocation}
        employeeNickname={employeeNickname}
      />
    </div>
  );
}

export default OrdersDetails;
