import React from 'react'
import { Link } from "react-router-dom";
function Navbar() {
  return (
    <div className="breadcrumbs d-none">
      <div
        className="container"
        style={{
          margin: "0 auto",
        }}
      >
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-6 col-12">
            <div className="breadcrumbs-content">
              <h1
                className="page-title"
                style={{ margin: 0, fontSize: "18px" }}
              >
                ตะกร้าสินค้า
              </h1>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-12">
            <ul className="breadcrumb-nav" style={{ margin: 0, padding: 0 }}>
              <li>
                <Link to="/">
                  <i className="lni lni-home" />
                  หน้าหลัก
                </Link>
              </li>
              <li>
                <Link to="/">สินค้า</Link>
              </li>
              <li>ตะกร้าสินค้า</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Navbar