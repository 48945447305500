

import React from 'react'

function Table({ data, setData, priceLevel, loading, foodType, userType}) {
    // ผมรวมของ unit
    const sumUnit =  data.filter((item) => { 
        return item.quantityRecipe > 0 && item.quantityRecipe !== null
    }).reduce((sum, item) => {
        const quantityRecipe = Number(item.quantityRecipe) || 0
        return sum + quantityRecipe
    }, 0)
    

    const sumCost = data.filter((item) => {
        return item.quantityRecipe > 0 && item.quantityRecipe !== null
    }).reduce((sum, item) => { 

        const price = Number(item.price) || 0
        return sum + price
        
     }, 0)

 

     const saveData = (e, index, productID, purchasePrice, multiplyFactor, sellingPrice) => {
        const value = e.target.value
 
        const formula = productID > 0 ? Number(purchasePrice) * Number(multiplyFactor) * Number(e.target.value) * Number(sellingPrice)  : Number(purchasePrice) * Number(multiplyFactor) * Number(e.target.value);       
         
        console.log("formula", {
            productID: productID,
            purchasePrice: purchasePrice,
            multiplyFactor: multiplyFactor,
            quantityRecipe: e.target.value,
            sellingPrice: sellingPrice,
            formula: formula,
        })
        if(userType === "CONTACT"){
            const newData = [...data]
            newData[index].quantityRecipe = Number(value)
            newData[index].price = Number(formula.toFixed(2)).toLocaleString()
            setData(newData)
            
            // เก็บค่า data ไว้ใน localStorage ชื่อ rawMaterial
            const localStorageData = JSON.parse(localStorage.getItem("rawMaterial"))
            localStorageData.data.filter((item) => { return item.foodType === foodType })[0].rawMaterial = newData
            localStorage.setItem("rawMaterial", JSON.stringify(localStorageData))
        } else if(userType === "EMPLOYEE"){
            const newData = [...data]
            newData[index].quantityRecipe = Number(value)
            newData[index].price = Number(formula.toFixed(2)).toLocaleString()
            setData(newData)
            
            // เก็บค่า data ไว้ใน localStorage ชื่อ rawMaterial
            const localStorageData = JSON.parse(localStorage.getItem("rawMaterial"))
            localStorageData.data.filter((item) => { return item.priceLevel === priceLevel })[0].rawMaterial.filter((item) => { return item.foodType === foodType })[0].product = newData
            localStorage.setItem("rawMaterial", JSON.stringify(localStorageData))
        }

     }

  
  return (
    <table className="table table-bordered   Table-costAruneeNoodles-border">
                      
                      <thead>
                          <tr style={{fontSize: "1rem", backgroundColor: "#eec0e1"}} >
                              <th scope="col" className='background-orange-costAruneeNoodles-1' style={{ width: "44%",  }}>วัตถุดิบ</th>
                              <th scope="col" className='background-blue-costAruneeNoodles-1' style={{ textAlign: "end", width: "28%"}}>
                                หน่วย
                              </th>
                              <th scope="col" className='background-blue-costAruneeNoodles-2' style={{ textAlign: "end", width: "28%"}}>
                                บาท
                              </th>
                            
                          </tr>
                      </thead>
                      <tbody>


                          {
                            data.map((item, index) => {
                               
                                return (
                                  <tr  key={index} >
                                    <th scope="row" style={{ width: "40%"}} className={item.isRed ? "background-orange-costAruneeNoodles-2" : "background-green-costAruneeNoodles-1"}>{item.ชื่อวัตถุดิบ}</th>
                                    <td  style={{ textAlign: "end", width: "100%", display: "flex", alignItems: "center", border: "none"}} className={item.isRed ? "background-blue-costAruneeNoodles-2" : "background-green-costAruneeNoodles-2"}>
                                        <input 
                                            onClick={(e) => { e.target.select() }}
                                            type="number"
                                            disabled={item.isRed}
                                            min="0"
                                            value={item.quantityRecipe || ""}
                                            onChange={(e) => saveData(e, index, item.productID, item.purchasePrice, item.multiplyFactor, item.sellingPrice)}
                                            inputMode="numeric"
                                            style={{ 
                                              width: "100%",
                                             textAlign: "end",
                                             border: "none",
                                              backgroundColor: "transparent",
                                            }}
                                        />
                                        <span style={{ marginLeft: "0.2rem", display: "flex", alignItems: "center", fontSize: "0.8rem"}}>
                                            {item.unit}
                                        </span>


                                    </td>

                                       <td style={{ textAlign: "end"}} className={item.isRed ? "background-blue-costAruneeNoodles-3" : "background-green-costAruneeNoodles-3"}>{
                                           !loading && item.quantityRecipe  ? item.price : ""
                                       }</td>
                                    </tr>
                                )
                            }
                            )
                            
                          }
                          
                          
                      </tbody>
                        <tfoot>
                            <tr style={{fontSize: "1rem"}}>
                                <th scope="col" style={{ width: "44%", backgroundColor: "#54d024"}}>ผลรวมต้นทุน</th>
                                <th scope="col" style={{ textAlign: "end", width: "28%", backgroundColor: "#88e863"}}>
                                    
                                </th>
                                <th scope="col" style={{ textAlign: "end", width: "28%", backgroundColor: "#afef97"}}>
                                    {/* ผมรวมของ cost */}
                                    {sumCost > 0 && sumCost.toFixed(2).toLocaleString()}
                                </th>
                            </tr>

                        </tfoot>
                  </table>
  )
}

export default Table