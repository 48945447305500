import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Cart from "./Cart";
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import apiService from "../../api/apiService";
import { useLocation } from "react-router-dom";


function GetShopDeta() {
  const location = useLocation();
  const service = apiService();
  const dispatch = useDispatch();
  const useLoadersReducer = useSelector((state) => state.useLoadersReducer);
  const [isError, setIsError] = useState(false);
  

 
  useEffect(() => {


    


    const getProduct = () => {
      return service.get({ url: `/api/v1/product/list` }).then((Product) => {
   
        return Product.data.result.filter((item) => item.productType === "aruneeFishballCustomer" && item.discontinued !== true)
      }).catch((err) => {
        console.log(err);
        setIsError(true);
      });

    };

    // /api/v1/selling-price-level/list
      const  sellingPriceLevel = () => {
        return service.get({ url: "/api/v1/selling-price-level/list" }).then((res) => {
          
          return res.data.result
        }).catch((err) => {
          console.log(err);
          setIsError(true);
        });

      };


  

    sellingPriceLevel().then((res) => {
        getProduct().then((Product) => {
          console.log("selling-price-level", res);
          console.log("product/list", Product);

          const listProduct = Product.map((item) => {
            const  sellingPriceLevel = res.filter((e) => e.productID === item.productID)[0];
            return {
              ...item,
              id: Number(item.productID),
              // สินค้า: item.productNameFull ? item.productNameFull : "ไม่มีข้อมูล", 
              สินค้า: item.productNameFull ? item.productNameFull : "ไม่มีข้อมูล",
              หมวด: "สินค้าทั่วไป",
              ราคา: sellingPriceLevel ? sellingPriceLevel.price : 0,
              // รูปภาพ: item.imageURL ? item.imageURL : process.env.REACT_APP_NO_IMAGE,
              รูปภาพ: item.imageURL ? item.imageURL : process.env.REACT_APP_NO_IMAGE,

              รหัสสินค้า: Number(item.productID) ? Number(item.productID) : 0,
              //  //  ให้สินค้า ปลาเส้นทอด (productID = 18) และปลาเส้นขาว (productID = 19) ให้เพิ่มทีละ 5 หน่วย
              จำนวน: Number(item.productID) === 18 || Number(item.productID) === 19 ? 5 : 1,
              // รายละเอียด: item.productDescription ? item.productDescription : "ไม่มีข้อมูล",
              รายละเอียด: item.productDescription ? item.productDescription : "ไม่มีข้อมูล",
              heart: JSON.parse(localStorage.getItem("heart"))?.filter((e) => e.id === Number(item.productID)).length > 0 ? true : false,
              ราคารวม: 0,

            };
            // ให้ product เรียงตาม productSequence (ปัจจุบันเรียงตาม productID)
          }).sort((a, b) => a.productSequence - b.productSequence);
        
          dispatch({ type: "SET_SHOP_DATA", payload: listProduct  });

      
          //เช็คว่ามี state ส่งมาหรือไม่ 
          if (location?.state && location?.state.length > 0) {
              const orderDetailRecords = location?.state;
              Promise.all(orderDetailRecords.map((item) => { 
                const { productID, quantity } = item;
                const { productNameFull, imageURL, productDescription } = listProduct.filter((e) => e.id === Number(productID))[0];
                return {
                  id: Number(productID),
                  รหัสสินค้า: Number(productID),
                  สินค้า: productNameFull,
                  ราคา: Number(item.purchasePrice),
                  รูปภาพ: imageURL,
                  จำนวน: Number(quantity),
                  ราคารวม: Number(item.purchasePrice) * Number(quantity),
                  หมวด: "สินค้าทั่วไป",
                  heart: JSON.parse(localStorage.getItem("heart"))?.filter((e) => e.id === Number(productID)).length > 0 ? true : false,
                  รายละเอียด: productDescription,

                }
              } )).then((res) => {
                dispatch({ type: "SET_CART_DATA", payload: res });
              })

          }   

            dispatch({ type: "SET_LOADERS", payload: false });
         

        })

    });

   
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, useLoadersReducer]);


     

    

  return (
    <Box className="container m-auto fade-in" sx={{
      width: '500px',
      '@media screen and (max-width: 600px)': {
        width: '100%',
      },

    }} >
     
      <div className="row " id="haed_false">
        {useLoadersReducer ?(
    <Box sx={{  display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '100vh',
       }}>
      <CircularProgress />
    </Box>
  )  : isError ? (
          <div>Error</div>
        ) : (
          <Cart />
        )}
      </div>
    </Box>
  );
}

export default GetShopDeta;
