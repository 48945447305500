import React, { useState } from 'react'
import Button from '@mui/material/Button';
import apiService from "../../../api/apiService";
import iconDefault from "./default.svg";
import iconRoute from "./route.svg";
import {
  getListOrderToShip,
  
} from "./api";
import { getResult } from "./utils";
const styleButton = {
  borderRadius: "0",
  width: "100%",
  textAlign: "center",
  padding: "10px",
  fontWeight: "bold",
  color: "#fff",
  backgroundColor: "unset",
  // min- width
  minWidth: "55px",
  '&:hover': {
    backgroundColor: "unset",
  }
}

function NavBar({
   navBar,
    setNavBar,
     setOrderID,
      setCustomer, 
      setOrderDetailRecords, 
      product, sellingPriceLevel, 
      setAmountToBePaid,
       setShippingStatus, 
       setOrderToReceiveCash, 
       setOrderToReceiveSlip, 
       setOrderToReceiveNull,
       OrderSelectedDate, 
       setOrderStatus,
        setDisabled,
        setProofOfPayment,
        getProofOfPayment,
        checkButton,
        setGetGeoLocation,
        setLoadingGeoLocation,
        setIsDisable,
        customer,
        setGetGeoLocationDefault,
        getLocation,
        getGeoLocationDefault,
        employeeID
       }) {
  const service = apiService();

      const [sortRouteID, setSortRouteID] = useState(false)


     
  

  const setLiActive = (index, orderID, customerID) => {
    getListOrderToShip({ employeeID, OrderSelectedDate }).then((res) => {
      const listOrderToShip = res.filter((item) => item.customerID !== null);
      if (listOrderToShip.length > 0) {
        const orderNavBar = listOrderToShip.sort((a, b) => a.orderID - b.orderID).map((item, index) => {
            function getOrderType(orderType) {
              // ให้ orderType ที่เริ่มต้นด้วยตัวอักษร O ---> ORDER, ที่เริ่มต้นด้วยตัวอักษร R ---> REORDER
              if (orderType.startsWith("O")) {
                return "ORDER";
              } else if (orderType.startsWith("R")) {
                return "REORDER";
              }
            }

            const case1Conditions = {
              ingStockRequestID: item.ingStockRequestID,
              shippingStatus: item.shippingStatus,
              orderStatus: item.orderStatus,
              orderType: getOrderType(item.orderType),
            };

            const Status = getResult(case1Conditions);
       
            return {
              ...item,
              color: index === 0 ? "blue" : Status,
              defaultColor: Status,
            };
          });

        setShippingStatus(orderNavBar.filter((item) => item.orderID === orderID)[0].shippingStatus)
      
      }
    })
    
    setIsDisable(null)
    setLoadingGeoLocation(true)
    const newNavBar = navBar.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          color: "blue"
        }
      } else {
        return {
          ...item,
          color: item.defaultColor
        }
      }
    })


    
    setNavBar(newNavBar) 
    setOrderID(orderID)

    setShippingStatus(navBar.filter((item) => item.orderID === orderID)[0].shippingStatus)

 
    if (customerID){
      service.get({ url: `/api/v1/customer/?customerID=${customerID}`}).then((res) => {
        setCustomer(res.data.result)
        console.log(res.data.result)
       
        service.get({ url: `/api/v1/order/?orderID=${orderID}`}).then((order) => {
          getLocation(res.data.result?.deliveryGeoLocation, order.data.result?.getGeoLocation)
     
          setGetGeoLocationDefault(order.data.result?.getGeoLocation);
        })
     

      })

      service.get({ url: `/api/v1/financial/customer-info/?customerID=${customerID}` }).then((res) => {
        const totalOutstandingBalance = res.data.result.totalOutstandingBalance ? res.data.result.totalOutstandingBalance : 0
        const advancePayment = res.data.result.advancePayment ? res.data.result.advancePayment : 0
        setAmountToBePaid(totalOutstandingBalance - advancePayment)
      })

      getProofOfPayment({ customerID: customerID }).then((res) => {
        console.log("ProofOfPayment", res.filter((item) => item.invoiceID ===  orderID))
        setProofOfPayment(res.filter((item) => item.invoiceID === orderID))
      })

    }else{
      setCustomer([])
    }

    checkButton(orderID)






   
  }


const sortRoute = sortRouteID ? navBar.sort((a, b) => a.routeOrder - b.routeOrder) : navBar.sort((a, b) => a.orderID - b.orderID)
 

  const li = sortRoute.map((item, index) => {
    return (
      <li className={`list-unstyled ${item.defaultColor} ${item.color}`} style={{ margin: "1px", }} key={index} onClick={() => setLiActive(index, item.orderID, item.customerID)}>
        <Button variant="contained" size="small" sx={{...styleButton,  color: item.color === "white" || item.color === "light-orange" ? "#000" : "#fff"}}>
          {item.orderID}
        </Button>
      </li>
    )
  })

  return (
    <nav id="sidebar" >
      <div className="custom-menu">
    
      </div>
      <div id="conten_ul"  >

        <ul className="list-unstyled components">


        
          {
            navBar.length > 0 ? 
            <>
      <li className={`list-unstyled`} style={{ margin: "1px", backgroundColor: "#dc3545" }}
       onClick={() => console.log(1)}>
        <Button variant="contained" size="small" sx={styleButton} onClick={() => setSortRouteID(!sortRouteID)}>
            {sortRouteID ? <img src={ iconRoute } alt="icon" style={{ width: "30px", height: "30px" }} /> : <img src={ iconDefault } alt="icon" style={{ width: "30px", height: "30px" }} />}

        </Button>
      </li>

              {li}
            </>
          
            : <li className={`list-unstyled`} style={{ margin: "1px", opacity: 0 }} >
              <Button variant="contained" size="small" sx={styleButton}>
             
              </Button>
               </li>
          }
      

        </ul>
      </div>
    </nav>

  )
}

export default NavBar