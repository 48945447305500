import React from 'react'

import Paper from '@material-ui/core/Paper';
import {
  MainTile,
  Tile,
  TableTile,
  stockRequestDate,
  stockRequestID,
  stockRequestDateData,
  stockRequestIDData

} from './style'
import { useHistory } from 'react-router-dom'



function Table_1({ stockRequestListByShipperID }) {
  const history = useHistory()
  return (
    <div style={{
      marginBottom: '2rem',
      marginTop: '.5rem',
    }}>
      <MainTile>
        <Paper style={Tile}>
          ใบเบิกที่ยังไม่ได้เบิก
        </Paper>
      </MainTile>

      <div style={TableTile}>
        <Paper style={stockRequestDate}>
          stockRequestDate
        </Paper>
        <Paper style={stockRequestID}>
          StockRequestID
        </Paper>
      </div>

     
      {
        stockRequestListByShipperID.map((item, index) => {
          return (
            <div style={TableTile} key={index}>
              <Paper style={stockRequestDateData}>
                {item.stockRequestDate ? new Date(item.stockRequestDate).toLocaleDateString('th-TH', { year: 'numeric', month: 'long', day: 'numeric' }) : "-"}
              </Paper>
              <Paper style={stockRequestIDData} onClick={() => {
                history.push(`/stockRequest2/${item.ingStockRequestID}`)
              }}>
                {item.ingStockRequestID}
              </Paper>
            </div>
          )
        })
      }
      

      <div>

      </div>

    </div>
  )
}

export default Table_1