export const useBuyNew = (state = [], action) => {
    switch (action.type) {
      case "SET_BUY_NEW":
        return action.payload;
      case "ADD_BUY_NEW":
        return [...state, action.payload];
      case "UPDATE_BUY_NEW":
        return state.map(buyNew => { return buyNew.key === action.payload.key ? action.payload : buyNew; });
      default:
        return state;
    }
  }