import React, { useState, useEffect, useRef} from "react";
import Box from "@material-ui/core/Box";
import Grid from "@mui/material/Grid";
import Paper from '@mui/material/Paper';
import apiService from "../../api/apiService";
import swal from '@sweetalert/with-react';
import Button from '@mui/material/Button';
import liff from "@line/liff/dist/lib";
import CircularProgress from '@mui/material/CircularProgress';
import './style.css';

function DateTime() {
 
  const service = apiService();
  const [Mon, setMon] = useState("00:00");
  const [Tue, setTue] = useState("00:00");
  const [Wed, setWed] = useState("00:00");
  const [Thu, setThu] = useState("00:00");
  const [Fri, setFri] = useState("00:00");
  const [Sat, setSat] = useState("00:00");
  const [Sun, setSun] = useState("00:00");
  const [customerID, setCustomerID] = useState(0);
  const [disabled, setDisabled] = useState(true);
  const formTime = useRef([]);
  const [loading, setLoading] = useState(true);

  const getLineGroupID = async () => {
    const url = window.location.href;
    
    const setUrl1 = url.split("?liff.state=%3FgroupID%3D")[1];
    const setUrl2 = url.split("?liff.state=%2FgroupID%3D")[1];
    const setUrl3 = url.split("?liff.state=%2F%3FgroupID%3D")[1];
    const setUrl = setUrl1 ? setUrl1 : setUrl2 ? setUrl2 : setUrl3 ? setUrl3 : null;

    const endUrl = setUrl?.split("#")[0] 
    console.log("endUrl", endUrl);
    return endUrl;
  };



  const getCustomer = async (gid) => {
 
    return service
      .get({
        url: `/api/v1/customer/list`,
      })
      .then((res) => {
        const result = res.data.result.filter((item) => item.lineGroupID === gid)[0]?.customerID
        console.log("result",{
            customerID: result,
            lineGroupID: gid,
            "/api/v1/customer/list": res.data.result.filter((item) => item.lineGroupID === gid)
        });
        return result 
      })
      .catch((err) => {
     
        swal({
          title: "เกิดข้อผิดพลาด",
          content: <p>{err.message}</p>,
          icon: "error",
          closeOnClickOutside: false,
          buttons: {
            cancel: "ปิด",
          },
        }).then((value) => {
          switch (value) {
            default:
              
                liff.closeWindow()
             
              console.log("close");
          }
        });
      });
  };

  
const getDate = async (e) => {
  setCustomerID(Number(e));
  return service
    .get({ url: `/api/v1/customer/bot-alert-time?customerID=${e}`})
    .then((res) => {

      for(let i = 0; i < res.data.result.length; i++){
        if(res.data.result[i].dateID === 1){
          setMon(res.data.result[i].time.length === 5 ? res.data.result[i].time : `0${res.data.result[i].time}`);
        }else if(res.data.result[i].dateID === 2){
          setTue(res.data.result[i].time.length === 5 ? res.data.result[i].time : `0${res.data.result[i].time}`)
        }else if(res.data.result[i].dateID === 3){
          setWed(res.data.result[i].time.length === 5 ? res.data.result[i].time : `0${res.data.result[i].time}`)
        }else if(res.data.result[i].dateID === 4){
          setThu(res.data.result[i].time.length === 5 ? res.data.result[i].time : `0${res.data.result[i].time}`)
        }else if(res.data.result[i].dateID === 5){
          setFri(res.data.result[i].time.length === 5 ? res.data.result[i].time : `0${res.data.result[i].time}`)
        }else if(res.data.result[i].dateID === 6){
          setSat(res.data.result[i].time.length === 5 ? res.data.result[i].time : `0${res.data.result[i].time}`)
        }else if(res.data.result[i].dateID === 7){
          setSun(res.data.result[i].time.length === 5 ? res.data.result[i].time : `0${res.data.result[i].time}`)
        }
      }

        formTime.current = {
          Mon: res.data.result.length >= 1 ? res.data.result[0].time : "00:00",
          Tue: res.data.result.length >= 2 ? res.data.result[1].time : "00:00",
          Wed: res.data.result.length >= 3 ? res.data.result[2].time : "00:00",
          Thu: res.data.result.length >= 4 ? res.data.result[3].time : "00:00",
          Fri: res.data.result.length >= 5 ? res.data.result[4].time : "00:00",
          Sat: res.data.result.length >= 6 ? res.data.result[5].time : "00:00",
          Sun: res.data.result.length >= 7 ? res.data.result[6].time : "00:00",
        };
    })
    .catch((err) => {
      throw err;
    });
};

  useEffect(() => {
     setLoading(true);
     getLineGroupID().then((lineGroupID)=>{
      getCustomer(lineGroupID).then((e)=>{
        getDate(e);
        setLoading(false);
     })
     
 })
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);


  const isChangeTimeAndInput = (input, value) => {
    const isChangeMon = input === "Mon" ? formTime.current.Mon !== value ||
    formTime.current.Sun !== Sun ||
    formTime.current.Sat !== Sat ||
    formTime.current.Fri !== Fri ||
    formTime.current.Thu !== Thu ||
    formTime.current.Wed !== Wed ||
    formTime.current.Tue !== Tue ? true : false : false;
    
    const isChangeTue = input === "Tue" ? formTime.current.Tue !== value ||
    formTime.current.Sun !== Sun ||
    formTime.current.Sat !== Sat ||
    formTime.current.Fri !== Fri ||
    formTime.current.Thu !== Thu ||
    formTime.current.Wed !== Wed ||
    formTime.current.Mon !== Mon ? true : false : false;

    const isChangeWed = input === "Wed" ? formTime.current.Wed !== value ||
    formTime.current.Sun !== Sun ||
    formTime.current.Sat !== Sat ||
    formTime.current.Fri !== Fri ||
    formTime.current.Thu !== Thu ||
    formTime.current.Tue !== Tue ||
    formTime.current.Mon !== Mon ? true : false : false;

    const isChangeThu = input === "Thu" ? formTime.current.Thu !== value ||
    formTime.current.Sun !== Sun ||
    formTime.current.Sat !== Sat ||
    formTime.current.Fri !== Fri ||
    formTime.current.Wed !== Wed ||
    formTime.current.Tue !== Tue ||
    formTime.current.Mon !== Mon ? true : false : false;

    const isChangeFri = input === "Fri" ? formTime.current.Fri !== value ||
    formTime.current.Sun !== Sun ||
    formTime.current.Sat !== Sat ||
    formTime.current.Thu !== Thu ||
    formTime.current.Wed !== Wed ||
    formTime.current.Tue !== Tue ||
    formTime.current.Mon !== Mon ? true : false : false;

    const isChangeSat = input === "Sat" ? formTime.current.Sat !== value ||
    formTime.current.Sun !== Sun ||
    formTime.current.Fri !== Fri ||
    formTime.current.Thu !== Thu ||
    formTime.current.Wed !== Wed ||
    formTime.current.Tue !== Tue ||
    formTime.current.Mon !== Mon ? true : false : false;

    const isChangeSun = input === "Sun" ? formTime.current.Sun !== value ||
    formTime.current.Sat !== Sat ||
    formTime.current.Fri !== Fri ||
    formTime.current.Thu !== Thu ||
    formTime.current.Wed !== Wed ||
    formTime.current.Tue !== Tue ||
    formTime.current.Mon !== Mon ? true : false : false;


    setDisabled(
      isChangeMon ||
      isChangeTue ||
      isChangeWed ||
      isChangeThu ||
      isChangeFri ||
      isChangeSat ||
      isChangeSun ? false : true
    );

  }


  return loading ? (
    <Box sx={{ 
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '100vh',
       }}>
      <CircularProgress />
    </Box>
  ) : (<Box style={{ display: "flex", justifyContent: "center" }}>
      <div className="container" id="EditDate" style={{ 
         paddingTop: ".75rem",paddingBottom: ".75rem",maxWidth:"500px"}}>
       <div className="row" style={{ 
        margin: "0",
         width: "100%", 
         backgroundColor: "#f5f5f5",
 
        }} >
         <div className="col-md-6 border p-4 shadow " style={{ width: "100%", }}>


         <div className="col-12">
              <h3 className="fw-normal   fs-4  mb-2" style={{
                fontSize: "18px",
                color: "#333",
                fontWeight: "bold",
                textAlign: "center"
              }}>
                เวลาที่สะดวกให้บอททักให้สั่งของ
              </h3>
              <p className="mb-2" style={{
                textAlign: "center",
              }}>ถ้าไม่ต้องการบอทให้ทัก ให้กรอก 00:00 นะคะ</p>
            </div>

         


            <Paper className="p-0">
                <Grid container spacing={0}>
                  <Grid item xs={4} sx={{
                    textAlign: "center",
                    padding: "8.5px 14px",
                    border: "1px solid #e4e4e4",
                    borderBottom: "none",
                    borderRight: "none",
                    background: "#f5f5f5",
                    color: "#333",
                  }}>
                    วัน

                  </Grid>
                  <Grid item xs={8} sx={{
                    textAlign: "center",
                    padding: "8.5px 14px",
                    border: "1px solid #e4e4e4",
                    borderBottom: "none",
                    borderRight: "none",
                    background: "#f5f5f5",
                    color: "#333",
                  }}>
                    ชั่วโมง : นาที
                  </Grid>

                </Grid>






                <Grid container spacing={0} sx={{
                  marginTop: "0px",
                }}>
                  <Grid item xs={4} sx={{
                    textAlign: "center",
                    padding: "8.5px 14px",
                    border: "1px solid #e4e4e4",
                    borderRight: "none",
                    color: "#333",
                    fontSize: "20px",

                  }}>
                    จันทร์

                  </Grid>
                  <Grid item xs={8} sx={{
                    textAlign: "center",
                    border: "1px solid #e4e4e4",
                    borderRight: "none",

                  }}>

                    <input
                      type="time"
                      className="form-control"
                     
                      onChange={(e) => {
                        setMon(e.target.value)
                        isChangeTimeAndInput("Mon", e.target.value)
                        
                      }}
                      value={Mon}
                      style={{
                        padding: "8.5px 14px",
                        border: 0,
                        textAlign: "center",
                        borderRadius: 0,
                        fontSize: "20px",
                      }}
                    />

                  </Grid>

                </Grid>






                <Grid container spacing={0} sx={{
                  marginTop: "0px",
                }}>
                  <Grid item xs={4} sx={{
                    textAlign: "center",
                    padding: "8.5px 14px",
                    border: "1px solid #e4e4e4",
                    borderRight: "none",
                    color: "#333",
                    fontSize: "20px",

                  }}>
                    อังคาร

                  </Grid>
                  <Grid item xs={8} sx={{
                    textAlign: "center",
                    border: "1px solid #e4e4e4",
                    borderRight: "none",

                  }}>

                    <input
                      type="time"
                      className="form-control"

                      onChange={(e) =>{
                        setTue(e.target.value)
                        isChangeTimeAndInput("Tue", e.target.value)
                        
                      }}
                      value={Tue}
                      style={{
                        padding: "8.5px 14px",
                        border: 0,
                        borderRadius: 0,
                        textAlign: "center",
                        fontSize: "20px",
                      }}
                    />

                  </Grid>

                </Grid>





                <Grid container spacing={0} sx={{
                  marginTop: "0px",
                }}>
                  <Grid item xs={4} sx={{
                    textAlign: "center",
                    padding: "8.5px 14px",
                    border: "1px solid #e4e4e4",
                    borderRight: "none",
                    color: "#333",
                    fontSize: "20px",

                  }}>
                    พุธ

                  </Grid>
                  <Grid item xs={8} sx={{
                    textAlign: "center",
                    border: "1px solid #e4e4e4",
                    borderRight: "none",

                  }}>
                    <input
                      type="time"
                      className="form-control"

                      onChange={(e) => {
                        setWed(e.target.value)
                        isChangeTimeAndInput("Wed", e.target.value)
                      }}
                      value={Wed}
                      style={{
                        padding: "8.5px 14px",
                        border: 0,
                        borderRadius: 0,
                        textAlign: "center",
                        fontSize: "20px",
                      }}
                    />


                  </Grid>

                </Grid>





                <Grid container spacing={0} sx={{
                  marginTop: "0px",
                }}>
                  <Grid item xs={4} sx={{
                    textAlign: "center",
                    padding: "8.5px 14px",
                    border: "1px solid #e4e4e4",
                    borderRight: "none",
                    color: "#333",
                    fontSize: "20px",


                  }}>
                    พฤหัสบดี

                  </Grid>
                  <Grid item xs={8} sx={{
                    textAlign: "center",
                    border: "1px solid #e4e4e4",
                    borderRight: "none",

                  }}>
                    <input
                      type="time"
                      className="form-control"

                      onChange={(e) =>{
                        setThu(e.target.value)
                        isChangeTimeAndInput("Thu", e.target.value)
                      }}
                      value={Thu}
                      style={{
                        padding: "8.5px 14px",
                        border: 0,
                        borderRadius: 0,
                        textAlign: "center",
                        fontSize: "20px",
                      }}
                    />


                  </Grid>

                </Grid>




                <Grid container spacing={0} sx={{
                  marginTop: "0px",
                }}>
                  <Grid item xs={4} sx={{
                    textAlign: "center",
                    padding: "8.5px 14px",
                    border: "1px solid #e4e4e4",
                    borderRight: "none",
                    color: "#333",
                    fontSize: "20px",

                  }}>
                    ศุกร์

                  </Grid>
                  <Grid item xs={8} sx={{
                    textAlign: "center",
                    border: "1px solid #e4e4e4",
                    borderRight: "none",

                  }}>
                    <input
                      type="time"
                      className="form-control"

                      onChange={(e) =>{
                        setFri(e.target.value)
                        isChangeTimeAndInput("Fri", e.target.value)
                      }}
                      value={Fri}
                      style={{
                        padding: "8.5px 14px",
                        border: 0,
                        borderRadius: 0,
                        textAlign: "center",
                        fontSize: "20px",
                      }}
                    />
                  </Grid>

                </Grid>





                <Grid container spacing={0} sx={{
                  marginTop: "0px",
                }}>
                  <Grid item xs={4} sx={{
                    textAlign: "center",
                    padding: "8.5px 14px",
                    border: "1px solid #e4e4e4",
                    borderRight: "none",
                    color: "#333",
                    fontSize: "20px",

                  }}>
                    เสาร์

                  </Grid>
                  <Grid item xs={8} sx={{
                    textAlign: "center",
                    border: "1px solid #e4e4e4",
                    borderRight: "none",

                  }}>
                    <input
                      type="time"
                      className="form-control"

                      onChange={(e) => {
                        setSat(e.target.value)
                        isChangeTimeAndInput("Sat", e.target.value)
                      }}
                      value={Sat}
                      style={{
                        padding: "8.5px 14px",
                        border: 0,
                        borderRadius: 0,
                        textAlign: "center",
                        fontSize: "20px",
                      }}
                    />

                  </Grid>

                </Grid>




                <Grid container spacing={0} sx={{
                  marginTop: "0px",
                }}>
                  <Grid item xs={4} sx={{
                    textAlign: "center",
                    padding: "8.5px 14px",
                    border: "1px solid #e4e4e4",
                    borderRight: "none",
                    color: "#333",
                    fontSize: "20px",

                  }}>
                    อาทิตย์

                  </Grid>
                  <Grid item xs={8} sx={{
                    textAlign: "center",
                    border: "1px solid #e4e4e4",
                    borderRight: "none",

                  }}>
                    <input
                      type="time"
                      className="form-control"

                      onChange={(e) =>{
                        setSun(e.target.value)
                        isChangeTimeAndInput("Sun", e.target.value)
                      }}
                      value={Sun}
                      style={{
                        padding: "8.5px 14px",
                        border: 0,
                        borderRadius: 0,
                        textAlign: "center",
                        fontSize: "20px",
                      }}
                    />

                  </Grid>

                </Grid>
              </Paper>

              
              <Paper className="col-md-12 mt-4 p-0 hadow-sm" >
              <Button
                  disabled={disabled}
                  onClick={()=>{
                    const Time = {
                      customerID: customerID,
                        data: [ 
                          {
                          customerID: customerID,
                          dateID: 1,
                          date: new Date().toISOString().slice(0, 10),
                          time:  Mon,
                        },
                        {
                          customerID: customerID,
                          dateID: 2,
                          date:  new Date().toISOString().slice(0, 10),
                          time:  Tue,
                        },
                        {
                          customerID: customerID,
                          dateID: 3,
                          date:  new Date().toISOString().slice(0, 10),
                          time:  Wed,
                        },
                        {
                          customerID: customerID,
                          dateID: 4,
                          date:  new Date().toISOString().slice(0, 10),
                          time:  Thu,
                        },
                        {
                          customerID: customerID,
                          dateID: 5,
                          date:  new Date().toISOString().slice(0, 10),
                          time:  Fri,
                        },
                        {
                          customerID: customerID,
                          dateID: 6,
                          date:  new Date().toISOString().slice(0, 10),
                          time:  Sat,
                        },
                        {
                          customerID: customerID,
                          dateID: 7,
                          date:  new Date().toISOString().slice(0, 10),
                          time:  Sun,
                        },]
                    }


                    
                
                  
                      service.post({ url: `/api/v1/customer/update-bot-alert-time`, params:Time }).then((res) => {
                        console.log(res.data.result);
                        console.log(Time);
                        
                        if (res.status === 200) {
                      

                          for(let i = 0; i < res.data.result.length; i++){
                            if(res.data.result[i].dateID === 1){
                              setMon(res.data.result[i].time)
                            }else if(res.data.result[i].dateID === 2){
                              setTue(res.data.result[i].time)
                            }else if(res.data.result[i].dateID === 3){
                              setWed(res.data.result[i].time)
                            }else if(res.data.result[i].dateID === 4){
                              setThu(res.data.result[i].time)
                            }else if(res.data.result[i].dateID === 5){
                              setFri(res.data.result[i].time)
                            }else if(res.data.result[i].dateID === 6){
                              setSat(res.data.result[i].time)
                            }else if(res.data.result[i].dateID === 7){
                              setSun(res.data.result[i].time)
                            }
                          }
                         
                        
                          
                          swal({
                            title: "บันทึกสำเร็จ",
                            text: "ข้อมูลของคุณถูกบันทึกเรียบร้อยแล้ว",
                            icon: "success",
                            button: "ตกลง",
                          }).then(() => {
                           setDisabled(true);
                          }).catch(err => { throw err });
                        }
                      }).catch((err) => { 
                        swal({
                          title: 'เกิดข้อผิดพลาด',
                          content: <p>{customerID}</p>,
                          icon: 'error',
                          closeOnClickOutside: false,
                          buttons: {
                            cancel: "ปิด",
                          },
                        }).then((value) => {
                          switch (value) {
                            default:
                            
                                liff.closeWindow()
                              
                              console.log("close");
                          }
                        });

                       });
                    
                  }}
                  variant="contained"
                  className="btn-endit-conf"
                  size="large" 
                  sx={{
                    fontSize: "20px !important",
                  }}
                  >บันทึก</Button>
              </Paper>
        </div>
       </div>
      </div>
    </Box>
  );
}

export default DateTime;
