import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Toastify from "toastify-js";
import LoadingButton from "@mui/lab/LoadingButton";
import UploadFile from "../uploadFile";
import apiService from "../../../../api/apiService";
import {
  Upload,
  FileImage,
  PaymentText,
} from "../../../paymentAmount/payment.module";
import {
  ToastifyError,
  copyToClipboard,
  swalChange,
  swalSuccess,
  Toastifys,
} from "../tools";
import { useParams, useLocation } from "react-router-dom";
import swal from "@sweetalert/with-react";
import CircularProgress from "@mui/material/CircularProgress";
import imageCompression from 'browser-image-compression';
import { useHistory } from 'react-router-dom';
import liff from "@line/liff/dist/lib"



const Counter = styled.div`
  background-color: #f9ecff;
  width: 100%;
  min-height: 100vh;
  padding: 1rem;
`;

const Main = styled.div`
  background-color: #fff;
  min-height: 100vh;
  padding: 1rem;
  padding-top: 2rem;
  width: 600px;
  margin: 0 auto;
  @media (max-width: 768px) {
    width: 100%;
  }
  h1 {
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    color: #1a1a1a;
    margin-bottom: 1.5rem;
  }
`;

const List = styled.div`
  display: flex;
  justify-content: center;
  .list {
    width: 500px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    margin-top: 1rem;

    .list__detail {
      display: flex;
      justify-content: space-between;
      padding: 0.5rem;
      background-color: #f9ecff;
      .list__detail__title {
        font-size: 16px;
        color: #1a1a1a;
      }
    }

    .list__detail_qrt {
      display: flex;
      justify-content: space-between;
      padding: 0.5rem;
      background-color: #f7a4d8;
      border-radius: 0 0 10px 10px;
      .list__detail_qrt__title {
        font-size: 16px;
        color: #1a1a1a;
      }
    }

    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;

const UploadFileContent = styled.div`
  width: 100%;
  height: 100%;
`;

const Button = styled.div`
  width: 500px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  margin-top: 1rem;

  .Button {
    margin: 0 auto;
    margin-top: 1.5rem;
    border-radius: 10px;
    font-size: 18px;
    padding: 0.4rem;
    background-color: #2652f9;
    border: 1px solid #fff;
    font-weight: 600;
    transition: all 0.3s ease;
    color: #fff;
    @media (max-width: 768px) {
      width: 100%;
    }

    &:active {
      background-color: #0a3cfc;
      color: #fff;
      border: 1px solid #2652f9;
    }
  }

  @media (max-width: 768px) {
    width: 85%;
  }
`;

function ShipperNotEnoughMoney() {
  const service = apiService();
 
  const [FileUrl, setFileUrl] = useState(null);
  const [FileLoading, setFileLoading] = useState("");
  const [FilePost, setFilePost] = useState(null);
  const [LoadingButtonCheck, setLoadingButtonCheck] = useState(false);
  const [advancePayment, setAdvancePayment] = useState(0);
  const [Loading, setLoading] = useState(true);
  const { orderID } = useParams();
  const location = useLocation();
  const [orderDetailRecords, setOrderDetailRecords] = useState([]);
  const history = useHistory();
  const [Order2, setOrder2] = useState([]);
  const [customerID, setCustomerID] = useState(null);
  // const [receiptAmount, setReceiptAmount] = useState(location.state.receiptAmount ? location.state.receiptAmount : 0);
  const confirm = () => {

    swal({
      title: "คำสั่งซื้อของคุณลูกค้าเรียบร้อยแล้วค่ะ",
      text: "ขอบคุณที่ใช้บริการ",
      icon: "success",
      button: "ตกลง",
      className: "swal-text-check-user-payment",
    }).then((value) => {


      liff.closeWindow()

    });

  }
  function getApi() {
    service.get({ url: "/api/v1/product/list" }).then((product) => {
      service
        .get({
          url: `/api/v1/order/?orderID=${orderID}`,
        })
        .then((res) => {
          console.log(res.data.result);
          setLoading(false);

          setOrderDetailRecords(res.data.result)

          const customerID = res.data.result.customerID;
          setCustomerID(customerID);
          
          if (customerID !== null) {
            service.get({ url: `/api/v1/order/list-by-customer?customerID=${customerID}` }).then((res) => {


              const result2 = res.data.result.filter((item) => { return item.paidDate === null })

              console.log(result2);


              setOrder2(result2);
              setLoadingButtonCheck(false);
              setFileUrl(null);
              setFileLoading("");
              setFilePost(null);
              setLoading(false);


              service.get({ url: `/api/v1/financial/advance-payment?customerID=${customerID}`, }).then((e) => {
                setAdvancePayment(e.data.result.advancePayment);
                const ยอดต้องชำระ = Number((result2.length > 0 ? result2.map((item) => { return item.totalPrice }).reduce((a, b) => a + b) : 0))
                const ยังต้องชำระอีก = ยอดต้องชำระ - Number(e.data.result.advancePayment)

                if (ยังต้องชำระอีก === 0) {
                  confirm()
                }

              });

            }).catch((err) => {
              swal({
                title: `${err.message}`,
                className: "swal-text-check-user",
                icon: "warning",
                button: "ตกลง",
              }).then((value) => {
               
                  liff.closeWindow()
                
              });
            })
        
          }
        }).catch((err) => {
          swal.close();
          swal({
            title: "Error",
            text: `${err.message}`,
            icon: "error",
            button: "ตกลง",
          });
          console.log(err);
        });
    });
  }


  const ยอดต้องชำระ = Number((Order2.length > 0 ? Order2.map((item) => { return item.totalPrice }).reduce((a, b) => a + b) : 0))
  const ชำระมาแล้ว = Number(advancePayment)
  const ยังต้องชำระอีก = ยอดต้องชำระ - Number(advancePayment)


  useEffect(() => {
 
  getApi()
    
  }, []);

  


  const documentClick = () => {
    document.getElementById("paymentAmountFile").click();
  };

  function fetchRequest() {
    const formData = new FormData();
    formData.append("file", FilePost);
    setFileLoading("loading");
    setLoadingButtonCheck(true);


    const reset = () => {
      setFileUrl(null);
      setFileLoading("");
      setFilePost(null);
      document.getElementById("paymentAmountFile").value = null;
      setLoadingButtonCheck(false);
    };
    // /api/v1/financial/upload-slip
    async function uploadSlip(body) {
      return await service.post({ url: "/api/v1/financial/upload-slip", params: body }).then((res) => {
        return res.data.result;
      }).catch((err) => {
        swalChange(false, "065-1-90826-4")
        ToastifyError(err.message)
        reset();
      })

    }


    function uploadImage(resultQR) {
      const formData = new FormData();
      formData.append("image", FilePost);
      service.post({ url: "api/v1/upload-image", params: formData, headers: { "Content-Type": "multipart/form-data" } }).then((img) => {
        const slipUrl = img.data.result.url
        const body = {
          "slipUrl": slipUrl,
          "data": resultQR,
          "customerID": customerID,
        }

        uploadSlip(body).then((res) => {
          // success === false
          if (res.success === false) {
            ToastifyError(res.message)
            reset();
            swalChange(false, "065-1-90826-4")
          } else {
             getApi()
          

          }
        })

      }).catch((err) => {
        ToastifyError(`${err.message}`)
        reset();
        swalChange(false, "065-1-90826-4")
        console.log(err);
      })
    }
    fetch("https://api.qrserver.com/v1/read-qr-code/", {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((r) => {
        const resultQR = r[0].symbol[0].data;
        console.log(resultQR);
        if (!resultQR) {
          ToastifyError("สลิปของท่านไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง")
          reset();
          swalChange(false, "065-1-90826-4")
        } else {
          setFileLoading("success");
          setLoadingButtonCheck(true);
          uploadImage(resultQR);
        }
      })
      .catch((e) => {
        console.log(e);
        ToastifyError("สลิปของท่านไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง");
        reset();
      });
  }

  const fileChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    // เช็คขนาดไฟล์ มากกว่า 1 MB ให้บีบอัด
    if (file.size > 1000000) {
      const options = {
        maxSizeMB: 0.5,
        maxWidthOrHeight: 1920,
        useWebWorker: true
      }
      imageCompression(file, options)
        .then(function (compressedFile) {
          // size in KB
   
          setFilePost(compressedFile);
        })
        .catch(function (error) {
          console.log(error.message);
        });
      setFileUrl(URL.createObjectURL(file));
    } else {
      setFilePost(file);
      setFileUrl(URL.createObjectURL(file));
    }
    
  }




  return (
    <Counter>
      <Main className="shadow-sm">
        <h1>ชำระเงินไม่ครบถ้วน</h1>

        {Loading === true ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "2rem",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <List>
            <div className="list shadow-sm">
              <div
                className="list__detail"
                style={{
                  borderBottom: "2px solid #dfdfdf",
                }}
              >
                <div className="list__detail__title">ยอดต้องชำระ</div>
                <div className="list__detail__title">
                  {ยอดต้องชำระ.toLocaleString()} บาท
                </div>
              </div>

              <div className="list__detail">
                <div className="list__detail__title">ชำระมาแล้ว</div>
                <div className="list__detail__title">
                  {ชำระมาแล้ว.toLocaleString()} บาท
                </div>
              </div>

              <div className="list__detail_qrt">
                <div className="list__detail_qrt__title">ยังต้องชำระอีก</div>
                <div className="list__detail_qrt__title">
                  {ยังต้องชำระอีก.toLocaleString()} บาท
                </div>
              </div>
            </div>
          </List>
        )}


        <PaymentText className="shadow-sm PaymentText" >
          <div className="Payment">
            <div className="Payment__title">หมายเลขบัญชีธนาคาร</div>
            <div className="Payment__detail">
              ของ บริษัท สุวรรณภูมิ ฟู้ด จำกัด
            </div>

            <div style={{ margin: "15px 0" }}>
              <div className="Payment__detail_dank">ธนาคารไทยพาณิชย์ เลขที่บัญชี</div>
              <div className="Payment__detail_dank_number" style={{ color: "#8A2BE2" }} onClick={() => copyToClipboard("347-404461-8")}>{"347-404461-8"}</div>
            </div>

            <div>
              <div className="Payment__detail_dank">ธนาคารกสิกรไทย เลขที่บัญชี</div>
              <div className="Payment__detail_dank_number " onClick={() => copyToClipboard("065-1-90826-4")}>{"065-1-90826-4"}</div>
            </div>

            <div className="Payment__detail_coppy mt-2">กดที่ตัวเลขเพื่อคัดลอก</div>
          </div>
        </PaymentText>

        <UploadFile
          File={File}
          FileUrl={FileUrl}
          documentClick={documentClick}
          fileChange={fileChange}
          FileLoading={FileLoading}
          Upload={Upload}
          FileImage={FileImage}
          UploadFileContent={UploadFileContent}
        />

        <Button>
          <LoadingButton
            onClick={() => {
              if (FileUrl === null) {
                Toastify({
                  text: "กรุณาอัพโหลดหลักฐานการชำระเงิน",
                  duration: 3000,
                  newWindow: true,
                  close: false,
                  gravity: "top",
                  position: "center",
                  backgroundColor:
                    "linear-gradient(to right, #ff416c, #ff4b2b)",

                  stopOnFocus: true,
                  onClick: function () { },
                }).showToast();

                document.getElementById("upload").style.border =
                  "1px solid red";
                document.querySelector(".Upload_title").style.color = "red";
                document.getElementById("AddIcon").style.color = "red";
                document
                  .getElementById("upload")
                  .classList.add("wobble-hor-bottom");
                setTimeout(() => {
                  document
                    .getElementById("upload")
                    .classList.remove("wobble-hor-bottom");
                }, 500);
              } else {
                fetchRequest();
                document.getElementById("upload").style.border =
                  "1px solid #9f98a3";
                document.querySelector(".Upload_title").style.color = "#888";
                document.getElementById("AddIcon").style.color = "#888";
              }
            }}
            size="small"
            variant="contained"
            loading={LoadingButtonCheck}
            loadingIndicator="กําลังตรวจสอบ..."
            className="shadow-sm"
            sx={{
              width: "80%",
              fontSize: "16px",
              marginTop: "1.5rem",

              "@media (max-width: 768px)": {
                width: "100%",
              },
            }}
          >
            ส่งสลิป
          </LoadingButton>
        </Button>
      </Main>
    </Counter>
  );
}

export default ShipperNotEnoughMoney;
