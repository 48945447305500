import React, { useEffect, useState } from 'react'
import Button from '@material-ui/core/Button';
import { TitleContent, ItemDetail, ButtonBrue, ButtonOrange } from './style.module';
import { useHistory, useParams } from 'react-router-dom';
import apiService from "../../api/apiService";
import swal from "@sweetalert/with-react";

function MoneyDetail1({setLoading2,  data, setReceiptAmount1 }) {
  const { shipperID, date } = useParams();
  const service = apiService();
  const history = useHistory();
  const [CT, setCT] = useState(0);
 

  useEffect(() => {
    

    async function getListCashtransfer() {
      return await service.get({url: `/api/v1/financial/list-cashtransfer?shipperID=${shipperID}&shippingDate=${date}`}).then((res) => {
        return res.data.result;
      })
    }
    

    async function getListCashtransferByShipper() {
      return await service.get({ url: `/api/v1/order/list-condition-by-shipper?shipperID=${shipperID}&shippingDate=${date}`}).then((res) => {
        return res.data.result;
      })
    }

    Promise.all([getListCashtransfer(), getListCashtransferByShipper()]).then((res) => {
      //return item.paymentMethod === "transfer_ThisPayment" || item.paymentMethod === "ATM_ThisPayment" || item.paymentMethod === "depositSlip_ThisPayment" || item.paymentMethod === "cheque_ThisPayment" || item.paymentMethod === "otherTransfer_ThisPayment" 
      const newCT = res[0].length > 0 ? res[0].filter((item) => { return ["transfer_ThisPayment", "ATM_ThisPayment", "depositSlip_ThisPayment", "cheque_ThisPayment", "otherTransfer_ThisPayment"].includes(item.paymentMethod) }).reduce((sum, item) => { return sum + item.receiptAmount }, 0) : 0
  
      // 4.6+4+3+8.6+8.9+9+13+12.6+12.9 + 35 + 36 + 37 + 38 + 39 + 40
 
      // (4.6 + 4 + 3 + 8.6 + 8.9 + 9 + 13 + 12.6 + 12.9 + 35 + 36 + 37 + 38 + 39 + 40) - (CT of 4.6 + 4 + 3 + 8.6 + 8.9 + 9 + 13 + 12.6 + 12.9 + 35 + 36 + 37 + 38 + 39 + 40)

      const newTotalCash = res[1].filter((item) => { return [
        4.6, 4, 3, 8.6, 8.9, 9, 13, 12.6, 12.9, 35, 36, 37, 38, 39, 40
      ].includes(item.invoiceCondition) }).reduce((sum, item) => sum + item.totalPrice, 0)
      console.log("newTotalCash", newTotalCash);
      console.log("newCT", newCT);
      setCT(newCT);
      setReceiptAmount1(newTotalCash - newCT < 0 ? 0 : newTotalCash - newCT);
      setLoading2(false);
    }).catch((err) => {
      console.log(err);
      swal({
        title: `${err.message}`,
        text: "กรุณาลองใหม่อีกครั้ง",
        icon: "error",
        button: "ตกลง",
      });
    })



  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
 

 
  // 4.6+4+3+8.6+8.9+9+13+12.6+12.9 + 35 + 36 + 37 + 38 + 39 + 40
  const totalCash = data.filter((item) => { return [
    4.6, 4, 3, 8.6, 8.9, 9, 13, 12.6, 12.9, 35, 36, 37, 38, 39, 40
  ].includes(item.invoiceCondition) }).reduce((sum, item) => sum + item.totalPrice, 0)



  //0+0.6+1+8+12 + 31 + 32 + 34
  const totalOrder = data.filter((item) => { return [
    0, 0.6, 1, 8, 12, 31, 32, 34
  ].includes(item.invoiceCondition) }).length

  

// 1+8+12 ออเดอร์ที่จ่ายแล้ว
  const totalOrder1 = data.filter((item) => { return [1, 8, 12, 32, 34].includes(item.invoiceCondition) }).length
// 0+0.6 ออเดอร์ที่ยังไม่จ่าย
  const totalOrder0 = data.filter((item) => { return [
    0, 0.6, 31
  ].includes(item.invoiceCondition) }).length
 

// CT of 3+4+9+13

  console.log("จำนวนออเดอร์ทั้งหมด", data.filter((item) => {
    return [
      0, 0.6, 1, 8, 12, 31, 32, 34
    ].includes(item.invoiceCondition)
  }));



  console.log("ออเดอร์ที่จ่ายแล้ว", data.filter((item) => { return [1, 8, 12, 32, 34].includes(item.invoiceCondition) }));

  console.log("ออเดอร์ที่ยังไม่จ่าย", data.filter((item) => {
    return [
      0, 0.6, 31
    ].includes(item.invoiceCondition)
  }));


 

  return (
    <div>
      {/* <TitleContent>
        <h1 className='shadow-sm'>1. ลูกค้าที่จ่ายงวดนี้</h1>
      </TitleContent> */}



      {/* <ItemDetail>
        <div className='shadow-sm ItemDetail_title'>
          1.1 ลูกค้าจ่ายเป็นเงินสด
        </div>

        <div className='shadow-sm ItemDetail_filex'>
          <div className='ItemDetail_filex__title_1'>
            ยอดเงินสดทั้งหมด
          </div>
          <div className='ItemDetail_filex__title_2'>
            {totalCash ? totalCash.toLocaleString() : 0} บาท
          </div>
        </div>


        <div className='shadow-sm ItemDetail_filex'>
          <div className='ItemDetail_filex__title_1'>
            สายส่งโอนเข้าบริษัท
          </div>
          <div className='ItemDetail_filex__title_2'>
           {CT ? CT.toLocaleString() : 0} บาท
          </div>
        </div>


        <div className='shadow-sm ItemDetail_filex_bg'>
          <div className='ItemDetail_filex__title_1'>
            เหลือเงินสดต้องชำระ
          </div>
          <div className='ItemDetail_filex__title_2'>
            {(totalCash - CT) ?  totalCash - CT < 0 ? 0 : (totalCash - CT).toLocaleString() : 0} บาท
          </div>
        </div>

      </ItemDetail> */}

<ItemDetail className='mt-3'>
        <div className='shadow-sm ItemDetail_title'>
        0 พนักงานจ่ายเป็นสลิป
        </div>

       


        <div className='shadow-sm ItemDetail_filex'>
          <div className='ItemDetail_filex__title_1'>
            สายส่งโอนเข้าบริษัท
          </div>
          <div className='ItemDetail_filex__title_2'>
           {CT ? CT.toLocaleString() : 0} บาท
          </div>
        </div>

 

      </ItemDetail>

      <ButtonBrue>
        <Button 
        className='btn shadow-sm'
          onClick={() => history.push(`/cashTransferThisPayment/${shipperID}/${date}`)}
        >สายส่งแนบสลิปที่โอนเงินเข้าบริษัท</Button>
      </ButtonBrue>

{/* 
      <ItemDetail>
        <div className='shadow-sm ItemDetail_title'>
          1.2 ลูกค้าโอนเงินเข้าบริษัท
        </div>

        <div className='shadow-sm ItemDetail_filex'>
          <div className='ItemDetail_filex__title_1'>
            จำนวนออเดอร์ทั้งหมด
          </div>
          <div className='ItemDetail_filex__title_2'>
            {totalOrder ? totalOrder.toLocaleString() : 0} ออเดอร์
          </div>
        </div>


        <div className='shadow-sm ItemDetail_filex'>
          <div className='ItemDetail_filex__title_1'>
            ออเดอร์ที่จ่ายแล้ว
          </div>
          <div className='ItemDetail_filex__title_2'>
         {totalOrder1 ? totalOrder1.toLocaleString() : 0} ออเดอร์
          </div>
        </div>


        <div className='shadow-sm ItemDetail_filex_bg'>
          <div className='ItemDetail_filex__title_1'>
            ออเดอร์ที่ยังไม่จ่าย
          </div>
          <div className='ItemDetail_filex__title_2'>
            {totalOrder0 ? totalOrder0.toLocaleString() : 0} ออเดอร์
          </div>
        </div>

      </ItemDetail> */}

{/* 
      <ButtonOrange>
        <Button className='btn shadow-sm' onClick={() => history.push(`/checkInvoice/${shipperID}/${date}`)}>ตรวจสอบออเดอร์ที่ยังไม่จ่าย</Button>
      </ButtonOrange> */}



</div>
  )
}

export default MoneyDetail1