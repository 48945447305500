import React, { useState, useEffect } from 'react'
import styled from 'styled-components';
import Toastify from 'toastify-js'
import LoadingButton from '@mui/lab/LoadingButton';
import UploadFile from '../paymentAmount/uploadFile';
import apiService from "../../api/apiService"
import { Upload, FileImage, PaymentText } from '../paymentAmount/payment.module'
import { ToastifyError, copyToClipboard, swalChange, swalSuccess } from '../paymentAmount/tools';
import { useHistory } from 'react-router-dom';
import imageCompression from 'browser-image-compression';
import swal from "@sweetalert/with-react";
import liff from "@line/liff/dist/lib";
import CircularProgress from '@mui/material/CircularProgress';

const Counter = styled.div`
  background-color: #f9ecff;
  padding-bottom: 3rem;
  padding-top: 1.5rem;
  width: 100%;
  min-height: 100vh;
  padding-left: 3rem;
  padding-right: 3rem;
 
  .title {
    font-size: 24px;
    font-weight: 600;
    border-bottom: 2px solid #1a1a1a;
    width: 500px;
    text-align: center;
    color: #1a1a1a;
    margin: 0 auto;
     height: auto;
  margin-bottom: 0.5rem;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;




const List = styled.div`
display: flex;
justify-content: center;
  .list {
    width: 500px;
       border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    margin-top: 1rem;
    .list__title {
      font-size: 16px;
       text-align: center;
       color: #1a1a1a;
       background-color: #fff;
       padding: 0.5rem;
       border-bottom: 2px solid #eee;
    }

    .list__detail {
      display: flex;
      justify-content: space-between;
       padding: 0.5rem;
        background-color: #fff;
      .list__detail__title {
        font-size: 16px;
        color: #1a1a1a;
       
      }
    }

    .list__qty {
      display: flex;
      justify-content: flex-end;
      padding: 0.5rem;
      background-color: #f7a4d8;
      border-radius: 0 0 10px 10px;
      .list__qty__title {
        font-size: 16px;
        color: #1a1a1a;
      }
    }



    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;



const UploadFileContent = styled.div`
  width: 100%;
  height: 100%;

`;




const Button = styled.div`
  width: 500px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  flex-direction: column;
  .Button {
    
  margin: 0 auto; 
  margin-top: 1.5rem;
  border-radius: 10px;
  font-size: 18px;
  padding: 0.4rem;
  background-color: #2652f9;
  border: 1px solid #fff;
  font-weight: 600;
  transition: all 0.3s ease;
  color: #fff;
  @media (max-width: 768px) {
    width: 100%;
  }
 
    
    &:active {
      background-color: #0a3cfc;
      color: #fff;
      border: 1px solid #2652f9;
    }
  }

  @media (max-width: 768px) {
    width:100%;
  }
`;


function WantToPay() {
  const service = apiService()
  
  const [FileUrl, setFileUrl] = useState(null);
  const [FileLoading, setFileLoading] = useState("");
  const [FilePost, setFilePost] = useState(null);
  const [LoadingButtonCheck, setLoadingButtonCheck] = useState(false);
  const [totalAll, setTotalAll] = useState(0);
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [customerID, setCustomerID] = useState(null);
  const [sale, setSale] = useState([]);


  function getApi() {

    const getUser = () => {
      return service.get({ url: "/api/v1/user/" }).then((res) => {
        return res.data.result 
      });
    }


    getUser().then((e) => {
      setCustomerID(e.customerID)

      service.get({ url: `/api/v1/employee/?userID=${e.saleID}` }).then((res) => {
        setSale(res.data.result);
      })

      service.get({ url: `/api/v1/order/list-by-customer?customerID=${e.customerID}` }).then((res) => {

        const result = res.data.result.filter((item) => {
          return item.paidDate === null || item.orderStatus !== true ? item : null
        })


        setTotalAll(result.reduce((acc, item) => {
          return acc + item.totalPrice
        }, 0))

        setLoading(false);
      }).catch(err => {
        setLoading(false);
        ToastifyError(err.message)

      }
      )

    }).catch(err => {
      setLoading(false);
      ToastifyError(err.message)

    }
    )




  }


  useEffect(() => {
    setLoading(true)
    getApi()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const documentClick = () => {
    document.getElementById('paymentAmountFile').click()
  }




  const confirm = () => {
    swal({
      title: "คำสั่งซื้อของคุณลูกค้าเรียบร้อยแล้วค่ะ",
      text: "ขอบคุณที่ใช้บริการ",
      icon: "success",
      button: "ตกลง",
      className: "swal-text-check-user-payment",
    }).then((value) => {
    
        liff.closeWindow()
    
    });

  }



  function fetchRequest() {
    const formData = new FormData();
    formData.append("file", FilePost);
    setFileLoading("loading");
    setLoadingButtonCheck(true);

    const reset = () => {
      setFileUrl(null);
      setFileLoading("");
      setFilePost(null);
      document.getElementById('paymentAmountFile').value = null;
      setLoadingButtonCheck(false);
    }

    // /api/v1/financial/upload-slip
    async function uploadSlip(body) {
      return await service.post({ url: "/api/v1/financial/upload-slip", params: body }).then((res) => {
        return res.data.result;
      }).catch((err) => {
        swalChange(false, "065-1-90826-4", sale?.employeeNickname, sale?.userPhone)
        
        ToastifyError(err.message)
        reset();
      })

    }


    function uploadImage(resultQR) {
      const formData = new FormData();
      formData.append("image", FilePost);
      service.post({ url: "api/v1/upload-image", params: formData, headers: { "Content-Type": "multipart/form-data" } }).then((img) => {
        const slipUrl = img.data.result.url
        const body = {
          "slipUrl": slipUrl,
          "data": resultQR,
          "customerID": customerID,
        }

        uploadSlip(body).then((res) => {
          // success === false
          if (res.success === false) {
            ToastifyError(res.message)
            reset();
            swalChange(false, "065-1-90826-4", sale?.employeeNickname, sale?.userPhone)
            
          } else {
            // จำนวนเงินที่ท่านต้องชําระ
            const amount = Number(totalAll)

            res.receiptAmount >= amount ? confirm() : history.push({
              pathname: "/statementOfAccount/wantToPayNotEnough",
              state: {
                receiptAmount: res.receiptAmount,
              }

            })
          }
        })

      }).catch((err) => {
        ToastifyError(`${err.message}`)
        reset();
        swalChange(false, "065-1-90826-4", sale?.employeeNickname, sale?.userPhone)
        
        console.log(err);
      })
    }
    fetch("https://api.qrserver.com/v1/read-qr-code/", {
      method: "POST",
      body: formData,
    }).then((res) => res.json()).then((r) => {
      const resultQR = r[0].symbol[0].data;
      console.log(resultQR);
      if (!resultQR) {
        ToastifyError("สลิปของท่านไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง")
        reset();
        swalChange(false, "065-1-90826-4", sale?.employeeNickname, sale?.userPhone)
        
      } else {
        setFileLoading("success");
        setLoadingButtonCheck(true);
        uploadImage(resultQR);
      }

    }).catch((e) => {
      console.log(e);
      ToastifyError("สลิปของท่านไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง")
      reset();


    })
  }



  const fileChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    // เช็คขนาดไฟล์ มากกว่า 1 MB ให้บีบอัด
    if (file.size > 1000000) {
      const options = {
        maxSizeMB: 0.5,
        maxWidthOrHeight: 1920,
        useWebWorker: true
      }
      imageCompression(file, options)
        .then(function (compressedFile) {
          // size in KB
   
          setFilePost(compressedFile);
        })
        .catch(function (error) {
          console.log(error.message);
        });
      setFileUrl(URL.createObjectURL(file));
    } else {
      setFilePost(file);
      setFileUrl(URL.createObjectURL(file));
    }
    
  }




  return (
    <Counter>
      {
        loading === true ? <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '2rem'
        }}>
          <CircularProgress />
        </div> : <><div className='title'>
          ชำระเงิน
        </div>

          <List >
            <div className='list shadow-sm' style={{ marginBottom: '1rem' }}> 
              <div className='list__title' style={{
                fontSize: '17px',
                textAlign: 'left',
              }}>
                ยอดที่ยังไม่ถึงกำหนดชำระเงินทั้งหมด
              </div>



              <div className='list__qty'>
                <div className='list__qty__title'>

                  {totalAll.toLocaleString()} บาท
                </div>
              </div>

            </div>
          </List>

          <div className='title'>

          </div>

            <PaymentText className="shadow-sm PaymentText" >
              <div className="Payment">
                <div className="Payment__title">หมายเลขบัญชีธนาคาร</div>
                <div className="Payment__detail">
                  ของ บริษัท สุวรรณภูมิ ฟู้ด จำกัด
                </div>

                <div style={{ margin: "15px 0" }}>
                  <div className="Payment__detail_dank">ธนาคารไทยพาณิชย์ เลขที่บัญชี</div>
                  <div className="Payment__detail_dank_number" style={{ color: "#8A2BE2" }} onClick={() => copyToClipboard("347-404461-8")}>{"347-404461-8"}</div>
                </div>

                <div>
                  <div className="Payment__detail_dank">ธนาคารกสิกรไทย เลขที่บัญชี</div>
                  <div className="Payment__detail_dank_number " onClick={() => copyToClipboard("065-1-90826-4")}>{"065-1-90826-4"}</div>
                </div>

                <div className="Payment__detail_coppy mt-2">กดที่ตัวเลขเพื่อคัดลอก</div>
              </div>
            </PaymentText>


          <UploadFile File={File} FileUrl={FileUrl} documentClick={documentClick} fileChange={fileChange} FileLoading={FileLoading} Upload={Upload} FileImage={FileImage} UploadFileContent={UploadFileContent} />

          <Button>


            <LoadingButton
              onClick={() => {
                if (FileUrl === null) {
                  Toastify({
                    text: "กรุณาอัพโหลดหลักฐานการชำระเงิน",
                    duration: 3000,
                    newWindow: true,
                    close: false,
                    gravity: "top",
                    position: 'center',
                    backgroundColor: "linear-gradient(to right, #ff416c, #ff4b2b)",

                    stopOnFocus: true,
                    onClick: function () { }
                  }).showToast();

                  document.getElementById('upload').style.border = '1px solid red';
                  document.querySelector('.Upload_title').style.color = 'red';
                  document.getElementById('AddIcon').style.color = 'red';
                  document.getElementById('upload').classList.add('wobble-hor-bottom');
                  setTimeout(() => {
                    document.getElementById('upload').classList.remove('wobble-hor-bottom');
                  }, 500);


                } else {
                  fetchRequest()

                }
              }}
              size="small"
              variant="contained"
              color='success'
              loading={LoadingButtonCheck}

              loadingIndicator="กําลังตรวจสอบ..."
              className='shadow-sm'
              sx={{
                width: '100%',
                fontSize: '16px',
                marginTop: '1.5rem',

                '@media (max-width: 768px)': {
                  width: '100%',
                }

              }}
            >
              ส่งสลิป
            </LoadingButton>



            <LoadingButton
              onClick={() => {
                history.push('/statementOfAccount')
              }}

              size="small"
              variant="outlined"
              color="error"

              loadingIndicator="กําลังตรวจสอบ..."
              className='shadow-sm'
              sx={{
                width: '100%',
                fontSize: '16px',
                marginTop: '1rem',

                '@media (max-width: 768px)': {
                  width: '100%',
                }

              }}
            >
              ย้อนกลับ
            </LoadingButton>
          </Button></>
      }



    </Counter>
  )
}

export default WantToPay