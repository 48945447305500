import React from 'react'


function SelectPriceLevel({ priceLevel, setPriceLevel, priceLevelAll}) {
  return (
    <div>
              
                <select
                 name="price level"
                 className="form-select form-select-sm mb-1 border-secondary"
                 aria-label=".form-select-sm example" 
                 style={{fontSize: "1rem", width: "115px"}}
                 onChange={(e) => { 
                  setPriceLevel(e.target.value) 
                  
                  const localStorageData = JSON.parse(localStorage.getItem("rawMaterial"))
                    localStorageData.priceLevel = e.target.value
                    localStorage.setItem("rawMaterial", JSON.stringify(localStorageData))
                }}
                 value={priceLevel || ""}
    
                 >  
                 
                    { priceLevelAll.map((item) => { return <option value={item}>{item}</option> } ) }
                </select>
               </div>
  )
}

export default SelectPriceLevel