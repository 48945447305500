
export const useOrderReult = (state = [], action) => {
    switch (action.type) {
        case "SET_ORDER_RESULT":
            return action.payload;
        case "UPDATE_ORDER_RESULT":
           return state.map(item => {
                if (item.orderID === action.payload.orderID) {
                  return action.payload;
                } else {
                  return item;
                }
              });
        case "RESET_ORDER_RESULT":
            return [];
        default:
            return state;

    }
  }

  export const useOrder = (state = [], action) => {
    switch (action.type) {
        case "SET_ORDER":
            return action.payload;
        case "RESET_ORDER":
            return [];
        default:
            return state;

    }
  }
  

export const useOrderAcc = (state = 0, action) => {
    switch (action.type) {
        case "SET_ORDER_ACC":
            return action.payload;
        case "RESET_ORDER_ACC":
            return 0;
        default:
            return state;

    }
  }


export const useInfoOrder = (state = [], action) => {
    switch (action.type) {
        case "SET_INFO_ORDER":
            return action.payload;
        case "RESET_INFO_ORDER":
            return [];
        default:
            return state;
    }
  }


export const useOrderID = (state = 0, action) => {
    switch (action.type) {
        case "SET_ORDER_ID":
            return action.payload;
        case "RESET_ORDER_ID":
            return 0;
        default:
            return state;
    }
  }





    
const today = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    return `${yyyy}-${mm}-${dd}`;
  }

  export const useOrderSelectedDate = (state = `${today()}`, action) => {
    switch (action.type) {
        case "SET_ORDER_SELECTED_DATE":
            return action.payload;
        case "RESET_ORDER_SELECTED_DATE":
            return `${today()}`;
        default:
            return state;
    }
  }



  export const useGetCash = (state = [], action) => {
    switch (action.type) {
        case "SET_GET_CASH":
            return action.payload;
        case "DELETE_GET_CASH":
            return state.filter(item => item.orderID !== action.payload.orderID);
        case "ADD_GET_CASH":
            return [...state, action.payload];
        case "RESET_GET_CASH":
            return [];
        default:
            return state;
    }
  }


  export const useGetSlip = (state = [], action) => {
    switch (action.type) {
        case "SET_GET_SLIP":
            return action.payload;
        case "DELETE_GET_SLIP":
            return state.filter(item => item.orderID !== action.payload.orderID);
        case "ADD_GET_SLIP":
            return [...state, action.payload];
        case "RESET_GET_SLIP":
            return [];
        default:
            return state;
    }
  }
  


  export const useOnActive = (state = 0, action) => {
    switch (action.type) {
        case "SET_ON_ACTIVE":
            return action.payload;
        case "RESET_ON_ACTIVE":
            return 0;
        default:
            return state;
    }
  }