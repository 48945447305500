import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Card from '../../components/checkout/card';
import Grid from '@mui/material/Grid';
import { useParams } from 'react-router-dom';
import { getProductList, getSellingPriceLevelList, getShipperCustomers, getCustomerInfo } from '../../components/checkout/api';
import AppBar from '../../components/checkout/appBar';
import Loading from '../../components/checkout/loading';
 

const productType = ["aruneeFishballCustomer", "course"]

export default function Orders() {
  const {shippingDate, shipperID, customerID} = useParams();
  const [productList, setProductList] = React.useState([]);
  const [debtInstallment, setDebtInstallment] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const [customerInfo, setCustomerInfo] = React.useState({})
   

  React.useEffect(() => {

    Promise.all([getProductList(), getSellingPriceLevelList(), getShipperCustomers(shipperID), getCustomerInfo(Number(customerID))]).then((res) => {
       const [productList, sellingPriceLevelList, shipperCustomers, customerInfo] = res;
       
       const filterProductList = productList.filter((item) => { return productType.includes(item.productType) })
       const customer = shipperCustomers.filter((item) => { return Number(item.customerID) === Number(customerID)})
       const priceLevelID = customer[0]?.priceLevelID
        
       // ใส่ราคาให้ product
       const productWithPrice = filterProductList.map((item) => {
          return {
            ...item,
            price: sellingPriceLevelList.filter((priceItem) => { return priceItem.productID === item.productID && priceItem.priceLevelID === priceLevelID })[0]?.price,
            // ใส่จำนวนที่สั่งซื้อได้
            quantity: 0,
          }
        })

        setCustomerInfo(customer[0])
        setProductList(productWithPrice)
        setDebtInstallment(customerInfo?.debtInstallment || 0)
        setLoading(false)

        console.log(productWithPrice)
    })

  }, [shipperID, customerID, shippingDate])





  return (
    <React.Fragment>
      <Box
        sx={{
          minHeight: "100vh",
          backgroundColor: `#ffe4e1`,
          paddingBottom: `76px`,
          paddingTop: `0px`,
        }}
      >
        <CssBaseline />

        <AppBar
          productList={productList}
          setProductList={setProductList}
          debtInstallment={debtInstallment}
          shippingDate={shippingDate}
          customerInfo={customerInfo}
          shipperID={shipperID}
          customerID={customerID}
          pages={"checkout-for-sell"}
        />

        <Container>
          {loading ? (
            <Loading pages={"checkout-for-sell"}/>
          ) : (
            <Grid container spacing={2} sx={{ my: 2, mb: 0, mt: 0 }}>
              {productList.sort((a, b) => { return a.productSequence - b.productSequence}).map((item, index) => {
                return (
                  <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
                    <Card
                      item={item}
                      setProductList={setProductList}
                      productList={productList}
                    />
                  </Grid>
                );
              })}
            </Grid>
          )}
        </Container>
      </Box>
    </React.Fragment>
  );
}
