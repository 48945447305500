import apiService from "../../api/apiService";
 
import { SweetError } from "../../components/checkout/modal";
const service = apiService();


  // /api/v1/user/
async function getUser() {
    return await service.get({ url: `/api/v1/user/` }).then((res) => {
        return res.data.result
    }).catch((err) => {
        console.log(err)
        SweetError("api/v1/user/", err.message)
        }
    )
}

// /api/v1/order/list-from-tomorrow-2

async function getListFromTomorrow2(userID) {
    return await service.get({ url: `/api/v1/order/list-from-tomorrow-2?shipperID=${userID}` }).then((res) => {
        return res.data.result
    }).catch((err) => {
        console.log(err)
        SweetError("api/v1/order/list-from-tomorrow-2", err.message)
        }
    )
}


// /api/v1/route/all-with-shipper
async function getAllWithShipper() {
    return await service.get({ url: `/api/v1/route/all-with-shipper` }).then((res) => {
        return res.data.result
    }).catch((err) => {
        console.log(err)
        SweetError("api/v1/route/all-with-shipper", err.message)
        }
    )
}


//  /api/v1/customer/list-in-route
async function getListInRoute(routeID) {
    return await service.get({ url: `/api/v1/customer/list-in-route?routeID=${routeID}` }).then((res) => {
        return res.data.result
    }).catch((err) => {
        console.log(err)
        SweetError("api/v1/customer/list-in-route", err.message)
        }
    )
}


export { getUser, getListFromTomorrow2, getAllWithShipper, getListInRoute  };