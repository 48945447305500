
export const useRegister = (state = {}, action) => {
  switch (action.type) {
    case "ADD_CONTACT":
      return action.payload;
    default:
      return state;
  }
}

 