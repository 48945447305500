import Toastify from 'toastify-js'
import "toastify-js/src/toastify.css"
import swal from "@sweetalert/with-react";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CallIcon from '@mui/icons-material/Call';
import { SwalFooter, PaymentText } from '../../paymentAmount/payment.module';


export const ToastifyError = (message) => {
  Toastify({
    text: message,
    duration: 3000,
    close: false,
    gravity: "top",
    position: "center",
    backgroundColor: "linear-gradient(to right, #ff416c, #ff4b2b)",
    stopOnFocus: false,
  }).showToast();
}

export const ToastifyGreen = (message) => {
  Toastify({
    text: message,
    duration: 3000,
    close: false,
    gravity: "top",
    position: "center",
    backgroundColor: "linear-gradient(to right, #00b09b, #96c93d)",
    stopOnFocus: false,
  }).showToast();
}

export   const ToastifyPurple = (message) => {
    Toastify({
      text: message,
      duration: 3000,
      close: false,
      gravity: "top",
      position: "center",
      backgroundColor: "linear-gradient(to right, #6a3093, #a044ff)",
      stopOnFocus: false,
    }).showToast();
  };


export async function copyToClipboard(bank) {
  await navigator.clipboard.writeText(bank);
  if (bank === "347-404461-8") {
    ToastifyPurple("คัดลอกเลขบัญชีธนาคารสำเร็จ");
    document.querySelector(".PaymentText").style.border = "1px solid #6a3093";
    setTimeout(() => {
      document.querySelector(".PaymentText").style.border = "1px solid #9f98a3";
    }, 500);

  } else {
    ToastifyGreen("คัดลอกเลขบัญชีธนาคารสำเร็จ");
    document.querySelector(".PaymentText").style.border = "1px solid #15a251";
    setTimeout(() => {
      document.querySelector(".PaymentText").style.border = "1px solid #9f98a3";
    }, 500);

  }
}


export const swalChange = (state, bank) => {
  if (state === false) {

 
    swal({
      //  closeOnClickOutside: false,
      buttons: ["ปิด", "ตกลง"],
      className: 'swal-payment',
      title: "สลิปของท่านไม่ถูกต้อง",
      text: "กรุณาตรวจสอบ และแนบสลิปอีกครั้ง",
      icon: "error",
      content: (
        <div>
          <PaymentText
            style={{
              marginTop: 0,
              backgroundColor: '#f9ecff',
              width: '100%',
            }}
            className='shadow-sm'

            onClick={() => copyToClipboard()}>
            <div className='Payment'>
              <div className='Payment__title'>
                หมายเลขบัญชีธนาคาร
              </div>
              <div className='Payment__detail'>
                ของ บริษัท สุวรรณภูมิ ฟู้ด จำกัด
              </div>

              <div className='Payment__detail_dank'>
              ธนาคารกสิกรไทย เลขที่บัญชี
              </div>

              <div className='Payment__detail_dank_number'>
                {bank}
              </div>

              <div className='Payment__detail_coppy'>
                กดเพื่อก๊อปปี้
              </div>
            </div>
          </PaymentText>




          <SwalFooter>
            <div className='SwalFooter__title'>
              หากท่านยังมีปัญหา กรุณาติดต่อฝ่ายบริการลูกค้า เพื่อช่วยเหลือท่าน
            </div>

            <div className='SwalFooter__detail'>
              <div className='SwalFooter__detail__icon'>
                <AccountCircleIcon sx={{
                  fontSize: 35,
                  color: '#1a1a1a',
                }} />
              </div>

              <div className='SwalFooter__detail__title'>
                ชื่อเซลล์
              </div>
            </div>

            <div className='SwalFooter__detail'>
              <div className='SwalFooter__detail__icon'>
                <CallIcon
                  sx={{
                    fontSize: 35,
                    color: '#1a1a1a',
                  }} />
              </div>

              <div className='SwalFooter__detail__title'>
                เบอร์โทรศัพท์
              </div>


            </div>
          </SwalFooter>


        </div>
      )
    })
  }
}



export const swalSuccess = (title, message) => {
  // title = ท่านชำระเงินถูกต้อง
  // message = คำสั่งซื้อของคุณลูกค้าเรียบร้อยแล้วค่ะ

  swal({
    title: title,
    text: message,
    icon: "success",
    button: "ตกลง",
  });
  


}