import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { useLocation } from "react-router-dom";
import { WatDatePicker } from "thaidatepicker-react";
import ProductDetails from "./ProductDetails";
import Typography from "@mui/material/Typography";
import Buttons from "./Buttons";

import moment from "moment";
import 'moment/locale/th';
// วันส่งของ
const stockRequestDate = {
  padding: ".5rem",
  borderRadius: "0",
  border: "1px solid #eaeaea",
  backgroundColor: "#873843",
  color: "#fff",
};

const NoOrderDetails = () => {
  return (
    <Grid container className="mt-3">
      <Grid item xs={12}>
        <Paper
          sx={{
            padding: "30px",
            borderRadius: "0",
            border: "1px solid #eaeaea",
            textAlign: "center",
            color: "#888",
            fontSize: "14px",
            borderBottom: "none",
          }}>
          <Typography noWrap>ไม่พบสินค้า</Typography>
        </Paper>
      </Grid>
    </Grid>
  );
};

function Details({
  OrderSelectedDate,
  setOrderSelectedDate,
  orderID,
  customer,
  orderDetailRecords,
  amountToBePaid,
  shippingStatus,
  orderToReceiveCash,
  orderToReceiveSlip,
  setOrderToReceiveCash,
  setOrderToReceiveSlip,
  orderStatus,
  setNavBar,
  navBar,
  setShippingStatus,
  setOrderStatus,
  disabled,
  product,
  employeeID,
  proofOfPayment,
  listNotShipped,
  updateOrder,
  setDisabled,
  setOrderDetailRecords,
  checkButton,
  isDisable,
  orderToReceiveNull,
  orderDate,
  getGeoLocation,
  
  distance,
  setGetGeoLocation,
  getLocation,
 

  getGeoLocationDefault,
  loadingGeoLocation,
  employeeNickname
}) {
  const location = useLocation();

  const handleWatDatePickerChange = (christDate, buddhistDate) => {
    setOrderSelectedDate(christDate);
  };

  const isEvenDay = (date) => {
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();

    const checkDate = listNotShipped.filter((order) => {
      const shippingDate = new Date(order.shippingDate);
      const toString = shippingDate.toString();
      const shipping = new Date(toString).getDate();
      const shippingMonth = new Date(toString).getMonth();
      const shippingYear = new Date(toString).getFullYear();

      return (
        shipping === day && shippingMonth === month && shippingYear === year
      );
    });

    return checkDate.length > 0;
  };

  const orderDateTime = orderDate ? new Date(orderDate) : null;

  return (
    <Box sx={{ flexGrow: 1 }} id="content__" className="p-2 p-md-5 pt-5">
      <Grid container>
        <Grid item xs>
          <Paper sx={stockRequestDate}>วันส่งของ</Paper>
        </Grid>
        <Grid item xs>
          <Paper
            sx={{
              padding: ".5rem",
              borderRadius: "0",
              border: "1px solid #eaeaea",
            }}>
            <WatDatePicker
              placeholder="กรุณาระบุวันส่ง"
              onChange={handleWatDatePickerChange}
              dateFormat={"yyyy-MM-dd"}
              displayFormat={"dd, DD MMMM YY"}
              value={OrderSelectedDate}
              reactDatePickerProps={{
                dayClassName: (date) => (isEvenDay(date) ? "bg-pink" : null),
              }}
              inputStyle={{
                color: "#0d6efd",
                padding: "0",
                width: "100%",
                margin: "0",
                height: "100%",
                border: "none",
              }}
            />
          </Paper>
        </Grid>
      </Grid>

      <Grid container className="mb-3">
        <Grid item xs>
          <Paper
           sx={stockRequestDate}>
            วันสั่งของ
          </Paper>
        </Grid>
        <Grid item xs>
          <Paper
            sx={{
              padding: ".5rem",
              borderRadius: "0",
              border: "1px solid #eaeaea",
              borderBottom: "none",
             
            }}>
              
            {/* {orderDate ? 
              // 01/10/2021, 10:00:00 AM
              new Date(orderDate).toLocaleString("th-TH", { timeZone: "Asia/Bangkok" })
             : "-"} */}
              
              {
                orderDate ? new Date(orderDateTime.setHours(orderDateTime.getHours() + 7)).toLocaleString("th-TH", { timeZone: "Asia/Bangkok" }) : "-"

              }


          </Paper>
        </Grid>
      </Grid>
      <Grid container className="mt-3">
        <Grid item xs>
          <Paper
            sx={{
              padding: ".5rem",
              borderRadius: "0",
              border: "1px solid #eaeaea",

              borderBottom: "none",
            }}>
            เลขที่ใบส่งของ
          </Paper>
        </Grid>
        <Grid item xs>
          <Paper
            sx={{
              padding: ".5rem",
              borderRadius: "0",
              border: "1px solid #eaeaea",
              borderBottom: "none",
              backgroundColor: "#1769aa",
              color: "#fff",
            }}>
            {employeeNickname ? employeeNickname : "-"}
          </Paper>
        </Grid>
      </Grid>

      <Grid container >
        <Grid item xs>
          <Paper
            sx={{
              padding: ".5rem",
              borderRadius: "0",
              border: "1px solid #eaeaea",

              borderBottom: "none",
            }}>
                 {orderID ? orderID : "-"}
          </Paper>
        </Grid>
        <Grid item xs>
          <Paper
            sx={{
              padding: ".5rem",
              borderRadius: "0",
              border: "1px solid #eaeaea",
              borderBottom: "none",
              
            }}>
                    {customer.customerName ? customer.customerName : "-"}
          </Paper>
        </Grid>
      </Grid>

      

      <Grid container>
        <Grid item xs>
          <Paper
            onClick={() => {
              if (customer.phone) {
                window.open(`tel:${customer.phone}`);
              }
            }}
            sx={{
              padding: ".5rem",
              borderRadius: "0",
              border: "1px solid #eaeaea",
              color: "#0d6efd",
              borderBottom: "none",
              cursor: "pointer",
              textAlign: "center",
              "&:hover": {
                color: customer.phone ? "#c41d7f" : "#0d6efd",
              },
            }}>
            {customer.phone ? customer.phone : "-"}
          </Paper>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs>
          <Paper
            onClick={() => {
              if (customer?.deliveryGeoLocation) {
                //https://www.google.com/maps/search/?api=1&query=[lat]%2C[long]
                const lat = customer?.deliveryGeoLocation?.split(",")[0];
                const long = customer?.deliveryGeoLocation?.split(",")[1];
                window.open(`https://www.google.com/maps/search/?api=1&query=${lat}%2C${long}`);
              }
            }}
            sx={{
              padding: ".5rem",
              borderRadius: "0",
              border: "1px solid #eaeaea",
              cursor: "pointer",
              color: "#0d6efd",
              "&:hover": {
                color: customer?.deliveryGeoLocation ? "#c41d7f" : "#0d6efd",
              },

              textAlign: "center",
            }}>
            {customer?.deliveryGeoLocation ? "กดดูแผนที่ร้าน" : "-"}
          </Paper>
        </Grid>

        <Grid item xs>
          <Paper
            onClick={() => {
              if (customer.lineGroupURL) {
                window.open(customer.lineGroupURL);
              }
            }}
            sx={{
              padding: ".5rem",
              borderRadius: "0",
              border: "1px solid #eaeaea",
              cursor: "pointer",
              color: "#0d6efd",
              "&:hover": {
                color: customer.lineGroupURL ? "#c41d7f" : "#0d6efd",
              },

              textAlign: "center",
            }}>
            {customer.lineGroupURL ? "ไลน์กลุ่มลูกค้า" : "-"}
          </Paper>
        </Grid>
      </Grid>

      {orderDetailRecords.length > 0 ? (
        <>
          {" "}
          <ProductDetails orderDetailRecords={orderDetailRecords} />{" "}
          <Grid container className="mt-5">
            <Grid item xs={2}>
              <Paper
                sx={{
                  padding: ".5rem",
                  borderRadius: "0",
                  border: "1px solid #eaeaea",
                  textAlign: "end",
                }}>
                รวม
              </Paper>
            </Grid>
            <Grid item xs={10}>
              <Paper
                sx={{
                  padding: ".5rem",
                  borderRadius: "0",
                  border: "1px solid #eaeaea",

                  textAlign: "end",
                }}>
                {
                  // ยอดรวมของ totalAmount
                  orderDetailRecords.reduce((a, b) => a + b.totalAmount, 0)
                    .toLocaleString()
                } บาท
              </Paper>
            </Grid>
          </Grid>
          <Grid container className="mt-3">
            <Grid item xs={12}>
              <Paper
                sx={{
                  padding: ".5rem",
                  borderRadius: "0",
                  border: "1px solid #eaeaea",
                  borderBottom: "none",
                  textAlign: "end",
                }}>
                ยอดที่ต้องชำระ
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper
                sx={{
                  padding: ".5rem",
                  borderRadius: "0",
                  border: "1px solid #eaeaea",

                  textAlign: "end",
                }}>
                {amountToBePaid ? amountToBePaid.toLocaleString() : 0} บาท
              </Paper>
            </Grid>
          </Grid>
        </>
      ) : (
        <NoOrderDetails />
      )}

      <Buttons
        shippingStatus={shippingStatus}
        orderID={orderID}
        OrderSelectedDate={OrderSelectedDate}
        orderToReceiveCash={orderToReceiveCash}
        orderToReceiveSlip={orderToReceiveSlip}
        setOrderToReceiveCash={setOrderToReceiveCash}
        setOrderToReceiveSlip={setOrderToReceiveSlip}
        orderStatus={orderStatus}
        setNavBar={setNavBar}
        navBar={navBar}
        setShippingStatus={setShippingStatus}
        setOrderStatus={setOrderStatus}
        customer={customer}
        disabled={disabled}
        orderDetailRecords={orderDetailRecords}
        product={product}
        isDisable={isDisable}
        employeeID={employeeID}
        updateOrder={updateOrder}
        setDisabled={setDisabled}
        setOrderDetailRecords={setOrderDetailRecords}
        checkButton={checkButton}
        orderToReceiveNull={orderToReceiveNull}
        getGeoLocation={getGeoLocation}
      
        distance={distance}
        getLocation={getLocation}
       
    
        getGeoLocationDefault={getGeoLocationDefault}
        loadingGeoLocation={loadingGeoLocation}
   
      />

      {proofOfPayment.length > 0 && (
        <div className="mt-5 d-flex justify-content-center align-items-center flex-column mb-5">
          <p className="text-center color-dark font-weight-bold fs-7">
            สลิปของออเดอร์นี้
          </p>

          {proofOfPayment.map((item, index) => {
            return (
              <div className="mt-3" key={index}>
                <img
                  src={item?.receiptPictureURL}
                  alt="slip"
                  className="img-fluid"
                />
              </div>
            );
          })}
        </div>
      )}
    </Box>
  );
}

export default Details;
