import React from 'react'
import swal from '@sweetalert/with-react'
 
function Modal({ jobType, setJobType, showModal, closeModal, addJobType, service, getApi, alert, liff, history }) {
  const postApi = () => {
    const data =   {
      "userID": addJobType?.userID,
      "customerID": addJobType?.customerID,
      "customerJobType": jobType,
    }
    
    
    service.post({ url: "/api/v1/contact/update-customer-job-type", params: data }).then((res) => {
      getApi()
      swal({
        title: "บันทึกข้อมูลเรียบร้อย", 
        
        icon: "success",
        buttons: ["ย้อนกลับไปตั้งค่า", "ออกจากการตั้งค่า"],
        dangerMode: true,
        className: "sweet-alert-jobtype-save-success",

      }).then((willDelete) => {
        if (willDelete) {

          liff.closeWindow()

        } else {
           closeModal()
        }
      });
    }).catch((err) => {
      console.log(err)
      alert(err.message)
    })

  }
  return showModal ? (<div className="swal-overlay swal-overlay--show-modal" tabIndex={-1}>
    <div className="swal-modal swal-jobType" role="dialog" aria-modal="true">
      <div className="swal-title">ระบุ jobType</div>
      <div className="swal-content">
        <div className="list-group list-group-flush">
          <button
            type="button"
            className={`list-group-item list-group-item-action p1rem text-left ${jobType === "owner" ? "active" : ""}`}
            onClick={() => setJobType("owner")}
          >
            เจ้าของร้าน
          </button>
          <button
            type="button"
            className={`list-group-item list-group-item-action p1rem text-left ${jobType === "orderer" ? "active" : ""}`}
            onClick={() => setJobType("orderer")}
          >
            ผู้สั่งสินค้า
          </button>
          <button
            type="button"
            className={`list-group-item list-group-item-action p1rem text-left ${jobType === "finance" ? "active" : ""}`}
            onClick={() => setJobType("finance")}
          >
            ผู้จ่ายเงิน
          </button>
          <button
            type="button"
            className={`list-group-item list-group-item-action p1rem text-left ${jobType === "receiving" ? "active" : ""}`}
            onClick={() => setJobType("receiving")}
          >
            ผู้รับสินค้า
          </button>
          <button
            type="button"
            className={`list-group-item list-group-item-action p1rem text-left ${jobType === "Disabled" ? "active" : ""}`}
            onClick={() => setJobType("Disabled")}
          >
            ไม่มีสิทธิ
          </button>
          <button
            type="button"
            className={`list-group-item list-group-item-action p1rem text-left ${jobType === "FreeStudent" ? "active" : ""}`}
            onClick={() => setJobType("FreeStudent")}
          >
             เรียนฟรี
          </button>
        </div>
      </div>
      <div className="swal-footer">
        <div className="swal-button-container">
          <button 
          className="swal-button swal-button--cancel"
            onClick={closeModal}
          >ยกเลิก</button>
          <div className="swal-button__loader">
            <div />
            <div />
            <div />
          </div>
        </div>
        <div className="swal-button-container">
          <button
            className="swal-button swal-button--catch"
            disabled={jobType === ""}
            onClick={()=> postApi()}
          >บันทึก</button>
          <div className="swal-button__loader">
            <div />
            <div />
            <div />
          </div>
        </div>
      </div>
    </div>
  </div>) : null
}

export default Modal