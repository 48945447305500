import React  from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Order from "./Order";
import BtnConfirm from "./BtnConfirm";
import { styled } from "@mui/material/styles";
 

const TitleTypography = styled(Typography)({
  fontSize: "22px",
   color: "#081828",
   
});
function Index() {
  
  

 
  
  return (
    <Box
      sx={{
        padding: "1rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        minHeight: "100vh",
        backgroundColor: "rgb(252, 230, 244)",
      }}
    >
      <Grid
        container
        spacing={0}
        sx={{
          width: "500px",
          '@media screen and (max-width: 480px)': {
            width: "100%",
          },
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            borderRadius: "0rem",
            border: "1px solid #e0e0e0",
            textAlign: "center",
            borderBottom: "0px",
          }}
        >
          <Paper
            elevation={1}
            sx={{
              textAlign: "center",
              borderBottom: "0px",
            }}
          >
            <Box
              sx={{
                p: "12px",
              }}
            >
              <TitleTypography noWrap> กรุณากรอกจำนวนสินค้า ว่าลูกค้าต้องการลดลงเหลือเท่าไหร่
               </TitleTypography>
            </Box>
          </Paper>
          
        </Grid>

              <Order/>  
                <BtnConfirm/>
      </Grid>
    </Box>
  );
}

export default Index;
