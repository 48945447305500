import React, { useEffect } from "react";
import { SectionShopDeta } from "../../styles/themes/ShopDeta.module";
import GetShopDeta from "../../components/ShopDeta/GetShopDeta";
import Navbar from "../../components/Navbar";
import GoTop from "../../components/Button/GoTop";
import apiService from "../../api/apiService";
import swal from "@sweetalert/with-react";
import liff from "@line/liff/dist/lib";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import 'moment/locale/th';
import Footer from "../../components/footer";

function ShopData() {
  const service = apiService();
  const dispatch = useDispatch();
  const customerID = useSelector((state) => state.customerID);
  const useShopReducer = useSelector((state) => state.useShopReducer);
  const useLoadersReducer = useSelector((state) => state.useLoadersReducer);
  const today = moment().format("YYYY-MM-DD");
  
  useEffect(() => {
   
 
      service
        .get({
          url: `/api/v1/user/`,
        })
        .then((res) => {
          if (res.data.result.userType === "CONTACT" && res.data.result.customerID) {

            if(['Disabled'].includes(res.data.result?.customerJobType)){
              swal({
                title: "ขออภัยค่ะ",
                text: "คุณไม่สามารถเข้าใช้งานระบบได้",
                icon: "warning",
                button: "ตกลง",
                closeOnClickOutside: false,
              }).then(() => {
                liff.closeWindow();
              });
            } else {
              dispatch({ type: "SET_CUSTOMER_ID", payload: res.data.result.customerID });
 

              service.get({ url: `/api/v1/financial/customer-info?customerID=${res.data.result.customerID}` }).then((x) => {
                console.log(x);
                dispatch({ type: "SET_CUSTOMER_INFO", payload: x.data.result });
  
                service.get({ url: `/api/v1/order/list-condition-by-customer?customerID=${res.data.result.customerID}&startShippingDate=${today}&endShippingDate=${today}` }).then((res) => {
               
                  // if invoiceCondition == 14, 14.6, 16, 16.6, 18, 18.6, 20, 20.6, 24, 24.6 ? ให้ล็อคให้สั่งของไม่ได้ : ให้ปล่อยให้สั่งของได้
                  const filterOrder = res.data.result.filter((item) => {
                    return [0, 0.6, 4, 4.6, 14, 14.6, 16, 16.6, 16.9, 18, 18.6, 20, 20.6, 20.9, 24, 24.6, 33, 38, 39].includes(item.invoiceCondition)
                  });
    
                  if (filterOrder.length > 0) {
                    dispatch({ type: "SET_CLOSE_ORDER", payload: true });
                  } else {
                    dispatch({ type: "SET_CLOSE_ORDER", payload: false });
                  }
    
    
                }) 
              }) 
  
            
  
  
              var currentTime = new Date();
              var startTime = new Date();
              startTime.setHours(16);
              startTime.setMinutes(30);
              startTime.setSeconds(0);
  
              var endTime = new Date();
              endTime.setHours(23);
              endTime.setMinutes(59);
              endTime.setSeconds(59);
  
            
              if (currentTime >= startTime && currentTime <= endTime) {
                swal({
                  title: "ขณะนี้เลยเวลาสั่งของ (16.30 น) แล้วลูกค้ายังสามารถสั่งซื้อได้ แต่กรุณารบกวนโทรแจ้งสายส่งของท่านให้ทราบด้วย เพื่อออเดอร์จะไม่ตกหล่นนะคะ",
                  className: "swal-text-check-user3",
                  icon: "warning",
                  button: "รับทราบ",
                  closeOnClickOutside: false,
                })
              }

            }

          } else if (res.data.result.userType === "EMPLOYEE") {

            swal({
              title:
                "นี่คือหน้าสั่งสินค้าในมุมมองเดียวกับที่ลูกค้าเห็น เพียงแต่คุณพนักงานจะสั่งสินค้าจริง ๆ ไม่ได้ค่ะ",
              className: "swal-text-check-user",
              icon: "info",
              button: "ตกลง",
              closeOnClickOutside: false,
            }).then((value) => {

             

            });

          } else {
            swal({
              title:
                "ขออภัยค่ะ ท่านยังไม่ได้ลงทะเบียนเป็นลูกค้าของอรุณี กรุณาติดต่อฝ่ายบริการลูกค้านะคะ",
              className: "swal-text-check-user",
              icon: "warning",
              button: "ตกลง",
              closeOnClickOutside: false,
            }).then((value) => {
           
                liff.closeWindow()
             
            });
          }
        });

      console.log(customerID);
    // eslint-disable-next-line react-hooks/exhaustive-deps
   


  }, [customerID]);

  return (
    <>
      <Navbar />
      <SectionShopDeta
        style={{
          minHeight: "100vh",
        }}
      >
       

        {useLoadersReducer === false ?  useShopReducer.length > 0 ? <GetShopDeta /> : <div style={{
            fontSize: "14px",
            minHeight: "90vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",

          }}>ไม่พบรายการสินค้า</div>
          : <GetShopDeta /> }

 

      </SectionShopDeta>
      <GoTop />
      <Footer />
    </>
  );
}

export default ShopData;
