import styled from 'styled-components';


const Counter = styled.div`
  background-color: #ffecf8;
  padding-bottom: 2rem;
  padding-top: 2rem;
  width: 100%;
  min-height: 100vh;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin-top: 3rem;
  .title {
    font-size: 20px;
    font-weight: 600;
    border-bottom: 2px solid #1a1a1a;
    width: 500px;
    text-align: center;
    color: #1a1a1a;
    margin: 0 auto;
    height: 40px;
  margin-bottom: 1rem;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;

const TableContent = styled.div`
  width: 500px;
  margin: 0 auto;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const BackTop = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 100;

`;

const Th = styled.th`
background-color: #730000 !important;
color: #fff;
`;

 
 
 

export { Counter, TableContent, BackTop, Th };