import React, { useState } from 'react'
import styled from "styled-components";
import Total from "./total";

import Typography from '@mui/material/Typography';
import Box from "@material-ui/core/Box";
import { useHistory } from "react-router-dom";
import moment from 'moment';
import swal from 'sweetalert';
import Toastify from 'toastify-js'
import Paper from '@mui/material/Paper';

const TableContent = styled.div`

  width: 500px;
  margin: 0 auto;

  padding: 1rem;
  padding-bottom: 0;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Th = styled.th`
background-color: #730000 !important;
color: #fff;
`;

const Checkbox = styled.input`
  width: 20px;
  height: 20px;
  margin: 0 auto;
`;

const Tile = styled.div`
  width: 460px;
  padding: 1rem;
  padding-bottom: 0;
  margin: 0 auto;
  font-size: 20px;
  font-weight: 600;
  color: #1a1a1a;
      height: 52px;
    text-align: center;
  border-bottom: 2px solid #1a1a1a;
  @media (max-width: 768px) {
    width: 90%;
  }
`;



 

function Table({ props, getData, service, table_1, table_2, table_3}) {

 
 

 


  const noData = (<Typography noWrap sx={{
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100vh",
    backgroundColor: "#ffecf8",
  }}> ไม่มีข้อมูล</Typography>)



  // const checkBoxPost = (orderID) => {

  //   const data = {
  //     orderID: orderID,
  //     clearMoneyDate: moment().format("YYYY-MM-DD"),
  //   }

   


  //   swal({
  //     title: "ลูกค้าชำระเงินมาแล้ว ใช่หรือไม่?",
  //     text: "เมื่อกดใช่แล้ว จะย้อนกลับไม่ได้นะคะ",
  //     icon: "warning",
  //     buttons: ["ไม่ใช่", "ใช่"],
  //     dangerMode: true,
  //   }).then((willDelete) => {
  //     if (willDelete) {
  //       service.post({ url: "/api/v1/order/update", params: data }).then((res) => {
  //         console.log(res)
  //         getData()
  //         Toastify({
  //           text: "เคลียร์ยอดค้าง เรียบร้อยแล้ว",
  //           duration: 3000,
  //           close: true,
  //           gravity: "top", // `top` or `bottom`
  //           position: 'center', // `left`, `center` or `right`
  //           backgroundColor: "linear-gradient(to right, #00b09b, #96c93d)",
  //           stopOnFocus: true, // Prevents dismissing of toast on hover
  //           onClick: function () { } // Callback after click
  //         }).showToast();
  //       }).catch((err) => {
  //         console.log(err)
  //         Toastify({
  //           text: "เกิดข้อผิดพลาด ไม่สามารถยกเลิกการเคลียร์ยอดได้",
  //           duration: 3000,
  //           close: true,
  //           gravity: "top", // `top` or `bottom`
  //           position: 'center', // `left`, `center` or `right`
  //           backgroundColor: "linear-gradient(to right, #00b09b, #96c93d)",
  //           stopOnFocus: true, // Prevents dismissing of toast on hover
  //           onClick: function () { } // Callback after click
  //         }).showToast();

  //       }
  //     )
  //     } 
      
  //   });
  // }




  return table_1.length === 0 && table_2.length === 0 ? noData : <div>
    {
      table_1.length > 0 ? <Box sx={{
        marginTop: "1rem",
      }}> <Tile>ติ๊กเพื่อเคลียร์ยอด overdue</Tile>

        <TableContent className="bd-example">
          

 
            <Paper>
          <table className="table table-bordered table-hover shadow-sm">
        
            <thead>
              <tr>
                  <Th scope="col" className="text-center p-3 pl-05 pr-05 clickTh" >
                  orderID
                </Th>
                <Th scope="col" className="text-center p-3 pl-05 pr-05">Date</Th>
                    <Th scope="col" className="text-center p-3 pl-05 pr-05">invoiceName</Th>
                 <Th scope="col" className="text-center p-3  pl-06 pr-06" >totalPrice</Th>
              </tr>
            </thead>
            <tbody className="border-top-0">
              {table_1.map((item, index) => {
                return (
                  <tr key={index} >
                    <th scope="row" className={`text-center p-3 pl-05 pr-05  ${item.checkbox ? 'text-line' : ''}`}
                     style={{ backgroundColor: item.backgroundColorCheckbox }}
                    //  onClick={()=>item.checkbox ? null : checkBoxPost(item.orderID)}
                     >
                        {item.orderID}
                    </th>
                    <th scope="row" className={`text-center p-3 pl-05 pr-05 pointer ${item.checkbox ? 'text-line' : ''}`}
                     style={{ backgroundColor: item.backgroundColorDate, }}
                    //  onClick={()=> item.checkbox ? null : checkBoxPost(item.orderID)}
                     >
                        {item.shippedDate ? moment(item.shippedDate).format('DD/MM') : '-'}
                    </th>
                    <th scope="row" className={`text-center p-3 pl-05 pr-05 pointer ${item.checkbox ? 'text-line' : ''}`}
                     style={{ backgroundColor: item.backgroundColorPaymentType, }}
                    //  onClick={()=>item.checkbox ? null : checkBoxPost(item.orderID)}
                     >
                     <Typography noWrap sx={{margin: '0 auto', padding: '0px 0px 0px 0px !important', color: "#212529", fontSize: '14px', fontWeight: 'bold', width: '200px', '@media screen and (max-width: 600px)': { width: '130px' ,} }}>
                      {item.invoiceName}
                       </Typography>
                    </th> 
                    <th scope="row" className={`text-center p-3 pl-06 pr-06 pointer ${item.checkbox ? 'text-line' : ''}`}
                    style={{backgroundColor: item.backgroundColorPayment, color: item.colorPayment, }}
                    // onClick={()=>item.checkbox ? null : checkBoxPost(item.orderID)}
                    >
               
                        {item.totalPrice ? item.totalPrice.toLocaleString() : '-'}
                     
                   </th>
                  </tr>
                );
              })}
 
              
             
            </tbody>
          </table>
            </Paper> 
       

        </TableContent>  <Total props={props} /></Box> : null
    }


 


{
      table_2.length > 0 ? <Box sx={{
        marginTop: "1rem",
      }}> <Tile>ติ๊กเพื่อเคลียร์ยอด overdue</Tile>

        <TableContent className="bd-example">
    
 
            <Paper>
          <table className="table table-bordered table-hover shadow-sm">
        
            <thead>
              <tr>
                  <Th scope="col" className="text-center p-3 pl-05 pr-05 clickTh" >
                  orderID
                </Th>
                <Th scope="col" className="text-center p-3 pl-05 pr-05">Date</Th>
                    <Th scope="col" className="text-center p-3 pl-05 pr-05">invoiceName</Th>
                 <Th scope="col" className="text-center p-3  pl-06 pr-06" >totalPrice</Th>
              </tr>
            </thead>
            <tbody className="border-top-0">
              {table_2.map((item, index) => {
                return (
                  <tr key={index} >
                    <th scope="row" className={`text-center p-3 pl-05 pr-05  ${item.checkbox ? 'text-line' : ''}`}
                     style={{ backgroundColor: item.backgroundColorCheckbox }}
                    //  onClick={()=>item.checkbox ? null : checkBoxPost(item.orderID)}
                     >
                        {item.orderID}
                    </th>
                    <th scope="row" className={`text-center p-3 pl-05 pr-05 pointer ${item.checkbox ? 'text-line' : ''}`}
                     style={{ backgroundColor: item.backgroundColorDate, }}
                    //  onClick={()=> item.checkbox ? null : checkBoxPost(item.orderID)}
                     >
                        {item.shippedDate ? moment(item.shippedDate).format('DD/MM') : '-'}
                    </th>
                    <th scope="row" className={`text-center p-3 pl-05 pr-05 pointer ${item.checkbox ? 'text-line' : ''}`}
                     style={{ backgroundColor: item.backgroundColorPaymentType, }}
                    //  onClick={()=>item.checkbox ? null : checkBoxPost(item.orderID)}
                     >
                     <Typography noWrap sx={{margin: '0 auto', padding: '0px 0px 0px 0px !important', color: "#212529", fontSize: '14px', fontWeight: 'bold', width: '200px', '@media screen and (max-width: 600px)': { width: '130px' ,} }}>
                      {item.invoiceName}
                       </Typography>
                    </th> 
                    <th scope="row" className={`text-center p-3 pl-06 pr-06 pointer ${item.checkbox ? 'text-line' : ''}`}
                    style={{backgroundColor: item.backgroundColorPayment, color: item.colorPayment, }}
                    // onClick={()=>item.checkbox ? null : checkBoxPost(item.orderID)}
                    >
               
                        {item.totalPrice ? item.totalPrice.toLocaleString() : '-'}
                     
                   </th>
                  </tr>
                );
              })}
 
              
             
            </tbody>
          </table>
            </Paper> 
       

        </TableContent>  </Box> : null
    }



{
      table_3.length > 0 ? <Box sx={{
        marginTop: "1rem",
      }}> <Tile>ติ๊กเคลียร์ยอดที่ยังไม่ถึง due</Tile>

        <TableContent className="bd-example">
    
 
            <Paper>
          <table className="table table-bordered table-hover shadow-sm">
        
            <thead>
              <tr>
                  <Th scope="col" className="text-center p-3 pl-05 pr-05 clickTh" >
                  orderID
                </Th>
                <Th scope="col" className="text-center p-3 pl-05 pr-05">Date</Th>
                    <Th scope="col" className="text-center p-3 pl-05 pr-05">invoiceName</Th>
                 <Th scope="col" className="text-center p-3  pl-06 pr-06" >totalPrice</Th>
              </tr>
            </thead>
            <tbody className="border-top-0">
              {table_3.map((item, index) => {
                return (
                  <tr key={index} >
                    <th scope="row" className={`text-center p-3 pl-05 pr-05  ${item.checkbox ? 'text-line' : ''}`}
                     style={{ backgroundColor: item.backgroundColorCheckbox }}
                    //  onClick={()=>item.checkbox ? null : checkBoxPost(item.orderID)}
                     >
                        {item.orderID}
                    </th>
                    <th scope="row" className={`text-center p-3 pl-05 pr-05 pointer ${item.checkbox ? 'text-line' : ''}`}
                     style={{ backgroundColor: item.backgroundColorDate, }}
                    //  onClick={()=> item.checkbox ? null : checkBoxPost(item.orderID)}
                     >
                        {item.shippedDate ? moment(item.shippedDate).format('DD/MM') : '-'}
                    </th>
                    <th scope="row" className={`text-center p-3 pl-05 pr-05 pointer ${item.checkbox ? 'text-line' : ''}`}
                     style={{ backgroundColor: item.backgroundColorPaymentType, }}
                    //  onClick={()=>item.checkbox ? null : checkBoxPost(item.orderID)}
                     >
                     <Typography noWrap sx={{margin: '0 auto', padding: '0px 0px 0px 0px !important', color: "#212529", fontSize: '14px', fontWeight: 'bold', width: '200px', '@media screen and (max-width: 600px)': { width: '130px' ,} }}>
                      {item.invoiceName}
                       </Typography>
                    </th> 
                    <th scope="row" className={`text-center p-3 pl-06 pr-06 pointer ${item.checkbox ? 'text-line' : ''}`}
                    style={{backgroundColor: item.backgroundColorPayment, color: item.colorPayment, }}
                    // onClick={()=>item.checkbox ? null : checkBoxPost(item.orderID)}
                    >
               
                        {item.totalPrice ? item.totalPrice.toLocaleString() : '-'}
                     
                   </th>
                  </tr>
                );
              })}
 
              
             
            </tbody>
          </table>
            </Paper> 
       

        </TableContent>  </Box> : null
    }
 


  </div>
}

export default Table