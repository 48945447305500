import { useEffect, useState } from "react"
import apiService from "../../api/apiService"
import swal from '@sweetalert/with-react';
import liff from "@line/liff/dist/lib";
// import liffIdConfig from '../../constants/liffIdConfig.json' 
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import DoneIcon from '@mui/icons-material/Done';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';

function RegisterAutoSuccess() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        backgroundColor: '#f5f5f5',
      }}
    >
      <CssBaseline />
      <Container component="main" sx={{ mt: 8, mb: 2 }} maxWidth="sm">

        <Box
          className="shadow-sm"
          sx={{
            padding: '30px',
            borderRadius: '10px',
            backgroundColor: '#fff',
            border: '1px solid #ffff',
            paddingBottom: '0px',
          }}>
          <Avatar sx={{
            m: 1,
            bgcolor: 'secondary.main',
            margin: '0 auto',
            marginBottom: '15px',
            backgroundColor: '#23bb44',
          }}>
            <DoneIcon />
          </Avatar>
          <Typography
            className="text-center "
            sx={{
              textAlign: 'center',
              fontSize: '20px',
            }}
            variant="h5" component="h5" gutterBottom >
            ขณะนี้ไลน์ของท่าน เชื่อมเข้ากับร้านเรียบร้อยแล้วค่ะ
          </Typography>
          <Box sx={{
            textAlign: 'center',
            marginTop: '25px',
            marginBottom: '20px',
          }}>
            <Button
              variant="contained"
              onClick={() => {

                liff.closeWindow()

              }}>รับทราบ</Button>

          </Box>
        </Box>



      </Container>

    </Box>
  )
}

export default RegisterAutoSuccess