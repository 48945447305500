import{useEffect,useState} from "react"
import apiService from "../../api/apiService"
import swal from '@sweetalert/with-react';
import liff from "@line/liff/dist/lib";
// import liffIdConfig from '../../constants/liffIdConfig.json' 
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import DoneIcon from '@mui/icons-material/Done';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';

const theme = createTheme();

 

function Register() {
    const service = apiService()
    // const [customerID,setCustomerID]=useState()
    const [contactName,setContactName]=useState('')
    const [LName, setLName]=useState('')
    const [phone,setPhone]=useState('')  
    const [Checkboxs, setCheckboxs]=useState(false)
    const [userState,setUserState]=useState()
    // const [lineGroupID,setLineGroupID]=useState()
    const [err,SetErr]=useState()
    const [FNameErr,setFNameErr]=useState(false)
    const [LNameErr,setLNameErr]=useState(false)
    const [PhoneErr,setPhoneErr]=useState(false)
    const [CheckboxErr,setCheckboxErr]=useState(false)
    const [loading,setLoading]=useState(true)

  const getUser = async () => {
    service.get({url:'api/v1/user'}).then((res)=>{
          console.log(res.data.result)
          setUserState(res.data.result.userState)
      }
  ).catch(
      (err)=>{
          console.log(err)

      }
  )
  }
  const getLineGroupID = async () => {
 
    const url = window.location.href;
    const setUrl = url.split("?liff.state=%3FgroupID%3D")[1];
    const endUrl = setUrl.split("#")[0];
    console.log(endUrl);
    return endUrl;

  };

    useEffect(()=>{
     
      getLineGroupID().then((res)=>{
        
        if (localStorage.getItem('lineGroupID')) {
          localStorage.setItem('lineGroupID', JSON.stringify(res))
        } else {
          localStorage.removeItem('lineGroupID')
          localStorage.setItem('lineGroupID', JSON.stringify(res))
        }
      })

      setLoading(true);
      getUser().then(()=>{
        setLoading(false);
        
      })
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
  
    const link = ()=>{
        const data = {
          
        }
        service.post({url:'api/v1/contact/link-customer-with-lineid',params:data}).then(
            (res)=>{
                console.log(res.data.result)
                swal({
                    title: "สำเร็จ",
                    text: "เชื่อมไลน์สำเร็จ",
                    icon: "success",
                    button: "ตกลง",
      
                  })
                setUserState('FULLY_ACCESS')
            }
        ).catch(
            (err)=>{
                console.log(err)
                swal({
                    title: 'เกิดข้อผิดพลาด',
                    content: <p>{err.message}</p>,
                    icon: 'error',
                    closeOnClickOutside: false,
                    buttons: {
                      cancel: "ปิด",
                    },
                  })
            }
        )
    }

 

 

const register = ()=>{
    contactName === '' ? setFNameErr(true) : setFNameErr(false);
    LName === '' ? setLNameErr(true) : setLNameErr(false);
    phone === '' || phone.length < 10 ? setPhoneErr(true) : setPhoneErr(false);
    Checkboxs === false ? setCheckboxErr(true) : setCheckboxErr(false);

    if(contactName !== '' && LName !== '' && phone !== '' && Checkboxs !== false && phone.length >= 10){
        const data = {
            'fullName':contactName + ' ' + LName,
            'phone':phone,
            'lineGroupID': JSON.parse(localStorage.getItem('lineGroupID'))
        }
  
        service.post({url:'/api/v1/contact/register',params:data}).then(
            (res)=>{
                console.log(res.data.result)
               
                setUserState('NOT_IN_CUSTOMER')
                setContactName('')
                setLName('')
                setPhone('')
                setCheckboxs(false)

            }
        ).catch(
            (err)=>{
                console.log(err)
                swal({
                    title: 'เกิดข้อผิดพลาด',
                    content: <p>{err.message}</p>,
                    icon: 'error',
                    closeOnClickOutside: false,
                    buttons: {
                      cancel: "ปิด",
                    },
                  })
            }
        )
    }
}


const ShowState = ()=>{
  if(userState==='USER_NOT_REGISTERED')
    {
    return(
       

        <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar 
            className="shadow-sm"
            sx={{ 
                m: 1, 
                bgcolor: 'secondary.main',
                marginBottom: 2,

                 }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
               สมัครสมาชิก
            </Typography>
            <Box component="form" noValidate  sx={{ mt: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    error={FNameErr}
                    autoComplete="given-name"
                    name="firstName"
                    required
                    onChange={(e)=>{
                        setContactName(e.target.value)
                      
                    }}
                    onBlur={(e)=>{
                        if(e.target.value==='' && FNameErr===true){
                            setFNameErr(true)
                        }else{
                            setFNameErr(false)
                        }
                    }}
                    fullWidth
                    id="firstName"
                    label="กรุณากรอกชื่อ"
                    autoFocus
                    value={contactName}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    error={LNameErr}
                    onChange={(e)=>{
                        setLName(e.target.value)
                        
                    }}
                    onBlur={(e)=>{
                        if(e.target.value==='' && LNameErr===true){
                            setLNameErr(true)
                        }else{
                            setLNameErr(false)
                        }
                    }}
                    value={LName}
                    required
                    fullWidth
                    id="lastName"
                    label="กรุณากรอกนามสกุล"
                    name="lastName"
                    autoComplete="family-name"
                    
                  />
                </Grid>
                 
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    name="phone"
                    error={PhoneErr}
                   
                    onChange={(e)=>{
                
                        if(e.target.value.length <= 10){
                            setPhone(e.target.value)
                        }
                    }}
                    onBlur={(e)=>{
                        if(e.target.value==='' && PhoneErr === true){
                            setPhoneErr(true)
                        }else{
                            setPhoneErr(false)
                        }
                    }}
                    label="กรุณากรอกเบอร์โทรศัพท์"
                    type="number"
                    inputMode="numeric"
                    id="phone"
                    autoComplete="new-phone"
                    inputProps={{ 
                    inputMode: 'numeric', 
                    pattern: '[0-9]*',
                
                    }}
                    value={phone}
                    />
                </Grid>
                <Grid item xs={12}>
                <FormControlLabel
                
                  control={
                  <Checkbox
                    value="allowExtraEmails"
                    color={'primary'}
                    onChange={(e)=>{
                        setCheckboxs(e.target.checked)
                        if(e.target.checked===true){
                            setCheckboxErr(false)
                        }
                    }}
                    sx={{
                        'svg': {
                            color:  CheckboxErr ? '#d32f2f' : 'primary',
                        }
                    }}
                     />}
                     className="checkbox"
                     sx={{
                        color: CheckboxErr ? '#d32f2f' : 'primary',
                     }}
                  label="ตรวจสอบความถูกต้อง ก่อนสมัครสมาชิก"
                />
              </Grid>
              </Grid>
              <Button
                type="button"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={() => register()}
              >
                สมัครสมาชิก
              </Button>
               
            </Box>
          </Box>
          
        </Container>
      </ThemeProvider>
    )
    }  else if(userState==='NOT_IN_CUSTOMER') {
        return(
       

        <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        backgroundColor: '#f5f5f5',
      }}
    >
      <CssBaseline />
      <Container component="main" sx={{ mt: 8, mb: 2 }} maxWidth="sm">
         
       <Box
       className="shadow-sm"
        sx={{
            padding: '30px',
            borderRadius: '10px',
            backgroundColor: '#fff',
            border: '1px solid #ffff',
            paddingBottom: '0px',
        }}>
             <Avatar sx={{ 
                m: 1, 
                bgcolor: 'secondary.main',
                margin: '0 auto',
                marginBottom: '15px',
                backgroundColor: '#f50057',
             }}>
              <PriorityHighIcon />
            </Avatar>
       <Typography 
        className="text-center "
        sx={{
            textAlign: 'center',
            fontSize: '20px',
        }}
        variant="h5" component="h5" gutterBottom >
            กรุณากดเพื่อเชื่อมไลน์ไอดีของท่านเข้ากับร้านนะคะ
        </Typography>
            <Box sx={{
                textAlign: 'center',
                 marginTop: '25px',
                 marginBottom: '20px',
            }}>
                <Button 
                     variant="contained"
                     onClick={() => link()}>
                     เชื่อมไลน์ไอดี
                </Button>

            </Box>
       </Box>


        
      </Container>
      
    </Box>
    )
    } else if(userState==='FULLY_ACCESS')
    {return(
       


        <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        backgroundColor: '#f5f5f5',
      }}
    >
      <CssBaseline />
      <Container component="main" sx={{ mt: 8, mb: 2 }} maxWidth="sm">
         
       <Box
       className="shadow-sm"
        sx={{
            padding: '30px',
            borderRadius: '10px',
            backgroundColor: '#fff',
            border: '1px solid #ffff',
            paddingBottom: '0px',
        }}>
             <Avatar sx={{ 
                m: 1, 
                bgcolor: 'secondary.main',
                margin: '0 auto',
                marginBottom: '15px',
                backgroundColor: '#23bb44',
             }}>
              <DoneIcon />
            </Avatar>
       <Typography 
        className="text-center "
        sx={{
            textAlign: 'center',
            fontSize: '20px',
        }}
        variant="h5" component="h5" gutterBottom >
          ขณะนี้ไลน์ของท่าน เชื่อมเข้ากับร้านเรียบร้อยแล้วค่ะ
        </Typography>
            <Box sx={{
                textAlign: 'center',
                 marginTop: '25px',
                 marginBottom: '20px',
            }}>
                <Button 
                variant="contained"
               onClick={()=>{
              
                   liff.closeWindow()
                
                }}>รับทราบ</Button>

            </Box>
       </Box>


       
      </Container>
       
    </Box>

    )
    }
    else if(userState==='LINE_GROUP_NOT_LINKED'){
    return(
    
<Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        backgroundColor: '#f5f5f5',
      }}
    >
      <CssBaseline />
      <Container component="main" sx={{ mt: 8, mb: 2 }} maxWidth="sm">
         
       <Box
       className="shadow-sm"
        sx={{
            padding: '30px',
            borderRadius: '10px',
            backgroundColor: '#fff',
            border: '1px solid #ffff',
            paddingBottom: '0px',
        }}>
             <Avatar sx={{ 
                m: 1, 
                bgcolor: 'secondary.main',
                margin: '0 auto',
                marginBottom: '15px',
                backgroundColor: '#23bb44',
             }}>
              <DoneIcon />
            </Avatar>
       <Typography 
        className="text-center "
        sx={{
            textAlign: 'center',
            fontSize: '20px',
        }}
        variant="h5" component="h5" gutterBottom >
        ขณะนี้ร้านค้าของท่านยังไม่เชื่อมกับไลน์
        </Typography>
            <Box sx={{
                textAlign: 'center',
                 marginTop: '25px',
                 marginBottom: '20px',
            }}>
                <Button 
                variant="contained"
               onClick={()=>{
               
                   liff.closeWindow()
                 
                
                }}>รับทราบ</Button>

            </Box>
       </Box>


       
      </Container>
       
    </Box>
    )
    }
    else
    {
    
    return (<div>  <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '100vh',

    }}>
      <CircularProgress />
    </div></div>)
    }
}

return loading ? (
    <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        
    }}>
      <CircularProgress />
    </div>
) : ShowState();



    
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function () {
  try {
    return Register()
  } catch (e) {
    alert(e)
    alert(e.stack)
  }
}