import React from 'react'
import styled from "styled-components";


const Totals = styled.div`
  width: 500px;
  margin: 0 auto;
   padding: 1rem;
   padding-top: 0rem;
   margin-bottom: 1rem;
  @media (max-width: 768px) {
    width: 100%;
  }

  .total{
    display: flex;
    justify-content: space-between;
    background-color: #ff00a6;
  }

`;


const List = styled.div`
 display: flex;
justify-content: center;
  .list {
    width: 500px;
       border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    margin-top: 1rem;
  

    .list__detail {
      display: flex;
      justify-content: space-between;
       padding: 0.5rem;
        background-color: #fff;
      .list__detail__title {
        font-size: 16px;
        color: #1a1a1a;

       
      }
    }

 
    .list__detail_qrt{
       display: flex;
      justify-content: space-between;
       padding: 0.5rem;
        background-color: #f7a4d8;
          border-radius: 0 0 10px 10px;
      .list__detail_qrt__title {
        font-size: 16px;
        color: #1a1a1a;
       
      }
    }


    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;



function Total({ props }) {
  const invoiceCondition = [8.6, 8.9, 12.6, 13, 16.9, 20.9, 36, 39]
  const total = props.data.filter((item) => { return invoiceCondition.includes(item.invoiceCondition) }).length;
  const totalAmount = props.data.filter((item) => { return invoiceCondition.includes(item.invoiceCondition) }).reduce((a, b) => a + b.totalPrice, 0);

  return (
    <Totals>
      <List >
        <div className='list shadow-sm'>


          <div className='list__detail' style={{
            borderBottom: '2px solid #dfdfdf'
          }}>
            <div className='list__detail__title'>
              รวมทั้งหมด
            </div>
            <div className='list__detail__title'>
              {total} ออเดอร์
            </div>
          </div>

          <div className='list__detail_qrt'>
            <div className='list__detail_qrt__title'>
              เป็นจำนวนเงิน
            </div>
            <div className='list__detail_qrt__title'>
              {totalAmount > 0 ? totalAmount.toLocaleString() : 0} บาท
            </div>
          </div>

        </div>
      </List>
    </Totals>
  )
}

export default Total