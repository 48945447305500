import React, { useState, useEffect } from "react";
import MoneyDetail1 from "./MoneyDetail1";
import MoneyDetail2 from "./MoneyDetail2";
import MoneyDetail3 from "./MoneyDetail3";
import { Counter } from "./style.module";
import Heading from "./heading";
import { useParams } from "react-router-dom";
import apiService from "../../api/apiService";
import swal from "@sweetalert/with-react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";
import Backdrop from '@mui/material/Backdrop';


function ClearMoneyDetail() {
  const { shipperID, date } = useParams();
  const service = apiService();
  const [loading1, setLoading1] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const [loading3, setLoading3] = useState(true);


  const [data, setData] = useState([]);
  const [returnProduct, setReturnProduct] = useState([]);
  const [receiptAmount1, setReceiptAmount1] = useState(0);
  const [receiptAmount2, setReceiptAmount2] = useState(0);
  const [showButton, setShowButton] = useState(true);
  const [orderCancel, setOrderCancel] = useState(1);
  const [orderOther1, setOrderOther1] = useState([]);
  const [orderOther2, setOrderOther2] = useState([]);
  const [orderOther3, setOrderOther3] = useState([]);
  const [employeeNickname, setEmployeeNickname] = useState("");

  const productList = async () => {
    return await service.get({ url: `/api/v1/product/list` }).then((res) => {
      return res.data.result;
    }).catch((err) => { 
      console.log(err);
      swal({
        title: `${err.message}`,
        text: "กรุณาลองใหม่อีกครั้ง",
        icon: "error",
        button: "ตกลง",
      });
    });

  };

 
  

 // /api/v1/financial/list-proof-of-payment
  async function getProofOfPayment() {
    return service.get({ url: `/api/v1/financial/list-proof-of-payment?receiptDate=${moment().format("YYYY-MM-DD")}` }).then((res) => {
      return res.data.result;
    })
  }

///api/v1/user/
  async function getUser() {
    return service.get({ url: `/api/v1/user/` }).then((res) => {
      return res.data.result;
    })
  }

  // /api/v1/employee/list
  async function getEmployee() {
    return service.get({ url: `/api/v1/employee/list` }).then((res) => {
      return res.data.result;
    })
  }



  useEffect(() => {

    getEmployee().then((res) => {
      setEmployeeNickname(res.filter((item) => { return item.userID === Number(shipperID) })[0].employeeNickname);
    })

    service.get({url: `/api/v1/order/list-condition-by-shipper?shipperID=${shipperID}&shippingDate=${date}`, }).then((res) => {
 
        setData(res.data.result); 
      
        const cancel = res.data.result.filter((item) => { 
          // shippingDate <= date
          const _date = date ? moment(date).format("YYYY-MM-DD") : null
          const _shippingDate = item.shippingDate ? moment(item.shippingDate).format("YYYY-MM-DD") : null
          return _shippingDate <= _date
        }).filter((item) => { return item.shippedDate === null && item.orderStatus !== true });


      setOrderCancel(cancel.length); 


      console.log("ออเดอร์ที่ยังไม่ส่ง", cancel); 
      // 6, 6.6, 10, 10.6
      setOrderOther1(res.data.result.filter((item) => { return [6, 6.6, 10, 10.6].includes(item.invoiceCondition)}));
      // 2, 5, 7, 11
      setOrderOther2(res.data.result.filter((item) => { return [2, 5, 7, 11].includes(item.invoiceCondition)}));
      // 18, 18.6, 20, 20.6, 22, 22.6, 24, 24.6, 26, 26.6
      setOrderOther3(res.data.result.filter((item) => { return [18, 18.6, 20, 20.6, 22, 22.6, 24, 24.6, 26, 26.6].includes(item.invoiceCondition)}));

      })
      .catch((err) => {
        console.log(err);
        swal({
          title: `${err.message}`,
          text: "กรุณาลองใหม่อีกครั้ง",
          icon: "error",
          button: "ตกลง",
        });
      });


    productList().then((product) => {
      service.get({ url: `/api/v1/product/return-list?shipperID=${shipperID}` }).then((res) => {
        console.log(res.data.result);
        const returnList = res?.data?.result ? res.data.result : [];
 
         

          Promise.all([getProofOfPayment(), getUser()]).then((res) => {
     
          
             
              const hasIngStockRequestID = returnList.length > 0 ? returnList.some((item) => item.hasOwnProperty("ingStockRequestID")) : true;
     
            // const checkPayment = res[0].filter((item) => { return item.isCashTransfer === true && item.shipperID === Number(shipperID) && item.paymentMethod === "cash_ThisPayment" });
          
            const JobType = res[1]?.employeeJobType === "Salesperson"
            setShowButton(JobType ? true : hasIngStockRequestID);
             
         }).catch((err) => {
            console.log(err);
            swal({
              title: `${err.message}`,
              text: "กรุณาลองใหม่อีกครั้ง",
              icon: "error",
              button: "ตกลง",
            });
          });
    
        setLoading1(false);

        setReturnProduct(res.data.result !== null ? res.data.result.map((item) => {
          return {
            ...item,
            productNameShort: product.filter((product) => { return product.productID === item.productID })[0].productNameShort,
          }
        }) : []);

        console.log(res.data.result !== null ? res.data.result.map((item) => {
          return {
            ...item,
            productNameShort: product.filter((product) => { return product.productID === item.productID })[0].productNameShort,
          }
        }) : [])
          

      }).catch((err) => {
        
        console.log(err);
        swal({
          title: `${err.message}`,
          text: "กรุณาลองใหม่อีกครั้ง",
          icon: "error",
          button: "ตกลง",
        });
      });
    });

   




    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipperID, date]);

  // 4.6+4+3+8.6+8.9+9+13+12.6+12.9
 
  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") 
        return;
}

  return (
    <Counter>
      <Heading />

          <div className="title">เคลียร์เงิน ({employeeNickname})</div>
            <MoneyDetail1 setLoading2={setLoading2}  data={data} setReceiptAmount1={setReceiptAmount1} />
            <MoneyDetail2 setLoading3={setLoading3} data={data} setReceiptAmount2={setReceiptAmount2} orderCancel={orderCancel} orderOther1={orderOther1} orderOther2={orderOther2} orderOther3={orderOther3} />
            <MoneyDetail3 loading1={loading1} loading2={loading2} loading3={loading3} returnProduct={returnProduct} receiptAmount1={receiptAmount1} receiptAmount2={receiptAmount2} showButton={showButton} setShowButton={setShowButton} orderCancel={orderCancel}/>
     
            <Backdrop
              onClose={handleClose}
             sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
             open={loading1 && loading2 && loading3 !== false}
             
            >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Counter>
  );
}

export default ClearMoneyDetail;
