

export const setPayment = (invoiceCondition) => {

  if ([2, 5, 7, 11].includes(invoiceCondition)) {
    return {
      backgroundColor: '#FFFFFF',
      text: "cancel",
      color: '#000000'
    };
  } else if ([2.5, 5.5, 7.5, 11.5].includes(invoiceCondition)) {
    return {
      backgroundColor: '#FFFFFF',
      text: "not shipped",
      color: '#FF0000'
    };
  } else if ([14, 14.6, 16, 16.6, 18, 18.6, 20, 20.6, 24, 24.6, 33, 38, 39].includes(invoiceCondition)) {
    return {
      backgroundColor: '#000000',
      text: "lock",
      color: '#FFFFFF'
    };
  } else if ([0, 0.6].includes(invoiceCondition)) {
    return {
      backgroundColor: '#FFFF00',
      text: "not paid",
      color: '#000000'
    };
  } else if ([6, 6.6, 10, 10.6].includes(invoiceCondition)) {
    return {
      backgroundColor: '#D3D3D3',
      text: "not due",
      color: '#000000'
    };
  } else if ([1, 8, 12].includes(invoiceCondition)) {
    return {
      backgroundColor: '#008000',
      text: "proof of payment this payment",
      color: '#FFFFFF'
    };
  } else if ([15, 19].includes(invoiceCondition)) {
    return {
      backgroundColor: '#008000',
      text: "proof of payment previous payment",
      color: '#FFFFFF'
    };
  } else if ([3, 9, 12.9].includes(invoiceCondition)) {
    return {
      backgroundColor: '#DC143C',
      text: "cash transfer this payment",
      color: '#FFFFFF'
    };
  } else if ([17, 21].includes(invoiceCondition)) {
    return {
      backgroundColor: '#DC143C',
      text: "cash transfer previous payment",
      color: '#FFFFFF'
    };
  } else if ([4, 4.6, 8.6, 8.9, 12.6, 13].includes(invoiceCondition)) {
    return {
      backgroundColor: '#FFFF00',
      text: "check cash transfer this payment",
      color: '#DC143C'
    };
  } else if ([16.9, 20.9].includes(invoiceCondition)) {
    return {
      backgroundColor: '#FFFF00',
      text: "check cash transfer previous payment",
      color: '#DC143C'
    };
  } else if ([22, 22.6, 26, 26.6, 28, 28.6].includes(invoiceCondition)) {
    return {
      backgroundColor: '#FFFFFF',
      text: "not paid credit",
      color: '#7F00FF'
    };
  } else if ([23, 25, 27, 29].includes(invoiceCondition)) {
    return {
      backgroundColor: '#7F00FF',
      text: "paid credit",
      color: '#FFFFFF'
    };
  } else if ([31, 35, 36].includes(invoiceCondition)) {
    return {
      backgroundColor: '#FFFF00',
      text: "not paid debt",
      color: '#DC143C'
    };
  } else if ([32, 34].includes(invoiceCondition)) {
    return {
      backgroundColor: '#008000',
      text: "proof of payment debt",
      color: '#DC143C'
    };
  } else if ([37, 40].includes(invoiceCondition)) {
    return {
      backgroundColor: '#DC143C',
      text: "cash transfer debt",
      color: '#FFFFFF'
    };
  } else {
    return {
      backgroundColor: 'unset',
      text: "no data",
      color: '#000000'
    };
  }


};


export const setInvoiceCondition = (invoiceCondition) => {

  if (invoiceCondition >= 0 && invoiceCondition <= 13) {
    return {
      text: "ส่งงวดนี้",
      backgroundColor: "#fce6f4",
    };
  } else if (invoiceCondition >= 14 && invoiceCondition <= 17) {
    return {
      text: "เก็บค้าง",
      backgroundColor: "#e6eefc",
    };
  } else if (invoiceCondition >= 18 && invoiceCondition <= 21) {
    return {
      text: "เลยกําหนดชําระ",
      backgroundColor: "#9dbffa",
    };
  } else if (invoiceCondition >= 22 && invoiceCondition <= 29) {
    return {
      text: "เครดิต",
      backgroundColor: "#c878fa",
    };
  } else {
    return {
      text: "no data",
      backgroundColor: "unset",
    };
  }



};


export const setPaymentType = (paymentType) => {


  if (["transferNow", "COD"].includes(paymentType)) {
    return {
      text: "สด",
      backgroundColor: "#ffddf4",
    };
  } else if ([
    "1_invoiceOverdue",
    "2_invoiceOverdue",
    "3_invoiceOverdue",
  ].includes(paymentType)) {
    return {
      text: "ค้าง",
      backgroundColor: "#AFDBF5",
    };
  } else if ([
    "15_Day_Defaultable",
    "30_Day_Defaultable",
    "15_Day_Non_Defaultable",
    "30_Day_Non_Defaultable",
  ].includes(paymentType)) {
    return {
      text: "เครดิต",
      backgroundColor: "#E0B0FF",
    };
  } else {
    return {
      text: "no data",
      backgroundColor: "unset",
    };
  }

}