import React from 'react'
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from "@mui/material/Typography";

function Product_Details({ orderDetailRecords }) {
  console.log("orderDetailRecords", orderDetailRecords);
  return orderDetailRecords.sort((a, b) => a.productSequence - b.productSequence).map((item, index) => {
    const quantity = item.quantity || 0 
    return (
      <div key={index}>
        <Grid container className="mt-3">
          <Grid item xs={10}>
            <Paper sx={{
              padding: ".5rem",
              borderRadius: "0",
              border: "1px solid #eaeaea",

              borderBottom: "none",
            }}>
              <Typography noWrap>
                {item?.productNameFull ? item?.productNameFull : '-'}
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={2}>
            <Paper sx={{
              padding: ".5rem",
              borderRadius: "0",
              border: "1px solid #eaeaea",
              borderBottom: "none",
              textAlign: "center",
            }}>
              <Typography noWrap>
               {item.receivedQuantity !== null ? item.receivedQuantity.toLocaleString() : quantity.toLocaleString()}
              </Typography>

            </Paper>
          </Grid>
        </Grid><Grid container>
          <Grid item xs={6}>
            <Paper sx={{
              padding: ".5rem",
              borderRadius: "0",
              border: "1px solid #eaeaea",
              textAlign: "end",
            }}>
              <Typography noWrap>
                ราคา {item.price.toLocaleString()}
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper sx={{
              padding: ".5rem",
              borderRadius: "0",
              border: "1px solid #eaeaea",

              textAlign: "end",
            }}>
              <Typography noWrap>
                รวม {item.totalAmount.toLocaleString()}
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </div>
    )
  })


}

export default Product_Details