import apiService from "../../../api/apiService";
const service = apiService();


async function getListOrderToShip({employeeID, OrderSelectedDate}) {
    // GET api/v1/order/list-order-to-ship (Payload employeeID && date=today) แล้วเอา response มาเก็บไว้ใน state ชื่อ listOrderToShip
    return await service
      .get({
        url: `/api/v1/order/list-order-to-ship/?employeeID=${employeeID}&date=${OrderSelectedDate}`,
      })
      .then((res) => {
        return res.data.result;
      });
  }

  async function getProductList() {
    return await service.get({ url: `/api/v1/product/list` }).then((res) => {
      return res.data.result;
    });
  }

  // /api/v1/selling-price-level/list
  async function getSellingPriceLevelList() {
    return await service
      .get({ url: `/api/v1/selling-price-level/list` })
      .then((res) => {
        return res.data.result;
      });
  }

  //  /api/v1/financial/list-proof-of-payment?approved=true&customerID=
  async function getProofOfPayment({ customerID }) {
    return await service
      .get({
        url: `/api/v1/financial/list-proof-of-payment?approved=true&customerID=${customerID}`,
      })
      .then((res) => {
        return res.data.result;
      });
  }

  // /api/v1/order/list-not-shipped?userID=1
  async function getListNotShipped({employeeID}) {
    return await service
      .get({ url: `/api/v1/order/list-not-shipped?userID=${employeeID}` })
      .then((res) => {
        return res.data.result;
      });
  }

  // POST /api/v1/order/update
  async function updateOrder(params) {
    return await service
      .post({ url: `/api/v1/order/update`, params: params })
      .then((res) => {
        return res.data.result;
      });
  }


  // /api/v1/order/?orderID=${orderID}
  async function getOrder({ orderID }) {
    return await service
      .get({ url: `/api/v1/order/?orderID=${orderID}` })
      .then((res) => {
        return res.data.result;
      });
  }

///api/v1/route/all-with-shipper
async function getRouteList() {
    return await service
        .get({ url: `/api/v1/route/all-with-shipper` })
        .then((res) => {
        return res.data.result;
        });
}



  export {
    getListOrderToShip,
    getProductList,
    getSellingPriceLevelList,
    getProofOfPayment,
    getListNotShipped,
    updateOrder,
    getOrder,
    getRouteList
}