import React from "react";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import RemoveIcon from "@mui/icons-material/Remove";
import { useSelector, useDispatch } from "react-redux";

const StyleStack = styled(Stack)({
  marginTop: "3rem",

  "@media screen and (max-width: 480px)": {
    marginTop: "2rem",
  },
  "@media screen and (max-width: 400px)": {
    marginTop: "1.5rem",
  },
  
});

const StyleIconButton = styled(IconButton)({
  border: "1px solid #e0e0e0",
  width: "40px",
  height: "40px",
  "@media screen and (max-width: 430px)": {
    width: "30px",
    height: "30px",
  },
});

const StyleAddIcon = styled(AddIcon)({
  padding: "0",
  fontSize: "18px",
});

const StyleRemoveIcon = styled(RemoveIcon)({
  padding: "0",
  fontSize: "18px",
});

function PlusMinus({value, productID}) {
  const dispatch = useDispatch();
  const useOrderAgainShippr = useSelector(state => state.useOrderAgainShippr);

 
  const addORder = () => {
    // const quantity = useOrderAgainShippr.find(item => item.productID === productID).quantity;
    const quantity = useOrderAgainShippr.filter(item => item.productID === productID)[0].quantity;
    // const quantityMax = useOrderAgainShippr.find(item => item.productID === productID).quantityMax;
    const quantityMax = useOrderAgainShippr.filter(item => item.productID === productID)[0].quantityMax;
    // const totalPrice = useOrderAgainShippr.find(item => item.productID === productID).totalPrice;
    const totalPrice = useOrderAgainShippr.filter(item => item.productID === productID)[0].totalPrice;
    // const purchasePrice = useOrderAgainShippr.find(item => item.productID === productID).purchasePrice;
    const purchasePrice = useOrderAgainShippr.filter(item => item.productID === productID)[0].purchasePrice;
    const setQuantity = quantity + 1 > quantityMax ? quantityMax : quantity + 1;
      // console.log("quantityMax", quantityMax);
      console.log("setQuantity", value);

    dispatch({
      type: "UPDATE_ORDER_AGAIN_SHIPPR",
      payload: {
        productID: productID,
        // imageURL:  useOrderAgainShippr.find(item => item.productID === productID).imageURL,
        imageURL:  useOrderAgainShippr.filter(item => item.productID === productID)[0].imageURL,
        // productNameFull: useOrderAgainShippr.find(item => item.productID === productID).productNameFull,
        productNameFull: useOrderAgainShippr.filter(item => item.productID === productID)[0].productNameFull,
        purchasePrice: purchasePrice,
        quantity: setQuantity,
        quantityMax : quantityMax,
        totalPrice: totalPrice,
        newTotalPrice: Number(setQuantity) * Number(purchasePrice),
      },
    });
  }



  const removeOrder = () => {
    
    // const quantity = useOrderAgainShippr.find(item => item.productID === productID).quantity;
    const quantity = useOrderAgainShippr.filter(item => item.productID === productID)[0].quantity;
    // const quantityMax = useOrderAgainShippr.find(item => item.productID === productID).quantityMax;
    const quantityMax = useOrderAgainShippr.filter(item => item.productID === productID)[0].quantityMax;
    // const totalPrice = useOrderAgainShippr.find(item => item.productID === productID).totalPrice;
    const totalPrice = useOrderAgainShippr.filter(item => item.productID === productID)[0].totalPrice;
    // const purchasePrice = useOrderAgainShippr.find(item => item.productID === productID).purchasePrice;
    const purchasePrice = useOrderAgainShippr.filter(item => item.productID === productID)[0].purchasePrice;
    const setQuantity = quantity - 1 < 0 ? 0 : quantity - 1;
   
    dispatch({
      type: "UPDATE_ORDER_AGAIN_SHIPPR",
      payload: {
        productID: productID,
        // imageURL:  useOrderAgainShippr.find(item => item.productID === productID).imageURL,
        imageURL:  useOrderAgainShippr.filter(item => item.productID === productID)[0].imageURL,
        // productNameFull: useOrderAgainShippr.find(item => item.productID === productID).productNameFull,
        productNameFull: useOrderAgainShippr.filter(item => item.productID === productID)[0].productNameFull,
        purchasePrice: purchasePrice,
        quantity: setQuantity,
        quantityMax : quantityMax,
        totalPrice: totalPrice,
        newTotalPrice: Number(setQuantity) * Number(purchasePrice),
      },
    });
  }


 
  

  const StyleInput = styled("input")({
    "@media screen and (max-width: 430px)": {
      fontSize: "18px",
      width: "30px",
      height: "30px",
    },
  });

  return (
    <StyleStack direction="row" alignItems="center" spacing={0}>
      <StyleIconButton aria-label="add" onClick={addORder}>
        <StyleAddIcon />
      </StyleIconButton>
      <Box>
        <StyleInput
          type="number"
          inputMode="numeric"
          value={value}
          disabled
          sx={{
            width: "50px",
            height: "40px",
            textAlign: "center",
            fontSize: "18px",
            border: "none",
            padding: "5px",
            '@media screen and (max-width: 480px)': {
              width: "70px",
            },
          }}

   
        />
      </Box>
      <StyleIconButton aria-label="RemoveIcon" onClick={removeOrder}>
        <StyleRemoveIcon />
      </StyleIconButton>
    </StyleStack>
  );
}

export default PlusMinus;
