import React, { Component } from "react";
 

class CloudinaryUploadWidget extends Component {

  componentDidMount() {
   
    let myWidget = window.cloudinary.createUploadWidget(
      {
        cloudName: "suvarnabhumi-food",
        uploadPreset: "jutttjti"
      },
      (error, result) => {
        if (!error && result && result.event === "success") {
       
          if (result.event === "success") {
            document.getElementById(`image${this.props.id}`).src = result.info.secure_url;
                this.props.setImage([
              {
                id: this.props.id,
                image: result.info.secure_url
              },
            ]);
          }
        }

      }
    );
   

    document.getElementById(`text-upload${this.props.id}`).addEventListener( "click", function() {
      myWidget.open();
    });

  }

  render() {
    return (
      <p className="text-upload-btn" id={`text-upload${this.props.id}`}>กรุณาอัพโหลด<br />รูปสินค้าที่เสียก่อน</p>
    );
  }
}

export default CloudinaryUploadWidget;
