import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
function Loading({ pages }) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
      }}
    >
      <CircularProgress
        color={
          pages === "checkout-for-sell"
            ? "secondary"
            : pages === "checkout-for-gift"
            ? "primary"
            :  pages === "checkout-sample" ? "success" : "default"
        }
      />
    </Box>
  );
}

export default Loading;
