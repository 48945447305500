
export const useNumberOfHeartsReducer = (state = 0, action) => {
  switch (action.type) {
    case "SET_NUMBER_OF_HEARTS":
      return action.payload;
    default:
      return state;
  }
}

 