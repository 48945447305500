import apiService from "../api/apiService";
import { useState ,useEffect} from "react";
import liff from "@line/liff/dist/lib";
// import liffIdConfig from '../constants/liffIdConfig.json' 

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import swal from '@sweetalert/with-react';
import Box from "@material-ui/core/Box";
import CircularProgress from '@mui/material/CircularProgress';
import { useHistory, useLocation } from "react-router-dom";



function UrlLinkToLineGroup(){
    const service = apiService()
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const [lineGroupUrl,setLineGroupUrl]=useState("")
    
    const [loading,setLoading]=useState(true)
    const [error,setError]=useState(false)
    const history = useHistory();
   const lineGroupID = params.get("lineGroupID")


    useEffect(()=>{
        setLoading(false);
    },[])


    const linkCustomer = ()=>{
        
        if(lineGroupUrl){
            const data = { 'lineGroupURL': lineGroupUrl, 'lineGroupID': lineGroupID }

            service.post({url:'api/v1/customer/link-line-group-url',params:data}).then(
                (res)=>{
                    console.log(res.data.result)
                    swal({
                        title: "กรอก lineGroupURL สำเร็จ",
                        text: "lineGroupURL ถูกเชื่อมต่อกับระบบเรียบร้อยแล้ว",
                        icon: "success",
                        buttons: ["ย้อนกลับไปตั้งค่า", "ออกจากการตั้งค่า"],
                    }).then((value) => {
                        if (value) {
                             console.log("ออกจากการตั้งค่า");
                    
                                liff.closeWindow()
                           

                        } else{
                            history.back()
                            
                        }
                    })
                }
            ).catch(
                (err)=>{
                    console.log(err)
                    swal({
                        title: 'เกิดข้อผิดพลาด',
                        content: <p>{err.message}</p>,
                        icon: 'error',
                        closeOnClickOutside: false,
                        buttons: {
                          cancel: "ปิด",
                        },
                      })
                }
            )
    
          
    
    
            
        }else{
            setError(true)
        }
       


    }
    return loading ? (<Box sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
      }}>
        <CircularProgress />
      </Box>) :   (
        <div style={{
            minHeight:'100vh',
            backgroundColor:'#f5f5f5',
        }}>
            <div className="pt-5 text-center pb-5">
                <h5>กรุณากรอก lineGroupURL</h5>
            </div>
            <div className="pt-1 text-center pb-1">
                <TextField  
                label="lineGroupURL ลิงก์เชิญ" 
                variant="outlined"
                className="input-customerID"
                sx={{
                    width:'250px',
                }}
                onChange={(e)=>{setLineGroupUrl(e.target.value)}}
                value={lineGroupUrl}
                error={error}
                />

            </div>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '10px'
            }}>
               
                <Button
                sx={{
                    width: '130px',
                }}
                 variant="contained" onClick={()=>{linkCustomer()}}>ถูกต้อง</Button>
            </div>
        </div>
        )
}
export default UrlLinkToLineGroup