import React from 'react'


function SelectFood({ foodType, setFoodType, foodTypeAll, setData }) {
  return (
    <div>
              
                <select
                 name="food type"
                 className="form-select form-select-sm mb-1 border-secondary"
                 style={{fontSize: "1rem", width: "100%"}}
                 onChange={(e) => { 
                    setFoodType(e.target.value) 
                    // รีเซ็ตค่า setData ให้ price กับ quantityRecipe เป็น null
                    setData((prev) => {
                          const newData = prev.map((item) => {
                              return {
                                  ...item,
                                  price: null,
                                  quantityRecipe: null
                              }
                          }
                          )
                          return newData
                    })

                 

                    const localStorageData = JSON.parse(localStorage.getItem("rawMaterial"))
                    localStorageData.foodType = e.target.value
                    localStorage.setItem("rawMaterial", JSON.stringify(localStorageData))
              

                }}
                 value={foodType || ""} >  
                    { foodTypeAll.map((item) => { return <option value={item}>{item}</option> } ) }
                </select>
               </div>
  )
}

export default SelectFood