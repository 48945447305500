import apiService from "../../api/apiService";
import { SweetError } from "./modal";
const service = apiService();

// /api/v1/product/list
async function getProductList() {
    return await service.get({ url: '/api/v1/product/list' }).then((res) => {
      return res.data.result
    }).catch((err) => {
        console.log(err)
        SweetError("api/v1/product/list", err.message)
     })
  }

  ///api/v1/selling-price-level/list
 async function getSellingPriceLevelList() {
        return await service.get({ url: '/api/v1/selling-price-level/list' }).then((res) => {
            return res.data.result
        }).catch((err) => {
            console.log(err)
            SweetError("api/v1/selling-price-level/list", err.message)
         })
 }

    // /api/v1/shipper/all
async function getShipperAll() {
        return await service.get({ url: '/api/v1/shipper/all' }).then((res) => {
            return res.data.result
        }).catch((err) => {
            console.log(err)
            SweetError("api/v1/shipper/all", err.message)
         })
}


// /api/v1/employee/list
async function getEmployeeList() {
        return await service.get({ url: '/api/v1/employee/list' }).then((res) => {
            return res.data.result
        }).catch((err) => {
            console.log(err)
            SweetError("api/v1/employee/list", err.message)
            })

}


// /api/v1/shipper/customers?shipperID=${shipperID}
async function getShipperCustomers(shipperID) {
        return await service.get({ url: `/api/v1/shipper/customers?shipperID=${shipperID}` }).then((res) => {
            return res.data.result
        }).catch((err) => {
            console.log(err)
            SweetError("api/v1/shipper/customers", err.message)
         })
}

///api/v1/order/list-condition-by-shipper?shipperID=${e}&shippingDate=${PostShippingDate}
 async function getOrderListConditionByShipper(shipperID, shippingDate) {
        return await service.get({ url: `/api/v1/order/list-condition-by-shipper?shipperID=${shipperID}&shippingDate=${shippingDate}` }).then((res) => {
            return res.data.result
        }).catch((err) => {
            console.log(err)
            SweetError("api/v1/order/list-condition-by-shipper", err.message)
         })
  }


// /api/v1/financial/customer-info?customerID=${customerID}
async function getCustomerInfo(customerID) {
    return await service.get({ url: `/api/v1/financial/customer-info?customerID=${customerID}` }).then((res) => {
        return res.data.result
    }).catch((err) => {
        console.log(err)
        SweetError("api/v1/financial/customer-info", err.message)
        })
}

// /api/v1/order/checkout-for-sell
async function postCheckoutForSell(data) {
    return await service.post({ url: `/api/v1/order/checkout-for-sell`, params: data }).then((res) => {
        return res.data.result
    }).catch((err) => {
        console.log(err)
        SweetError("api/v1/order/checkout-for-sell", err.message)
        })
}

// /api/v1/order/checkout-for-gift
async function postCheckoutForGift(data) {
    return await service.post({ url: `/api/v1/order/checkout-for-gift`, params: data }).then((res) => {
        return res.data.result
    }).catch((err) => {
        console.log(err)
        SweetError("api/v1/order/checkout-for-gift", err.message)
        })
}


///api/v1/user/
async function getUser() {
    return await service.get({ url: `/api/v1/user/` }).then((res) => {
        return res.data.result
    }).catch((err) => {
        console.log(err)
        SweetError("api/v1/user/", err.message)
        })
}


// /api/v1/order/checkout-for-sample
async function postCheckoutForSample(data) {
    return await service.post({ url: `/api/v1/order/checkout-for-sample`, params: data }).then((res) => {
        return res.data.result
    }).catch((err) => {
        console.log(err)
        SweetError("api/v1/order/checkout-for-sample", err.message)
        })
}


///api/v1/route/all-with-shipper
async function getRouteAllWithShipper() {
    return await service.get({ url: `/api/v1/route/all-with-shipper` }).then((res) => {
        return res.data.result
    }).catch((err) => {
        console.log(err)
        SweetError("api/v1/route/all-with-shipper", err.message)
        })
}


export {
  getProductList,
  getSellingPriceLevelList,
  getShipperAll,
  getEmployeeList,
  getShipperCustomers,
  getOrderListConditionByShipper,
  getCustomerInfo,
  postCheckoutForSell,
  postCheckoutForGift,
  getUser,
  postCheckoutForSample,
  getRouteAllWithShipper
};