import React from 'react'
import "./404.css";
import { Link } from 'react-router-dom';


function Error_pages() {

  const pathname = window.location.pathname;


  return (
    <div>
      <section className="page_404">
        <div className="page_404_container">
          <div className="page_404_row">
            <div className="page_404_col-sm-12 ">
              <div className="page_404_col-sm-10 page_404_col-sm-offset-1  page_404_text-center">
                <div className="page_404_four_zero_four_bg">
                  <h1 className="page_404_text-center ">404</h1>
                </div>
                <div className="page_404_contant_box_404">
                  <h3 className="page_404_h2">ดูเหมือนคุณจะหลงทาง</h3>
                  <p>หน้าที่คุณกําลังมองหาหน้า <span style={{ color: '#f00' }}>{pathname.split('/')}</span> ไม่สามารถใช้งานได้!!</p>
                   
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Error_pages