import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import NoProductList from "./NoProductList";
import { Link } from "react-router-dom";
import swal from "@sweetalert/with-react";
import apiService from "../../api/apiService";
import './style.css';
import { useHistory, useLocation
} from "react-router-dom";
import Modal from '@mui/material/Modal';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { SweetError } from "../checkout/modal";
import { Button, Paper } from "@material-ui/core";
import Backdrop from '@mui/material/Backdrop';

 
function ProductList({ loading }) {
  const history = useHistory();
  const service = apiService();
  const location = useLocation();
  const dispatch = useDispatch();
  const useCartReducer = useSelector((state) => state.useCartReducer);
 
  const customerID = useSelector((state) => state.customerID);
  const closeOrder = useSelector((state) => state.closeOrder);
  const [open, setOpen] = useState(false);
  const customerInfo = useSelector((state) => state.customerInfo);
  const [userType, setUserType] = useState("");
  const ค่าสินค้า = (useCartReducer.reduce((total, item) => { return total + item.ราคารวม }, 0)) 
  const รวมหนี้ที่ชำระงวดนี้ = customerInfo?.debtInstallment ? Number(customerInfo?.debtInstallment) : 0;
  const รวมเป็นเงิน = Number(ค่าสินค้า) + Number(รวมหนี้ที่ชำระงวดนี้); 

  

const swalError = (message) => {
  if(message === "Customer has over limit outstandingorder."){
    swal({
      title: "วันนี้คุณลูกค้าได้สั่งมา 1 ออเดอร์แล้ว",
      text: "คุณลูกค้าไม่สามารถสั่งซ้อนกันออเดอร์ซ้อนกัน 2 ออเดอร์ได้นะคะ คุณลูกค้าต้องการแก้ไขออเดอร์เดิมใช่ไหมคะ?",
      icon: "info",
      className: "swal-text-check-user2",
       buttons: ["ปิด", "ต้องการแก้ไขออเดอร์เดิม"],
     
    }).then((value) => {
      if(value){
        history.push("/CancelOrder")
      }
    });
   } else {
     swal({
       title: "ขออภัย สินค้าไม่พร้อมให้บริการ",
       text: "กรุณาลองใหม่อีกครั้ง หรือติดต่อเจ้าหน้าที่",
       icon: "error",
       button: "ตกลง",
     });
   }
}


  // /api/v1/order/delete

  async function postOrderDelete({ orderID }) {
    return await service.post({ url: `/api/v1/order/delete`, params: { orderID : orderID} }).then((res) => {
        return res.data.result
    }).catch((err) => {
        console.log(err)
   })
}

  async function getUser() {
    return await service.get({ url: `/api/v1/user/` }).then((res) => {
      return res.data.result
    }).catch((err) => {
      console.log(err)
      SweetError("api/v1/user/", err.message)
    })
  }

 

  useEffect(() => {
    getUser().then(res => {
      const userType = res.userType
      setUserType(userType);
    })
  }, []);

  const checkout = () => {

    if (closeOrder === true) {
      swal({
        title: "ขอประทานโทษนะคะ กรุณาชําระค่าสินค้างวดที่แล้วให้เรียบร้อยก่อนค่ะ จึงจะสามารถสั่งสินค้างวดนี้ได้นะคะ ขออภัยที่ทำให้เกิดความไม่สะดวกค่ะ",
        className: "swal-text-check-user2",
        icon: "error",
        button: "กรุณาชำระเงิน",
      }).then((value) => {
       if(value){
         history.push({
            pathname: "/paymentAmount",
            state: {Baskets: true}
         })
       }
      });
    }else if(location?.state && location?.state.length > 0){

      

       // setIsLoading(true);
       setOpen(true);
       function checkout(){
        
       const data = {
         customerID: customerID,
         orderDetail: [
           ...useCartReducer.map(item => {
             return {
               productID: item.id,
               quantity: item.จำนวน
             }
           })

         ]
       }

       // console.log("data", data);

       service.post({ url: '/api/v1/order/checkout', params: data }).then(res => {
         console.log(res)
         console.log(res.data.result)

         dispatch({
           type: "SET_CART_DATA",
           payload: []
         })


         history.push({
          pathname: "/paymentAmount",
          state: {Baskets: true }
       })
         setOpen(false);
       }).catch(err => {
         console.log(err.message)
         setOpen(false);
         swalError(err.message)

       })

       console.log(data);

       }

       
      if(location?.state  && location?.state.length > 0){
        const orderID =  location?.state && location?.state[0]?.orderID;
        if(orderID){
          postOrderDelete({ orderID }).then(res => {
             checkout();
          })
        } else {
          checkout();
        }
      } else {
         checkout();
      }


       
      
       } else {
        swal({
          className: "swal-custom-checkout",
          // ไม่ไห้ปิดได้
          closeOnClickOutside: false,
          content: (
            <div >
              <div>
                <p style={{
                  padding: "10px",
                  border: " 1px solid #fff",
                  backgroundColor: "#ff32a4",
                  color: "#fff",
                  borderBottom: "0",
                  fontSize: "16px",
                  fontWeight: "500",
  
                }}>
                  ใบสั่งสินค้า 
                </p>
  
                <p style={{
                  padding: "10px",
                  border: "1px solid  #fff",
                  backgroundColor: "#f1c7e0",
  
                  fontSize: "16px",
                  fontWeight: "500",
                  color: " #1A1A1A"
                }}>
                  วันสั่ง : {new Date().toLocaleDateString(
                    "th-TH",
                    {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    }
                  )}
                </p>
  
              </div>
              <div style={{
                marginTop: "20px",
                height: "30vh",
                overflow: "auto"
              }}>
                {useCartReducer.map((item, index) => {
                  return (
                    <div className='mt-4' key={index}>
                      <div className='d-flex'>
                        <div>
                          <img src={item.รูปภาพ} className="img-fluid" alt={item.สินค้า} width={120} height={100} style={{
                            borderRadius: ".25rem",
                          }} onError={
                            (e) => {
                              e.target.onerror = null;
                              e.target.src = process.env.REACT_APP_NO_IMAGE
                            }
                          } />
                        </div>
                        <div style={{
                          width: "100%",
                          textAlign: "left",
                          marginLeft: "10px",
                          display: "flex",
  
                          justifyContent: "center",
                          flexDirection: "column"
  
                        }}>
                          <p style={{
                            fontSize: "16px",
                            fontWeight: "500",
                            color: " #1A1A1A"
                          }}>{item.สินค้า}</p>
  
                          <p style={{
                            fontSize: "16px",
                            fontWeight: "500",
                            color: " #1A1A1A"
                          }}>จำนวน : {item.จำนวน}</p>
                          <p style={{
                            fontSize: "16px",
                            fontWeight: "500",
                            color: " #1A1A1A"
                          }}>ราคา : {item.ราคา}</p>
                        </div>
                      </div>
                    </div>
                  )
                }
  
                )}
  
              </div>
  
              <div className="d-flex mt-3">
                <p style={{
                  padding: "10px",
                  border: " 1px solid  #fff",
                  borderRight: "0",
                  width: "100%",
                  fontSize: "16px",
                  fontWeight: "500",
                  color: " #1A1A1A",
                  backgroundColor: "#f1c7e0",
                }}>
                  ค่าสินค้า
                </p>
                <p style={{
                  padding: "10px",
                  border: "1px solid  #fff",
                  width: "150px",
                  textAlign: "right",
                  fontSize: "16px",
                  fontWeight: "500",
                  color: " #1A1A1A",
                  backgroundColor: "#f1c7e0",
  
                }}>
                  {ค่าสินค้า.toLocaleString('en-US', { style: 'currency', currency: 'THB' })}
                </p>
                
              </div>
              {Number(รวมหนี้ที่ชำระงวดนี้) > 0 && <div className="d-flex mt-0">
                <p style={{
                  padding: "10px",
                  border: " 1px solid  #fff",
                  borderRight: "0",
                  width: "100%",
                  fontSize: "16px",
                  fontWeight: "500",
                  color: " #1A1A1A",
                  backgroundColor: "#f1c7e0",
                }}>
                  รวมหนี้ที่ชำระงวดนี้
                </p>
              
                <p style={{
                  padding: "10px",
                  border: "1px solid  #fff",
                  width: "150px",
                  textAlign: "right",
                  fontSize: "16px",
                  fontWeight: "500",
                  color: " #1A1A1A",
                  backgroundColor: "#f1c7e0",
  
                }}>
                  {รวมหนี้ที่ชำระงวดนี้.toLocaleString('en-US', { style: 'currency', currency: 'THB' })}
                </p>
  
              </div>}
   
              <div className="d-flex mt-0">
                <p style={{
                  padding: "10px",
                  border: " 1px solid  #fff",
                  borderRight: "0",
                  width: "100%",
                  fontSize: "16px",
                  fontWeight: "500",
                  color: " #1A1A1A",
                  backgroundColor: "#f1c7e0",
                }}>
                  รวมเป็นเงิน
                </p>
                <p style={{
                  padding: "10px",
                  border: "1px solid  #fff",
                  width: "150px",
                  textAlign: "right",
                  fontSize: "16px",
                  fontWeight: "500",
                  color: " #1A1A1A",
                  backgroundColor: "#f1c7e0",
  
                }}>
                  {รวมเป็นเงิน.toLocaleString('en-US', { style: 'currency', currency: 'THB' })}
                </p>
  
              </div>
            </div>
          ),
          buttons: [
            {
              text: "ยกเลิก",
              className: "btn btn-danger",
            },
            {
              text: "ยืนยัน",
              className: "btn btn-success",
            }
          ],
  
          // eslint-disable-next-line no-dupe-keys
          buttons: ["ยกเลิก",
  
            {
              text: "ยืนยันคำสั่งซื้อ",
              className: "__btn-success",
            }
  
          ],
        }).then((value) => {
          if (value) {
            // setIsLoading(true);
  
            setOpen(true);
            const data = {
              customerID: customerID,
              orderDetail: [
                ...useCartReducer.map(item => {
                  return {
                    productID: item.id,
                    quantity: item.จำนวน
                  }
                })
  
              ]
            }
  
            // console.log("data", data);
  
            service.post({ url: '/api/v1/order/checkout', params: data }).then(res => {
              console.log(res)
              console.log(res.data.result)
  
              dispatch({
                type: "SET_CART_DATA",
                payload: []
              })
  
  
              history.push({
                pathname: "/paymentAmount",
                state: {Baskets: true }
             })
              setOpen(false);
            }).catch(err => {
              console.log(err.message)
              setOpen(false);
              swalError(err.message)
            })

            
  
            console.log(data);
  
          }
        });
       }
    



   

  }

  const addtocart = (e) => {
    const id = e.รหัสสินค้า;
    const name = e.สินค้า;
    const price = e.ราคา 
    const image = e.รูปภาพ;
    const amount = e.จำนวน;
    const heart = e.heart;
    const Category = e.หมวด;
    const details = e.รายละเอียด;

    // console.log(e);

  


    if(id === 18 || id === 19){
      //  ให้สินค้า ปลาเส้นทอด (productID = 18) และปลาเส้นขาว (productID = 19) ให้เพิ่มทีละ 5 หน่วย
      if (useCartReducer.length > 0) {
     
        Promise.all(useCartReducer.map((item) => {
            if (item.id === id) {
         
              
              dispatch({
                type: "UPDATE_CART_DATA",
                payload: {
                  id: id,
                  รหัสสินค้า: id,
                  สินค้า: name,
                  ราคา: price,
                  รูปภาพ: image,
                  จำนวน: item.จำนวน + 5,
                  ราคารวม: (item.จำนวน + 5) * price,
                  หมวด: Category,
                  heart: heart,
                  รายละเอียด: details,
                },
              });
            }
          })
        );
      } else {
        console.log("ไม่มีสินค้าในตะกร้า2");
        dispatch({
          type: "ADD_CART_DATA",
          payload: {
            id: id,
            รหัสสินค้า: id,
            สินค้า: name,
            ราคา: price,
            รูปภาพ: image,
            จำนวน: 5,
            ราคารวม: price * 5,
            หมวด: Category,
            heart: false,
  
            รายละเอียด: details,
          },
        });
      }
    } else {
      if (useCartReducer.length > 0) {
     
        Promise.all(useCartReducer.map((item) => {
            if (item.id === id) {
             
              dispatch({
                type: "UPDATE_CART_DATA",
                payload: {
                  id: id,
                  รหัสสินค้า: id,
                  สินค้า: name,
                  ราคา: price,
                  รูปภาพ: image,
                  จำนวน: item.จำนวน + 1,
                  ราคารวม: item.ราคารวม + price,
                  หมวด: Category,
                  heart: heart,
                  รายละเอียด: details,
                },
              });
            }
          })
        );
      } else {
        console.log("ไม่มีสินค้าในตะกร้า2");
        dispatch({
          type: "ADD_CART_DATA",
          payload: {
            id: id,
            รหัสสินค้า: id,
            สินค้า: name,
            ราคา: price,
            รูปภาพ: image,
            จำนวน: 1,
            ราคารวม: price,
            หมวด: Category,
            heart: false,
  
            รายละเอียด: details,
          },
        });
      }

     }

  };

  const minusNum = (e) => {
    const id = e.รหัสสินค้า;
    const name = e.สินค้า;
    const price = e.ราคา;
    const image = e.รูปภาพ;
    const details = e.รายละเอียด;
    const category = e.หมวด;
    const heart = e.heart;

    if(id === 18 || id === 19){
      if (useCartReducer.length > 0) {
        Promise.all(
          useCartReducer.map((item) => {
            if (item.id === id) {
              if (item.จำนวน <= 5 || item.จำนวน - 1 < 0 || item.จำนวน === 1) {
                dispatch({
                  type: "SET_CART_DATA",
                  payload: useCartReducer.filter((item) => item.id !== id),
                });
              } else {
                dispatch({
                  type: "UPDATE_CART_DATA",
                  payload: {
                    id: id,
                    รหัสสินค้า: id,
                    สินค้า: name,
                    ราคา: price,
                    รูปภาพ: image,
                    จำนวน: item.จำนวน - 5 < 0 ? 0 : item.จำนวน - 5,
                    ราคารวม: (item.จำนวน - 5) * price,
                    หมวด: category,
                    heart: heart,
                    รายละเอียด: details,
                  },
                });
              }
            }
          })
        );
      }
    }else{
      if (useCartReducer.length > 0) {
        Promise.all(
          useCartReducer.map((item) => {
            if (item.id === id) {
              if (item.จำนวน === 1) {
                dispatch({
                  type: "SET_CART_DATA",
                  payload: useCartReducer.filter((item) => item.id !== id),
                });
              } else {
                dispatch({
                  type: "UPDATE_CART_DATA",
                  payload: {
                    id: id,
                    รหัสสินค้า: id,
                    สินค้า: name,
                    ราคา: price,
                    รูปภาพ: image,
                    จำนวน: item.จำนวน - 1,
                    ราคารวม: item.ราคารวม - price,
                    หมวด: category,
                    heart: heart,
                    รายละเอียด: details,
                  },
                });
              }
            }
          })
        );
      }
    }
  };



   //ราคารวมทั้งหมด = จำนวน * ราคา
    const total = useCartReducer.reduce((acc, item) => {
      return acc + item.จำนวน * item.ราคา;
    }, 0);

    const handleClose = (event, reason) => {
      if (reason && reason === "backdropClick") 
          return;
  }
  
 
  const buttonConfirm = () => {
    if (userType === "EMPLOYEE"){
      return <div className="button">
        <div className="mt-2 disabled">
          <button className="btn btn-alt" disabled>
            สั่งสินค้าเพิ่ม
          </button>
        </div>
      </div>
    }else{
      return <div className="button">

        <div>
          <Paper elevation={3}
            style={{
              fontSize: "16px",
            }}
            className="btn btn-alt" onClick={checkout}>
            {
              location?.state && location?.state[0]?.orderID ? "ยืนยันการแก้ไข" : "สั่งซื้อสินค้า"
            }
          </Paper>
        </div>

        <div className="mt-2">


          {
            location?.state && location?.state[0]?.orderID &&
            <Paper elevation={3}

              style={{
                fontSize: "16px",
                backgroundColor: "#ff80bf",
                color: "#000",
              }}
              className="btn btn-alt" onClick={() => {
                history.push({
                  pathname: "/",
                  state: useCartReducer.map((item) => {
                    return {
                      ...item,
                      orderID: location?.state && location?.state[0]?.orderID ? location?.state[0]?.orderID : null,
                      productID: item.id,
                      quantity: item.จำนวน,
                      purchasePrice: item.ราคา,
                    }
                  })
                })


              }}>
              สั่งสินค้าเพิ่ม
            </Paper>
          }






        </div>


      </div>
    }
  };

  return (
    <div
      className="shopping-cart-b section fade-in"
      style={{
        minHeight: "100vh",
        display: "flex",
        paddingTop: "100px",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#FFE4E1",

      }}
    >
    
      {
        loading ? <CircularProgress /> : <Box
        className="container"
        id="Basketscontainer"
        sx={{
          margin: "0 auto",
          width: "500px",
          '@media (max-width: 600px)': {
            width: "100%",
          },

        }}
      >
        {useCartReducer.reduce((acc, item) => {
          return item.จำนวน + acc;
        }, 0) <= 0 ? <NoProductList /> : null}

        <div style={{
          display: useCartReducer.reduce((acc, item) => {
            return item.จำนวน + acc;
          }, 0) > 0 ? "block" : "none",
       

        }}>
          {useCartReducer.reduce((acc, item) => {
            return item.จำนวน + acc;
          }, 0) > 0 ? useCartReducer.map((item, index) => {
              return (
               
                <Paper
                  elevation={2}
                  key={index}
                  style={{
                    width: "100%",
                    marginTop: index === 0 ? "0px" : "10px",
               
                  }}>
                  <div style={{
                    display: "flex",
                    background: "#FFFFFF",
                    padding: "10px",
                    borderRadius: "4px",
                  }}>

                    <img
                      className="img-thumbnail"
                      style={{
                        width: "35%",
                      }}
                      src={item.รูปภาพ}
                      alt={item.สินค้า}
                      onClick={() => { history.push({
                        pathname: `/product/${item.id}`,
                        state: location.state,
                      }) }} 
                    />

                    <div style={{
                      marginLeft: "10px",

                    }}>
                      <h5 className="product-name mb-0">
                        <Link to={{
                          pathname: `/product/${item.id}`,
                          state: location.state,
                        }} style={{
                          fontSize: "20px",
                        }}>
                          {item.สินค้า}
                        </Link>
                      </h5>
                      <p className="product-des">

                        <span style={{
                          fontSize: "18px",
                        }}>
                          <em>ราคา:</em> {item.ราคา} บาท
                        </span>
                      </p>

                      <div className="num-block skin-2 d-flex ">
                        <div className="num-in" style={{
                          border: "2px solid rgb(204, 204, 204)",
                        }}>
                          <span className="minus dis" onClick={() => minusNum(item)} />
                          <input

                            type="number"
                            className="in-num"
                            value={item.จำนวน ? item.จำนวน : 0}
                            onClick={(e) => { e.target.select() }}
                            inputMode="numeric"
                            style={{
                              height: "35px",
                            }}
                            onChange={(e) => {
                             
                              if (e.target.value > 0) {
                                // เช็คว่า useCartReducer มีข้อมูลหรือไม่
                                if (useCartReducer.filter(item2 => item2.id === item.รหัสสินค้า).length > 0) {
                                  // ถ้ามีข้อมูลให้เอาข้อมูลมาใช้

                                  dispatch({
                                    type: "UPDATE_CART_DATA",
                                    payload: {
                                      id: item.รหัสสินค้า,
                                      รหัสสินค้า: item.รหัสสินค้า,
                                      สินค้า: item.สินค้า,
                                      ราคา: item.ราคา,
                                      รูปภาพ: item.รูปภาพ,
                                      จำนวน: parseInt(e.target.value),
                                      ราคารวม: item.ราคา * parseInt(e.target.value),
                                      หมวด: item.หมวด,
                                      heart: item.heart,
                                      รายละเอียด: item.รายละเอียด,
                                    }
                                  })
                                } else {
                                  // ถ้าไม่มีข้อมูลให้เพิ่มข้อมูลใหม่
                                  dispatch({
                                    type: "ADD_CART_DATA",
                                    payload: {
                                      id: item.รหัสสินค้า,
                                      รหัสสินค้า: item.รหัสสินค้า,
                                      สินค้า: item.สินค้า,
                                      ราคา: item.ราคา,
                                      รูปภาพ: item.รูปภาพ,
                                      จำนวน: parseInt(e.target.value),
                                      ราคารวม: item.ราคา * parseInt(e.target.value),
                                      หมวด: item.หมวด,
                                      heart: item.heart,
                                      รายละเอียด: item.รายละเอียด,
                                    }
                                  })
                                }



                              } else {
                                dispatch({
                                  type: "DELETE_CART_DATA",
                                  payload: {
                                    id: item.รหัสสินค้า,
                                  }
                                })
                              }



                            }}
                          />
                          <span className="plus" onClick={() => addtocart(item)} />
                        </div>
                      </div>

                    </div>

                  </div>
                </Paper>
              );
            })
            : null}


        </div>

        {useCartReducer.reduce((acc, item) => {
          return item.จำนวน + acc;
        }, 0) > 0 ? (
          <div className="row">
            <div className="col-12">

              <div className="total-amount">
                <div className="row">
                  <div
                    style={{ width: "100%" }}
                  >
                    <Paper className="right">
                      <ul style={{
                        listStyleType: "none",
                      }}>
                        {useCartReducer.map((item, index) => {
                          return (
                            <li key={index}>
                              {/* {item.สินค้า}  <span>{(item.ราคารวม).toLocaleString()} บาท</span> */}
                              <div className="row">
                                <div className="col-7 overflow-example">
                                  {item.สินค้า}
                                </div>
                                <div className="col-1 pl-0 text-center">
                                   {(item.จำนวน).toLocaleString()}
                                </div>
                                <div className="col-4 overflow-example text-right">
                                   { ((item.จำนวน || 0) * (item.ราคา || 0)).toLocaleString()} บาท
                                </div>
                              </div>

                            </li>
                          );
                        })}



                        <li>
                          ราคารวมทั้งหมด<span>{total.toLocaleString() } บาท</span>
 

                        </li>
                      </ul>
                      

                          {buttonConfirm()}
                    </Paper>
                  </div>
                </div>
              </div>

            </div>
          </div>
        ) : null}
      </Box>
      }
     

      <Backdrop
        onClose={handleClose}
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={()=> setOpen(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

    </div>
  );
}

export default ProductList;