import apiService from "../../api/apiService";
import swal from '@sweetalert/with-react';

const service = apiService();

// /api/v1/employee/list
async function getEmployeeList() {
  return await service.get({ url: `/api/v1/employee/list` }).then((res) => {
    return res.data.result
  }).catch((err) => {
    console.log(err)

    swal({ title: "Error", text: err?.response?.data?.message || err.message, icon: "error", button: "close" });
  })
}

///api/v1/order/list-by-shipping-date
export async function getOrderListByShippingDate({ startDate, endDate }) {
  return await service.get({ url: `/api/v1/order/list-by-shipping-date?startDate=${startDate}&endDate=${endDate}` }).then((res) => {
    return res.data.result
  }).catch((err) => {
    console.log(err)
    swal({ title: "Error", text: err?.response?.data?.message || err.message, icon: "error", button: "close" });
  })
}
//product/list
export async function getProductList() {
  return await service.get({ url: `/api/v1/product/list` }).then((res) => {
    return res.data.result
  }).catch((err) => {
    console.log(err)
    swal({ title: "Error", text: err?.response?.data?.message || err.message, icon: "error", button: "close" });
  })
}

/// api / v1 / order /
export async function getOrder({ orderID }) {
  return await service.get({ url: `/api/v1/order?orderID=${orderID}` }).then((res) => {
    return res.data.result
  }).catch((err) => {
    console.log(err)
    swal({ title: "Error", text: err?.response?.data?.message || err.message, icon: "error", button: "close" });
  })
}

///api/v1/order/full-detail
export async function getOrderFullDetail({ orderID }) {
  return await service.get({ url: `/api/v1/order/full-detail?orderID=${orderID}` }).then((res) => {
    return res.data.result
  }).catch((err) => {
    console.log(err)
    swal({ title: "Error", text: err?.response?.data?.message || err.message, icon: "error", button: "close" });
  })
}

///api/v1/customer/?customerID=1
export async function getCustomer({ customerID }) {
  return await service.get({ url: `/api/v1/customer/?customerID=${customerID}` }).then((res) => {
    return res.data.result
  }).catch((err) => {
    console.log(err)
    swal({ title: "Error", text: err?.response?.data?.message || err.message, icon: "error", button: "close" });
  })
}