import React, { useState, useEffect} from "react";
import Paper from "@mui/material/Paper";
import Typography   from "@mui/material/Typography";
 
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useHistory } from "react-router-dom";
import Select from 'react-select'



const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // ชมพูเข้ม
    backgroundColor: "#990000",
    color: theme.palette.common.white,
    border: 0,
    fontSize: '1rem',
    borderRight: "1px solid #ddd",
    
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: '1rem',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  
  // hide last border
  '&:last-child td, &:last-child th': {
    border: "1px solid #ddd",
    padding: 6,
  
  },
}));

 

 
function Table1({ options, setCusID, cusID, route, cusList}) {

  const routeID = cusID && route.length > 0 && cusList.length > 0 ? cusList.filter((item) => Number(item.customerID) === Number(cusID))[0]?.routeID : null;

  
  return (
    <div>

   


   
        
        <TableContainer component={Paper}>
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell sx={{ width: "50%"}}>ชื่อลูกค้า</StyledTableCell>
            <StyledTableCell sx={{ width: "50%"}}>สายในการส่ง</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>

       
                <StyledTableRow  >
                <StyledTableCell component="th" scope="row" sx={{ width: "50%"}} align="left">
                  <Select options={options} menuPortalTarget={document.body}  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} onChange={(e) => {
                    setCusID(e.value);
                    
                  }} />
                </StyledTableCell>
                <StyledTableCell sx={{ width: "50%", paddingLeft: "16px !important", }} align="left">
                  {
                    routeID ? route.filter((item) => Number(item.routeID) === Number(routeID))[0]?.routeName : null
                  }
                </StyledTableCell>
              
           
              </StyledTableRow>
             
         
           
       
        </TableBody>
      </Table>
    </TableContainer>
 
      
    </div>
  );
}

export default Table1;
