import React, { useState, useEffect } from 'react'
import './style.css';
import UploadFile from './uploadFile';
import LoadingButton from '@mui/lab/LoadingButton';
import apiService from "../../api/apiService"
import { ToastifyError, copyToClipboard, swalChange, swalSuccess } from './tools';
import CircularProgress from '@mui/material/CircularProgress';
import { useHistory } from 'react-router-dom';
import swal from "@sweetalert/with-react";
import liff from "@line/liff/dist/lib";
import imageCompression from 'browser-image-compression';
import QrScanner from 'qr-scanner';
import { SweetError } from '../../components/checkout/modal'
import {
  Counter,
  List,
  Payment,
  PaymentText,
  Upload,
  Button,
  FileImage,
  UploadFileContent,
} from './payment.module'



function PaymentAmount() {
  const service = apiService();
  const history = useHistory();
  
  const [FileUrl, setFileUrl] = useState(null);
  const [FileLoading, setFileLoading] = useState("");
  const [FilePost, setFilePost] = useState(null);
  const [LoadingButtonCheck, setLoadingButtonCheck] = useState(false);
  const [advancePayment, setAdvancePayment] = useState(0);
  const [Order, setOrder] = useState([]);
  const [Order2, setOrder2] = useState([]);
  const [Loading, setLoading] = useState(true);
  const [customerID, setCustomerID] = useState(null);
  const [show_button, setShowButton] = useState(false);
  const [sale, setSale] = useState([]);
  const [transferNow, setTransferNow] = useState(false);


  // /api/v1/product/list
async function getProductList() {
  return await service.get({ url: '/api/v1/product/list' }).then((res) => {
    return res.data.result
  }).catch((err) => {
      console.log(err)
      SweetError("api/v1/product/list", err.message)
   })
}

// /api/v1/order/?orderID=1
async function getOrder(orderID) {
  return await service.get({ url: `/api/v1/order/?orderID=${orderID}` }).then((res) => {
    return res.data.result
  }).catch((err) => {
      console.log(err)
      SweetError("api/v1/order/?orderID=1", err.message)
    })
}


async function getSellingPriceLevelList() {
  return await service.get({ url: '/api/v1/selling-price-level/list' }).then((res) => {
      return res.data.result
  }).catch((err) => {
      console.log(err)
      SweetError("api/v1/selling-price-level/list", err.message)
   })
}

  

  function getApi() {

    const getUser = async () => {
      const res = await service.get({ url: "/api/v1/user/" });
      return res.data.result
    }


    getUser().then((e) => {
      // saleID
      if (['Disabled'].includes(e?.customerJobType)) {
        swal({
          title: "ขออภัยค่ะ",
          text: "คุณไม่สามารถเข้าใช้งานระบบได้",
          icon: "warning",
          button: "ตกลง",
          // ให้ปิดไม่ให้กดถ้าไม่กดตกลง
          closeOnClickOutside: false,
        }).then(() => {
          liff.closeWindow();
        });
      }

      setCustomerID(e.customerID);

      service.get({ url: `/api/v1/employee/?userID=${e.saleID}` }).then((res) => {
        setSale(res.data.result);
      })



      service.get({
        url: `/api/v1/financial/advance-payment?customerID=${e.customerID}`
      }).then((res) => {
        setAdvancePayment(res.data.result.advancePayment);
      })

      service.get({ url: `/api/v1/order/list-by-customer?customerID=${e.customerID}` }).then((res) => {

         
        const result = res.data.result.filter((item) => {
          //  paidDate == null || orderStatus != true} && {orderDate == TODAY 
          return item.paidDate === null && item.orderStatus !== true ? new Date(item.orderDate).toLocaleDateString() === new Date().toLocaleDateString() ? item : null : null
        }).filter((item) => {
          return item.shippedDate === null ? item : null
        })

        const result2 = res.data.result.filter((item) => {
          return item.paidDate === null && item.orderStatus !== true ? item.invoiceStatus !== null ? item : null : null
        })


        const orderID = result.map((item) => { return item.orderID });
 
        Promise.all([getProductList(), getSellingPriceLevelList()]).then((res) => {
            const product = res[0];
            const sellingPriceLevel = res[1];
       
            Promise.all(orderID.map((item) => { return getOrder(item) })).then((order) => {
              const list = result.map((item1) => { 
                return {
                  ...item1,
                  orderDetailRecords: order.filter((item2) => { return item1.orderID === item2.orderID })[0].orderDetailRecords.map((item3) => {
                    const price = sellingPriceLevel.filter((item5) => { return item3.productID === item5.productID })[0].price;
                    const productNameFull = product.filter((item4) => { return item3.productID === item4.productID })[0].productNameFull;
                    const totalPrice = price * item3.quantity;
                    return {
                      ...item3,
                      productNameFull: productNameFull,
                      price: price,
                      totalPrice: totalPrice
                    }
                  })

                }
              })
              console.log(list);
              setOrder(list);
              setLoading(false);
          })
        })

      
        setOrder2(result2);
       

       
        console.log(result2);

      }).catch((err) => {
        swal({
          title: `${err.message}`,
          className: "swal-text-check-user",
          icon: "warning",
          button: "ตกลง",
        }).then((value) => {

          liff.closeWindow()

        });
      })


      async function GetCustomerInfo() {
        return await service.get({ url: `/api/v1/financial/customer-info?customerID=${e.customerID}` }).then((res) => {
          return res.data.result;
        })
      }

      // /api/v1/customer/?customerID=1
      async function GetCustomer() {
        return await service.get({ url: `/api/v1/customer/?customerID=${e.customerID}` }).then((res) => {
          return res.data.result;
        })
      }

      Promise.all([GetCustomerInfo(), GetCustomer()]).then((result) => {
        const dueAndOverdueBalance = result[0] ? result[0].dueAndOverdueBalance : 0;
        const advancePayment = result[0] ? result[0].advancePayment : 0;
        const overdueBalance = result[0] ? result[0].overdueBalance : 0;
        const customer = result[1];
        console.log(customer);
        // paymentType === transferNow ให้ === true
       
        const Baskets = history?.location?.state?.Baskets;
   
        setTransferNow(Baskets ? customer.paymentType === "transferNow" ? true : false : true);
        // console.log(dueOrNotDue);
        // - API customer-info  (dealAndOverdueBalance - advancePayment <= 0)
        if (dueAndOverdueBalance - advancePayment <= 0) {
          setShowButton(true);
          //- else API /api/v1/customer/ (paymentType === 15_Day_Defaultable || 30_Day_Defaultable, && API customer-info overdueBalance - advancePayment <=0 
        } else if (result[1].paymentType === "15_Day_Defaultable" || result[1].paymentType === "30_Day_Defaultable") {
          if (overdueBalance - advancePayment <= 0) {
            setShowButton(true);
          }
          // else API /api/v1/customer/ (paymentType === 15_Day_Non_Defaultable || 30_Day_Non_Defaultable : ไม่ต้องทำงาน
        } else if (result[1].paymentType === "15_Day_Non_Defaultable" || result[1].paymentType === "30_Day_Non_Defaultable") {
          setShowButton(true);

        } else {
          setShowButton(false);
        }
       

      })




    })
  }


  /// /api/v1/financial/customer-info



  useEffect(() => {

    getApi();


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);













  const confirm = () => {
    swal({
      title: "คำสั่งซื้อของคุณลูกค้าเรียบร้อยแล้วค่ะ",
      text: "ขอบคุณที่ใช้บริการ",
      icon: "success",
      button: "ตกลง",
      className: "swal-text-check-user-payment",
    }).then((value) => {

      liff.closeWindow()

    });

  }



  const documentClick = () => {
    document.getElementById('paymentAmountFile').click()
  }


  function fetchRequest() {

    const reset = () => {
      setFileUrl(null);
      setFileLoading("");
      setFilePost(null);
      document.getElementById('paymentAmountFile').value = null;
      setLoadingButtonCheck(false);
    }


    // /api/v1/financial/upload-slip
    async function uploadSlip(body) {
      return await service.post({ url: "/api/v1/financial/upload-slip", params: body }).then((res) => {
        return res.data.result;
      }).catch((err) => {
        swalChange(false, "065-1-90826-4", sale?.employeeNickname, sale?.userPhone)
        ToastifyError(err.message)
        reset();
      })

    }


    function uploadImage(resultQR) {
      const formData = new FormData();
      formData.append("image", FilePost);
      service.post({ url: "api/v1/upload-image", params: formData, headers: { "Content-Type": "multipart/form-data" } }).then((img) => {
        const slipUrl = img.data.result.url
        const body = {
          "slipUrl": slipUrl,
          "data": resultQR,
          "customerID": customerID,
        }

        uploadSlip(body).then((res) => {
          // success === false
          if (res.success === false) {
            ToastifyError(res.message)
            reset();
            swalChange(false, "065-1-90826-4", sale?.employeeNickname, sale?.userPhone)
          } else {
            // จำนวนเงินที่ท่านต้องชําระ
            const amount = Number((Order2.length > 0 ? Order2.map((item, index) => { return item.totalPrice }).reduce((a, b) => a + b) - advancePayment : 0 - advancePayment
            ) > 0 ? (Order2.length > 0 ? Order2.map((item, index) => { return item.totalPrice }).reduce((a, b) => a + b) - advancePayment : 0 - advancePayment) : 0)

            res.receiptAmount >= amount ? confirm() : history.push({
              pathname: `/paymentAmount/notEnoughMoney`,
              state: {
                receiptAmount: res.receiptAmount,
              }

            })
          }
        })

      }).catch((err) => {
        ToastifyError(`${err.message}`)
        reset();
        swalChange(false, "065-1-90826-4", sale?.employeeNickname, sale?.userPhone)
        console.log(err);
      })
    }



    const formData = new FormData();
    formData.append("file", FilePost);


    setFileLoading("loading");
    setLoadingButtonCheck(true);


    QrScanner.scanImage(FilePost).then(result => {
      console.log(result)
      const resultQR = result
      if (!resultQR) {
        ToastifyError("สลิปของท่านไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง")
        reset();
        swalChange(false, "065-1-90826-4", sale?.employeeNickname, sale?.userPhone)
      } else {
        setFileLoading("success");
        setLoadingButtonCheck(true);
        uploadImage(resultQR);
      }
    }).catch(err => {
      console.log(err)
      ToastifyError("สลิปของท่านไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง")
      reset();

    })

  }

  const fileChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    // เช็คขนาดไฟล์ มากกว่า 1 MB ให้บีบอัด
    if (file.size > 1000000) {
      const options = {
        maxSizeMB: 0.5,
        maxWidthOrHeight: 1920,
        useWebWorker: true
      }
      imageCompression(file, options)
        .then(function (compressedFile) {
          // size in KB

          setFilePost(compressedFile);
        })
        .catch(function (error) {
          console.log(error.message);
        });
      setFileUrl(URL.createObjectURL(file));
    } else {
      setFilePost(file);
      setFileUrl(URL.createObjectURL(file));
    }

  }



const uploadSlip = () => {
    return (
      <div>
        {

          Order2.length > 0 ? <div className='title' style={{
            marginTop: Order.length > 0 ? '60px' : '0px'
          }}>
            รายละเอียดการชำระเงิน
          </div> : null

        }



        {
          Order2.length > 0 ? Order2.map((item, index) => {
            return (
              <List key={index}>
                <div className='list shadow-sm'>
                  <div className='list__title'>

                    {item.shippedDate !== null ? new Date(item.shippedDate).toLocaleDateString('th-TH', {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                    })

                      : "null"
                    }
                  </div>

                  <div className='list__detail'>
                    <div className='list__detail__title'>
                      เลขที่ใบสั่งซื้อ
                    </div>
                    <div className='list__detail__title'>
                      {item.orderID}
                    </div>
                  </div>

                  <div className='list__qty'>
                    <div className='list__qty__title'>
                      {item.totalPrice !== null ? item.totalPrice.toLocaleString() : 0} บาท
                    </div>
                  </div>

                </div>
              </List>
            )
          }) : null
        }



        <Payment
          className='shadow-sm'
          style={{
            backgroundColor: '#ffcce6',
          
            marginTop: '2.5rem'
          }}>
          <div className='Payment d-flex justify-content-between'>
            <div className='Payment__title'>
            รวมยอดที่ต้องชำระ
            </div>
            <div className='Payment__detail'>

              {
                Order2.length > 0 ? Order2.map((item, index) => { return item.totalPrice }).reduce((a, b) => a + b).toLocaleString() : 0
              } บาท
            </div>
          </div>

        </Payment>


        <Payment  style={{ backgroundColor: '#ffcce6',  }} className='shadow-sm'>
          <div className='Payment d-flex justify-content-between'>
            <div className='Payment__title'>
            ยอดที่ชำระเกิน
            </div>
            <div className='Payment__detail'>
              {advancePayment ? Number(advancePayment).toLocaleString() : 0} บาท
            </div>
          </div>
        </Payment >


        <Payment className='shadow-sm' style={{ backgroundColor: '#99ccff', }}>
          <div className='Payment d-flex justify-content-between'>
            <div className='Payment__title'>
             สรุปยอดที่ต้องชำระ
            </div>
            <div className='Payment__detail'>
              {
                (
                  Order2.length > 0 ? Order2.map((item, index) => { return item.totalPrice }).reduce((a, b) => a + b) - advancePayment : 0 - advancePayment
                ) > 0 ? (Order2.length > 0 ? Order2.map((item, index) => { return item.totalPrice }).reduce((a, b) => a + b) - advancePayment : 0 - advancePayment).toLocaleString() : 0
              } บาท

            </div>
          </div>
        </Payment >


        <PaymentText className="shadow-sm PaymentText" >
          <div className="Payment">
            <div className="Payment__title">หมายเลขบัญชีธนาคาร</div>
            <div className="Payment__detail">
              ของ บริษัท สุวรรณภูมิ ฟู้ด จำกัด
            </div>

            <div style={{ margin: "15px 0" }}>
              <div className="Payment__detail_dank">ธนาคารไทยพาณิชย์ เลขที่บัญชี</div>
              <div className="Payment__detail_dank_number" style={{ color: "#8A2BE2" }} onClick={() => copyToClipboard("347-404461-8")}>{"347-404461-8"}</div>
            </div>

            <div>
              <div className="Payment__detail_dank">ธนาคารกสิกรไทย เลขที่บัญชี</div>
              <div className="Payment__detail_dank_number " onClick={() => copyToClipboard("065-1-90826-4")}>{"065-1-90826-4"}</div>
            </div>

            <div className="Payment__detail_coppy mt-2">กดที่ตัวเลขเพื่อคัดลอก</div>
          </div>
        </PaymentText>

        <UploadFile File={File} FileUrl={FileUrl} documentClick={documentClick} fileChange={fileChange} FileLoading={FileLoading} Upload={Upload} FileImage={FileImage} UploadFileContent={UploadFileContent} />

      </div>
    )
  }



const buttonUploadSlip = () => {
  return (
    <LoadingButton
            onClick={() => {
              if (FileUrl === null) {
                ToastifyError('กรุณาอัพโหลดหลักฐานการชำระเงิน');

                document.getElementById('upload').style.border = '1px solid red';
                document.querySelector('.Upload_title').style.color = 'red';
                document.getElementById('AddIcon').style.color = 'red';
                document.getElementById('upload').classList.add('wobble-hor-bottom');
                setTimeout(() => {
                  document.getElementById('upload').classList.remove('wobble-hor-bottom');
                }, 500);


              } else {
                fetchRequest()

              }
            }}
            size="small"
            variant="contained"
            loading={LoadingButtonCheck}
            color="success"
            loadingIndicator="กําลังตรวจสอบ..."
            className='shadow-sm'
            sx={{
              width: '500px',
              fontSize: '16px',
              marginTop: '1.5rem',
              backgroundColor: '#2e7d32',
              '@media (max-width: 768px)': {
                width: '100%',
              }

            }}
          >
            ส่งสลิป
          </LoadingButton>
  )
 }








  const item = () => {
    return (
      <div>
        {
          Order.length > 0 ? (<div className='title'>
            สรุปรายการสินค้าปัจจุบัน
          </div>
          ) : null
        }


        {Order.length > 0 ? Order.map((item, index) => {
          return (
            <List key={index}>
              <div className='list shadow-sm'>
                <div className='list__title'>
                  {
                    new Date(item.orderDate).toLocaleDateString('th-TH', {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                    })

                  }
                </div>

                <div className='list__detail'>
                  <div className='list__detail__title'>
                    เลขที่ใบสั่งซื้อ
                  </div>
                  <div className='list__detail__title'>
                    {item.orderID}
                  </div>
                </div>

                {
                  item.orderDetailRecords.map((item, index) => {
                    return (
                    <div className='list__detail' style={{ borderTop: '2px solid #eee' }} key={index}>
                      <div className='list__detail__title' style={{ width: '40%' }}>  
                        {item.productNameFull}
                      </div>
                      <div className='list__detail__title'>
                        {item.quantity ? item.quantity.toLocaleString() : 0}
                      </div>
    
                      <div className='list__detail__title' style={{ width: '20%', textAlign: 'right' }}> 
                        {item.totalPrice ? item.totalPrice.toLocaleString() : 0} บาท
                      </div>
                    </div>
                    )
                  })
                }

                 

                <div className='list__qty justify-content-between'>
                <div className='list__qty__title'>
                  ราคารวมทั้งหมด
                </div>
                  <div className='list__qty__title'>

                    {item.totalPrice !== null ? item.totalPrice.toLocaleString() : 0} บาท


                  </div>
                </div>

              </div>
            </List>
          )
        }) : null}


        {transferNow && uploadSlip()}


        <Button>


          {transferNow && buttonUploadSlip()}



          {
            show_button ? <LoadingButton
              onClick={() => { confirm() }}
              size="small"
              variant="outlined"
              color="error"
              loadingIndicator="กําลังตรวจสอบ..."
              className='shadow-sm'
              sx={{
                width: '500px',
                fontSize: '16px',
                marginTop: '10px',

                '@media (max-width: 768px)': {
                  width: '100%',
                }

              }}
            >
              ยืนยันคำสั่งซื้อ
            </LoadingButton> : null
          }


        </Button>


      </div>
    )
  }



  return (
    <Counter>
      {
        Loading === true ? <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '2rem'
        }}>
          <CircularProgress />
        </div> : Order.length > 0 || Order2.length > 0 ? item() : <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '2rem'
        }}>
          ไม่มีข้อมูล
          {

            liff.closeWindow()
          }
        </div>
      }
    </Counter>
  )
}

export default PaymentAmount