import React, { useEffect } from 'react';
import apiService from "../../api/apiService";
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from "@mui/material/TextField";


import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Table from './table';
import {  useLocation } from 'react-router-dom';
import { getOrderListByShippingDate, getProductList, getOrderFullDetail, getCustomer } from './api';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));



function MentorMonthlySales() {
  const classes = useStyles();
  //http://localhost:3000/StudentWeeklySales?customerID=100
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const customerID = searchParams.get('customerID') ? parseInt(searchParams.get('customerID')) : null;
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [customer, setCustomer] = React.useState(null);
  function getFormattedDate(daysAgo) {
    const date = new Date();
    date.setDate(date.getDate() - daysAgo);
    return date.toISOString().split('T')[0]; 
  }


  useEffect(() => {
 


    // 2 ให้ยิง order / list - by - shipping - date แบ่งเป็น 4 ก้อนดังนี้คือ
    // 2.1 สำหรับ column สัปดาห์นี้-- -> order / list - by - shipping - date ? startDate = "today" & endDate="today-7"
    // 2.2 สําหรับ column สัปดาห์ที่แล้ว-- -> order / list - by - shipping - date ? startDate = "today-8" & endDate="today-14"
    // 2.3 สําหรับ column สองสัปดาห์ที่แล้ว-- -> order / list - by - shipping - date ? startDate = "today-15" & endDate="today-21"
    // 2.4 สําหรับ column สามสัปดาห์ที่แล้ว-- -> order / list - by - shipping - date ? startDate = "today-22" & endDate="today-28"

    // 3123
    Promise.all([
      getOrderListByShippingDate({ startDate: getFormattedDate(7), endDate: getFormattedDate(0) }),
      getOrderListByShippingDate({ startDate: getFormattedDate(14), endDate: getFormattedDate(8) }),
      getOrderListByShippingDate({ startDate: getFormattedDate(21), endDate: getFormattedDate(15) }),
      getOrderListByShippingDate({ startDate: getFormattedDate(28), endDate: getFormattedDate(22) }),
      getProductList(),
      getCustomer({ customerID }),
      
    ]).then(([week1, week2, week3, week4, products, customer]) => {
      console.log({
        week1,
        week2,
        week3,
        week4,
        products,
        customer
      });
      setCustomer(customer);
      // 3 filter response ที่ได้จากข้อ 2 ด้วยเงื่อนไขนี้ if customerID == customerID จาก query parameter && paidDate != NULL || orderStatus != NULL ? ให้เลือก orderID นี้มาเก็บใน local storage
      const week1OrderIDs = week1.filter((order) => order.customerID === customerID)
        .filter((order) => order.paidDate !== null && order.orderStatus === null)
      .map((order) => order.orderID);
      const week2OrderIDs = week2.filter((order) => order.customerID === customerID)
        .filter((order) => order.paidDate !== null && order.orderStatus === null)
      .map((order) => order.orderID);
      const week3OrderIDs = week3.filter((order) => order.customerID === customerID)
        .filter((order) => order.paidDate !== null && order.orderStatus === null)
      .map((order) => order.orderID);
      const week4OrderIDs = week4.filter((order) => order.customerID === customerID)
        .filter((order) => order.paidDate !== null && order.orderStatus === null)
        .map((order) => order.orderID);

      console.log({
        week1OrderIDs,
        week2OrderIDs,
        week3OrderIDs,
        week4OrderIDs,
      });
      // Simulating an asynchronous function to fetch order details by ID
      function fetchOrderDetails(orderID) {
        return new Promise((resolve, reject) => {
          getOrderFullDetail({
            orderID,
          }).then((order) => {
            resolve(order);
          }).catch((error) => {
            reject(error);
          }
          );
        });
      }

      const promises = [];

      if (week1OrderIDs.length > 0) {
        promises.push(Promise.all(week1OrderIDs.map(id => fetchOrderDetails(id))));
      }
      if (week2OrderIDs.length > 0) {
        promises.push(Promise.all(week2OrderIDs.map(id => fetchOrderDetails(id))));
      }
      if (week3OrderIDs.length > 0) {
        promises.push(Promise.all(week3OrderIDs.map(id => fetchOrderDetails(id))));
      }
      if (week4OrderIDs.length > 0) {
        promises.push(Promise.all(week4OrderIDs.map(id => fetchOrderDetails(id))));
      }
      // Function to extract productID and receivedQuantity
  const extractProductDetails = (orders) => {
  return orders.map(weekOrders => {
    return weekOrders.map(orderWrapper => {
      return orderWrapper.order.orderDetail.map(detail => {
        const product = products.find(product => product.productID === detail.productID);

        return {
          productID: detail.productID,
          receivedQuantity: detail.receivedQuantity,
          week: week1OrderIDs.includes(orderWrapper.order.orderID) ? 1 : 
                week2OrderIDs.includes(orderWrapper.order.orderID) ? 2 : 
                week3OrderIDs.includes(orderWrapper.order.orderID) ? 3 : 4,

          productNameFull: product ? product.productNameFull : 'Unknown Product', // Add a fallback or handle null case
          productSequence: product ? product.productSequence : null, // Add fallback for productSequence
        };
      });
    }).flat(); // Flatten the array
  });
};

      Promise.all(promises).then((orders) => {
        const productDetails = extractProductDetails(orders);
        const combinedProductDetails = productDetails.flat(); 
    
        const combinedProducts = combinedProductDetails.reduce((acc, curr) => {
          const existingProduct = acc.find(p => p.productID === curr.productID && p.week === curr.week);

          if (existingProduct) {
            existingProduct.receivedQuantity += curr.receivedQuantity;
          } else {
            acc.push({ ...curr });
          }

          return acc;
        }, []);
        console.log(combinedProducts);


        const combinedProducts2 = combinedProducts.reduce((acc, curr) => {
          const existingProduct = acc.find(p => p.productID === curr.productID);

          if (existingProduct) {
            const existingWeek = existingProduct.table.find(t => t.week === curr.week);

            if (existingWeek) {
              existingWeek.receivedQuantity += curr.receivedQuantity;
            } else {
              existingProduct.table.push({
                week: curr.week,
                receivedQuantity: curr.receivedQuantity,
              });
            }
          } else {
            acc.push({
              productID: curr.productID,
              productNameFull: curr.productNameFull,
              productSequence: curr.productSequence,
              table: [
                {
                  week: curr.week,
                  receivedQuantity: curr.receivedQuantity,
                }
              ]
            });
          }

          return acc;
        }, []);

        console.log(combinedProducts2);
        setData(combinedProducts2);
        setLoading(false);
      });
      
    });
  }, [customerID]);

  return (
    <Container component="main" maxWidth="md" style={{
      padding: '10px',
    }}>
      <CssBaseline />
      <div className={classes.paper}>
       
        <Typography component="h1" variant="h5">
          ยอดขายประจำสัปดาห์ของ {customer?.customerName}
        </Typography>

      
        <Table data={data} loading={loading}/>
    
      </div>
      <Backdrop

        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}

      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
}

export default MentorMonthlySales;