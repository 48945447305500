import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, Link, useLocation } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
function Cart() {
  const location = useLocation();
  const useShopReducer = useSelector((state) => state.useShopReducer);
  const useCartReducer = useSelector((state) => state.useCartReducer);
  

  const dispatch = useDispatch();
  const history = useHistory();

   const addtocart = (e) => {
    const id = e.รหัสสินค้า;
    const name = e.สินค้า;
    const price = e.ราคา 
    const image = e.รูปภาพ;
    const amount = e.จำนวน;
    const heart = e.heart;
    const Category = e.หมวด;
    const details = e.รายละเอียด;

    // console.log(e);

  


    if(id === 18 || id === 19){
      //  ให้สินค้า ปลาเส้นทอด (productID = 18) และปลาเส้นขาว (productID = 19) ให้เพิ่มทีละ 5 หน่วย
      if (useCartReducer.length > 0) {
     
            //  // หา id ที่ซ้ำกับ id ที่เพิ่มเข้ามา
            if (useCartReducer.find((item) => item.id === id)) {

              dispatch({
                type: "UPDATE_CART_DATA",
                payload: {
                  id: id,
                  รหัสสินค้า: id,
                  สินค้า: name,
                  ราคา: price,
                  รูปภาพ: image,
                  จำนวน: useCartReducer.find((item) => item.id === id).จำนวน +  5,
                  ราคารวม:  (useCartReducer.find((item) => item.id === id).จำนวน + 5) * price,
                  หมวด: Category,
                  heart: heart,
                  รายละเอียด: details,
                },
              });
            } else {
    
              dispatch({
                type: "ADD_CART_DATA",
                payload: {
                  id: id,
                  รหัสสินค้า: id,
                  สินค้า: name,
                  ราคา: price,
                  รูปภาพ: image,
                  จำนวน: 5,
                  ราคารวม: price,
                  หมวด: Category,
                  heart: false,
        
                  รายละเอียด: details,
                },
              });
            }
 
      } else {
        console.log("ไม่มีสินค้าในตะกร้า2");
        dispatch({
          type: "ADD_CART_DATA",
          payload: {
            id: id,
            รหัสสินค้า: id,
            สินค้า: name,
            ราคา: price,
            รูปภาพ: image,
            จำนวน: 5,
            ราคารวม: price * 5,
            หมวด: Category,
            heart: false,
  
            รายละเอียด: details,
          },
        });
      }
    } else if (useCartReducer.length > 0) {
 
        //  // หา id ที่ซ้ำกับ id ที่เพิ่มเข้ามา
        if (useCartReducer.find((item) => item.id === id)) {

          dispatch({
            type: "UPDATE_CART_DATA",
            payload: {
              id: id,
              รหัสสินค้า: id,
              สินค้า: name,
              ราคา: price,
              รูปภาพ: image,
              จำนวน: useCartReducer.find((item) => item.id === id).จำนวน + 1,
              ราคารวม:  (useCartReducer.find((item) => item.id === id).จำนวน + 1) * price,
              หมวด: Category,
              heart: heart,
              รายละเอียด: details,
            },
          });
        } else {

          dispatch({
            type: "ADD_CART_DATA",
            payload: {
              id: id,
              รหัสสินค้า: id,
              สินค้า: name,
              ราคา: price,
              รูปภาพ: image,
              จำนวน: 1,
              ราคารวม: price,
              หมวด: Category,
              heart: false,
    
              รายละเอียด: details,
            },
          });
        }

          



      } else {
        console.log("ไม่มีสินค้าในตะกร้า2");
        dispatch({
          type: "ADD_CART_DATA",
          payload: {
            id: id,
            รหัสสินค้า: id,
            สินค้า: name,
            ราคา: price,
            รูปภาพ: image,
            จำนวน: 1,
            ราคารวม: price,
            หมวด: Category,
            heart: false,
  
            รายละเอียด: details,
          },
        });
      }

     

  };

  const setheart2 = (e) => {
    const getlocalStorage = localStorage.getItem("heart");

    console.log(useShopReducer.find((item) => item.id === e.id));
  
    if(getlocalStorage){
      const getlocalStorage2 = JSON.parse(getlocalStorage);
      const find = getlocalStorage2.find((item) => item.id === e.id);
      if (find) {
        // ถ้ามีอยู่แล้วให้ลบออก
        const filter = getlocalStorage2.filter((item) => item.id !== e.id);
        localStorage.setItem("heart", JSON.stringify(filter));
          dispatch({ type: "UPDATE_SHOP_DATA", payload: { ...e, heart: false, }, });
      } else {
        // ถ้าไม่มีให้เพิ่มเข้าไป
        localStorage.setItem("heart",JSON.stringify([...getlocalStorage2, { id: e.id }]));
        dispatch({ type: "UPDATE_SHOP_DATA", payload: { ...e, heart: true, }, });
      }
     
 
    } else {
      localStorage.setItem("heart", JSON.stringify([{ id: e.id }]));
      dispatch({ type: "UPDATE_SHOP_DATA", payload: { ...e, heart: true, }, });
    }
    

  };

  const minusNum = (e) => {
    const id = e.รหัสสินค้า;
    const name = e.สินค้า;
    const price = e.ราคา;
    const image = e.รูปภาพ;
    const details = e.รายละเอียด;
    const category = e.หมวด;
    const heart = e.heart;

    if(id === 18 || id === 19){
      if (useCartReducer.length > 0) {
        Promise.all(
          useCartReducer.map((item) => {
            if (item.id === id) {
              if (item.จำนวน <= 5 || item.จำนวน - 1 < 0 || item.จำนวน === 1) {
                dispatch({
                  type: "SET_CART_DATA",
                  payload: useCartReducer.filter((item) => item.id !== id),
                });
              } else {
                dispatch({
                  type: "UPDATE_CART_DATA",
                  payload: {
                    id: id,
                    รหัสสินค้า: id,
                    สินค้า: name,
                    ราคา: price,
                    รูปภาพ: image,
                    จำนวน: item.จำนวน - 5 < 0 ? 0 : item.จำนวน - 5,
                    ราคารวม: (item.จำนวน - 5) * price,
                    หมวด: category,
                    heart: heart,
                    รายละเอียด: details,
                  },
                });
              }
            }
          })
        );
      }
    }else{
      if (useCartReducer.length > 0) {
        Promise.all(
          useCartReducer.map((item) => {
            if (item.id === id) {
              if (item.จำนวน === 1) {
                dispatch({
                  type: "SET_CART_DATA",
                  payload: useCartReducer.filter((item) => item.id !== id),
                });
              } else {
                dispatch({
                  type: "UPDATE_CART_DATA",
                  payload: {
                    id: id,
                    รหัสสินค้า: id,
                    สินค้า: name,
                    ราคา: price,
                    รูปภาพ: image,
                    จำนวน: item.จำนวน - 1,
                    ราคารวม: item.ราคารวม - price,
                    หมวด: category,
                    heart: heart,
                    รายละเอียด: details,
                  },
                });
              }
            }
          })
        );
      }
    }
  };

  
  

  return useShopReducer.map((item, index) => {
    return (
      <div key={index}  className={index === 0 ? "mt-2 mb-1" : "mt-1 mb-1"}>
      <Paper
        className="cart-list-head p-0"
        elevation={2}
        style={{
          display: "block",
           
        }}
       
      >
        <div style={{ width: "100%", marginTop: 0 }}>
          <div
            style={{
              display: "flex",
              background: "rgb(255, 255, 255)",
              padding: 10,
            }}
          >
            <img
              className="img-thumbnail"
              src={item.รูปภาพ} 
              alt={item.สินค้า} 
              style={{ width: "35%" }}
              onClick={() => { history.push({
                pathname: `/product/${item.id}`,
                state: location?.state
              }) }} 
            />
            <div style={{ marginLeft: 10 }}>
              <h5 className="product-name mb-0">
                <Link to={{
                  pathname: `/product/${item.id}`,
                  state: location?.state
                }} style={{ fontSize: 20 }}>
                  {item.สินค้า}
                </Link>
              </h5>
              <p className="product-des">
                <span style={{ fontSize: 18 }}>
                  <em>ราคา:</em> {item.ราคา} บาท
                </span>
              </p>
              <div className="num-block skin-2 d-flex ">
                <div
                  className="num-in"
                  style={{ border: "2px solid rgb(204, 204, 204)" }}
                >
                  <span className="minus dis" onClick={() => minusNum(item)}/>
                  <input
                    type="number"
                    className="in-num"
                    onClick={(e) => { e.target.select() }}
                    inputMode="numeric"
                    value={useCartReducer.filter(item2 => item2.id === item.รหัสสินค้า).map(item3 => item3.จำนวน).length > 0 ? useCartReducer.filter(item2 => item2.id === item.รหัสสินค้า).map(item3 => item3.จำนวน) : 0 } 
                    onChange={(e) => {
                      if (e.target.value === "") {
                        dispatch({
                          type: "DELETE_CART_DATA",
                          payload: {
                            id: item.รหัสสินค้า,
                          }
                        })
                      }
                      if (e.target.value > 0) {
                        // เช็คว่า useCartReducer มีข้อมูลหรือไม่
                        if (useCartReducer.filter(item2 => item2.id === item.รหัสสินค้า).length > 0) {
                          // ถ้ามีข้อมูลให้เอาข้อมูลมาใช้
                          
                          dispatch({
                            type: "UPDATE_CART_DATA",
                            payload: {
                              id: item.รหัสสินค้า,
                              รหัสสินค้า: item.รหัสสินค้า,
                              สินค้า: item.สินค้า,
                              ราคา: item.ราคา,
                              รูปภาพ: item.รูปภาพ,
                              จำนวน: parseInt(e.target.value),
                              ราคารวม: item.ราคา * parseInt(e.target.value),
                              หมวด: item.หมวด,
                              heart: item.heart,
                              รายละเอียด: item.รายละเอียด,
                            }
                          })
                        } else {
                          // ถ้าไม่มีข้อมูลให้เพิ่มข้อมูลใหม่
                          dispatch({
                            type: "ADD_CART_DATA",
                            payload: {
                              id: item.รหัสสินค้า,
                              รหัสสินค้า: item.รหัสสินค้า,
                              สินค้า: item.สินค้า,
                              ราคา: item.ราคา,
                              รูปภาพ: item.รูปภาพ,
                              จำนวน: parseInt(e.target.value),
                              ราคารวม: item.ราคา * parseInt(e.target.value),
                              หมวด: item.หมวด,
                              heart: item.heart,
                              รายละเอียด: item.รายละเอียด,
                            }
                          })
                        }
            
                      }
                    }}
                    style={{ 
                      height: 35,
                      fontSize: '1rem'
                    }}
                  />
                  <span className="plus" onClick={() => addtocart(item)}/>
                </div>

<div className="d-flex justify-content-end align-items-center" style={{marginLeft: 40, marginRight: 10}}>

{
   item.heart ? <i className="lni lni-heart-filled" onClick={() => setheart2(item)} style={{ fontSize: "20px", marginTop: "1.5px", color:"red" }} /> :
   <i className="lni lni-heart" onClick={() => setheart2(item)} style={{ fontSize: "20px", marginTop: "1.5px" }} /> 
}
</div>
                
              </div>
            </div>
          </div>
        </div>
      </Paper>
      </div>
    );
  });
}

export default Cart;
