import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
import apiService from "../../../api/apiService";
import Modal from "@mui/material/Modal";
import CircularProgress from "@mui/material/CircularProgress";
import { useParams, useLocation } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import LoadingButton from "@mui/lab/LoadingButton";
import ConfirmModal from "./confirmModal";
import ErrorModal from "./errorModal";


function Buttons({
  shippingStatus,
  orderID,
  OrderSelectedDate,
  orderToReceiveCash,
  orderToReceiveSlip,
  setOrderToReceiveCash,
  setOrderToReceiveSlip,
  orderStatus,
  setNavBar,
  navBar,
  setShippingStatus,
  setOrderStatus,
  customer,
  disabled,
  orderDetailRecords,
  product,
  updateOrder,
  setDisabled,
  setOrderDetailRecords,
  checkButton,
  getGeoLocation,
  loading,
  isDisable,
  orderToReceiveNull,

  distance,

  getGeoLocationDefault,
  getLocation,
  loadingGeoLocation,

}) {


  const history = useHistory();
  const service = apiService();
  const [open, setOpen] = useState(false);
  const { employeeID } = useParams();
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [errModal, setErrModal] = useState(false);
  const [isFunctionCalled, setFunctionCalled] = React.useState(false);


  const updateOrderPayInCash = (params) => {
    updateOrder(params)
      .then((res) => {
        // /api/v1/order
        checkButton(orderID);
      })
      .catch((err) => {
        swal("ไม่สำเร็จ", "", "error");
      });
  };

  const orderToReceiveCashClick = () => {

    const data = {
      orderID: orderID,
      payInCash: true,
    };

    updateOrderPayInCash(data);

  };

  const orderToReceiveSlipClick = () => {
    //  เมื่อกด ได้รับเงินสด เก็บค่า [{"orderID":798,"date":"2022-12-07"}] ไว้ใน LocalStorage ชื่อ orderToReceiveSlip
    const data = {
      orderID: orderID,
      payInCash: false,
    };
    updateOrderPayInCash(data);

  };

  const orderToReceiveNullClick = () => {
    const data = {
      orderID: orderID,
      payInCash: null,
    };
    updateOrderPayInCash(data);
  }



  const confirm = () => {
    setShowModal(false);
    setOpen(true);
    // /api/v1/order/shipped
    service
      .post({ url: "/api/v1/order/shipped", params: { orderID: orderID } })
      .then((res) => {
        // update orderID defaultColor ใน setNavBar เป็น green
        const upDateNavBar = navBar.map((item) => {
          if (item.orderID === orderID) {
            return {
              ...item,
              defaultColor: "green",
            };
          } else {
            return item;
          }
        });

      
        setShippingStatus("SHIPPED");
        setOpen(false);
        setFunctionCalled(false);
        setNavBar(upDateNavBar);

        service
          .post({
            url: `/api/v1/order/update`,
            params: { orderID: orderID, getGeoLocation: getGeoLocation },
          })
          .then((res) => {

            checkButton(orderID);
            return res.data.result;
          });

      }).catch((err) => {
   
        setShowModal(false);
        swal({
          title: "ส่งสินค้าไม่สำเร็จ",
          text: `${err.message}`,
          icon: "error",
        }).then(() => {
          setOpen(false);
          setFunctionCalled(false);
        });
      });
  };


  React.useEffect(() => {
    // เช็คว่าฟังก์ชันถูกเรียกไปแล้วหรือไม่
    if (isFunctionCalled) {
      confirm();
    }
  }, [isFunctionCalled]);  // dependencies เป็น isFunctionCalled เพื่อให้ useEffect ทำงานเมื่อมีการเปลี่ยนแปลง



  const cancel = () => {
    // 13. เมื่อกด ยกเลิกออร์เดอร์ POST /api/v1/order/delete (Payload orderID) เรียกใช่ function 5,8 [เปลี่ยนสี NavBar ให้เป็นสีชมพู, สีปุ่มให้เป็นสีแดง, ส่งสินค้ากดไม่ได้]
    swal({
      title: "ต้องการยกเลิกออร์เดอร์ใช่หรือไม่",
      text: "หากยกเลิกออร์เดอร์แล้ว จะไม่สามารถเปลี่ยนแปลงได้",
      icon: "warning",
      buttons: ["ไม่", "ยืนยัน"],
      dangerMode: true,
      className: "sweet-alert-delete-order",
    }).then((willDelete) => {
      if (willDelete) {
        setOpen(true);
        service
          .post({ url: "/api/v1/order/delete", params: { orderID: orderID } })
          .then((res) => {
            const upDateNavBar = navBar.map((item) => {
              if (item.orderID === orderID) {
                return {
                  ...item,
                  defaultColor: "orange",
                };
              } else {
                return item;
              }
            });
            setNavBar(upDateNavBar);
            setOrderStatus(true);
            setOpen(false);

            swal({
              title:
                "คุณจะสั่งสินค้าใหม่ เพราะลูกค้าต้องการลดจำนวนสินค้าลงใช่ไหม?",
              className: "sweet-alert-info-custom",
              buttons: ["ไม่", "ใช่ ฉันต้องการสั่งสินค้าใหม่"],
            }).then((willDelete) => {
              if (willDelete) {
                history.push({
                  pathname: "/OrderAgain/shipper",
                  state: {
                    pathname: `/Orders/${employeeID}`,
                    employeeNickname: location?.state?.employeeNickname,
                    orderID: orderID,
                    shipperID: employeeID,
                    product: orderDetailRecords.map((item) => {
                      return {
                        productID: item.productID,
                        productNameFull: item.productNameFull,
                        purchasePrice: item.price,
                        quantity: item.quantity,
                        imageURL: product.filter(
                          (product) => product.productID === item.productID
                        )[0]?.imageURL,
                        totalPrice: item.totalAmount,
                        receivedQuantity: item.receivedQuantity,
                      };
                    }),
                  },
                });
              }
            });
          })
          .catch((err) => {
            swal("ยกเลิกออร์เดอร์ไม่สำเร็จ", "", "error");
      
          });
      }
    });
  };


  const updateGeoLocation = () => {

    console.log("checkHaversineMeters", {
      isDisable: isDisable,
      distance: distance,
    });

    if (getGeoLocation) {
      // getGeoLocation = true หรือป่าวไม่รู้
      if (isDisable === false) {
        swal({
          title: "อัพเดทตำแหน่ง GPS ใหม่",
          text: "ต้องการอัพเดทตำแหน่ง GPS ใหม่ใช่หรือไม่",
          icon: "info",
          buttons: ["ยกเลิก", "ยืนยัน"],
          className: "sweet-alert-info-custom",

        }).then((value) => {
          if (value) {
            service.post({
              url: `/api/v1/order/update`,
              params: { orderID: orderID, getGeoLocation: getGeoLocation },
            })
              .then((res) => {
                checkButton(orderID);

                return res.data.result;
              });
          }
        })
      } else {
        setErrModal(true)
      }

    }



  }



  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick")
      return;
  }


  const checkButtonConfirm = shippingStatus ? shippingStatus === "SHIPPED" || shippingStatus === "SHIPPED_PAIDED" ? true : false : false
  // checkButtonConfirm === true --> เคยกดส่งสินค้าแล้ว
  // getGeoLocationDefault --> ตำแหน่ง GPS ของผู้ส่งสินค้าที่ออกมาจาก API
  // isDisable === false --> อยู่ในพื้นที่
  // getGeoLocation --> ตำแหน่งปัจจุบันของผู้ส่งสินค้า

  // checkButtonConfirm === true แล้ว มี getGeoLocationDefault และ isDisable === false และ ล็อคไว้ ไม่ให้กด 
  const checkLocationDefault = checkButtonConfirm === true && getGeoLocationDefault && isDisable === false ? true : false
  const disabledConfirm = shippingStatus === null ? true : shippingStatus === "SHIPPED" || shippingStatus === "SHIPPED_PAIDED" ? true : orderStatus ? true : false
  // ให้ disable ปุ่ม "ยกเลิกออเดอร์" เมื่อ api/v1/customer > payload invoiceType = invoiceShort || invoiceLong
  const disabledCancel = customer?.invoiceType === "invoiceShort" || customer?.invoiceType === "invoiceLong" ? true :
    shippingStatus === null ? true : shippingStatus === "SHIPPED" || shippingStatus === "SHIPPED_PAIDED" ? true : orderStatus ? true : false


  const textCheckLocation = isDisable === false ? "กดที่จุดส่งสินค้า" : isDisable === true ? "ไม่ได้กดที่จุดส่งสินค้า" : "กดที่จุดส่งสินค้า"
  const deliveryGeoLocation = customer?.deliveryGeoLocation ? customer?.deliveryGeoLocation !== "null" ? customer?.deliveryGeoLocation : null : null

  const loadButton = (<LoadingButton
    loading
    loadingPosition="start"
    variant="outlined"
    className="w-100 mb-1"

  >
    <Typography noWrap>
      กำลังโหลด
    </Typography>
  </LoadingButton>)
  return (

    <Box>
      <Grid container className="mt-4" spacing={1}>
        <Grid item xs={6}>


          {
            loadingGeoLocation ? loadButton : <Button
              className="w-100 mb-1"
              variant="outlined"
              color="primary"
              onClick={() => setFunctionCalled(true)}
              style={{ ...(shippingStatus ? shippingStatus === "SHIPPED" || shippingStatus === "SHIPPED_PAIDED" ? { backgroundColor: "#52c41a", color: "white", } : { border: "1px solid #2e7d32", color: "#2e7d32", } : {}), }}
              disabled={disabledConfirm}
            >
              {" "}
              <Typography noWrap>ส่งสินค้าแล้ว</Typography>{" "}
            </Button>
          }



          {
            loadingGeoLocation ? loadButton :
              <Button
                variant={orderID ? "contained" : "outlined"}
                className="w-100 mb-1"
                disabled={orderDetailRecords.length > 0 && getGeoLocation !== null && isDisable !== null && deliveryGeoLocation ? checkLocationDefault : true}

                onClick={updateGeoLocation}
                sx={{

                  ...(orderDetailRecords.length > 0 && getGeoLocation !== null && deliveryGeoLocation ? (checkLocationDefault === true
                    ? {
                      backgroundColor: "#52c41a !important",
                      color: "white !important",
                      border: "1px solid #52c41a !important",
                      "&:hover": {
                        border: "1px solid #52c41a !important",
                        backgroundColor: "#52c41a !important",
                      },
                    }
                    : checkLocationDefault === false
                      ? {
                        backgroundColor: "#000 !important",
                        color: "white !important",
                        border: "1px solid #000 !important",
                        "&:hover": {
                          border: "1px solid #000 !important",
                          backgroundColor: "#000 !important",
                        },
                      }
                      : {}) : {}),


                }}>
                <Typography noWrap>
                  {
                    orderDetailRecords.length > 0 ? deliveryGeoLocation ? textCheckLocation : "ไม่มีข้อมูลตำแหน่งร้านลูกค้า" : "ไม่มีข้อมูลสินค้า"
                  }
                </Typography>
              </Button>
          }



          <Button
            className="w-100 mb-1"
            variant="outlined"
            color="primary"
            disabled={disabledCancel}
            onClick={cancel}
            style={{
              ...(orderStatus ? orderStatus
                ? {
                  backgroundColor: "#f5222d",
                  color: "white",
                }
                : {
                  border: "1px solid #d32f2f",
                  color: "#c62828",
                } : {}),
            }}>
            {" "}
            <Typography noWrap>ยกเลิกออร์เดอร์</Typography>{" "}
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="outlined"
            className="w-100 mb-1"
            disabled={orderID ? orderToReceiveCash : true}
            onClick={orderToReceiveCashClick}
            sx={{
              ...(orderID
                ? orderToReceiveCash
                  ? {
                    backgroundColor: "#dc3545",
                    color: "white !important",
                    border: "1px solid #dc3545",
                    "&:hover": {
                      border: "1px solid #dc3545",
                      backgroundColor: "#dc3545",
                    },
                  }
                  : {}
                : {}),
            }}>
            <Typography noWrap>ได้รับเงินสด</Typography>
          </Button>

          <Button
            variant="outlined"
            className="w-100 mb-1"
            disabled={orderID ? orderToReceiveSlip : true}
            onClick={orderToReceiveSlipClick}
            sx={{
              ...(orderID
                ? orderToReceiveSlip
                  ? {
                    backgroundColor: "#52c41a",
                    color: "white !important",
                    border: "1px solid #52c41a",
                    "&:hover": {
                      border: "1px solid #52c41a",
                      backgroundColor: "#52c41a",
                    },
                  }
                  : {}
                : {}),
            }}>
            <Typography noWrap>ได้รับสลิป</Typography>
          </Button>

          <Button
            variant="outlined"
            className="w-100 mb-1"
            disabled={orderID ? orderToReceiveNull : true}
            onClick={orderToReceiveNullClick}
            sx={{
              ...(orderID
                ? orderToReceiveNull
                  ? {
                    backgroundColor: "#FFFF00",
                    color: "#000 !important",
                    border: "1px solid #000",
                    "&:hover": {
                      border: "1px solid #000",
                      backgroundColor: "#FFFF00",
                    },
                  }
                  : {}
                : {}),
            }}>
            <Typography noWrap>ยังไม่จ่าย</Typography>
          </Button>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs>


        </Grid>

      </Grid>

      <ConfirmModal isDisable={isDisable} distance={distance} confirm={confirm} setShowModal={setShowModal} showModal={showModal} />
      <ErrorModal isDisable={isDisable} distance={distance} updateGeoLocation={updateGeoLocation} setShowModal={setErrModal} showModal={errModal} />

      <Backdrop
        onClose={handleClose}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
}

export default Buttons;
