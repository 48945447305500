import React, { useState, useEffect } from 'react';

import {
  Counter,
  Main,
  MainTile,
  Tile,
  TableTile,
  stockRequestDate_,
  stockRequestID_,
 

} from './style'
import Paper from '@material-ui/core/Paper';
import apiService from "../../api/apiService";
import { useParams, useHistory } from 'react-router-dom'
import Button from "@mui/material/Button";
import swal from "@sweetalert/with-react";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CircularProgress from '@mui/material/CircularProgress';

 
 
function StockRequest2() {
  const service = apiService();
  const history = useHistory();
  const { stockRequestID } = useParams();

  const [employeeNickname, setEmployeeNickname] = useState('');
  const [stockRequestShipperStatus, setstockRequestShipperStatus] = useState(false);
  const [stockRequestDate, setStockRequestDate] = useState("");
  const [stockRequestDetail, setStockRequestDetail] = useState([]);
  const [Loading, setLoading] = useState(true);
  const [orderType, setOrderType] = useState("");

  const handleStockRequest = () => {
    const data = { stockRequestID: stockRequestID };
    service
      .post({ url: `/api/v1/stock-request/fulfill`, params: data })
      .then((res) => {
        console.log(res.data.result);
        swal({
          title: "สำเร็จ",
          text: "ยืนยันการรับสินค้าสำเร็จ",
          icon: "success",
          button: "ตกลง",
          // บังคับให้กดปุ่มตกลง
          closeOnClickOutside: false,
        }).then((value) => {
          history.push("/stockRequest");

        });
      })
      .catch((err) => {
        console.log(err);
      });
  };


 async function getUser() {
    return await service.get({ url: `/api/v1/user/` }).then((res) => {
      return res.data.result
    })
  }

  async function getEmployee() {
    return await service.get({ url: `/api/v1/employee/list` }).then((res) => {
      return res.data.result
    })
  }

  // /api/v1/stock-request/?stockRequestID=${stockRequestID}

  async function getStockRequest() {
    return await service.get({ url: `/api/v1/stock-request/?stockRequestID=${stockRequestID}` }).then((res) => {
      return res.data.result
    })
  }

  // /api/v1/stock-request/get-details?stockRequestID=${stockRequestID}

  async function getStockRequestDetails() {
    return await service.get({ url: `/api/v1/stock-request/get-details?stockRequestID=${stockRequestID}` }).then((res) => {
      return res.data.result
    })
  }

  ///api/v1/product/list
  async function getProduct() {
    return await service.get({ url: `/api/v1/product/list` }).then((res) => {
      return res.data.result
    })
  }


  useEffect(() => {
      
    Promise.all([getUser(), getEmployee(), getStockRequest(), getStockRequestDetails(), getProduct()]).then((res) => {

 

        setEmployeeNickname(res[1].filter((item) => item.userID === res[0].userID)[0].employeeNickname);
        setstockRequestShipperStatus(res[2].stockRequestShipperStatus);
        setStockRequestDate(res[2].stockRequestDate);
        setOrderType(res[3].orders[0].orderType);
     
      const StockRequestBody = res[3].orders.map((item) => {
        return item.products.map((item) => {
          return {
            ...item,
            รายการ: item.productNameShort,
            จำนวน: item.quantity,
            status: false,
          }
        })
      }).flat().reduce((acc, cur) => {
        const x = acc.filter(item => item.รายการ === cur.รายการ)[0]
        if (!x) {
          return acc.concat([cur])
        } else {
          x.จำนวน += cur.จำนวน
          return acc
        }
      }, [])


      setStockRequestDetail(StockRequestBody.sort((a, b) => a.productSequence - b.productSequence));
      setLoading(false)

      })

  }, []);
  


  const paper_title = {
    width: '100%', 
    height: '100%', 
    padding: '.5rem',
     borderRadius: '0px',
    border: '1px solid #f5f5f5',
    fontSize: '22px',
  }



  const element = () =>{
    return (
       <Paper style={Counter}>
      <Main className='shadow-sm'>
        <MainTile>
            <Paper style={Tile}>
            รายละเอียดใบเบิก
          </Paper>
        </MainTile>
        <Paper style={{
          ...paper_title,
          marginTop: '0.5rem',
          textAlign: 'center',
          borderBottom: 'none',
          
        }}>
          {employeeNickname ? employeeNickname : 'ไม่พบข้อมูล'}
        </Paper>
           <Paper style={{
          ...paper_title,
         
          textAlign: 'center',
          borderBottom: 'none',
        }}>
          {orderType ? orderType : 'ไม่พบข้อมูล'}
        </Paper>
        <div style={{
          width: '100%',
          display: 'flex',

        }}>
          <Paper style={{
            ...paper_title,
            borderRight: 'none',
            textAlign: 'center',
          }}>
            {stockRequestID ? stockRequestID : 'ไม่พบข้อมูล'}
          </Paper>
           
            <Paper style={{ ...paper_title, textAlign: 'center', }}>
            {stockRequestDate ? new Date(stockRequestDate).toLocaleDateString('th-TH', { year: 'numeric', month: 'long', day: 'numeric' }) : 'ไม่พบข้อมูล'}
          </Paper>

        </div>



        <div style={{
          ...TableTile,
          marginTop: '1rem',
        }}>
          <Paper style={stockRequestDate_}>
            รายการ
          </Paper>
          <Paper style={stockRequestID_}>
            จำนวน
          </Paper>
        </div>

        {
          stockRequestDetail.map((item, index) => {
            return (
              <div style={TableTile} key={index} onClick={() => {
                const newStockRequestDetail = [...stockRequestDetail];
                newStockRequestDetail[index].status = !newStockRequestDetail[index].status;
                setStockRequestDetail(newStockRequestDetail);
              }}  >
                <Paper style={{
                  width: '100%',
                  height: '50px',
                  backgroundColor: item.status === true ? '#20c997' : '#fff',
                  borderRadius: '0px',
                  border: '1px solid #f5f5f5',
                  borderRight: 'none',
                  color: '#000000de',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderBottom: 'none',
                }}>
                  {item.รายการ}
                </Paper>
                <Paper style={{
                  width: '100%',
                  height: '50px',
                  backgroundColor: item.status === true ? '#20c997' : '#fff',
                  borderRadius: '0px',
                  border: '1px solid #f5f5f5',
                  color: '#1890ff',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderBottom: 'none',
                }}>
                  {item.จำนวน}
                </Paper>
              </div>
            )
          })
        }


        <div style={{
          marginTop: '1rem',
        }}>
          {stockRequestDetail.length > 0 ? stockRequestShipperStatus === true ?
            <Button
              startIcon={<ArrowBackIcon />}
              variant="outlined" sx={{ width: "100%" }} onClick={() => {
                history.push("/stockRequest");

              }}  >
              กลับไปหน้ารายการใบเบิก
            </Button>
            : <div>
              <Button
                variant="contained"
                sx={{ width: "100%" }}
                onClick={() => { handleStockRequest(); }}
                disabled={
                  stockRequestDetail.filter((item) => {
                    return item.status === false;
                  }).length > 0 ? true : false
                }
              > ได้รับสินค้าครบแล้ว  </Button>
            </div>
            //  205 207 208 210 77 100
            : <Button
              startIcon={<ArrowBackIcon />}
              variant="outlined" sx={{ width: "100%" }} onClick={() => {
                history.push("/stockRequest");

              }}  >
              กลับไปหน้ารายการใบเบิก
            </Button>}
        </div>

      </Main>
    </Paper>
    )
  }

  const renderLoading = () => {
    return (
      <div style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        fontSize: "1.5rem",
        backgroundColor: "#ffecf8"
      }}>
        <CircularProgress />
      </div>
    )
  }


  return Loading ? renderLoading() : element()
}

export default StockRequest2