import React, { useEffect } from 'react'
import apiService from "../../api/apiService"
import swal from '@sweetalert/with-react';
import liff from "@line/liff/dist/lib";


function RegisterAuto() {
  const service = apiService()


  const getLineGroupID = async () => {
    const url = window.location.href;
    const setUrl = url.split("?liff.state=%3FgroupID%3D")[1];
    const endUrl = setUrl.split("#")[0];

    return endUrl;
  };


  //post /api/v1/contact/register
  async function postRegister(lineGroupID) {
    return await service.post({
      url: '/api/v1/contact/register', params: {
        fullName: "",
        phone: "",
        customerJobType: "owner",
        lineGroupID: lineGroupID
      }
    }).then((res) => {
      return res.data.result
    })
  }


  //post /api/v1/contact/register
  // Remove the duplicate function declaration
  // async function postRegister(lineGroupID) {
  //   return await service.post({
  //     url: '/api/v1/contact/register', params: {
  //       fullName: "",
  //       phone: "",
  //       customerJobType: "owner",
  //       lineGroupID: lineGroupID
  //     }
  //   }).then((res) => {
  //     return res.data.result
  //   })
  // }




  const register = (lineGroupID) => {
    postRegister(lineGroupID).then((e) => {
      window.location.href = "https://liff.line.me/1661514375-76E1YREP"

    })
  }




  useEffect(() => {

    swal({
      title: "กำลังเชื่อมไลน์",
      text: "กรุณารอสักครู่...",
      icon: "info",
      button: false,
      closeOnClickOutside: false,
      closeOnEsc: false,
    })


    getLineGroupID().then((res) => {
      if (res) {
        if (localStorage.getItem('lineGroupID')) {
          localStorage.setItem('lineGroupID', res)

        } else {
          localStorage.removeItem('lineGroupID')
          localStorage.setItem('lineGroupID', res)

        }
      } else {
        swal({
          title: "ลงทะเบียนไม่สำเร็จ",
          text: "กรุณากดปุ่มลงทะเบียนใหม่อีกครั้ง",
          icon: "error",
          button: "ตกลง",
        }).then(() => {
          liff.closeWindow()
        })
      }
    })

    register(localStorage.getItem('lineGroupID'))

  }, [])

  return (
    <div></div>
  )
}

export default RegisterAuto