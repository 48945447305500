import React, { useEffect, useState } from 'react'

import { createTheme, ThemeProvider } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import CssBaseline from '@mui/material/CssBaseline';
import swal from '@sweetalert/with-react';
import liff from "@line/liff/dist/lib";
import { useHistory } from "react-router-dom";
import Modal from './modal';
import NoData from './noData';
import apiService from "../../../api/apiService";
import CircularProgress from '@mui/material/CircularProgress';
 
import { useLocation } from "react-router-dom";


 

const theme = createTheme();
 

function c_undefined(value) {
  if (value === undefined) {
    return null
  } else {
    return value
  }
}



function JobTypeList() {
  const service = apiService();
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [jobType, setJobType] = useState("")
  const [showModal, setShowModal] = useState(false)

  const [contact_no_jobType, setContact_no_jobType] = useState([])
  const [contact_no_customer, setContact_no_customer] = useState([])
  const [contact_customer, setContact_customer] = useState([])
  const [employee, setEmployee] = useState([])
  const [loading, setLoading] = useState(true)
  const [addJobType, setAddJobType] = useState([])
  
   // query params
  const lineGroupID = params.get("lineGroupID")

 
  const openModal = (item) => {
      setShowModal(true) 
      setAddJobType(item)
    }
  const closeModal = () => { 
     setShowModal(false) 
     setJobType("")
     setAddJobType([])
     }

  const alert = (message) => {
    swal({
      content: <p className="fs-6">{message}</p>,
      icon: 'error',
      closeOnClickOutside: false,
      buttons: {
        cancel: "ปิด",
      },
    }).then((value) => {
      if (value) {
        liff.closeWindow();
      }
    })
  }
 //Ca6877578942a7053c4800a97397bebb0
 
  async function getLineGroupMembersProfile() {
    return service.get({ url: `/api/v1/line-flex/group-members-profile?lineGroupID=${lineGroupID}` }).then((res) => {
      return res.data.result.members
    }).catch((err) => {
      console.log(err)
      alert(err.message)
    })
  }



  async function getLineIDcustomer(profile) {
    console.log(profile)
    return Promise.all(profile.map(async (item) => {
      const res = await service.get({ url: `/api/v1/user/line-customer-employee?lineID=${item.userId}` })
      return res.data.status === false ? null : res.data.result
    }))
  }


  const getApi = () => {

    getLineGroupMembersProfile().then((profile) => {
    
      getLineIDcustomer(profile).then((line_customer) => {
     
        const line_customer_map = profile.map((item) => {
          const customer = line_customer.filter((item) => item !== null)
          return {
 
            customerID: c_undefined(customer.filter((i) => i.lineID === item.userId)[0]?.customerID),
            customerJobType: c_undefined(customer.filter((i) => i.lineID === item.userId)[0]?.customerJobType),
            displayName: c_undefined(item.displayName),
           
            employeeID: c_undefined(customer.filter((i) => i.lineID === item.userId)[0]?.employeeID),
            employeeJobType: c_undefined(customer.filter((i) => i.lineID === item.userId)[0]?.employeeJobType),
            lineGroupID: c_undefined(customer.filter((i) => i.lineID === item.userId)[0]?.lineGroupID),
            lineID: c_undefined(customer.filter((i) => i.lineID === item.userId)[0]?.lineID),
            pictureUrl: c_undefined(item.pictureUrl),
            userID: c_undefined(customer.filter((i) => i.lineID === item.userId)[0]?.userID),
          }
        })

        console.log(line_customer_map)
        //  //  Contact ที่ยังไม่มี jobType ==> if customerID != null && customerJobType == null ? แสดง displayName
        setContact_no_jobType(line_customer_map.filter((item) => item.customerID !== null && item.customerJobType === null))
        //  // Contact ที่ยังไม่เป็น customer ==> if userID == null ? แสดง displayName
        setContact_no_customer(line_customer_map.filter((item) => item.customerID === null && item.employeeID === null))

        //   // Contact ที่เป็น customer แล้ว ==> if customerID != null && customerJobType != null ? แสดง displayName
        setContact_customer(line_customer_map.filter((item) => item.customerID !== null && item.customerJobType !== null))
        //   // Employee ==> if employeeID != null && employeeJobType != null ? แสดง displayName
        setEmployee(line_customer_map.filter((item) => item.employeeID !== null && item.employeeJobType !== null))

        setLoading(false)

      }).catch((err) => {
        
        console.log(err)
        alert(err.message)
      })

    })

   
  }

 
     useEffect(() => {
      
       getApi()

      
     }, [])



 


  const content = () => {


    const owner = () => {
      return <span className="badge badge-owner badge-pill fs09" >เจ้าของร้าน</span>
    }

    const orderer = () => {
      return <span className="badge badge-orderer badge-pill fs09">ผู้สั่งสินค้า</span>
    }

    const finance = () => {
      return <span className="badge badge-finance badge-pill fs09">ผู้จ่ายเงิน</span>
    }

    const receiving = () => {
      return <span className="badge badge-receiving badge-pill fs09">ผู้รับสินค้า</span>
    }

    const disabled = () => {
      return <span className="badge badge-disabled badge-pill fs09">ไม่มีสิทธิ</span>
    }

    const FreeStudent = () => {
      return <span className="badge badge-FreeStudent badge-pill fs09">เรียนฟรี</span>
    }

    return contact_no_jobType.length === 0 && contact_no_customer.length === 0 && contact_customer.length === 0 && employee.length === 0 ? <NoData /> : (
      <ThemeProvider theme={theme}>
        <Modal jobType={jobType} setJobType={setJobType} showModal={showModal} setShowModal={setShowModal} closeModal={closeModal} addJobType={addJobType} service={service} getApi={getApi} alert={alert} liff={liff} history={history}/>
        <Container component="main" maxWidth="xs" sx={{
          '@media (min-width: 0px)': {
            maxWidth: '500px',
          },
        }}>
          <CssBaseline />
          <Box
            sx={{
              marginTop: 3,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}>


            {
              contact_no_jobType.length > 0 ? <Paper elevation={3} sx={{ width: '100%', height: '100%', mb: 3, }}>
                <div className="list-group" style={{ width: '100%', height: '100%' }}>
                  <div className="list-group-item list-group-item-action active p1rem" style={{ textAlign: 'center' }} >
                    <span className='fs-15'> Contact ที่ยังไม่มี jobType </span> <br />
                    <span className="badge rounded-pill pb-0 fs-08" style={{ color: '#fff', }}>กรุณากดที่ชื่อลูกค้าเพื่อ assign jobType</span>
                  </div>

                  {contact_no_jobType.map((item, index) => {
                    return <button type="button" key={index} className="list-group-item list-group-item-action p1rem d-flex justify-content-between align-items-center fs-13" 
                    onClick={() => openModal(item)}>{item.displayName} {item.customerJobType === 'owner' ? owner() 
                    : item.customerJobType === 'orderer' ? orderer() 
                    : item.customerJobType === 'finance' ? finance() 
                    : item.customerJobType === 'receiving' ? receiving() 
                    : item.customerJobType === 'Disabled' ? disabled() 
                    : item.customerJobType === 'FreeStudent' ? FreeStudent()
                    : null}</button>
                  }
                  )}

                </div>
              </Paper> : null
            }

            {
              contact_no_customer.length > 0 ? <Paper elevation={3} sx={{ width: '100%', height: '100%', mb: 3, }}>
                <div className="list-group" style={{ width: '100%', height: '100%' }}>
                  <div className="list-group-item   active p1rem" style={{ textAlign: 'center' }} >
                    <span className='fs-15'> Contact ที่ยังไม่เป็น customer </span> <br />
                    <span className="badge rounded-pill pb-0  fs-08" style={{ color: '#fff', }}>กรุณาพิมพ์ "ลงทะเบียน" ในไลน์กลุ่ม แล้วให้ลูกค้าลงทะเบียน</span>
                  </div>


                  {contact_no_customer.map((item, index) => {
                    return <div key={index} className="list-group-item  p1rem d-flex justify-content-between align-items-center fs-13" >{item.displayName} </div>
                  }
                  )}

                </div>

              </Paper> : null
            }


            {
              contact_customer.length > 0 ? <Paper elevation={3} sx={{ width: '100%', height: '100%', mb: 3, }}>
                <div className="list-group" style={{ width: '100%', height: '100%' }}>
                  <div className="list-group-item list-group-item-action active p1rem" style={{ textAlign: 'center' }} >
                    <span className='fs-15'>Contact ที่เป็น customer แล้ว</span> <br />

                  </div>


                  {contact_customer.map((item, index) => {
                    return <button type="button" key={index} className="list-group-item list-group-item-action p1rem d-flex justify-content-between align-items-center fs-13" onClick={() => openModal(item)}>{item.displayName} {item.customerJobType === 'owner' ? owner() 
                    : item.customerJobType === 'orderer' ? orderer() 
                    : item.customerJobType === 'finance' ? finance() 
                    : item.customerJobType === 'receiving' ? receiving() 
                    : item.customerJobType === 'Disabled' ? disabled() 
                    : item.customerJobType === 'FreeStudent' ? FreeStudent()

                    : null}</button>
                  }
                  )}

                </div>

              </Paper> : null
            }

            {
              employee.length > 0 ? <Paper elevation={3} sx={{ width: '100%', height: '100%', mb: 3, }}>
                <div className="list-group" style={{ width: '100%', height: '100%' }}>
                  <div className="list-group-item   active p1rem" style={{ textAlign: 'center' }} >
                    <span className='fs-15'>Employee</span> <br />

                  </div>

                  {employee.map((item, index) => {
                    return <div key={index} className="list-group-item  p1rem d-flex justify-content-between align-items-center fs-13" >{item.displayName} </div>
                  }
                  )}

                </div>

              </Paper> : null

            }

          </Box>
        </Container>
      </ThemeProvider>
 
    )
  }


  return loading ? <div style={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '2rem',
  
  }}>
    <CircularProgress />
  </div> : content()



}

export default JobTypeList