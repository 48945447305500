import React from "react";

 function SModal({isDisable, distance, confirm, setShowModal, showModal}) {
  
  return showModal ? (<div className="swal-overlay swal-overlay--show-modal" tabIndex={-1}>
  <div className="swal-modal sweet-alert-info-custom" role="dialog" aria-modal="true">
 
  <div className="swal-icon swal-icon--info" /> 
    <div className="swal-title" style={{}}>
      { isDisable === false ? "ยืนยันการส่งสินค้า" : "ตำแหน่ง GPS ไม่ถูกต้อง"}
    </div>
    <div className="swal-text" style={{}}>
      {isDisable === false ? "ต้องการยืนยันการส่งสินค้าใช่หรือไม่" : `ท่านต้องอยู่ในระยะ 280 เมตร จากตำแหน่งร้านลูกค้า และตอนนี้ ท่านอยู่ห่างจากร้านลูกค้าประมาณ ${distance} เมตร ยังต้องการยืนยันการส่งสินค้าใช่หรือไม่`}
    </div>
    <div className="swal-footer">
      <div className="swal-button-container">
        <button className="swal-button swal-button--cancel"
        onClick={() => setShowModal(false)}
        >ยกเลิก</button>
        <div className="swal-button__loader">
          <div />
          <div />
          <div />
        </div>
      </div>
      <div className="swal-button-container">
        <button
         className="swal-button swal-button--confirm"
          onClick={confirm}>ส่งแล้ว</button>
        <div className="swal-button__loader">
          <div />
          <div />
          <div />
        </div>
      </div>
    </div>
  </div>

</div>) : null;

}

 


export default SModal;
