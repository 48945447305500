 


import React from 'react'
 

function ClearLocalStorage() {
 

  return (
  <div style={{height:'100vh'}} className='d-flex justify-content-center align-items-center flex-column'>
   
      <div >
    <a href="https://www.google.com/maps/search/?api=1&query=13.708359%2C100.735477" class="mb-2">
        <button>Google Maps</button>
      </a>
  </div>

  <div  >
    <a href="https://maps.google.com/?q=7P623JF7%2B66V" class="mb-2">
        <button>Google Maps</button>
      </a>
  </div>


  </div>

  
  
  )
}

export default ClearLocalStorage