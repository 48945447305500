import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import apiService from "../../../api/apiService"
import swal from '@sweetalert/with-react';
import './style.css'
import CircularProgress from '@mui/material/CircularProgress';
import { ToastifyError, copyToClipboard, swalChange, swalSuccess, Toastifys } from './tools';
import liff from "@line/liff/dist/lib";

import {
  Counter,

  PaymentText,
  Upload,
  Button,
  FileImage,
  UploadFileContent,
} from '../../paymentAmount/payment.module'
import styled from 'styled-components';

import UploadFile from './uploadFile';
import { useHistory } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import imageCompression from 'browser-image-compression';

const List = styled.div`
display: flex;
justify-content: center;
  .list {
    width: 500px;
       border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    margin-top: 1rem;
 

    .list__detail {
      display: flex;
      justify-content: space-between;
       padding: 0.5rem;
        background-color: #fff;
        border-bottom:1px solid rgba(0,0,0,0.12) ;
      .list__detail__title {
        font-size: 16px;
        color: #1a1a1a;
       
      }
    }

    .list__qty {
      display: flex;
      justify-content: space-between;
      padding: 0.5rem;
      background-color: #f7a4d8;
      border-radius: 0 0 10px 10px;
      .list__qty__title {
        font-size: 16px;
        color: #1a1a1a;
      }
    }



    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;

function PaymentAmountShipper() {
  const { orderID } = useParams();
  const history = useHistory();
  const service = apiService();
  const [Order, setOrder] = useState([]);
  const [Loading, setLoading] = useState(true);
  const [orderDetailRecords, setOrderDetailRecords] = useState([]);
  
  const [FileUrl, setFileUrl] = useState(null);
  const [FileLoading, setFileLoading] = useState("");
  const [FilePost, setFilePost] = useState(null);
  const [LoadingButtonCheck, setLoadingButtonCheck] = useState(false);
  const [advancePayment, setAdvancePayment] = useState(0);
  const [phone, setPhone] = useState(null);
  const [lineGroupURL, setLineGroupURL] = useState(null);
  const [customerID, setCustomerID] = useState(null);
  const [customer, setCustomer] = useState([]);
  const documentClick = () => {
    document.getElementById('paymentAmountFile').click()
  }

  const fileChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    // เช็คขนาดไฟล์ มากกว่า 1 MB ให้บีบอัด
    if (file.size > 1000000) {
      const options = {
        maxSizeMB: 0.5,
        maxWidthOrHeight: 1920,
        useWebWorker: true
      }
      imageCompression(file, options)
        .then(function (compressedFile) {
          // size in KB
   
          setFilePost(compressedFile);
        })
        .catch(function (error) {
          console.log(error.message);
        });
      setFileUrl(URL.createObjectURL(file));
    } else {
      setFilePost(file);
      setFileUrl(URL.createObjectURL(file));
    }
    
  }



  function getOrders() {


    service.get({ url: "/api/v1/selling-price-level/list" }).then((product) => {

      service.get({
        url: `/api/v1/order/?orderID=${orderID}`
      }).then((res) => {
        console.log(res.data.result)
        setLoading(false);
        setOrder(res.data.result);
  
        setOrderDetailRecords(res.data.result)

        const customerID = res.data.result.customerID;

        setCustomerID(customerID);
        console.log("customerID", customerID)

       

        if (customerID !== null) {
          service.get({
            url: `/api/v1/financial/advance-payment?customerID=${customerID}`
          }).then((res) => {
            setAdvancePayment(res.data.result?.advancePayment ? res.data.result.advancePayment : 0);
          })

          service.get({
            url: `/api/v1/customer/?customerID=${customerID}`
          }).then((res) => {
            console.log("customer", res.data.result)
            setPhone(res.data.result.phone);
            setLineGroupURL(res.data.result.lineGroupURL);
            setCustomer(res.data.result);
          })


        }

      }).catch((err) => {
        
        swal({
          title: "Error",
          text: `${err.message}`,
          icon: "error",
          button: "ตกลง",
        })
        console.log(err)
      })
    });
  }


  useEffect(() => {
    setLoading(true);

    getOrders();
    console.log(orderDetailRecords)
  }, []);

  const confirm = () => {
    swal({
      title: "คำสั่งซื้อของคุณลูกค้าเรียบร้อยแล้วค่ะ",
      text: "ขอบคุณที่ใช้บริการ",
      icon: "success",
      button: "ตกลง",
      className: "swal-text-check-user-payment",
    }).then((value) => {

        liff.closeWindow()
    
    });

  }

  function fetchRequest() {
    const formData = new FormData();
    formData.append("file", FilePost);


    setFileLoading("loading");
    setLoadingButtonCheck(true);

    const reset = () => {
      setFileUrl(null);
      setFileLoading("");
      setFilePost(null);
      document.getElementById('paymentAmountFile').value = null;
      setLoadingButtonCheck(false);
    }
    // /api/v1/financial/upload-slip
    async function uploadSlip(body) {
      return await service.post({ url: "/api/v1/financial/upload-slip", params: body }).then((res) => {
        return res.data.result;
      }).catch((err) => {
        swalChange(false, "065-1-90826-4", customer?.customerName, customer?.phone, customer?.lineGroupURL)
        ToastifyError(err.message)
        reset();
      })

    }


    function uploadImage(resultQR) {
      const formData = new FormData();
      formData.append("image", FilePost);
      service.post({ url: "api/v1/upload-image", params: formData, headers: { "Content-Type": "multipart/form-data" } }).then((img) => {
        const slipUrl = img.data.result.url
        const body = {
          "slipUrl": slipUrl,
          "data": resultQR,
          "customerID": customerID,
        }

        uploadSlip(body).then((res) => {
          // success === false
          if (res.success === false) {
            ToastifyError(res.message)
            reset();
            swalChange(false, "065-1-90826-4", customer?.customerName, customer?.phone, customer?.lineGroupURL)

          } else {
            // จำนวนเงินที่ท่านต้องชําระ
            const amount = Number(orderDetailRecords.totalPrice - advancePayment)

            res.receiptAmount >= amount ? confirm() : history.push({
              pathname: `/ShipperNotEnoughMoney/${orderID}`,
              state: {
                receiptAmount: res.receiptAmount,
              }

            })
          }
        })

      }).catch((err) => {
        ToastifyError(`${err.message}`)
        reset();
        swalChange(false, "065-1-90826-4", customer?.customerName, customer?.phone, customer?.lineGroupURL)

        console.log(err);
      })
    }
    fetch("https://api.qrserver.com/v1/read-qr-code/", {
      method: "POST",
      body: formData,
    }).then((res) => res.json()).then((r) => {
      const resultQR = r[0].symbol[0].data;
      console.log(resultQR);
      if (!resultQR) {
        ToastifyError("สลิปของท่านไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง")
        reset();
        swalChange(false, "065-1-90826-4", customer?.customerName, customer?.phone, customer?.lineGroupURL)

      } else {
        setFileLoading("success");
        setLoadingButtonCheck(true);
        uploadImage(resultQR);
      }



    }).catch((e) => {
      console.log(e);

      reset();
      history.push({
        pathname: `/ShipperUploadNotSlip/${orderID}`,
        state: {
          fileUrl: FileUrl,
          filePost: FilePost,
        }
      });
    })
  }
  return (
    <Counter>
      <div className='title'>
        ยอดสั่งสินค้าล่าสุด
      </div>

      {Loading === true ?
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '2rem'
        }}>
          <CircularProgress />
        </div>
        : <List>
          <div className='list shadow-sm'>

            <div className='list__detail' >
              <div className='list__detail__title'>
                เลขที่ใบสั่งซื้อ
              </div>
              <div className='list__detail__title'>

                {Order.orderID}
              </div>
            </div>
            <div className='list__detail'>
              <div className='list__detail__title'>
                จํานวนเงิน
              </div>
              <div className='list__detail__title'>

                {orderDetailRecords.totalPrice ? Number(orderDetailRecords.totalPrice).toLocaleString() : 0} บาท
              </div>
            </div>
            <div className='list__detail'>
              <div className='list__detail__title'>
                advancePayment
              </div>
              <div className='list__detail__title'>

                {advancePayment.toLocaleString()} บาท
              </div>
            </div>
            <div className='list__qty'>
              <div className='list__qty__title'>
                ต้องชำระ

              </div>
              <div className='list__qty__title'>
                {orderDetailRecords.totalPrice - advancePayment ? (Number(orderDetailRecords.totalPrice)- advancePayment).toLocaleString() : 0} บาท
              </div>
            </div>

          </div>
        </List>}


      <PaymentText className="shadow-sm PaymentText" >
        <div className="Payment">
          <div className="Payment__title">หมายเลขบัญชีธนาคาร</div>
          <div className="Payment__detail">
            ของ บริษัท สุวรรณภูมิ ฟู้ด จำกัด
          </div>

          <div style={{ margin: "15px 0" }}>
            <div className="Payment__detail_dank">ธนาคารไทยพาณิชย์ เลขที่บัญชี</div>
            <div className="Payment__detail_dank_number" style={{ color: "#8A2BE2" }} onClick={() => copyToClipboard("347-404461-8")}>{"347-404461-8"}</div>
          </div>

          <div>
            <div className="Payment__detail_dank">ธนาคารกสิกรไทย เลขที่บัญชี</div>
            <div className="Payment__detail_dank_number " onClick={() => copyToClipboard("065-1-90826-4")}>{"065-1-90826-4"}</div>
          </div>

          <div className="Payment__detail_coppy mt-2">กดที่ตัวเลขเพื่อคัดลอก</div>
        </div>
      </PaymentText>

      <UploadFile File={File} FileUrl={FileUrl} documentClick={documentClick} fileChange={fileChange} FileLoading={FileLoading} Upload={Upload} FileImage={FileImage} UploadFileContent={UploadFileContent} />

      <Button>


        <LoadingButton
          onClick={() => {
            if (FileUrl === null) {
              ToastifyError('กรุณาอัพโหลดหลักฐานการชำระเงิน');

              document.getElementById('upload').style.border = '1px solid red';
              document.querySelector('.Upload_title').style.color = 'red';
              document.getElementById('AddIcon').style.color = 'red';
              document.getElementById('upload').classList.add('wobble-hor-bottom');
              setTimeout(() => {
                document.getElementById('upload').classList.remove('wobble-hor-bottom');
              }, 500);


            } else {
              fetchRequest()
              document.getElementById('upload').style.border = '1px solid #9f98a3';
              document.querySelector('.Upload_title').style.color = '#888';
              document.getElementById('AddIcon').style.color = '#888';
            }
          }}
          size="small"
          variant="contained"
          loading={LoadingButtonCheck}

          loadingIndicator="กําลังตรวจสอบ..."
          className='shadow-sm'
          sx={{
            width: '500px',
            fontSize: '16px',
            marginTop: '1.5rem',

            '@media (max-width: 768px)': {
              width: '100%',
            }

          }}
        >
          ตรวจสอบหลักฐาน
        </LoadingButton>

        <LoadingButton
          onClick={() => {
            history.push({
              pathname: `/ShipperUploadNotSlip/${orderID}`,
              state: {
                fileUrl: FileUrl,
                filePost: FilePost,
              }
            })
          }}
          size="small"
          variant="contained"
          loadingIndicator="กําลังตรวจสอบ..."
          className='shadow-sm'
          sx={{
            width: '500px',
            fontSize: '16px',
            marginTop: '10px',

            '@media (max-width: 768px)': {
              width: '100%',
            }

          }}
        >
          แนบหลักฐาน
          การโอนชนิดอื่น
        </LoadingButton>

        <LoadingButton
          onClick={() => {
            history.push('/Orders')
          }}
          size="small"
          variant="contained"
          loadingIndicator="กําลังตรวจสอบ..."
          className='shadow-sm'
          sx={{
            width: '500px',
            fontSize: '16px',
            marginTop: '10px',

            '@media (max-width: 768px)': {
              width: '100%',
            }

          }}
        >
          กลับสู่รายการส่งของ
        </LoadingButton>


      </Button>

    </Counter>
  )
}

export default PaymentAmountShipper