import React, { useEffect, useState } from 'react'
import Table from './table';
import GoTop from './goTop';
import Heading from './heading';
import  { useParams }  from  'react-router-dom'
import apiService from "../../api/apiService";
import swal from '@sweetalert/with-react';
import './style.css';

function CheckInvoice() {
  const { shipperID, date } = useParams();
  const service = apiService()
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])

  useEffect(() => {
    service.get({ url: `/api/v1/order/list-condition-by-shipper?shipperID=${shipperID}&shippingDate=${date}`}).then((res) => {
        console.log(res.data.result)
        setLoading(false)
        setData(res.data.result)
      }).catch((err) => {
        console.log(err)
        swal({
          title: `${err.message}`,
          text: "กรุณาลองใหม่อีกครั้ง",
          icon: "error",
          button: "ตกลง",
        });
      });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipperID, date]);


 

  const props = {
    loading,
    data,
    date,
  }
  


  return (
    <div>
      <Heading />
      <Table props={props} />
      <GoTop />
    </div>
  )
}

export default CheckInvoice