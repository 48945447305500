import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography";

import { ImageViewer } from "react-image-viewer-dv"
import Heading from "./heading";
import { useParams } from "react-router-dom"
import apiService from "../../api/apiService";
import swal from '@sweetalert/with-react';
import CircularProgress from '@mui/material/CircularProgress';

const Counter = styled.div`
  background-color: #ffecf8;
  padding-bottom: 3rem;
  padding-top: 1.5rem;
  width: 100%;
  min-height: 100vh;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-top: 3rem;




 .title {
    font-size: 24px;
    font-weight: 600;
    border-bottom: 2px solid #1a1a1a;
    width: 500px;
    text-align: center;
    color: #1a1a1a;
    margin: 0 auto;
     height: auto;
  margin-bottom: 1rem;
    @media (max-width: 768px) {
      width: 100%;
    }
  }


#content__{
  width: 500px;
  margin: 0 auto;


  @media (max-width: 768px) {
    width: 100%;
  }


  @media (max-width: 768px) {
    width: 100%;
  }


}



}


`;



const SlipInProofOfPayment = styled.div`
  width: 500px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
   img{
    width: 350px;
    height: auto;
    @media (max-width: 768px) {
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;


const OrderDetailSlip = () => {
  const { customerID, orderID } = useParams();
  const [loading, setLoading] = useState(true);
  const [invoiceName, setInvoiceName] = useState("");
  const [phone, setPhone] = useState("");
  const [googleMapURL, setGoogleMapURL] = useState("");
  const [lineGroupURL, setLineGroupURL] = useState("");
  const [orderDetailRecords, setOrderDetailRecords] = useState([]);
  const service = apiService();
  const [slip, setSlip] = useState([]);
  const [shippingDate, setShippedDate] = useState("");

  useEffect(() => {

    service.get({ url: `/api/v1/customer/?customerID=${customerID}` }).then((res) => {
      console.log(res.data.result)
      setLoading(false)
      const result = res.data.result;
      setInvoiceName(result.invoiceName);
      setPhone(result.phone);
      setGoogleMapURL(result.customerShopGeoLocation);
      setLineGroupURL(result.lineGroupURL);

    }).catch((err) => {
      console.log(err)
      swal({
        title: `${err.message}`,
        text: "กรุณาลองใหม่อีกครั้ง",
        icon: "error",
        button: "ตกลง",
      });
    });





    service.get({ url: `/api/v1/product/list` }).then((product) => {
      service.get({ url: `/api/v1/order/?orderID=${Number(orderID) }` }).then((res) => {

        const result = res.data.result;
        setShippedDate(result.shippingDate ? new Date(result.shippingDate).toLocaleDateString(
          "th-TH", { year: "numeric", month: "long", day: "numeric" }
        ) : "");

        setOrderDetailRecords(result.orderDetailRecords.map((item) => {
          return {
            ...item,
            productNameFull: product.data.result.find((e) => e.productID === item.productID).productNameFull,
            // ถ้ามี receivedQuantity ไห้เอา receivedQuantity ไปรวมกลับ unitPrice แต่ถ้าไม่มี ให้เอา quantity ไปรวมกลับ unitPrice
            total: item.receivedQuantity ? item.receivedQuantity * item.unitPrice : item.quantity * item.unitPrice,
            price: item.unitPrice,
            quantity: item.receivedQuantity ? item.receivedQuantity : item.quantity,
          }
        }));

      }).catch((err) => {
        console.log(err)
        swal({
          title: `${err.message}`,
          text: "กรุณาลองใหม่อีกครั้ง",
          icon: "error",
          button: "ตกลง",
        });
      });
    }).catch((err) => {
      console.log(err)
      swal({
        title: `${err.message}`,
        text: "กรุณาลองใหม่อีกครั้ง",
        icon: "error",
        button: "ตกลง",
      });
    });




    ///api/v1/financial/list-proof-of-payment?approved=true&customerID=1
    service.get({ url: `/api/v1/financial/list-proof-of-payment?approved=true&customerID=${customerID}` }).then((res) => {
      console.log(res.data.result)
      // if orderID === invoiceID ? ให้แสดง receiptPictureURL : ไม่ต้องแสดง
      const result = res.data.result;
      setSlip(result.filter((item) => item.invoiceID === Number(orderID)));
      console.log(result.filter((item) => item.invoiceID === Number(orderID)))
    

    }).catch((err) => {
      console.log(err)
      swal({
        title: `${err.message}`,
        text: "กรุณาลองใหม่อีกครั้ง",
        icon: "error",
        button: "ตกลง",
      });
    });







    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Heading />
      {
        loading ? <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100vh",
            backgroundColor: "#ffecf8",
          }}
        >
          <CircularProgress />
        </Box> :
          <Counter>
            <div className="title">รายละเอียดคำสั่งซื้อ</div>

            <Box sx={{ flexGrow: 1 }} id="content__"   >



              {/* <Grid container className="mt-3">
          <Grid item xs>
            <Paper sx={{
              padding: ".5rem",
              borderRadius: "0",
              border: "1px solid #eaeaea",

              borderBottom: "none",

            }}>
              ใบส่งของ
            </Paper>
          </Grid>
          <Grid item xs>
            <Paper sx={{
              padding: ".5rem",
              borderRadius: "0",
              border: "1px solid #eaeaea",
              borderBottom: "none",
              backgroundColor: "#1769aa",
              color: "#fff",
            }}>
             1
            </Paper>
          </Grid>
        </Grid> */}


              <Grid container>
                <Grid item xs>
                  <Paper sx={{
                    padding: ".5rem",
                    borderRadius: "0",
                    border: "1px solid #eaeaea",
                    fontSize: "1rem",
                    borderBottom: "none",
                    textAlign: "center",
                  }}>
                    {invoiceName ? invoiceName : "ไม่มีข้อมูล"}
                  </Paper>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs>
                  <Paper sx={{
                    padding: ".5rem",
                    borderRadius: "0",
                    border: "1px solid #eaeaea",
                    fontSize: "1rem",
                    borderBottom: "none",
                    textAlign: "center",
                  }}>
                    {/* {'customerID' + ' ' + customerID } */}
                    {`customerID ${customerID ? customerID : "ไม่มีข้อมูล"}`}
                  </Paper>
                </Grid>
              </Grid>


              <Grid container>
                <Grid item xs>
                  <Paper sx={{
                    padding: ".5rem",
                    borderRadius: "0",
                    border: "1px solid #eaeaea",
                    fontSize: "1rem",

                    borderBottom: "none",
                    textAlign: "center",
                  }}>
               
                      {/* {'orderID' + ' ' + orderID ? orderID : "ไม่มีข้อมูล"} */}
                      {`orderID ${orderID ? orderID : "ไม่มีข้อมูล"}`}
                  </Paper>
                </Grid>
              </Grid>

              
              <Grid container>
                <Grid item xs>
                  <Paper sx={{
                    padding: ".5rem",
                    borderRadius: "0",
                    border: "1px solid #eaeaea",
                    fontSize: "1rem",

                    borderBottom: "none",
                    textAlign: "center",
                  }}>
               
                      {/* {'shippingDate ' + ' ' + shippingDate  } */}
                      {`shippingDate ${shippingDate ? shippingDate : "ไม่มีข้อมูล"}`}
                  </Paper>
                </Grid>
              </Grid>

              {
                phone ? <Grid container>
                  <Grid item xs>
                    <Paper

                      sx={{
                        fontSize: "1rem",

                        padding: ".5rem",
                        borderRadius: "0",
                        border: "1px solid #eaeaea",
                        color: "#0d6efd",
                        borderBottom: "none",
                        cursor: "pointer",
                        textAlign: "center",
                        '&:hover': {
                          color: "#c41d7f",
                        },

                      }}

                      onClick={() => {
                        window.open(`tel:${phone}`)
                      }}

                    >
                      {phone}
                    </Paper>
                  </Grid>
                </Grid> : null
              }

              {
                googleMapURL ? <Grid container>
                  <Grid item xs>
                    <Paper


                      sx={{
                        fontSize: "1rem",

                        padding: ".5rem",
                        borderRadius: "0",
                        border: "1px solid #eaeaea",
                        cursor: "pointer",
                        color: "#0d6efd",
                        '&:hover': {
                          color: "#c41d7f",
                        },

                        textAlign: "center",
                      }}
                      onClick={() => {
                        // window.open(googleMapURL)
                        //เปิดในแท็บใหม่
                        const lat =  googleMapURL?.split(",")[0];
                        const lng =  googleMapURL?.split(",")[1];
                        const url = `https://www.google.com/maps/search/?api=1&query=${lat}%2C${lng}`
                        window.open(url, '_blank');
                      }}
                    >
                      กดดูแผนที่ร้าน
                    </Paper>
                  </Grid>
                </Grid> : null
              }


              {
                lineGroupURL ? <Grid container>
                  <Grid item xs>
                    <Paper
                      onClick={() => {
                        window.open(lineGroupURL, '_blank');
                      }}

                      sx={{
                        fontSize: "1rem",

                        padding: ".5rem",
                        borderRadius: "0",
                        border: "1px solid #eaeaea",
                        cursor: "pointer",
                        color: "#0d6efd",
                        '&:hover': {
                          color: "#c41d7f",
                        },

                        textAlign: "center",
                      }}>
                      ไลน์กลุ่มลูกค้า
                    </Paper>
                  </Grid>
                </Grid> : null
              }



              {/* order */}








              {orderDetailRecords.map((item, index) => {
                return (

                  <div key={index}>
                    <Grid container className="mt-3">
                      <Grid item xs={10}>
                        <Paper sx={{
                          padding: ".5rem",
                          borderRadius: "0",
                          border: "1px solid #eaeaea",

                          borderBottom: "none",
                        }}>
                          <Typography noWrap>
                            {item.productNameFull ? item.productNameFull : "ไม่มีข้อมูล"}
                          </Typography>
                        </Paper>
                      </Grid>
                      <Grid item xs={2}>
                        <Paper sx={{
                          padding: ".5rem",
                          borderRadius: "0",
                          border: "1px solid #eaeaea",
                          borderBottom: "none",
                          textAlign: "center",
                        }}>
                          <Typography noWrap>
                            {item.quantity ? item.quantity.toLocaleString() : "ไม่มีข้อมูล"}
                          </Typography>

                        </Paper>
                      </Grid>
                    </Grid><Grid container>
                      <Grid item xs={6}>
                        <Paper sx={{
                          padding: ".5rem",
                          borderRadius: "0",
                          border: "1px solid #eaeaea",
                          textAlign: "end",
                        }}>
                          <Typography noWrap>
                            ราคา {item.price ? item.price.toLocaleString() : "ไม่มีข้อมูล"} บาท
                          </Typography>
                        </Paper>
                      </Grid>
                      <Grid item xs={6}>
                        <Paper sx={{
                          padding: ".5rem",
                          borderRadius: "0",
                          border: "1px solid #eaeaea",

                          textAlign: "end",
                        }}>
                          <Typography noWrap>
                            รวม {item.total ? item.total.toLocaleString() : "ไม่มีข้อมูล"} บาท
                          </Typography>
                        </Paper>
                      </Grid>
                    </Grid>
                  </div>
                )
              })}




              {/* end order */}

              <Grid container className="mt-4">
                <Grid item xs={2}>
                  <Paper sx={{
                    fontSize: "1rem",

                    padding: ".5rem",
                    borderRadius: "0",
                    border: "1px solid #eaeaea",
                    textAlign: "end",
                  }}>
                    รวม
                  </Paper>
                </Grid>
                <Grid item xs={10}>
                  <Paper sx={{
                    fontSize: "1rem",

                    padding: ".5rem",
                    borderRadius: "0",
                    border: "1px solid #eaeaea",

                    textAlign: "end",
                  }}>
                    {/* ยอดรวมของ total ทั้งหมด */}
                    {orderDetailRecords.reduce((sum, item) => sum + item.total, 0).toLocaleString()} บาท
                  </Paper>
                </Grid>
              </Grid>



              <div className="title" style={{
                marginTop: "3rem",
              }}>{slip.length > 0 ? `สลิปย้อนหลัง ${slip.length} ใบ` : "ไม่มีสลิปย้อนหลัง"}
              </div>
              {/* 

              <SlipInProofOfPayment className="mb-2" >

                <ImageViewer> <img src={slip} alt="slip" className='mt-3' /></ImageViewer>
                <ImageViewer><img src={slip} alt="slip" className='mt-3' /></ImageViewer>
                <ImageViewer><img src={slip} alt="slip" className='mt-3' /></ImageViewer>

              </SlipInProofOfPayment> */}
              <SlipInProofOfPayment className="mb-2" >
                {slip.map((item, index) => {
                  return (
                    <ImageViewer key={index}>
                      <img src={item.receiptPictureURL} alt={item.receiptPictureURL} className='mt-3' />
                    </ImageViewer>
                  )

                })}
              </SlipInProofOfPayment>





            </Box>
          </Counter>
      }
    </div>
  );
}



export default OrderDetailSlip;