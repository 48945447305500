import React, { useState, useEffect} from "react";
import Paper from "@mui/material/Paper";
import Typography   from "@mui/material/Typography";
 
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
 
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor:"#888",
    color: theme.palette.common.white,
    border: 0,
    fontSize: '1rem',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: '1rem',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  },
}));

 

 
function Table2({ tableData2, gitLocation }) {

 
  return (
    <div>
        
        {tableData2.length > 0 &&
        <TableContainer component={Paper} className="mt-5">
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell sx={{ width: "35%", }}>เวลาสั่งของ</StyledTableCell>
            <StyledTableCell sx={{ width: "40%", }}>ลูกค้าที่ยังไม่ได้สั่ง</StyledTableCell>
            <StyledTableCell sx={{ width: "25%", }}>ยอด</StyledTableCell>
     
          </TableRow>
        </TableHead>
        <TableBody>

          {
            tableData2.map((item, index) => {
              return(
                <StyledTableRow key={index} id={`table2-${index}`}>
                <StyledTableCell component="th" scope="row" sx={{ width: "35%", backgroundColor: item.style.backgroundColor, color: item.style.color, }}>
                <Typography variant="body2" gutterBottom component="div" noWrap sx={{ width: "100px", margin: "0", '@media (max-width: 600px)': { width: '80px', }, }}>

                 </Typography> 
                </StyledTableCell>
                <StyledTableCell sx={{ width: "40%", backgroundColor: item.style.backgroundColor, color: item.style.color, }}>
                <Typography variant="body2" gutterBottom component="div" noWrap sx={{ width: "200px", margin: "0", '@media (max-width: 600px)': { width: '140px', }, }}>
                 <a href={item?.lineGroupURL}
                  target="_blank" rel="noopener noreferrer" 
                  style={{ textDecoration: item?.lineGroupURL ? "underline" : "none" }} 
                  onClick={() => {
                    gitLocation(`table2-${index}`)
                  }}>
                  {item.invoiceName}
                  </a>
                </Typography>
                   </StyledTableCell>
                <StyledTableCell sx={{ width: "25%", backgroundColor: item.style.backgroundColor, color: item.style.color, }}>
                <Typography variant="body2" gutterBottom component="div" noWrap sx={{ width: "100px", margin: "0", '@media (max-width: 600px)': { width: '70px', }, }}>
              
                 </Typography> 
                 </StyledTableCell>
           
              </StyledTableRow>
              )
            })
          }

         
           
       
        </TableBody>
      </Table>
    </TableContainer>
}
      
    </div>
  );
}

export default Table2;
