import React from "react";
import ButtonGroup from "./ButtonGroup";
import Box from "@mui/material/Box";
import { getCustomerList } from "./api";
import swal from "sweetalert";
import liff from "@line/liff/dist/lib";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
 
function RecordGeolocation() {
  const [customer, setCustomer] = React.useState([]); // ตัวแปรเก็บข้อมูลลูกค้า
  const [loading, setLoading] = React.useState(true); // ตัวแปรเก็บข้อมูลลูกค้า
  const getLineGroupID = async () => {
    const url = window.location.href;

    const setUrl1 = url.split("?liff.state=%3FgroupID%3D")[1];
    const setUrl2 = url.split("?liff.state=%2FgroupID%3D")[1];
    const setUrl3 = url.split("?liff.state=%2F%3FgroupID%3D")[1];
    const setUrl = setUrl1
      ? setUrl1
      : setUrl2
      ? setUrl2
      : setUrl3
      ? setUrl3
      : null;

    const endUrl = setUrl?.split("#")[0];
    console.log("endUrl", endUrl);
    return endUrl;
  };

  React.useEffect(() => {
    getLineGroupID().then((e) => {
      if (e) {
        getCustomerList().then((res) => {
          console.log(res);
          const customer = res.find((r) => r.lineGroupID === e);
          setCustomer(customer);
          console.log("customer", customer);
          setLoading(false);
        });
      } else {
        setLoading(false);
        swal({
          title: "เกิดข้อผิดพลาด",
          text: "ไม่พบ lineGroupID",
          icon: "error",
        }).then((value) => {
          switch (value) {
            default:
              liff.closeWindow();
              console.log("close");
          }
        });
      }
    });
  }, []);

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "50vh",
        "& > *": {
          m: 1,
        },
      }}
    >
      
        <Grid container spacing={2}>
        
          <Grid item xs={12} sx={{ textAlign: "center" }}>
           
            <ButtonGroup
              label="กดส่งตำแหน่งร้านลูกค้า"
              color="primary"
              type="customerShopGeoLocation"
              customer={customer}
            />
          </Grid>
          <Grid item xs={12} sx={{ textAlign: "center" }}>
            <ButtonGroup
              label="กดส่งตำแหน่งจุดส่งสินค้า"
              color="secondary"
              type="deliveryGeoLocation"
              customer={customer}
            />
          </Grid>
        </Grid>
      

      <Backdrop
        onClose={handleClose}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
}

export default RecordGeolocation;
