import React, { useState, useEffect} from "react";

import { Counter, TableContent, Th } from "./style.module";
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Box from "@mui/material/Box";
import {  setPaymentType, setPayment, setInvoiceCondition } from '../../components/invoiceCondition';
import Paper from '@mui/material/Paper';
import { useHistory } from "react-router-dom";
import moment from 'moment';
import 'moment/locale/th';

function Table({ props }) {
  const history = useHistory();
 
  const table1 = props.data?.map((item) => {
    return {
      ...item,
      backgroundColorRouteOrder: item.shippedDate !== null ? "#ff8ccb" : "#acd3bd",
      backgroundColorPaymentType: setPaymentType(item.paymentType).backgroundColor,
      payment: setPayment(item.invoiceCondition).text,
      backgroundColorPayment: setPayment(item.invoiceCondition).backgroundColor,
      colorPayment: setPayment(item.invoiceCondition).color,
   
      backgroundColorDate: setInvoiceCondition(item.invoiceCondition).backgroundColor,
    }
//     1.1 shippingDate = SHIPPINGDATE ที่เลือก
// 1.2 invoiceCondition = ไม่ต้องมีอีกแล้ว
  }).sort((a, b) => a.orderID  - b.orderID )
  .filter((item) => moment(item.shippingDate).format('YYYY-MM-DD') === props.date);
 
  
 
  const table2 = props.data?.map((item) => { 
    return {
      ...item,
      backgroundColorRouteOrder: item.shippedDate !== null ? "#ff8ccb" : "#acd3bd",
      backgroundColorPaymentType: setPaymentType(item.paymentType).backgroundColor,
      payment: setPayment(item.invoiceCondition).text,
      backgroundColorPayment: setPayment(item.invoiceCondition).backgroundColor,
      colorPayment: setPayment(item.invoiceCondition).color,
      backgroundColorDate: setInvoiceCondition(item.invoiceCondition).backgroundColor,
    }
     //1.1 shippingDate < SHIPPINGDATE ที่เลือก
  //1.2 invoiceStatus = null
  }).sort((a, b) => a.orderID - b.orderID)
  .filter((item) => moment(item.shippingDate).format('YYYY-MM-DD') < props.date && item.invoiceStatus === null);
 



 
  const table3 = props.data.map((item) => {
    return {
      ...item,
      backgroundColorRouteOrder: item.shippedDate !== null ? "#ff8ccb" : "#acd3bd",
      backgroundColorPaymentType: setPaymentType(item.paymentType).backgroundColor,
      payment: setPayment(item.invoiceCondition).text,
      backgroundColorPayment: setPayment(item.invoiceCondition).backgroundColor,
      colorPayment: setPayment(item.invoiceCondition).color,
      backgroundColorDate: setInvoiceCondition(item.invoiceCondition).backgroundColor,
    }
    //1.1 shippingDate < SHIPPINGDATE ที่เลือก
    //1.2 invoiceStatus = false
  }).sort((a, b) => a.orderID - b.orderID)
  .filter((item) => moment(item.shippingDate).format('YYYY-MM-DD') < props.date && item.invoiceStatus === false);


 
  const table4 = props.data.map((item) => {
    return {
      ...item,
      backgroundColorRouteOrder: item.shippedDate !== null ? "#ff8ccb" : "#acd3bd",
      backgroundColorPaymentType: setPaymentType(item.paymentType).backgroundColor,
      payment: setPayment(item.invoiceCondition).text,
      backgroundColorPayment: setPayment(item.invoiceCondition).backgroundColor,
      colorPayment: setPayment(item.invoiceCondition).color,
      backgroundColorDate: setInvoiceCondition(item.invoiceCondition).backgroundColor,
    }
    // 1.1 shippingDate < SHIPPINGDATE ที่เลือก
    // 1.2 invoiceStatus = true
  }).sort((a, b) => a.orderID - b.orderID).filter((item) => moment(item.shippingDate).format('YYYY-MM-DD') < props.date && item.invoiceStatus === true);


 



 



 

  function table(data){
    return (
      <table className="table table-bordered table-hover shadow-sm">
        
      <thead>
        <tr>
            <Th scope="col" className="text-center p-3 pl-05 pr-05 clickTh" >
            orderID
          </Th>
          <Th scope="col" className="text-center p-3 pl-05 pr-05">Date</Th>
              <Th scope="col" className="text-center p-3 pl-05 pr-05">invoiceName</Th>
           <Th scope="col" className="text-center p-3  pl-06 pr-06" >totalPrice</Th>
        </tr>
      </thead>
      <tbody className="border-top-0">
          {data.map((item, index) => {
            return (
              <tr key={index} >
                <th scope="row" className={`text-center p-3 pl-05 pr-05  ${item.checkbox ? 'text-line' : ''}`}
                 style={{ backgroundColor: item.backgroundColorRouteOrder }}
                 
                 onClick={() => {
                  history.push(`/orderDetailSlip/${item.customerID}/${item.orderID}`);
                }}
                 >
                    {item.orderID}
                </th>
                <th scope="row" className={`text-center p-3 pl-05 pr-05 pointer ${item.checkbox ? 'text-line' : ''}`}
                 style={{ backgroundColor: item.backgroundColorDate, }}
                 onClick={() => {
                  history.push(`/orderDetailSlip/${item.customerID}/${item.orderID}`);
                }}
                 >
                    {item.shippedDate ? moment(item.shippedDate).format('DD/MM') : '-'}
                </th>
                <th scope="row" className={`text-center p-3 pl-05 pr-05 pointer ${item.checkbox ? 'text-line' : ''}`}
                 style={{ backgroundColor: item.backgroundColorPaymentType, }}
                 onClick={() => {
                  history.push(`/orderDetailSlip/${item.customerID}/${item.orderID}`);
                }}
                 >
                 <Typography noWrap sx={{margin: '0 auto', padding: '0px 0px 0px 0px !important', color: "#212529", fontSize: '14px', fontWeight: 'bold', width: '200px', '@media screen and (max-width: 600px)': { width: '130px' ,} }}>
                  {item.invoiceName}
                   </Typography>
                </th> 
                <th scope="row" className={`text-center p-3 pl-06 pr-06 pointer ${item.checkbox ? 'text-line' : ''}`}
                style={{backgroundColor: item.backgroundColorPayment, color: item.colorPayment, }}
                onClick={() => {
                  history.push(`/orderDetailSlip/${item.customerID}/${item.orderID}`);
                }}
                >
           
                    {item.totalPrice ? item.totalPrice.toLocaleString() : '-'}
                 
               </th>
              </tr>
            );
          })}

          
         
        </tbody>
    </table>
    
    )
  }

  const noData = (<Typography noWrap sx={{
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100vh",
    backgroundColor: "#ffecf8",
  }}> ไม่มีข้อมูล</Typography>)

  return props.loading ? <Box
    sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: "100vh",
      backgroundColor: "#ffecf8",
    }}
  >
    <CircularProgress />
  </Box> : table1.length === 0 && table2.length === 0 && table3.length === 0 && table4.length === 0 ? noData : <Counter>
   


 {
      table1.length > 0 ? <Box><Typography className="title" noWrap> บิลที่ส่งงวดนี้ทั้งหมด</Typography>
        <TableContent className="bd-example">
            <Paper>
              {table(table1)}
            </Paper>
        </TableContent>
      </Box> : null
    }



    {/* End Table1  */}


    {/* Table2 */}

    {
      table2.length > 0 ? <Box><Typography noWrap className="title mt-5">ยอดค้าง
      ที่ยังไม่ถึงกำหนดชำระเงิน</Typography>
        <TableContent className="bd-example">
            <Paper>
          {table(table2)}
            </Paper>
        </TableContent>
     </Box> : null
    }
    {/* End Table2 */}


    {/* Table3 */}

    {
      table3.length > 0 ? <Box><Typography noWrap className="title mt-5">ยอดค้าง
      ถึงกำหนดชำระเงิน (due)</Typography>
        <TableContent className="bd-example">
            <Paper>
           {table(table3)}
            </Paper>
        </TableContent>
     </Box> : null
    }
    {/* End Table3 */}


    {/* Table4 */}

    {
      table4.length > 0 ? <Box>
        <Typography noWrap className="title mt-5">ยอดค้าง
เลยกำหนดชำระเงิน (overdue)</Typography>
       
          <TableContent className="bd-example">
            <Paper>
              {table(table4)}
            </Paper>
          </TableContent>
      
      </Box> : null
    }
    {/* End Table4 */}

 
 


  </Counter> 
}

export default Table;
