import { Cloudinary } from '@cloudinary/url-gen';


const cld = new Cloudinary({
  cloud: {
    cloudName: 'suvarnabhumi-food',
    apiKey: '424348937647442',
    apiSecret: 'Fv7xG9fo2Y9LHjFPObYqdGhIBCg',
    authToken: 'CLOUDINARY_URL=cloudinary://424348937647442:Fv7xG9fo2Y9LHjFPObYqdGhIBCg@suvarnabhumi-food'
  },
});



function getCloudinaryUrl(url) {
    try{
        const publicId = url.split("upload/")[1]
        const myImage = cld.image(publicId, { format: "auto" }).format('webp');
        const myUrl = myImage.toURL()
        return myUrl
    } catch (error) {
        console.log(error)
        return null
    }
}



function uploadImageToCloudinary({ file, folder}) {
 
    return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append("upload_preset", "jutttjti");
        formData.append("folder", folder);
        fetch('https://api.cloudinary.com/v1_1/suvarnabhumi-food/image/upload', { // Your POST endpoint
            method: 'POST',
            body: formData,
        }).then(response => response.json())
            .then(data => {
                resolve(data)
            })
            .catch(error => {
                reject(error)
            })
    })
}


export { getCloudinaryUrl, uploadImageToCloudinary}