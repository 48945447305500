import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import CssBaseline from "@mui/material/CssBaseline";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Slide from "@mui/material/Slide";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Fab from "@mui/material/Fab";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Badge from "@mui/material/Badge";
import { showCart } from './modal';
import { useHistory } from "react-router-dom";
import { SweetError, SweetSuccess } from "./modal";
import { postCheckoutForSell, postCheckoutForGift, postCheckoutForSample } from "./api";
import DiaLog from "./diaLogs";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';


function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

function ScrollTop(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      "#back-to-top-anchor"
    );

    if (anchor) {
      anchor.scrollIntoView({
        block: "center",
      });
    }
  };

  return (
    <Fade in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: "fixed", bottom: 16, right: 16 }}
      >
        {children}
      </Box>
    </Fade>
  );
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};




export default function HideAppBar(props) {
    const history = useHistory();
    const {
      productList,
      setProductList,
      debtInstallment,
      shippingDate,
      customerInfo,
      customerID,
      shipperID,
      pages,
      openDialog,
      setOpenDialog,
      discount,
      setDiscount,
    } = props;
    // productList ที่ quantity > 0
    const quantityProduct = productList.filter((product) => product.quantity > 0);
    // ผลรวมของ quantity ทั้งหมด
    const sumQuantity = quantityProduct.reduce((sum, product) => sum + product.quantity, 0);
    // ราคารวม
    const sumPrice = quantityProduct.reduce((sum, product) => sum + (product.quantity * product.price), 0);
    const [openBackdrop, setOpenBackdrop] = React.useState(false);
    

    const handleConfirmOrder = () => {
       setOpenBackdrop(true);
        if(pages === "checkout-for-sell"){
            if(quantityProduct.length > 0){
                
              const data = {
                customerID: Number(customerID),
                shipperID: Number(shipperID),
                shippingDate: shippingDate,
                orderDetail: quantityProduct.map((product) => { return { productID: Number(product.productID), quantity: Number(product.quantity) } } )
              }
                postCheckoutForSell(data).then((res) => {
                  setOpenBackdrop(false);
                  res.status !== false && SweetSuccess("สั่งซื้อสินค้าสำเร็จ", "ระบบได้ทำการสั่งซื้อสินค้าเรียบร้อยแล้ว")
                   setProductList(productList.map((product) => { return { ...product, quantity: 0 } }))
                }).catch((err) => {
                  setOpenBackdrop(false);
                  SweetError("ไม่สามารถสั่งซื้อได้", err.message)
                })

              
            }else{
              SweetError("ไม่สามารถสั่งซื้อได้", "กรุณาเลือกสินค้าก่อนสั่งซื้อ")
            }
        } else if(pages === "checkout-for-gift"){
            if(quantityProduct.length > 0){
              const data = {
                customerID: Number(customerID),
                shipperID: Number(shipperID),
                shippingDate: shippingDate,
                orderDiscount: Number(discount),
                orderDetail: quantityProduct.map((product) => { return { productID: Number(product.productID), quantity: Number(product.quantity) } } )
              }
                postCheckoutForGift(data).then((res) => {
                  setOpenBackdrop(false);
                  res.status !== false && SweetSuccess("สั่งซื้อสินค้าสำเร็จ", "ระบบได้ทำการสั่งซื้อสินค้าเรียบร้อยแล้ว")
                  setProductList(productList.map((product) => { return { ...product, quantity: 0 } }))
                }).catch((err) => {
                  setOpenBackdrop(false);
                  SweetError("ไม่สามารถสั่งซื้อได้",  err.message)
                })
            }else{
              SweetError("ไม่สามารถสั่งซื้อได้", "กรุณาเลือกสินค้าก่อนสั่งซื้อ")
            }
        } else if(pages === "checkout-sample"){
            if(quantityProduct.length > 0){
              const data = {
                shipperID: Number(shipperID),
                shippingDate: shippingDate,
                orderDetail: quantityProduct.map((product) => { return { productID: Number(product.productID), quantity: Number(product.quantity) } } )
              }
              postCheckoutForSample(data).then((res) => {
                  setOpenBackdrop(false);
                  res.status !== false && SweetSuccess("สั่งซื้อสินค้าสำเร็จ", "ระบบได้ทำการสั่งซื้อสินค้าเรียบร้อยแล้ว")
                  setProductList(productList.map((product) => { return { ...product, quantity: 0 } }))
                }).catch((err) => {
                  SweetError("ไม่สามารถสั่งซื้อได้",  err.message)
                })
            }else{
              setOpenBackdrop(false);
              SweetError("ไม่สามารถสั่งซื้อได้", "กรุณาเลือกสินค้าก่อนสั่งซื้อ")
            }
        }
       
        
        
    }



    const postApi = () => {
      const invoiceName = customerInfo?.invoiceName
      showCart(quantityProduct, debtInstallment, shippingDate, invoiceName, handleConfirmOrder, pages, discount )
    }
  

    const handleCloseBackdrop = () => {
      setOpenBackdrop(false);
    };

    const handleClose = (event, reason) => {
      if (reason && reason === "backdropClick") 
          return;
  }
  
     
  return (
    <React.Fragment>
      <CssBaseline />
       <DiaLog 
         openDialog={openDialog}
         setOpenDialog={setOpenDialog}
         discount={discount}
         setDiscount={setDiscount}
         postApi={postApi}
         sumPrice={sumPrice}
        />
      <HideOnScroll {...props}>
        <AppBar color={pages === "checkout-for-sell" ? "secondary" : pages === "checkout-for-gift" ? "primary" : pages === "checkout-sample" ? "success" : "default"}>
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={() => history.goBack()}
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              สั่งซื้อสินค้า
            </Typography>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <Toolbar id="back-to-top-anchor" />

      {/* ปุ่มสั้งซื้อสินค้า ลอยอยู่บน */}

      <Box
        sx={{
          flexGrow: 1,
          position: "fixed",
          bottom: 16,
          right: 16,
          zIndex: 1000,
          'span span': {
            zIndex: 2000,
          },
        }}

        onClick={() => {
          if(pages !== "checkout-for-gift"){
            postApi()
          }else{
            
            if(quantityProduct.length > 0){
              setOpenDialog(true)
            }else{
              postApi()
            }
          }
        }} 
      >
        <Badge
          badgeContent={sumQuantity}
          max={999}
          overlap="circular"
          color="warning"
        >
          <Fab
            variant="extended"
            color={pages === "checkout-for-sell" ? "secondary" : pages === "checkout-for-gift" ? "primary" : pages === "checkout-sample" ? "success" : "default"}
            size="large"
            aria-label="add"
          >
            สั่งซื้อสินค้า
          </Fab>
        </Badge>
      </Box>

      {/* <ScrollTop {...props}>
        <Fab size="small" aria-label="scroll back to top" color="secondary">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop> */}

<Backdrop
onClose={handleClose}
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
        onClick={handleCloseBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </React.Fragment>
  );
}
