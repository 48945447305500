import React, { useEffect, useState } from "react";
import ReplayCircleFilledIcon from '@mui/icons-material/ReplayCircleFilled';
import CircularProgress from "@mui/material/CircularProgress";
import { Counter, Main, MainTable, Button } from "./styled";
import Backdrop from '@mui/material/Backdrop';
import { getUser, getListFromTomorrow2, getAllWithShipper, getListInRoute } from "./api";
import Table1 from "./table1";
import Table2 from "./table2";
function CustomerNotYetOrder() {
  const [open, setOpen] = useState(true);
  const [tableData1, setTableData1] = useState([]);
  const [tableData2, setTableData2] = useState([]);

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") 
        return;
}

const ThDate = (date) => {
  // + 7 hours
  const d = new Date(date);
  d.setHours(d.getHours() + 7);
  const hours = d.getHours();
  const minutes = d.getMinutes() < 10 ? `0${d.getMinutes()}` : d.getMinutes();
  const seconds = d.getSeconds();
  
   
const setD = d.toLocaleDateString('th-TH', { 
 // DD/MM hh/mm
  day: 'numeric',
  month: 'short',
});

return `${setD} ${hours}:${minutes}`;

}


const getData = () => {
    setOpen(true);
    setTableData1([]);
    setTableData2([]);

  
  Promise.all([getUser(), getAllWithShipper()]).then((res) => {
      
    const userID = res[0].userID;
    
    getListFromTomorrow2(userID).then((b) => {
      
       const setListFromTomorrow2 = b.map((item) => {
         return {
           ...item,
           // 2.2.1 if orderType == ORDER_LINE ? ให้ใช้ format สีเขียวนะครับ text=สีเขียวเข้ม backgroundColor=สีเขียวอ่อน
           // 2.2.2 if orderType == ORDER_OFFLINE ? ให้ใช้ format สีแดงนะครับ text=สีแดง backgroundColor=สีแดงอ่อน
           // 2.2.3 if orderType == ORDER_SALESPERSON ? ให้ใช้ format สีฟ้านะครับ text=สีฟ้าเข้ม backgroundColor=สีฟ้าอ่อน
           style: item.orderType === "ORDER_LINE" ? { color: "#006600", backgroundColor: "#e1ffeb" } : item.orderType === "ORDER_OFFLINE" ? { color: "#990000", backgroundColor: "#ffebee" } :item.orderType === "ORDER_SALESPERSON" ? { color: "#990000", backgroundColor: "#e3f2fd" } : { color: "#000", backgroundColor: "#fff" },
           // orderDate == DD/MM hh/mm
           orderDate: item.orderDate ? ThDate(item.orderDate) : "",
         };
       });
       console.log("tableData1", setListFromTomorrow2);
       setTableData1(setListFromTomorrow2);

       const routeID = res[1].filter((item) => item.userID === userID)[0]?.routeID;

       getListInRoute(routeID).then((c) => {
        //4 เมื่อได้ array ผลลัพธ์จากข้อที่ 3 มาแล้ว ให้เอาเฉพาะ row ที่มี customerID ไม่ซ้ำกับ array ผลลัพธ์จากข้อ 1 ใน table ด้านบน มาใช้
        const setListInRoute = c.filter((item) => !setListFromTomorrow2.some((item2) => item2.customerID === item.customerID));
       // 5.1 format ของ table เป็นสีเทา ดังที่ออกแบบ และแต่ละ row ให้แสดงเฉพาะ invoiceName โดยเรียงตาม customerID (ASC)
        const setTableData2ListInRoute = setListInRoute.map((item) => {
          return {
            ...item,
            style: { color: "#000", backgroundColor: "#F5F5F5" },
  
             
          };
        }).sort((a, b) => a.customerID - b.customerID);
  
        console.log("tableData2", setTableData2ListInRoute);
        setTableData2(setTableData2ListInRoute);
        setOpen(false);

       });
 
    });  


  });

 
  
}

useEffect(() => {
 
  getData();


}, []);


useEffect(() => {
  // เช็คว่าใน localStorage มี customerNotYetOrderLocation อยู่หรือไม่
  if(open === false){
    
   if (localStorage.getItem("customerNotYetOrderLocation")) {
      console.log("customerNotYetOrderLocation", JSON.parse(localStorage.getItem("customerNotYetOrderLocation")));
      if(tableData1.length > 0 || tableData2.length > 0){
          // เลื่อน scroll ไปยัง id ที่เก็บไว้ใน localStorage
          try {
            document.getElementById(JSON.parse(localStorage.getItem("customerNotYetOrderLocation"))).scrollIntoView();
          } catch (error) {
            console.log(error);
          }
      }
   }
 }

}, [open]);

 

const gitLocation = (e) => {

     
  if (localStorage.getItem('customerNotYetOrderLocation')) {
    localStorage.setItem('customerNotYetOrderLocation', JSON.stringify(e))
  } else {
    localStorage.removeItem('customerNotYetOrderLocation')
    localStorage.setItem('customerNotYetOrderLocation', JSON.stringify(e))
  }

};

  return (
    <Counter>

      <Button className='shadow-sm' onClick={() => {
        setOpen(true);
        getData();
      }}>
        <ReplayCircleFilledIcon />
      </Button>
      <Main>
        <div className="title">ลูกค้าที่สั่งสินค้าแล้ว</div>
      </Main>

      <MainTable>
        <Table1 tableData1={tableData1} tableData2={tableData2} gitLocation={gitLocation} />
        <Table2 tableData2={tableData2} gitLocation={gitLocation} />
      </MainTable>
 

<Backdrop
  onClose={handleClose}
  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
  open={open}
  onClick={handleClose}
>
  <CircularProgress color="inherit" />
</Backdrop>

    </Counter>



  );
}

export default CustomerNotYetOrder;
