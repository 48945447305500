import axios from "axios";
import Production from "../constants/serviceAPI.json";
import Staging from "../constants/serviceAPI-staging.json";
import endpointsData from '../constants/endpoints.json';

function checkIfTextMatchesEndpoint(text) {
  const endpoints = endpointsData.endpoints;
 
  // ตรวจสอบว่า text เริ่มต้นด้วยข้อความ name ใน endpoints หรือไม่
  return endpoints.some((endpoint) => text.startsWith(endpoint.name));
}

const url = window.location.hostname === new URL(Staging.serviceAPI.liff).hostname || window.location.hostname === 'localhost' ? Staging : Production

const serviceAPI = url.serviceAPI.api;
const login = url.serviceAPI.login
const liff = url.serviceAPI.liff


axios.defaults.headers = {
  'Cache-Control': 'no-cache',
  'Pragma': 'no-cache',
  'Expires': '0',
};

const oprSuccess = (res) => {
  if (res) {
    return res;
  } else {
    return false;
  }
};
 
const oprError = (error) => {
  
  const pathname = window.location.pathname.split('/')[1]
  const staging = "1657137993-lqYxDLoD"
  const production = "1657362537-0nxLYMOB"
  const local = "register1"
  const checkLiff = pathname === staging || pathname === production || pathname === local ? true : false

  if(error.response.status === 401 && !checkLiff){
   window.location.href = `${login}?success=${liff}${window.location.pathname.split('/')[1]}`
    // check cookie และ url ถ้าไม่มีให้ไปหน้า login
  } else if(error.response.status === 401 && checkLiff){
    window.location.href = `${login}?success=${liff}${'registerAuto'}`
  }
  throw error.response.data;
    
};

function getCookie(name) {
  var value = "; " + document.cookie;
  var parts = value.split("; " + name + "=");
  if (parts.length === 2) return parts.pop().split(";").shift();
}


const axiosService = async ({type, url, params , baseAPI }) => {
  // get token from cookie
  const token = getCookie("token")
  const config = { baseURL: serviceAPI , withCredentials: true, headers: { 'authorization': `Bearer ${token}` }};

  const lineGroupID = localStorage.getItem('lineGroupID')
  const urlIncludes = url.includes('?') ? '&' : '?'
  const urlWithLineGroupID = checkIfTextMatchesEndpoint(url) ? `${url}${urlIncludes}lineGroupID=${lineGroupID}` : url
  
  
  
   switch (type) {
    case "get":
      return axios.get(urlWithLineGroupID, config).then(oprSuccess).catch(oprError);
    case "post":
      return axios.post(urlWithLineGroupID, params, config).then(oprSuccess).catch(oprError);
    case "put":
      return axios.put(urlWithLineGroupID, params, config).then(oprSuccess).catch(oprError);
    case "delete":
      return axios.delete(urlWithLineGroupID, config).then(oprSuccess).catch(oprError);
    default:
      return false;
  }
};

const apiService = (baseAPI = '') => {
  return {
    get: ({ url }) => axiosService({type :"get", url : url , baseAPI : baseAPI}),
    post: ({ url, params }) => axiosService({type : "post", url : url, params : params , baseAPI : baseAPI}),
    put: ({ url, params }) => axiosService({type : "put", url : url, params : params , baseAPI : baseAPI}),
    delete: ({ url }) => axiosService({type :"delete", url : url , baseAPI : baseAPI}),
  };
};

export default apiService;
