import {
  Upload,
  Button,
  FileImage,
  UploadFileContent,
} from "../../../paymentAmount/payment.module";

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import UploadFile from "../uploadFile";
import LoadingButton from "@mui/lab/LoadingButton";
import { useLocation, useHistory } from "react-router-dom";

import { ToastifyError } from "../tools";
import apiService from "../../../../api/apiService";
import swal from '@sweetalert/with-react';
import { useParams } from "react-router-dom";
import imageCompression from 'browser-image-compression';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import liff from "@line/liff/dist/lib";


const Counter = styled.div`
  background-color: #f9ecff;
  padding-bottom: 3rem;
  padding-top: 1.5rem;
  width: 100%;
  min-height: 100vh;
  padding-left: 2rem;
  padding-right: 2rem;

  .title {
    font-size: 20px;
    font-weight: 600;
    border-bottom: 2px solid #1a1a1a;
    width: 500px;
    text-align: center;
    color: #1a1a1a;
    margin: 0 auto;
     height: auto;
    margin-bottom: 0.5rem;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;

const CounterInput = styled.div`
  width: 500px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 1.5rem;

  .dropdown {
    width: 100%;
    display: flex;
    margin-bottom: 0.5rem;
    padding: 10px;
    font-size: 15px;
    color: #1a1a1a;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    .dropdown__content .form-control {
      width: 160px;
    }
  }

  .datePicker {
    width: 100%;
    display: flex;
    padding: 10px;
    margin-bottom: 0.5rem;
    font-size: 15px;
    color: #1a1a1a;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    .datePicker__content .form-control {
      width: 160px;
    }
  }

  .timePicker {
    width: 100%;
    display: flex;
    padding: 10px;
    margin-bottom: 0.5rem;
    font-size: 15px;
    color: #1a1a1a;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    .timePicker__content .form-control {
      width: 160px;
    }
  }

  .inputTypeNumber {
    width: 100%;
    display: flex;
    padding: 10px;
    margin-bottom: 0.5rem;
    font-size: 15px;
    color: #1a1a1a;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    .inputTypeNumber__content .form-control {
      width: 160px;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const List = styled.div`
display: flex;
justify-content: center;
margin-bottom: 2rem;
  .list {
    width: 500px;
       border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    margin-top: 1rem;
 

    .list__detail {
      display: flex;
      justify-content: space-between;
       padding: 0.5rem;
        background-color: #fff;
        border-bottom:1px solid rgba(0,0,0,0.12) ;
      .list__detail__title {
        font-size: 16px;
        color: #1a1a1a;
       
      }
    }

    .list__qty {
      display: flex;
      justify-content: space-between;
      padding: 0.5rem;
      background-color: #f7a4d8;
      border-radius: 0 0 10px 10px;
      .list__qty__title {
        font-size: 16px;
        color: #1a1a1a;
      }
    }



    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;
const TypographyOrderList = styled(Typography)({
  fontSize: "16px",
  fontWeight: "400",
  borderRadius: "0",

})

const PaperOrderList = styled(Paper)({
  padding: "10px",
  backgroundColor: "#f5f5f5",
  borderRadius: "0 !important",

})

const PaperOrderList3 = styled(Paper)({
  padding: "10px",
 
  // border-redius ด้านขวาล่าง 10px ด้านซ้ายล่าง 0px ด้านขวาบน 0px ด้านซ้ายบน 0px
  borderRadius: "0 0 0 10px !important",
})

const PaperOrderList4 = styled(Paper)({
  padding: "10px",
 
  // border-redius ด้านขวาล่าง 10px ด้านซ้ายล่าง 0px ด้านขวาบน 0px ด้านซ้ายบน 0px
  borderRadius: "0 0 10px 0 !important",
})


function ShipperUploadNotSlip() {
  const [FileUrl, setFileUrl] = useState(null);
  const service = apiService()
  const [FileLoading, setFileLoading] = useState("");
  const [FilePost, setFilePost] = useState(null);
  const location = useLocation();
  const history = useHistory();
  const [dropdown, setDropdown] = useState("");
  const [datePicker, setDatePicker] = useState("");
  const [timePicker, setTimePicker] = useState("");
  const [inputTypeNumber, setInputTypeNumber] = useState("");
  const [selectError, setSelectError] = useState(false);
  const [datePickerError, setDatePickerError] = useState(false);
  const [timePickerError, setTimePickerError] = useState(false);
  const [inputTypeNumberError, setInputTypeNumberError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [customerID, setCustomerID] = useState("");
  const { orderID } = useParams();
  const [advancePayment, setAdvancePayment] = useState(0);
  const [orderDetailRecords, setOrderDetailRecords] = useState([]);









const getApi = () =>{
  if (location.state === undefined) {
    history.push("/Orders");
  } else {
    setFileUrl(location.state.fileUrl);
    setFilePost(location.state.filePost);

  }
  console.log(location.state);


  setLoading(true);
  service.get({ url: `/api/v1/order/?orderID=${orderID}` }).then((res) => {
    setLoading(false);
    setCustomerID(res.data.result.customerID);

    setOrderDetailRecords(res.data.result)
    const customerID = res.data.result.customerID;

    if (customerID !== null) {
      service
        .get({
          url: `/api/v1/financial/advance-payment?customerID=${customerID}`,
        })
        .then((res) => {
          console.log(res.data.result.advancePayment);
          setAdvancePayment(res.data.result.advancePayment);
          console.log(res.data.result);
        });
    }





  }).catch((err) => {
    setLoading(false);
    console.log(err);
    ToastifyError(err.message);
  });






}



 
  useEffect(() => {
 
    getApi()
  }, [location]);

  const ยอดต้องชำระ = Number(orderDetailRecords.totalPrice);
  const ยอดที่ชำระมาแล้ว = advancePayment ? Number(advancePayment) : 0;
console.log(ยอดที่ชำระมาแล้ว);

  const documentClick = () => {
    document.getElementById("paymentAmountFile").click();
  };

  const fileChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    // เช็คขนาดไฟล์ มากกว่า 1 MB ให้บีบอัด
    if (file.size > 1000000) {
      const options = {
        maxSizeMB: 0.5,
        maxWidthOrHeight: 1920,
        useWebWorker: true
      }
      imageCompression(file, options)
        .then(function (compressedFile) {
          // size in KB
   
          setFilePost(compressedFile);
        })
        .catch(function (error) {
          console.log(error.message);
        });
      setFileUrl(URL.createObjectURL(file));
    } else {
      setFilePost(file);
      setFileUrl(URL.createObjectURL(file));
    }
    
  }


  const postApi = () => {
    setLoading(true);

    if (customerID === null) {
      setLoading(false);
      ToastifyError("มีข้อผิดพลาด");
    } else {
      if (dropdown === "" || datePicker === "" || timePicker === "" || inputTypeNumber === "") {
        setLoading(false);
        ToastifyError("กรุณากรอกข้อมูลให้ครบถ้วน");
        // ถ้าไม่กรอกข้อมูลให้เปลี่ยนสีเป็นแดง
        dropdown === "" ? setSelectError(true) : setSelectError(false);
        datePicker === "" ? setDatePickerError(true) : setDatePickerError(false);
        timePicker === "" ? setTimePickerError(true) : setTimePickerError(false);
        inputTypeNumber === "" ? setInputTypeNumberError(true) : setInputTypeNumberError(false);
      } else {
        // ถ้ากรอกข้อมูลครบให้เปลี่ยนสีเป็นเทา
        setSelectError(false);
        setDatePickerError(false);
        setTimePickerError(false);
        setInputTypeNumberError(false);



        //api/v1/upload-image
        const formData = new FormData();
        formData.append("image", FilePost);

        service.post({ url: "api/v1/upload-image", params: formData, headers: { "Content-Type": "multipart/form-data" } }).then((res) => {


          const data = {
            customerID: customerID,
            slipUrl: res.data.result.url,
            data: "POPcreatedByShipper",
            receiptDate: new Date(datePicker + " " + timePicker).toISOString(),
            receiptAmount: inputTypeNumber,
            paymentMethod: dropdown
          }
          console.log(data);
          service.post({ url: "/api/v1/financial/upload-slip", params: data }).then((res) => {
            setLoading(false);

          

            if (ยอดต้องชำระ >= ยอดที่ชำระมาแล้ว) {
              swal({
                title: "สำเร็จ",
                text: "อัพโหลดสลิปเรียบร้อยแล้ว",
                icon: "success",
                button: "ตกลง",
              }).then((value) => {
                setInputTypeNumber("");
                setDatePicker("");
                setTimePicker("");
                setDropdown("");
                setFileUrl(null);
                setFilePost(null);
                liff.closeWindow()
              });
            }else{
              swal({
                text: "ชำระเงินไม่ครบถ้วน",
                icon: "warning",
                buttons: ["ยกเลิก", "รับทราบ"],
                className: "swal-shippr-listOrder",
                // ให้้ปิดโดยการกดปุ่มเท่านั้น
                closeOnClickOutside: false,
                content: (
                  <Box>

                    <Grid container spacing={0}>
                      <Grid item xs={6}>
                        <PaperOrderList>
                          <TypographyOrderList>ยอดต้องชำระ</TypographyOrderList>
                        </PaperOrderList>
                      </Grid>
                      <Grid item xs={6}>
                        <PaperOrderList>
                          <TypographyOrderList> {ยอดต้องชำระ ? ยอดต้องชำระ.toLocaleString() : 0} บาท</TypographyOrderList>
                        </PaperOrderList>
                      </Grid>
                    </Grid>


                    <Grid container spacing={0}>
                      <Grid item xs={6}>
                        <PaperOrderList>
                          <TypographyOrderList>ยอดที่ชำระมาแล้ว</TypographyOrderList>
                        </PaperOrderList>
                      </Grid>
                      <Grid item xs={6}>
                        <PaperOrderList>
                          <TypographyOrderList>
                            {ยอดที่ชำระมาแล้ว ? ยอดที่ชำระมาแล้ว.toLocaleString() : 0} บาท
                          </TypographyOrderList>
                        </PaperOrderList>
                      </Grid>
                    </Grid>

                    <Grid container spacing={0}>
                      <Grid item xs={6}>
                        <PaperOrderList3 style={{ backgroundColor: "#f7a4d8" }} >
                          <TypographyOrderList>ยังต้องชำระอีก</TypographyOrderList>
                        </PaperOrderList3>
                      </Grid>
                      <Grid item xs={6}>
                        <PaperOrderList4 style={{ backgroundColor: "#f7a4d8" }} >
                          <TypographyOrderList>
                            {ยอดต้องชำระ - ยอดที่ชำระมาแล้ว ? (ยอดต้องชำระ - ยอดที่ชำระมาแล้ว).toLocaleString() : 0} บาท
                          </TypographyOrderList>
                        </PaperOrderList4>
                      </Grid>
                    </Grid>

                  </Box>
                ),



              }).then((value) => {
                setInputTypeNumber("");
                setDatePicker("");
                setTimePicker("");
                setDropdown("");
                setFileUrl(null);
                setFilePost(null);
                getApi()

              });
            }



          



          }).catch((err) => {
            console.log(err)
            setLoading(false);
            ToastifyError("เกิดข้อผิดพลาด " + err.message)
          })

        }).catch((err) => {
          console.log(err)
          setLoading(false);
          ToastifyError("เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง")
        })




      }
    }


  };



  return (
    <Counter>
    
      
      <div
        className="title"
        style={{
          marginBottom: "0.5rem",
        }}
      >
        แนบหลักฐาน การโอนเงินชนิดอื่น
      </div>


      {loading === true ?
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '2rem'
        }}>
          <CircularProgress />
        </div>
        : <List style={{
          marginButtom: '2rem'
        }}>
          <div className='list shadow-sm'>

            <div className='list__detail' >
              <div className='list__detail__title'>
                ยอดต้องชำระ
              </div>
              <div className='list__detail__title'>

                {ยอดต้องชำระ ? ยอดต้องชำระ.toLocaleString() : 0} บาท
              </div>
            </div>
            <div className='list__detail'>
              <div className='list__detail__title'>
                ยอดที่ชำระมาแล้ว
              </div>
              <div className='list__detail__title'>

                {ยอดที่ชำระมาแล้ว ? ยอดที่ชำระมาแล้ว.toLocaleString() : 0} บาท
              </div>
            </div>

            <div className='list__qty'>
              <div className='list__qty__title'>
                ยังต้องชำระอีก

              </div>
              <div className='list__qty__title'>
                {ยอดต้องชำระ - ยอดที่ชำระมาแล้ว ? (ยอดต้องชำระ - ยอดที่ชำระมาแล้ว).toLocaleString() : 0} บาท
              </div>
            </div>

          </div>
        </List>}

      <UploadFile
        style={{
          marginTop: "1rem",
        }}
        File={File}
        FileUrl={FileUrl}
        documentClick={documentClick}
        fileChange={fileChange}
        FileLoading={FileLoading}
        Upload={Upload}
        FileImage={FileImage}
        UploadFileContent={UploadFileContent}
      />

      <div
        className="title"
        style={{
          marginTop: "3.5rem",
        }}
      >
        กรอกรายละเอียด
      </div>

      <CounterInput>
        <div className="dropdown shadow-sm">
          <div className="dropdown__title">paymentMethod</div>
          <div className="dropdown__content">
            <select
              className={
                selectError ? "form-control is-invalid" : "form-control"
              }
              onChange={(e) => {
                setDropdown(e.target.value);
              }}
              value={dropdown}
            >
              <option value="">เลือกวิธีชำระเงิน</option>
              <option value="transfer">สลิปเงินโอน</option>
              <option value="ATM">ใบฝากเอทีเอ็ม</option>
              <option value="depositSlip">ใบนำฝากธนาคาร</option>
              <option value="cheque">เช็ค</option>
              <option value="otherTransfer">เงินโอนประเภทอื่น</option>

            </select>
          </div>
        </div>

        <div className="datePicker shadow-sm">
          <div className="datePicker__title">วันโอน</div>
          <div className="datePicker__content">
            <input
              type="date"
              className={
                datePickerError ? "form-control is-invalid" : "form-control"
              }
              onChange={(e) => {
                setDatePicker(e.target.value);
              }}
              value={datePicker}
            />
          </div>
        </div>

        <div className="timePicker shadow-sm">
          <div className="timePicker__title">เวลาโอน</div>
          <div className="timePicker__content">
            <input
              type="time"
              className={
                timePickerError ? "form-control is-invalid" : "form-control"
              }
              onChange={(e) => {
                setTimePicker(e.target.value);
              }}
              value={timePicker}
            />
          </div>
        </div>

        <div className="inputTypeNumber shadow-sm">
          <div className="inputTypeNumber__title">จำนวนเงิน</div>
          <div className="inputTypeNumber__content">
            <input
              type="number"
              inputMode="numeric"
              className={
                inputTypeNumberError ? "form-control is-invalid" : "form-control"
              }
              placeholder="จำนวนเงิน"
              onChange={(e) => {
                setInputTypeNumber(e.target.value);
              }}
              value={inputTypeNumber}
            />
          </div>
        </div>
      </CounterInput>

      <Button
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "2rem",
        }}
      >
        <LoadingButton
          loading={loading}
          onClick={postApi}
          size="small"
          variant="contained"
          loadingIndicator="กําลังตรวจสอบ..."
          className="shadow-sm"
          sx={{
            width: "80%",
            fontSize: "16px",
            marginTop: "10px",

            "@media (max-width: 768px)": {
              width: "80%",
            },
          }}
        >
          รับทราบ
        </LoadingButton>
      </Button>
    </Counter>
  );
}

export default ShipperUploadNotSlip;
