import styled from 'styled-components';

const Counter = styled.div`
  background-color: #ffecf8;
  padding-bottom: 3rem;
  padding-top: 1.5rem;
  width: 100%;
  min-height: 100vh;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-top: 3rem;
  .title {
    font-size: 24px;
    font-weight: 600;
    border-bottom: 2px solid #1a1a1a;
    width: 500px;
    text-align: left;
    color: #1a1a1a;
    margin: 0 auto;
     height: auto;
  margin-bottom: 0.5rem;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;


const TitleContent = styled.div`
   width: 500px;
   margin: 0 auto;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    h1{
      font-size: 18px;
      color: #1a1a1a;
      font-weight: 600;
      padding: 0.5rem;
      background-color: #ff8ccb;
      border-radius: 10px;
      border: 2px solid #fff;
      text-align: center;
    }
       @media (max-width: 768px) {
      width: 100%;
    } 

`;


const TitleContent2 = styled.div`
  width: 500px;
   margin: 0 auto;
    margin-top: 5rem;
    margin-bottom: 1.5rem;
    h1{
      font-size: 18px;
      color: #1a1a1a;
      font-weight: 600;
      padding: 0.5rem;
      background-color: #9ad3f7;
      border-radius: 10px;
      border: 2px solid #fff;
      text-align: center;
    }
       @media (max-width: 768px) {
      width: 100%;
    } 

`;





const ItemDetail = styled.div`
  width: 500px;
  margin: 0 auto;
  
.ItemDetail_title{
  font-size: 18px;
  color: #1a1a1a;
  font-weight: 600;
  padding: 0.5rem;
  background-color: #ff8ccb;
 border-top-right-radius : 10px;
  border-top-left-radius : 10px;
  text-align: center;
  
}

.ItemDetail_filex{
  font-size: 15px;
  display: flex;
  justify-content: space-between;

  .ItemDetail_filex__title_1{
    padding: 0.7rem;
    background-color: #fff;
    border-left: 2px solid #eee;
    border-right: 2px solid #eee;
    border-top: 2px solid #eee;
    width: 50%;
    text-align: left;
    color: #1a1a1a;
  }
   .ItemDetail_filex__title_2{
    padding: 0.7rem;
    background-color: #fff;
  
    border-top: 2px solid #eee;
    border-right: 2px solid #eee;
    width: 50%; 
    text-align: right;
    color: #1a1a1a;
  }
}

.ItemDetail_filex_bg{
  font-size: 15px;
  display: flex;
  justify-content: space-between;

  .ItemDetail_filex__title_1{
    padding: 0.7rem;
    background-color: #ffd9f0;
    color: #1a1a1a;
    border-top: 2px solid #eee;
    border-bottom: 2px solid #eee;
    border-right: 2px solid #eee;
    border-left: 2px solid #eee;
    width: 50%;
    text-align: left;
  }
   .ItemDetail_filex__title_2{
    padding: 0.7rem;
    background-color: #ffd9f0;
    border-top: 2px solid #eee;
    border-bottom: 2px solid #eee;
    border-right: 2px solid #eee;
    color: #1a1a1a;
    width: 50%;
    text-align: right;
  }
}

@media (max-width: 768px) {
      width: 100%;
}

`;


const ItemDetail2 = styled.div`
 width: 500px;
  margin: 0 auto;
  
.ItemDetail_title{
  font-size: 18px;
  color: #1a1a1a;
  font-weight: 600;
  padding: 0.5rem;
  background-color: #9ad3f7;
 border-top-right-radius : 10px;
  border-top-left-radius : 10px;
  text-align: center;
  
}

.ItemDetail_filex{
  font-size: 15px;
  display: flex;
  justify-content: space-between;

  .ItemDetail_filex__title_1{
    padding: 0.7rem;
    background-color: #fff;
    border-left: 2px solid #eee;
    border-right: 2px solid #eee;
    border-top: 2px solid #eee;
    width: 50%;
    text-align: left;
    color: #1a1a1a;
  }
   .ItemDetail_filex__title_2{
    padding: 0.7rem;
    background-color: #fff;
  
    border-top: 2px solid #eee;
    border-right: 2px solid #eee;
    width: 50%; 
    text-align: right;
    color: #1a1a1a;
  }
}

.ItemDetail_filex_bg{
  font-size: 15px;
  display: flex;
  justify-content: space-between;

  .ItemDetail_filex__title_1{
    padding: 0.7rem;
    background-color: #ebe7f9;
    color: #1a1a1a;
    border-top: 2px solid #eee;
    border-bottom: 2px solid #eee;
    border-right: 2px solid #eee;
    border-left: 2px solid #eee;
    width: 50%;
    text-align: left;
  }
   .ItemDetail_filex__title_2{
    padding: 0.7rem;
    background-color: #ebe7f9;
    border-top: 2px solid #eee;
    border-bottom: 2px solid #eee;
    border-right: 2px solid #eee;
    color: #1a1a1a;
    width: 50%;
    text-align: right;
  }
}

@media (max-width: 768px) {
      width: 100%;
}

`;




const ItemDetailCansel = styled.div`
 width: 500px;
  margin: 0 auto;
  
.ItemDetail_title{
  font-size: 18px;
  color: #ff5e08;
  font-weight: 600;
  padding: 0.5rem;
  background-color: #fff;
 border-top-right-radius : 10px;
  border-top-left-radius : 10px;
  text-align: center;
  border: 1px solid #ff5e08;
}

.ItemDetail_filex{
  font-size: 15px;
  display: flex;
  justify-content: space-between;

  .ItemDetail_filex__title_1{
    padding: 0.7rem;
    background-color: #fff;
    border-left: 1px solid #ff5e08;
    border-right: 1px solid #ff5e08;
    border-top: 0px solid #ff5e08;
    border-bottom: 1px solid #ff5e08;
    width: 50%;
    text-align: left;
    color: #ff5e08;
  }
   .ItemDetail_filex__title_2{
    padding: 0.7rem;
    background-color: #fff;
  
    border-top: 0;
    border-right: 1px solid #ff5e08;
    border-bottom: 1px solid #ff5e08;
    width: 50%; 
    text-align: right;
    color: #ff5e08;
  }
}

.ItemDetail_filex_bg{
  font-size: 15px;
  display: flex;
  justify-content: space-between;

  .ItemDetail_filex__title_1{
    padding: 0.7rem;
    background-color: #ebe7f9;
    color: #1a1a1a;
    border-top: 2px solid #eee;
    border-bottom: 2px solid #eee;
    border-right: 2px solid #eee;
    border-left: 2px solid #eee;
    width: 50%;
    text-align: left;
  }
   .ItemDetail_filex__title_2{
    padding: 0.7rem;
    background-color: #ebe7f9;
    border-top: 2px solid #eee;
    border-bottom: 2px solid #eee;
    border-right: 2px solid #eee;
    color: #1a1a1a;
    width: 50%;
    text-align: right;
  }
}

@media (max-width: 768px) {
      width: 100%;
}

`;


const ItemDetailOther = styled.div`
 width: 500px;
  margin: 0 auto;
  
.ItemDetail_title{
  font-size: 18px;
  color: #fff;
  font-weight: 600;
  padding: 0.5rem;
  background-color: #800000;
 border-top-right-radius : 10px;
  border-top-left-radius : 10px;
  text-align: center;
  border: 1px solid #800000;

 
}
 .ItemDetail_title_filex{
    font-size: 15px;
    width: 100%;
    background-color: #e3bdc6;
    padding: 0.7rem;
    border: 1px solid #fff;
    color: #1a1a1a;
  }
.ItemDetail_filex{
  font-size: 15px;
  display: flex;
  justify-content: space-between;

  .ItemDetail_filex__title_1{
    padding: 0.7rem;
    background-color: #fff;
    border-left: 1px solid #eee;
    border-right: 1px solid #eee;
    border-top: 0px solid #eee;
    border-bottom: 1px solid #eee;
    width: 50%;
    text-align: left;
    color: #1a1a1a;
  }
   .ItemDetail_filex__title_2{
    padding: 0.7rem;
    background-color: #fff;
  
    border-top: 0;
    border-right: 1px solid #eee;
    border-bottom: 1px solid #eee;
    width: 50%; 
    text-align: right;
    color: #1a1a1a;
  }
}

.ItemDetail_filex_bg{
  font-size: 15px;
  display: flex;
  justify-content: space-between;

  .ItemDetail_filex__title_1{
    padding: 0.7rem;
    background-color: #ebe7f9;
    color: #1a1a1a;
    border-top: 2px solid #eee;
    border-bottom: 2px solid #eee;
    border-right: 2px solid #eee;
    border-left: 2px solid #eee;
    width: 50%;
    text-align: left;
  }
   .ItemDetail_filex__title_2{
    padding: 0.7rem;
    background-color: #ebe7f9;
    border-top: 2px solid #eee;
    border-bottom: 2px solid #eee;
    border-right: 2px solid #eee;
    color: #1a1a1a;
    width: 50%;
    text-align: right;
  }
}

@media (max-width: 768px) {
      width: 100%;
}

`;




const ItemDetail3 = styled.div`
 width: 500px;
  margin: 0 auto;
  
.ItemDetail_title{
  font-size: 18px;
  color: #1a1a1a;
  font-weight: 600;
  padding: 0.5rem;
  background-color: #f24726;
  color: #fff;
 border-top-right-radius : 10px;
  border-top-left-radius : 10px;
  text-align: center;
  
}

.ItemDetail_filex{
  font-size: 15px;
  display: flex;
  justify-content: space-between;

  .ItemDetail_filex__title_1{
    padding: 0.7rem;
    background-color: #fff;
    border-left: 2px solid #eee;
    border-right: 2px solid #eee;
    border-top: 2px solid #eee;
    width: 50%;
    text-align: left;
    color: #1a1a1a;
  }
   .ItemDetail_filex__title_2{
    padding: 0.7rem;
    background-color: #fff;
  
    border-top: 2px solid #eee;
    border-right: 2px solid #eee;
    width: 50%; 
    text-align: right;
    color: #1a1a1a;
  }
}

 

@media (max-width: 768px) {
      width: 100%;
}

`;
const ButtonBrue = styled.div`
  width: 500px;
  margin: 0 auto;
  margin-top: 1rem;
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;

  button{
    background-color: #2652f9 !important;
    border: 2px solid #fff !important;
    color: #fff !important;
    width: 80%;
    border-radius: 10px !important;
    cursor: pointer !important;
    
  }
     

    @media (max-width: 768px) {
      width: 100%;
    }
`;

const BittonGreen = styled.div`
width: 500px;
  margin: 0 auto;
  margin-top: 1rem;
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;

  button{
    background-color: #079d46 !important;
    border: 2px solid #fff !important;
    color: #fff !important;
    width: 80%;
    border-radius: 10px !important;
    cursor: pointer !important;
    
  }
     

    @media (max-width: 768px) {
      width: 100%;
    }
`;

const ButtonOrange = styled.div`
  width: 500px;
  margin: 0 auto;
  margin-top: 1rem;
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;

  button{
    background-color: #ff9900 !important;
    border: 2px solid #fff !important;
    color: #fff !important;
    width: 80%;
    border-radius: 10px !important;
    cursor: pointer !important;
    
  }
     

    @media (max-width: 768px) {
      width: 100%;
    }

`;





export { Counter, TitleContent, ItemDetail, ButtonBrue, TitleContent2, ItemDetail2, ItemDetail3, BittonGreen, ButtonOrange, ItemDetailCansel, ItemDetailOther };