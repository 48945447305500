import React from 'react'
import swal from '@sweetalert/with-react';
function RegisterAuto2() {

  swal({
    title: "กำลังเชื่อมไลน์",
    text: "กรุณารอสักครู่...",
    icon: "info",
    button: false,
    closeOnClickOutside: false,
    closeOnEsc: false,
  })


  window.location.href = `https://influencer-backend.azurewebsites.net/api/v1/authen/login?lineID=true&success=https://liffapp.azurewebsites.net/registerAutoSuccess`
  return (
    <div></div>
  )
}

export default RegisterAuto2