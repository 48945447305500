import apiService from '../../api/apiService';
import swal from '@sweetalert/with-react';
import moment from 'moment';
import 'moment/locale/th';


const service = apiService();




// /api/v1/employee/list
async function getEmployeeList() {
    return await service.get({ url: `/api/v1/employee/list` }).then((res) => {
        return res.data.result
    }).catch((err) => {
        console.log(err)

        swal({ title: "Error", text: err?.response?.data?.message || err.message, icon: "error", button: "close" });
    })
}

// /api/v1/customer/add 
async function addCustomer(data) {
    return await service.post({ url: `/api/v1/customer/add`, params: data }).then((res) => {
        return res.data.result
    }).catch((err) => {
        console.log(err)
        swal({ title: "Error", text: err?.response?.data?.message || err.message, icon: "error", button: "close" });

    })
}


// /api/v1/customer/list-customer-board
async function getCustomerList() {
    return await service.get({ url: `/api/v1/customer/list-customer-board` }).then((res) => {
        return res.data.result
    }).catch((err) => {
        console.log(err)
        swal({ title: "Error", text: err?.response?.data?.message || err.message, icon: "error", button: "close" });


    })

}

// /api/v1/sale/customers?saleID=1
async function getSalCustomers({ saleID }) {
    return await service.get({ url: `/api/v1/sale/customers?saleID=${saleID}` }).then((res) => {
        return res.data.result
    }).catch((err) => {
        console.log(err)
        swal({ title: "Error", text: err?.response?.data?.message || err.message, icon: "error", button: "close" });


    })

}

///api/v1/customer/update
async function updateCustomer(data) {
    return await service.post({ url: `/api/v1/customer/update`, params: data }).then((res) => {
        return res.data.result
    }).catch((err) => {
        console.log(err)
        swal({ title: "Error", text: err?.response?.data?.message || err.message, icon: "error", button: "close" });


    })
}

///api/v1/customer/list-customer-board
async function getListCustomerBoard({ saleID }) {
    return await service.get({ url: `/api/v1/customer/list-customer-board?salesID=${saleID}` }).then((res) => {
        return res.data.result
    }).catch((err) => {
        console.log(err)
        swal({ title: "Error", text: err?.response?.data?.message || err.message, icon: "error", button: "close" });


    })

}


// /api/v1/customer/update-detail
async function updateCustomerDetails(data) {
    return await service.post({ url: `/api/v1/customer/update-detail`, params: data }).then((res) => {
        return res.data.result
    }).catch((err) => {
        console.log(err)
        swal({ title: "Error", text: err?.response?.data?.message || err.message, icon: "error", button: "close" });


    })
}

// /api/v1/customer/add-detail
async function addCustomerDetails(data) {
    return await service.post({ url: `/api/v1/customer/add-detail`, params: data }).then((res) => {
        return res.data.result
    }).catch((err) => {
        console.log(err)
        swal({ title: "Error", text: err?.response?.data?.message || err.message, icon: "error", button: "close" });


    })
}

// /api/v1/user/
async function getUser() {
    return await service.get({ url: `/api/v1/user/` }).then((res) => {
        return res.data.result
    }).catch((err) => {
        console.log(err)
        swal({ title: "Error", text: err?.response?.data?.message || err.message, icon: "error", button: "close" });
    })
}


export {
    getEmployeeList,
    addCustomer,
    getCustomerList,
    getSalCustomers,
    updateCustomer,
    getListCustomerBoard,
    updateCustomerDetails,
    addCustomerDetails,
    getUser
}