
import styled from 'styled-components'

const MainTile = styled.div`
  display: flex;
  width: 100%;
`;



const Tile = {
  padding: '0.5rem',
  fontSize: '22px',
  backgroundColor: '#893843',
  color: '#fff',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

}


const TableTile = {
  display: 'flex',
  fontSize: '22px',
 
}


const stockRequestDate = {
  width: '100%',
  height: '50px',
  backgroundColor: '#1769aa',
  borderRadius: '0px',
  border: '1px solid #f5f5f5',
  borderRight: 'none',
  color: '#fff',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderBottom: 'none',

}


const stockRequestID = {
  width: '100%',
  height: '50px',
  backgroundColor: '#1769aa',
  borderRadius: '0px',
  border: '1px solid #f5f5f5',
  color: '#fff',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderBottom: 'none',
} 

const stockRequestDate_ = {
  width: '100%',
  height: '50px',
  backgroundColor: '#1769aa',
  borderRadius: '0px',
  border: '1px solid #f5f5f5',
  borderRight: 'none',
  color: '#fff',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderBottom: 'none',
}
const stockRequestID_ = {
  width: '100%',
  height: '50px',
  backgroundColor: '#1769aa',
  borderRadius: '0px',
  border: '1px solid #f5f5f5',
  color: '#fff',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderBottom: 'none',
} 

const stockRequestDateData = {
  width: '100%',
  height: '50px',
  backgroundColor: '#fff',
  borderRadius: '0px',
  border: '1px solid #f5f5f5',
  borderRight: 'none',
  color: '#000000de',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderBottom: 'none',
}

const stockRequestIDData = {
  width: '100%',
  height: '50px',
  backgroundColor: '#fff',
  borderRadius: '0px',
  border: '1px solid #f5f5f5',
  color: '#1890ff',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderBottom: 'none',
  cursor: 'pointer',
}



const Counter = {
 
background: "#ffecf8",
paddingBottom: "3rem",
paddingTop: "1.5rem",
width: "100%",
minHeight: "100vh",
paddingLeft: "0.5rem",
paddingRight: "0.5rem",

}






const Main = styled.div`
  width: 700px;
  
  background-color: #fff;
  border-radius: 5px;
   
  margin: 0 auto;
  @media (max-width: 768px) {
    width: 100%;
  }

`;

export {
  MainTile,
  Tile,
  TableTile,
  stockRequestDate,
  stockRequestID,
  Counter,
  Main,
  stockRequestDateData,
  stockRequestIDData,
  stockRequestDate_,
  stockRequestID_,
  
}