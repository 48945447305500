
export const user = (state = [], action) => {
    switch (action.type) {
      case "SET_USER":
        return action.payload;
      default:
        return state;
    }
  }
  
  

export const customerID = (state = [], action) => {
    switch (action.type) {
      case "SET_CUSTOMER_ID":
        return action.payload;
      default:
        return state;
    }
  }


  export const closeOrder = (state = false, action) => {
    switch (action.type) {
      case "SET_CLOSE_ORDER":
        return action.payload;
      default:
        return state;
    }
  }



export const customerInfo = (state = false, action) => {
    switch (action.type) {
      case "SET_CUSTOMER_INFO":
        return action.payload;
      default:
        return state;
    }
  }