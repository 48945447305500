import apiService from '../../api/apiService';
import swal from 'sweetalert';
import moment from 'moment';
import 'moment/locale/th';

const service = apiService();


const errSwal = (title, text) => {
    swal({
        title: title,
        text: text,
        icon: "error",
        button: "close",
    });
};

 

//2.1 ยิง /api/v1/price-level/list-all ให้เอา response priceSymbol มาแสดงใน dropdownList

async function getPriceLevelList() {
    return await service.get({ url: `/api/v1/price-level/list-all` }).then((res) => {
        return res.data.result
    }).catch((err) => {
        console.log(err)
        errSwal('/api/v1/price-level/list-all', err.message)
    })
}

// api/v1/customer/add
async function postAddCustomer(data) {
    return await service.post({ url: `/api/v1/customer/add`, params: data }).then((res) => {
        return res.data.result
    }).catch((err) => {
        console.log(err)
        errSwal('/api/v1/customer/add', err.message)
    })
}

// /api/v1/employee/list
async function getEmployeeList() {
    return await service.get({ url: `/api/v1/employee/list` }).then((res) => {
        return res.data.result
    }).catch((err) => {
        console.log(err)
        errSwal('/api/v1/employee/list', err.message)
    })
}

export {
    getPriceLevelList,
    postAddCustomer,
    getEmployeeList
}