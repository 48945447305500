import React, { useState, useEffect} from "react";
import Paper from "@mui/material/Paper";
import Typography   from "@mui/material/Typography";
 
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useHistory } from "react-router-dom";
 
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor:"#009c49",
    color: theme.palette.common.white,
    border: 0,
    fontSize: '1rem',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: '1rem',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  },
}));

 

 
function Table1({ tableData1, tableData2,  gitLocation }) {

  const history = useHistory();

  
  return (
    <div>

      { tableData1.length === 0 && tableData2.length === 0 ? null : <Paper className="p-2 mb-2 rounded-0 fs-5 fw-bold" sx={{
          backgroundColor: "#198754",
           color: "#fff",
            width: 250,
            borderRadius: "10px",
            '@media (max-width: 600px)': {
              width: '100%',
            },
          }}>

          <Typography variant="h6" gutterBottom component="div" className="mb-0"> ที่ตนเองรับผิดชอบจัดส่ง</Typography>
        </Paper> 
        
      }


        { tableData1.length > 0 &&
        
        <TableContainer component={Paper}>
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell sx={{ width: "35%", }}>เวลาสั่งของ</StyledTableCell>
            <StyledTableCell sx={{ width: "40%", }}>ลูกค้าที่สั่งแล้ว</StyledTableCell>
            <StyledTableCell sx={{ width: "25%", }}>ยอด</StyledTableCell>
     
          </TableRow>
        </TableHead>
        <TableBody>

          {
            tableData1.map((item, index) => {
              return(
                <StyledTableRow key={index} id={`table1-${index}`}
                onClick={() => { 
                  gitLocation(`table1-${index}`)
                  history.push(`/orderDetailSlip/${item.customerID}/${item.orderID}`)
                }}>
                <StyledTableCell component="th" scope="row" sx={{ width: "35%", backgroundColor: item.style.backgroundColor, color: item.style.color, }}>
                <Typography variant="body2" gutterBottom component="div" noWrap sx={{ width: "100px", margin: "0", '@media (max-width: 600px)': { width: '80px', }, }}>
                  {item.orderDate}
                 </Typography> 
                </StyledTableCell>
                <StyledTableCell sx={{ width: "40%", backgroundColor: item.style.backgroundColor, color: item.style.color, }}>
                <Typography variant="body2" gutterBottom component="div" noWrap sx={{ width: "200px", margin: "0", '@media (max-width: 600px)': { width: '140px', }, }}>
                  {item.customerName}
                </Typography>
                   </StyledTableCell>
                <StyledTableCell sx={{ width: "25%", backgroundColor: item.style.backgroundColor, color: item.style.color, }}>
                <Typography variant="body2" gutterBottom component="div" noWrap sx={{ width: "100px", margin: "0", '@media (max-width: 600px)': { width: '70px', }, }}>
                  {item.totalPrice ? item.totalPrice.toLocaleString() : 0}
                 </Typography> 
                 </StyledTableCell>
           
              </StyledTableRow>
              )
            })
          }
         
           
       
        </TableBody>
      </Table>
    </TableContainer>
}
      
    </div>
  );
}

export default Table1;
