 import React from 'react'
 

function ClearLocalStorage() {

  async function clearLocalStorage() {
    return new Promise((resolve, reject) => { 
      try {
        localStorage.clear()
        resolve(true)
      } catch (error) {
        reject(error)
      }
    })

  }

 

  return (<div className='d-flex justify-content-center align-items-center' style={{height:'100vh'}}>
  <button className='btn btn-danger'
   onClick={() => {
    clearLocalStorage().then(() => {
       alert('Clear Local Storage Success')
    })
  }}>
    Clear Local Storage
  </button>
  
  </div>)
}

export default ClearLocalStorage