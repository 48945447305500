import React from 'react'
import Form from './form'
import { getUser, getEmployeeList } from './api'
import swal from '@sweetalert/with-react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import liff from '@line/liff';
function CreateNewCustomer() {
    // openCustomer, employeeList, getData, saleID, setOpen, setSaleID
    const [openCustomer, setOpenCustomer] = React.useState("add");
    const [employeeList, setEmployeeList] = React.useState([]);
    const [saleID, setSaleID] = React.useState(null);
    const [open, setOpen] = React.useState(false);

    React.useEffect(() => {
        Promise.all([getEmployeeList(), getUser()]).then((res) => {
            setEmployeeList(res[0].filter((item) => item.employmentStatus === true))
            if(res[1]?.employeeJobType === "Salesperson" || res[1]?.employeeJobType === "Executive"){
                setSaleID(res[1]?.userID)
            } else {
                console.log("res[1]", res[1]);
                setSaleID(null)
                swal({ title: "Error", text: "กรุณาเข้าสู่ระบบด้วยบัญชีพนักงานขาย", icon: "error", button: "close",  closeOnClickOutside: false,  }).then((value) => {
                  if(value){
                    liff.closeWindow()
                  }
                })
            }
        })
    }, [])

    const handleClose = (event, reason) => {
        if (reason && reason === "backdropClick")  
            return;
      }  
    
  return ( 
    <div>
      <Form  
        openCustomer={openCustomer}
        setOpenCustomer={setOpenCustomer}
        employeeList={employeeList}
        setEmployeeList={setEmployeeList}
        open={open}
        setOpen={setOpen}
     
        saleID={saleID}
        setSaleID={setSaleID}
       />
         <Backdrop
        onClose={handleClose}
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        hideBackdrop={true}
        open={open}
       >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  )
}

export default CreateNewCustomer