import React, { useState, useEffect } from "react";
import {
  Counter,
  Main,
  MainTable,
  Button,
} from "../customerNotYetOrder/styled";
import Backdrop from "@mui/material/Backdrop";
 
import CircularProgress from "@mui/material/CircularProgress";
import Table from "./table";
import { getCusList, getAllWithShipper} from "./api";


function CustomerRouteName() {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [cusList, setCusList] = useState([]);
  const [route, setRoute] = useState([]);
  const [cusID, setCusID] = useState(null);
  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
  };


  useEffect(() => {

    Promise.all([getCusList(), getAllWithShipper()]).then((res) => {
        const options = res[0].map((item) => {
            return {
                value: item.customerID,
                label: item.invoiceName ,
            }
        })

        setOptions(options);
        setRoute(res[1]);
        setCusList(res[0]);
    });

  }, []);

  return (
    <Counter style={{ backgroundColor: 
        // ชมพูอ่อน
        "#ffebee",
    }}>
      <Main>
        <div className="title">เช็คว่าลูกค้าคนไหน ใครส่ง?</div>
      </Main>
      <MainTable>
        <Table options={options} setCusID={setCusID} cusID={cusID} route={route} cusList={cusList} />
    </MainTable>
      <Backdrop
        onClose={handleClose}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Counter>
  );
}

export default CustomerRouteName;
