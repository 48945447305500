
export const useOrderAgainShippr = (state = [], action) => {
    switch (action.type) {
      case "SET_ORDER_AGAIN_SHIPPR":
        return action.payload;
      case "UPDATE_ORDER_AGAIN_SHIPPR":
        return state.map(item => { if (item.productID === action.payload.productID) { return action.payload; } else { return item; } });
      default:
        return state;
    }
  }
  
   