import React, { useEffect } from 'react';
import apiService from "../../api/apiService";
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from "@mui/material/TextField";

 
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import swal from '@sweetalert/with-react';
import liff from "@line/liff/dist/lib";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));



function UpdateUser() {
    const service = apiService();
  const classes = useStyles();
   const [bankAccountNameError, setBankAccountNameError] = React.useState(false);
    const [bankNameError, setBankNameError] = React.useState(false);
    const [bankAccountNumberError, setBankAccountNumberError] = React.useState(false);
    const [fullNameError, setFullNameError] = React.useState(false);
    const [phoneError, setPhoneError] = React.useState(false);
  const [bankAccountName, setBankAccountName] = React.useState('');
 
  const [bankName, setBankName] = React.useState('');
  const [bankAccountNumber, setBankAccountNumber] = React.useState('');
  const [fullName, setFullName] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [userID, setUserID] = React.useState('');
   useEffect(() => {
     service.get({ url: '/api/v1/user/' }).then((res) => {
        setUserID(res?.data?.result?.userID || '');
       console.log(res.data.result);
        setBankAccountName(res?.data?.result?.bankAccountName || '');
        setBankName(res?.data?.result?.bankName || '');
        setBankAccountNumber(res?.data?.result?.bankAccountNumber || '');
        setFullName(res?.data?.result?.fullName || '');
       setPhone(res?.data?.result?.userPhone || '');
      });
    }, []);


  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    if(data.get('bankAccountName') === '') {
      setBankAccountNameError(true);
    } else {
      setBankAccountNameError(false);
    }
    if(data.get('bankName') === '') {
      setBankNameError(true);
    } else {
      setBankNameError(false);
    }
    if(data.get('bankAccountNumber') === '') {
      setBankAccountNumberError(true);
    } else {
      setBankAccountNumberError(false);
    }
    if(data.get('fullName') === '') {
      setFullNameError(true);
    } else {
      setFullNameError(false);
    }
    if(data.get('phone') === '') {
      setPhoneError(true);
    } else {
      setPhoneError(false);
    }

    if(data.get('bankAccountName') !== '' && data.get('bankName') !== '' && data.get('bankAccountNumber') !== '' && data.get('fullName') !== '' && data.get('phone') !== '') {
      const data = {
        bankAccountName: bankAccountName,
        bankName: bankName,
        bankAccountNumber: bankAccountNumber,
        fullName: fullName,
        userPhone: phone,
        userID: userID
      };

      service.post({ url: '/api/v1/user/update', params: data }).then((res) => {
        console.log(res);
        swal({ 
          title: "Success",
           text: "บันทึกข้อมูลสำเร็จ",
            icon: "success",
             button: "close"
             }).then(() => {
                 liff.closeWindow();
             });

      });
      
    }

    
  }



  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <AccountBalanceIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          อัพเดทรายละเอียดของท่านเพื่อรับการโอนเงิน
        </Typography>

        
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="bankAccountName"
            label="ชื่อบัญชีธนาคาร"
            name="bankAccountName"
            autoComplete="bankAccountName"
            autoFocus
            value={bankAccountName}
       
            error={bankAccountNameError}
            onChange={(e) => {
              setBankAccountNameError(false)
              setBankAccountName(e.target.value)
            }}
            helperText={bankAccountNameError ? "กรุณากรอกชื่อบัญชีธนาคารให้ถูกต้อง" : ""}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="bankName"
            label="ชื่อธนาคาร"
          
            id="bankName"
            autoComplete="bankName"
            error={bankNameError}
            value={bankName}
     
            onChange={(e) => {
              setBankNameError(false)
              setBankName(e.target.value)
            }}
            helperText={bankNameError ? "กรุณากรอกชื่อธนาคารให้ถูกต้อง" : ""}
          /> 
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="bankAccountNumber"
            label="หมายเลขบัญชี"
              
            id="bankAccountNumber"
            autoComplete="bankAccountNumber"
            value={bankAccountNumber}
            error={bankAccountNumberError}
            onChange={(e) => {
              setBankAccountNumberError(false)
              setBankAccountNumber(e.target.value)
            }}
            helperText={bankAccountNumberError ? "กรุณากรอกหมายเลขบัญชีให้ถูกต้อง" : ""}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="fullName"
            label="ชื่อของท่าน"
            type="text"
            id="fullName"
            autoComplete="fullName"
            value={fullName}
            error={fullNameError}
            onChange={(e) => {
              setFullNameError(false)
              setFullName(e.target.value)
            }}
            helperText={fullNameError ? "กรุณากรอกชื่อของท่านให้ถูกต้อง" : ""}

          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="phone"
            label="เบอร์โทรศัพท์"
            // inputmode="numeric"

            inputProps={{
              inputMode: 'numeric',
            }} 
         

            id="phone"
            autoComplete="phone"
            error={phoneError}
            value={phone}
            onChange={(e) => {
              setPhoneError(false)
              setPhone(e.target.value)
            }}
            helperText={phoneError ? "กรุณากรอกเบอร์โทรศัพท์ให้ถูกต้อง" : "" }

          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            บันทึกการแก้ไข
          </Button>
        
        </form>
      </div>
    
    </Container>
  );
}

export default UpdateUser;