import apiService from "../api/apiService";
import { useState,useEffect } from "react";
import liff from "@line/liff/dist/lib";
// import liffIdConfig from '../constants/liffIdConfig.json' 
import { useLocation } from "react-router-dom";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import swal from '@sweetalert/with-react';
import { useHistory, useParams } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import Box from "@material-ui/core/Box";
 

function CustomerLinkToLineGruoup(){
    const service = apiService();
    const history = useHistory();
    const [customerID,setCustomerID]=useState("");
    
    const location = useLocation();
    const [loading,setLoading]=useState(true);
    const [error,setError]=useState(false);
   
    
    const params = new URLSearchParams(location.search);
    const lineGroupID = params.get("lineGroupID")

    
    useEffect(()=>{
        setLoading(false);
    },[])

    

    const linkCustomer = ()=>{
        if(customerID){
            setError(false);
            const data = { 'customerID': Number(customerID), 'lineGroupID': lineGroupID }

        service.post({url:'/api/v1/customer/link-line-group-id',params:data}).then(
            (res)=>{
                setCustomerID("");
                console.log(res.data.result)
                if(location.search.split("=")[1]==='true')
                {
                    swal({
                        title: "การเชื่อม customerID สำเร็จแล้ว",
                        text: "customerID ถูกเชื่อมต่อกับระบบเรียบร้อยแล้ว",
                        icon: "success",
                        buttons: ["ย้อนกลับไปตั้งค่า", "ออกจากการตั้งค่า"],
                    }).then((value) => {
                        if (value) {
                             console.log("ออกจากการตั้งค่า");
                          
                                liff.closeWindow()
                           
                            
                        } else {
                            history.back()
                        }
                    })
                }
                else
                {
                    setCustomerID("");

                    swal({
                        title: "การเชื่อม customerID สำเร็จแล้ว",
                        text: "customerID ถูกเชื่อมต่อกับระบบเรียบร้อยแล้ว",
                        icon: "success",
                        className: "swal-CustomerLinkToLineGroup",
                    }).then((value) => {
                        if (value) {
                             console.log("ออกจากการตั้งค่า");
                           
                                liff.closeWindow()
                           
                        } 
                    })
                }
                
            }
        ).catch(
            (err)=>{
                setCustomerID("");

                console.log(err)
                swal({
                    title: 'เกิดข้อผิดพลาด',
                    content: <p>{err.message}</p>,
                    icon: 'error',
                    closeOnClickOutside: false,
                    buttons: {
                      cancel: "ปิด",
                    },
                  })
            }
        )
        }else{
            setError(true)
        }
    }



        // const data = {'customerID':customerID,'lineGroupID':gid}
        // service.post({url:'/api/v1/customer/link-line-group-id',params:data})

       
 

    return loading ? (<Box sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
      }}>
        <CircularProgress />
      </Box>) : (
        <div style={{
            minHeight:'100vh',
            backgroundColor:'#f5f5f5',
        }}>
            <div className="pt-5 text-center pb-5">
                <h5>กรุณากรอก customerID</h5>
            </div>
            <div className="pt-1 text-center pb-1">
              
                <TextField  
                error={error}
                label="customerID" 
                type="number"
                        inputMode="numeric"
                sx={{
                    width:'250px',
                }}
                variant="outlined"
                className="input-customerID"
                onChange={(e)=>{setCustomerID(e.target.value)}}
                value={customerID}
                />
           
            </div>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '10px'
            }}>
                <Button
                sx={{
                    width: '130px',
                }}
                 variant="contained" onClick={()=>{linkCustomer()}}>ถูกต้อง</Button>
            </div>
        </div>
        )
            }

export default CustomerLinkToLineGruoup