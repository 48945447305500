import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import Box from '@mui/material/Box';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { getCustomerList, addStudent, getUserList } from './api';
import Autocomplete from '@mui/material/Autocomplete';
import swal from '@sweetalert/with-react';
// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function AddMentor() {
  const [customerList, setCustomerList] = React.useState([]);
  const [customerID, setCustomerID] = React.useState('');
  const [mentorID, setMentorID] = React.useState('');
  const [userList, setUserList] = React.useState([]);
    React.useEffect(() => {
       

        Promise.all([getCustomerList(), getUserList()]).then((res) => {
            setCustomerList(res[0])
            setUserList(res[1])
        })
    }, []);



  const handleSubmit = (event) => {
    event.preventDefault();
    const data = { 'studentID': customerID, 'mentorID': mentorID }

    if(!customerID){
      swal({ title: "กรุณาเลือกนักเรียน",
         text: "Please select student", icon: "error", button: "close" });
      return
    } else if(!mentorID){
      swal({ title: "กรุณาเลือกเมนเตอร์",
         text: "Please select mentor", icon: "error", button: "close" });
      return
    } else {
      console.log(data);
      
      addStudent(data).then((res) => {
        if (res){
            swal({
              title: "เพิ่มข้อมูลสำเร็จ",
              text: "Data added successfully",
              icon: "success",
              button: "close",
            });
          }
        }
      )
    }
    
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <AddIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
             Add Mentor
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate  sx={{ mt: 1, width: '100%' }}>
            <Autocomplete
              disablePortal
              id="student"
              fullWidth
              options={userList.map((option) => {
                return {
                  value: option?.userID || '',
                  label: option?.invoiceName || '',
                }
              }).filter((option) => option.label && option.value)}
              onChange={(event, newValue) => {
                setCustomerID(newValue?.value || '')
              }}
              renderInput={(params) => <TextField {...params} label="student" fullWidth required margin="normal" id="student" />}
            />

           

            <Autocomplete
              disablePortal
              id="mentor"
              options={customerList.map((option) => {
                return {
                  value: option?.customerID || '',
                  label: option?.invoiceName || '',
                }
              }).filter((option) => option.label && option.value)}
              onChange={(event, newValue) => {
                setMentorID(newValue?.value || '')
              }}
              renderInput={(params) => <TextField {...params} label="mentor" fullWidth required margin="normal"  id="mentor" />}
            />

           
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Add Mentor
            </Button>
         
          </Box>
        </Box>
  
      </Container>
    </ThemeProvider>
  );
}