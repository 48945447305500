import styled from "styled-components";

const Counter = styled.div`
  background-color: #e1ffeb;
  padding-bottom: 3rem;
  padding-top: 1.5rem;
  width: 100%;
  min-height: 100vh;

  .title {
    font-size: 24px;
    font-weight: bold;
    border-bottom: 2px solid #1a1a1a;

    text-align: center;
    color: #1a1a1a;
    margin: 0 auto;
    height: 45px;
    margin-bottom: 2rem;

    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;
const Main = styled.div`
  position: relative;
  width: 500px;
  margin: 0 auto;
  padding-left: 3rem;
  padding-right: 3rem;
  @media (max-width: 768px) {
    width: 100%;
  }
  div{
        letter-spacing: 1.5px;
  }
`;

const MainTable = styled.div`
  width: 500px;
  margin: 0 auto;
  @media (max-width: 768px) {
    padding-left: 1rem;
    padding-right: 1rem;
    width: 100%;
  }
`;

const Th = styled.th`
width: 70%;
font-size: 1rem;
background-color: #86d1a4 !important;
padding: 0.75rem !important;
font-weight: 600 !important;
`

const Td = styled.td`
width: 30%;
font-size: 1rem;
padding: 0.75rem !important;
`

const Button = styled.button`
  position: fixed;
  right: 15px;
  top: 80px;
  background-color: #5ed2a2;
  color: #fff;
  border: none;
  padding: 0.5rem 0.5rem;
  border-radius: 0.5rem;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
    svg {
      font-size: 2rem;

    }
  &:hover {
    background-color: #4db38d;
  }
  &:active {
    background-color: #4db38d;
  }
`;

export { Counter, Main, MainTable, Th, Td, Button };