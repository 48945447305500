import React from 'react'
 
function NoProductList() {
  return (
    <div className='d-flex justify-content-center  align-items-center 'style={{
      paddingTop: "34px",
  
      flexDirection: "column",
      maxHeight:"60vh",
    }}> 
      {/* <i className="lni lni-cart" style={{
        fontSize: "100px",
        marginBottom: "27px",
        color: "#e6e6e6"
      }}></i> */}
      ไม่พบรายการสินค้า
      {/* <Link to="/" style={{
        textDecoration: "none",

        fontWeight: "bold",
        marginTop: "12px",
        
      }}>
         กลับสู่หน้ารายการสั่งซื้อ
      </Link> */}
    </div>
  )
}

export default NoProductList