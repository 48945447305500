import React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useHistory } from 'react-router-dom';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgb(218, 24, 132)",
    color: theme.palette.common.white,
    border: '1px solid rgba(224, 224, 224, 1)',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    border: '1px solid rgba(224, 224, 224, 1)',
    
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: '1px solid rgba(224, 224, 224, 1)',
  },

  // hover
  '&:hover': {
    backgroundColor: "rgb(218, 24, 132, 0.1)",
  },
}));

 

export default function CustomizedTables({ data, loading }) {
  const history = useHistory()
  return (
    <TableContainer component={Paper} style={{ marginTop: '20px' }}>
      <Table  aria-label="customized table">
        <TableHead sx={{overflowX: 'auto'}}>
          <TableRow>
            <StyledTableCell>
          
              <Typography noWrap sx={{ fontSize: "0.875rem" }}>
                ชื่อลูกศิษย์
              </Typography>
            </StyledTableCell>
            <StyledTableCell align="right">
              <Typography noWrap sx={{ fontSize: "0.875rem" }}>
                เดือนนี้
                </Typography>
            </StyledTableCell>
            <StyledTableCell align="right">
              
              <Typography noWrap sx={{ fontSize: "0.875rem" }}>
                เดือนที่แล้ว
              </Typography>
              </StyledTableCell>
            <StyledTableCell align="right">
              <Typography noWrap sx={{ fontSize: "0.875rem" }}>
                สองเดือนที่แล้ว
              </Typography>
            </StyledTableCell>
          
          </TableRow>
        </TableHead>
        <TableBody>
          {data.length > 0 &&
           data.map((item, index) => {
            return (
              <StyledTableRow key={index} sx={{
                cursor: 'pointer',
              }}
                onClick={() => {
                  history.push(`/StudentWeeklySales?customerID=${item.customerID}`)
                }}
              >
                <StyledTableCell component="th" scope="row" >

                  <div className='MentorMonthlySales-StyledTableCell'>
                    <Typography noWrap sx={{ fontSize: "0.875rem" }}>
                      {item.ชื่อลูกศิษย์}
                    </Typography>
                  </div>
                </StyledTableCell>
                <StyledTableCell align="right">
                  <Typography noWrap sx={{ fontSize: "0.875rem" }}>
                    {item.เดือนนี้}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="right">
                  <Typography noWrap sx={{ fontSize: "0.875rem" }}>
                    {item.เดือนที่แล้ว}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="right">
                  <Typography noWrap sx={{ fontSize: "0.875rem" }}>
                    {item.สองเดือนที่แล้ว}
                  </Typography>
                </StyledTableCell>

              </StyledTableRow>
            )
           }) }
        </TableBody>
        {data.length === 0 && <TableBody> <TableRow><StyledTableCell colSpan={4} align="center">
          <Typography noWrap sx={{  marginTop: '20px', marginBottom: '20px' }}>
            {
              loading ? 'กำลังโหลดข้อมูล...' : 'ไม่พบข้อมูล'
            }
          </Typography>
          </StyledTableCell></TableRow></TableBody>}
      </Table>
    </TableContainer>
  );
}
