import React, { useEffect, useState, useRef } from "react";
import liff from "@line/liff/dist/lib";


import swal from '@sweetalert/with-react';
import CloudinaryUploadWidget from "../../components/Button/CloudinaryUploadWidget";
import apiService from "../../api/apiService";
function CheckoutChange() {
  const [product, setProduct] = useState([]);
  const [priceLevelID, setPriceLevelID] = useState(0);
  const [levelList, setLevelList] = useState([]);
  const [shipperID, setShipperID] = useState(0);
  
  const [PostShippingDate, setPostShippingDate] = useState("");
  const setOrderDetails = useRef([]);
  const [isMain, setIsMain] = useState(false);
  const [invoice_codeLoader, setInvoice_codeLoader] = useState(false);
  const [invoice_code, setInvoice_code] = useState("");

  const [customerName, setCustomerName] = useState("");
  const [ShipperName, setShipperName] = useState("ค้นหาข้อมูล...");
 
  const [orderDetailRecords, setOrderDetailRecords] = useState([]);
  const [_shippingDate, _setShippingDate] = useState("");
  const [NextLoader, setNextLoader] = useState(false);
  const [shipperAll, setShipperAll] = useState([]);
  const [orderID, setOrderID] = useState(0);
  const [dataImage, setDataImage] = useState([]);
  const service=apiService()

  useEffect(() => {
    setPriceLevelID(7);
    service.get({
            url:`/api/v1/selling-price-level/list`
        }).then((res) => {
      setLevelList(res.data.result);
    });
    service.get({url:"/api/v1/product/list?sample=false"}).then((res) => {
      //เรียงตาม productSequence 
      setProduct(res.data.result.sort((a, b) => a.productSequence - b.productSequence));
    });
    service.get({url:"/api/v1/shipper/all"}).then((res) => {
      console.log(res.data.result);
      setShipperAll(res.data.result);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  

  const test = (productID, priceLevelID, price) => {
    let ans = "?";
    for (let i = 0; i < price.length; i++) {
      if (
        price[i]["productID"] === productID &&
        price[i]["priceLevelID"] === priceLevelID
      ) {
        ans = price[i]["price"];
      }

    }

    return ans;
  };

  const dis = (id) => {
    const input = document.getElementById(`in-num${id}`);
    const num = input.value;
    if (num > 0) {
      const setNum = input.value = num - 1;
      if (setNum > 0) {
        if (setOrderDetails.current.length > 0) {
          let checks = true;
          for (let i = 0; i < setOrderDetails.current.length; i++) {
            if (setOrderDetails.current[i]["productID"] === id) {
              setOrderDetails.current[i]["quantity"] = setNum;
              checks = false;
            }
          }
          if (checks) {
            setOrderDetails.current.push({
              productID: id,
              quantity: setNum,
            });
          }
        }

      } else {
        setOrderDetails.current = setOrderDetails.current.filter(
          (item) => item.productID !== id
        );

      }

    } else {
      input.value = 0;
    }
  };

  const plus = (id) => {
    const input = document.getElementById(`in-num${id}`);
    const num = parseInt(input.value);
 
    const max = parseInt(orderDetailRecords.filter((e) => e.productID === id)[0].receivedQuantity);
    const setNum = (input.value = num + 1 > max ? max : num + 1);
    // max 10 
    


    if (setNum > 0) {
      if (setOrderDetails.current.length > 0) {
        let checks = true;
        for (let i = 0; i < setOrderDetails.current.length; i++) {
          if (setOrderDetails.current[i]["productID"] === id) {
            setOrderDetails.current[i]["quantity"] = setNum;
            checks = false;
          }
        }
        if (checks) {
          setOrderDetails.current.push({
            productID: id,
            quantity: setNum,

          });
        }
      } else {
        setOrderDetails.current.push({
          productID: id,
          quantity: setNum,

        });
      }
    }
  };


 
  
  const postApi = () => {
    console.log(dataImage.length === 0 ? "no image" : dataImage);
    // / วันสังของ วันที่ส่ง ชื่อสินค้า ราคารวม

    if (setOrderDetails.current.length > 0 && dataImage.length > 0) {
      const tomorrow = () => {
        const d = new Date();
        d.setDate(d.getDate() + 1);
        return d.toISOString().split("T")[0];
      };
    
      const data = {
        orderID: Number(orderID),
        shipperID: Number(shipperID),
        //shippingDate = tomorrow
        shippingDate: tomorrow(),
        orderDetail: setOrderDetails.current.map(item => {
          return {
            productID: item.productID,
            quantity: item.quantity,
            // returnPictureURL: dataImage.find(e => e.id === item.productID).image,
            returnPictureURL: dataImage.filter(e => e.id === item.productID)[0].image,

          }
        })
      }

      console.log(data);

      // กําลังส่งข้อมูล
      swal({ text: "กำลังส่งข้อมูล...", icon: "info", button: false });

      service.post({url:'/api/v1/order/checkout-for-change',params:data}).then((res) => {
       
          console.log(res.data.result);
          setOrderDetails.current = [];
          swal({
            title: "สำเร็จ",
            text: "เปลี่ยนสินค้าสำเร็จ",
            icon: "success",
            button: "ตกลง",
          }).then(() => {
          
              liff.closeWindow()
            
          });
         
        
      }).catch((err) => { 
        
        swal.close();
        swal({
          title: "Error",
          text: `${err.message}`,
          icon: "error",
          button: "ตกลง",
        })
        console.log(err)
      
      });


    } else {

      if (setOrderDetails.current.length === 0) {
        swal("ผิดพลาด", "กรุณาเลือกสินค้า", "error");
      }

     
      if (dataImage.length === 0){
        swal("ผิดพลาด", "กรุณาเลือกรูปภาพ", "error");
      }



    }
  };



  const Next = () => {
    
   

    if (invoice_code !== "") {
     
        setInvoice_codeLoader(true);
        setNextLoader(true);
      

      service.get({
            url:`api/v1/order?orderID=${invoice_code}`
        }).then((res) => {
          console.log(res.data.result);
            
        
            setOrderID(res.data.result.orderID);
            setInvoice_codeLoader(false);
            setNextLoader(false);
            document.getElementById("root").style.backgroundColor = "rgb(255, 228, 225)";
            const _shipperID = res.data.result.shipperID;
            const _customer = res.data.result.customer;
            _setShippingDate(res.data.result.shippedDate === null || res.data.result.shippedDate === "" ? "ไม่พบข้อมูล" : res.data.result.shippedDate);
            console.log(res.data.result.shippedDate);
            setShipperID(res.data.result.shipperID);
            setIsMain(true);
            setPostShippingDate(res.data.result.shippedDate);
      
            setOrderDetailRecords(res.data.result.orderDetailRecords);

            if (_customer !== null && _customer !== undefined && _customer !== "") {
              setCustomerName(_customer);
            }else{
              setCustomerName("ไม่พบข้อมูล");
            }


            if (_shipperID !== null && _shipperID !== "" && _shipperID !== undefined) {
              service.get({
                  url:`/api/v1/shipper/all`
                 }).then((res) => {
                const Shipper = res.data.result.filter((item) => item.userID === _shipperID);
                setShipperName(...Shipper.map((item) => item.employeeNickname));
              
              });
            } else {
              setShipperName("ไม่พบข้อมูล");
            }
           
          
        }).catch((err) => {
          console.log(err);
          
          swal("ผิดพลาด", err.message, "error").then(() => {
              window.location.reload();
          });
        });
        
    } else if (invoice_code === "") {
      swal("ผิดพลาด", "กรุณากรอกรหัสใบส่งของ", "error");
      setInvoice_codeLoader(false);
      setNextLoader(false);
    } 
  }


  const btnSet = () => {
    document.getElementById("root").style.backgroundColor = "#fff";

    setIsMain(false);
    setOrderDetails.current = [];
    setOrderDetailRecords([]);
  }

  const setImage = (e) => {
    const image = e.map((item) => item.image).toString();
    const id = Number(...e.map((item) => item.id))
    if(dataImage.length === 0){
      setDataImage([...dataImage, { image, id }]);
    }else{
      const newData = dataImage.filter((item) => item.id !== id);
      setDataImage([...newData, { image, id }]);
    }
  }


  const DateFormat = (date) => {
    // thailand date format
      const d = new Date(date);
      const month = String(d.getMonth() + 1);
      const day = String(d.getDate());
      const year = String(d.getFullYear() + 543);
      return `${day}/${month}/${year}`;

  }

  return (
    <div style={{
      minHeight: "100vh",

    }}>


      <div className={isMain ? "justify-content-center flex-wrap d-flex fade-in" : "d-none"}
        style={{ paddingBottom: "50px", paddingTop: "0px", }}
      >
        <div id="setNavBarShipper" style={{
          height: "10rem",
        }}>
          <p>
            รหัสใบส่งของ: {invoice_code}
          </p>
          <p>ชื่อลูกค้า: {customerName}</p>
          <p>
            วันที่ส่งสินค้า: {DateFormat(_shippingDate) === "NaN/NaN/NaN" ? "ไม่พบข้อมูล" : DateFormat(_shippingDate)}
          </p>
          <p>ผู้ส่งสินค้า: {shipperID === null ? "null" : shipperAll.filter((item) => item.userID === shipperID).map((item) => item.employeeNickname) === "" ? "ไม่พบข้อมูล" : shipperAll.filter((item) => item.userID === shipperID).map((item) => item.employeeNickname)}</p> 
          
        
        </div>
        { orderDetailRecords.length > 0 ? orderDetailRecords.map((item, index) => {
   
  return (
    <div
      className="d-flex bring align-items-center"
      key={index}
      style={{
        background: "#fff",
      }}
    >
      <div className="flex-shrink-0 position-relative">
        <img
          src={product.filter((e) => e.productID === item.productID)[0]?.imageURL}
          alt={product.filter((e) => e.productID === item.productID)[0]?.productNameFull}
          className="img-fluid rounded "
          id={`image${item.productID}`}
           
        />
      
        <CloudinaryUploadWidget id={item.productID} setImage={setImage}/>
      </div> 
      <div className="flex-grow-1 ms-2">
        <p
          style={{
            fontSize: "20px",
            color: "#081828",
          }}
        >
          {product.filter((e) => e.productID === item.productID)[0]?.productNameFull}
        </p>
        <p>
          ฿{test(item.productID, priceLevelID, levelList)} บาท
        </p>
        <div className="num-block skin-7">
          <div className="num-in">
            <span
              className="minus dis"
              onClick={() => {
                dis(item.productID);
              }}
            >
              -
            </span>
            <input
              type="number"
              inputMode="numeric"
              className="in-num"
              onClick={(e) => { e.target.select() }}
              id={`in-num${item.productID}`}
              value={0}
              readOnly
            />
            <span
              className="plus"
              onClick={() => plus(item.productID)}
            >
              +
            </span>
          </div>
        </div>
      </div>
    </div>
  );
 
}):  <div style={{
       minHeight: "50vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",

}}> <p>
ไม่พบข้อมูล
</p> </div> }   

        

        <div id="content-active" >
          <button onClick={btnSet} id="back">ย้อนกลับ</button>
          <button onClick={postApi} id="ok">ยืนยัน</button>

        </div>

      </div>

      <div id="nav-bring" className={isMain ? "d-none" : "fade-in"}>

        <div className="bg-white shadow" style={{
          padding: "23px 0px",
        }}>
          <div id="deliveryDate" style={{
            flexDirection:"column",
            padding: "13px 60px "
          }}>
            <h1>เบิก-เปลี่ยนสินค้า</h1>
            <input
            className="mb-1"
            style={{
              width: "260px",
            }}
              type="number"
              inputMode="numeric"
              id="invoice_code"
              placeholder="รหัสใบส่งของ"
              value={invoice_code}
              onChange={(e) => setInvoice_code(e.target.value)}
              disabled={invoice_codeLoader ? true : false}
            />
          
         
          </div>

          <div className=" d-flex justify-content-center mt-2">
            <button
              id="next"
              className={NextLoader === false ? "btn color-fff f-18 bg-f07" : "btn disabled  f-18"}
              onClick={Next}
            >
              ถัดไป{" "}
              {NextLoader ? (
                <div
                  className="spinner-border text-dark"
                  role="status"
                  style={{
                    width: "1rem",
                    height: "1rem",
                    marginLeft: "10px",
                  }}
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              ) : null}
            </button>

            

          </div>
        </div>
      </div>


    </div>
  );
}

export default CheckoutChange;
