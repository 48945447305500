import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import TimePickerNewListItemOrder from './timePickerNewListItemOrder';
import SearchableTextField from './searchableTextField';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { Button } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import IconButton from '@mui/material/IconButton';
import { uploadImageToCloudinary } from './cloudinary';
import CircularProgress from '@mui/material/CircularProgress';
import { addCustomer, updateCustomer, updateCustomerDetails, addCustomerDetails } from './api';
import swal from '@sweetalert/with-react';
import LaunchIcon from '@mui/icons-material/Launch';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import moment from 'moment';
import 'moment/locale/th';
import Typography from '@mui/material/Typography';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LoadingButton from '@mui/lab/LoadingButton';
import FormHelperText from '@mui/material/FormHelperText';
import Avatar from '@mui/material/Avatar';
import GroupAddIcon from '@mui/icons-material/GroupAdd';


const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});


const defaultTheme = createTheme();

function getCookie(name) {
  var value = "; " + document.cookie;
  var parts = value.split("; " + name + "=");
  if (parts.length === 2) return parts.pop().split(";").shift();
}

function Form({ openCustomer, setOpenCustomer, employeeList, getData, saleID, setOpen, setSaleID }) {

  const [customerID, setCustomerID] = React.useState(null);
  const [customerRegisterDate, setCustomerRegisterDate] = React.useState(null);
  const [invoiceName, setInvoiceName] = React.useState(null);
  const [phone, setPhone] = React.useState(null);
  const [errorPhone, setErrorPhone] = React.useState(null);

  const [googleMapURL, setGoogleMapURL] = React.useState(null);
  const [shopOpeningTime, setShopOpeningTime] = React.useState(null);
  const [shopCloseTime, setShopCloseTime] = React.useState(null);
  const [lineGroupURL, setLineGroupURL] = React.useState("");

  const [adminValue, setAdminValue] = React.useState(null);
  const [adminError, setAdminError] = React.useState(null);
  const adminOptions = employeeList?.map((item) => {
    return { id: item?.userID, value: item?.employeeNickname };
  }) || [];


  const [image, setImage] = React.useState(null);
  const [loadingImage, setLoadingImage] = React.useState(false);


  const [invoiceNameError, setInvoiceNameError] = React.useState(null);
  const [googleMapURLError, setGoogleMapURLError] = React.useState(null);
  const [shopOpeningTimeError, setShopOpeningTimeError] = React.useState(null);
  const [shopCloseTimeError, setShopCloseTimeError] = React.useState(null);

  const [weekDayOpen, setWeekDayOpen] = React.useState(null);
  const [errorWeekDayOpen, setErrorWeekDayOpen] = React.useState(null);
  const [weekDayClose, setWeekDayClose] = React.useState(null);
  const [errorWeekDayClose, setErrorWeekDayClose] = React.useState(null);
  
  const [road, setRoad] = React.useState(null);
  const [roadError, setRoadError] = React.useState(null);
  const [customerShopGeoLocation, setCustomerShopGeoLocation] = React.useState(null);
  const [customerShopGeoLocationError, setCustomerShopGeoLocationError] = React.useState(null);
  const [errorImage, setErrorImage] = React.useState(null);

  const token = getCookie("token")
 const expireDate = "3153600000000" // 100 years


 const query = saleID && customerID && image ? `?saleID=${saleID}&customerID=${customerID}&image=${image}` : 
 saleID && customerID && !image ? `?saleID=${saleID}&customerID=${customerID}` :
 saleID && !customerID && image ? `?saleID=${saleID}&image=${image}` :
 saleID && !customerID && !image ? `?saleID=${saleID}` :
 !saleID && customerID && image ? `?customerID=${customerID}&image=${image}` :
 !saleID && customerID && !image ? `?customerID=${customerID}` :
 !saleID && !customerID && image ? `?image=${image}` : null;




  React.useEffect(() => {
    console.log("openCustomer", openCustomer);


    if (openCustomer !== 'add' && openCustomer) {
      setCustomerID(openCustomer?.customerID)
      setCustomerRegisterDate(openCustomer?.customerRegisterDate)
      setInvoiceName(openCustomer?.invoiceName)
      setPhone(openCustomer?.phone)
      setGoogleMapURL(openCustomer?.googleMapURL)
      setShopOpeningTime(openCustomer?.shopOpeningTime ? moment(openCustomer?.shopOpeningTime).format('HH:mm') : null)
      setShopCloseTime(openCustomer?.shopCloseTime ? moment(openCustomer?.shopCloseTime).format('HH:mm') : null)
      setAdminValue({
        id: openCustomer?.adminID,
        value: employeeList?.find((item) => item?.userID === openCustomer?.adminID)?.employeeNickname
      })


      setLineGroupURL(openCustomer?.lineGroupURL)
      setImage(openCustomer?.pictureURL)
      setWeekDayOpen(openCustomer?.weekDayOpen)
      setWeekDayClose(openCustomer?.weekDayClose)
      setRoad(openCustomer?.road)
    }

  }, [openCustomer]);





  const dayWeekMapping = {
    1: "วันอาทิตย์",
    2: "วันจันทร์",
    3: "วันอังคาร",
    4: "วันพุธ",
    5: "วันพฤหัสบดี",
    6: "วันศุกร์",
    7: "วันเสาร์",
  };




  const handleSubmit = ({
    status,
  }) => {
    if (!invoiceName) {
      setInvoiceNameError('กรุณากรอกชื่อร้านและที่ตั้งของลูกค้า');
    }

    if (!adminValue) {
      setAdminError('กรุณากรอกผู้หาลูกค้า');
    }

    if (!shopOpeningTime) {
      setShopOpeningTimeError('กรุณากรอกเวลาเปิดร้าน');
    }

    if (!shopCloseTime) {
      setShopCloseTimeError('กรุณากรอกเวลาปิดร้าน');
    }

    if (!road) {
      setRoadError('กรุณากรอกถนน');
    }

    if (!customerShopGeoLocation) {
      setCustomerShopGeoLocationError('เพิ่มตำแหน่งที่ตั้งร้าน');
    }

    if(!phone){
      setErrorPhone('กรุณากรอกเบอร์โทรศัพท์')
    }

    if (!image) {
      setErrorImage('กรุณาอัพโหลดรูปหน้าร้าน')
    }

    if (!weekDayOpen) {
      setErrorWeekDayOpen('กรุณาเลือกเปิดร้านขาย - จากวัน')
    }

    if (!weekDayClose) {
      setErrorWeekDayClose('กรุณาเลือกเปิดร้านขาย - ถึงวัน')
    }

    


    if (!invoiceName || !adminValue || !shopOpeningTime || !shopCloseTime || !road || !customerShopGeoLocation) {
      return;
    } else {
      const customer = {
        pictureURL: image,
        invoiceName: invoiceName,
        phone: phone,
        customerShopGeoLocation: customerShopGeoLocation?.lat + "," + customerShopGeoLocation?.lng,
        adminID: adminValue?.id,
        lineGroupURL: lineGroupURL,
        saleID: saleID,
        ...status === 'add' && { customerStatus: 'NEW_LEAD' },
        road: road,
      }

      console.log("customer", customer);
      setOpen(true)
      if (status === 'add') {
        addCustomer(customer).then((res) => {
          const params = {
            "customerID": res?.customerID,
            "weekDayOpen": weekDayOpen,
            "weekDayClose": weekDayClose,
            "shopOpeningTime": shopOpeningTime,
            "shopCloseTime": shopCloseTime,
            "googleMapUrlReceiving": customerShopGeoLocation?.lat + "," + customerShopGeoLocation?.lng,
            "shopPicture": image,
            "orderingPhoneNumber": phone,
            "orderingLineLink": lineGroupURL,

          }
          console.log("params", params);
          if (res) {
            addCustomerDetails(params).then((res) => {


              // message.success('เพิ่มลูกค้าสำเร็จ', 3);
              swal({
                title: "Success",
                text: "เพิ่มลูกค้าสำเร็จ",
                icon: "success",
                button: "ตกลง",
                closeOnClickOutside: false,
              }).then((value) => {
                if (value) {
                  setOpen(true)  
        
                  window.location.href = `https://arunee1admin.azurewebsites.net/jottingSalesReport?customerID=${res?.customerID}&saleID=${saleID}&image=${image}&expire_in=${expireDate}&token=${token}&customerShopGeoLocation=${customerShopGeoLocation?.lat},${customerShopGeoLocation?.lng}`
                }
              })
              setOpen(false)
            })

          } else {
            setOpen(false)
          }
        })
      } else if (status === 'update') {
        updateCustomer({
          ...customer,
          customerID: customerID,
        }).then((res) => {
          if (res) {
            const params = {
              "customerID": customerID,
              "weekDayOpen": weekDayOpen,
              "weekDayClose": weekDayClose,
              "shopOpeningTime": shopOpeningTime,
              "shopCloseTime": shopCloseTime,
              "googleMapUrlReceiving": googleMapURL?.lat + "," + googleMapURL?.lng,
              "shopPicture": image,
              "orderingPhoneNumber": phone,
              "orderingLineLink": lineGroupURL,
            }
            updateCustomerDetails(params).then((res) => {
              getData({ saleID: saleID })
              setOpenCustomer(null)
              setOpen(false)

              swal({
                title: "Success",
                text: "อัพเดทลูกค้าสำเร็จ",
                icon: "success",
                button: "ตกลง",
                //บังคับให้กดปุ่ม หรือ กด close เท่านั้นเพื่อปิด
                closeOnClickOutside: false,
              }).then((value) => {
                if (value) {
                  setOpen(true)
                  window.location.reload();
                }
              })
            })
          } else {
            setOpen(false)
          }
        })
      }

    }
  };



  const showError = (error) => {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        setCustomerShopGeoLocationError("ผู้ใช้ปฏิเสธคำขอสำหรับตำแหน่งที่ตั้ง.");

        break;
      case error.POSITION_UNAVAILABLE:
        setCustomerShopGeoLocationError("ข้อมูลตำแหน่งไม่สามารถให้ได้.");

        break;
      case error.TIMEOUT:
        setCustomerShopGeoLocationError("คำขอเพื่อรับตำแหน่งของผู้ใช้หมดเวลา.");
        break;
      case error.UNKNOWN_ERROR:
        setCustomerShopGeoLocationError("เกิดข้อผิดพลาดที่ไม่รู้จัก.");
        break;
    }
  };

  function getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          console.log("position", position);
          setCustomerShopGeoLocationError(null);
          setCustomerShopGeoLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });

        },
        showError,
        {
          enableHighAccuracy: true,
        }
      );
    } else {
      setCustomerShopGeoLocationError("เบราว์เซอร์นี้ไม่รองรับการระบุตำแหน่ง.");
    }
  }









  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs" sx={{ mt: 2, mb: 10, }}>
        <CssBaseline />
        <Box
          sx={{

            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <GroupAddIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            เพิ่มลูกค้าใหม่
          </Typography>

          <Box component="form" noValidate sx={{ mt: 2 }}>
            <Grid container spacing={2}>


              <Grid item xs={12}>


                <FormControl variant="outlined" fullWidth focused={Boolean(image)} error={errorImage ? true : false} >

                  <InputLabel htmlFor="outlined-adornment-image"
                  >รูปหน้าร้าน</InputLabel>
                  {/* // ถ้ามีข้อมูลให้เอาข้อมูลมาใส่ใน value ให้ Label ลอยไป อยู่บน */}
                  <OutlinedInput
                    required
                    fullWidth
                    name="image"
                    value={image}

                    disabled={loadingImage}
                    onChange={(e) =>{
                      setImage(e.target.value)
                      setErrorImage(null)
                    }}
                    sx={{ backgroundColor: "#dbe9ff", }}
                    label="รูปหน้าร้าน"
                    placeholder="รูปหน้าร้าน"
                    id="image"
                    helperText={errorImage}
                    autoComplete="off"
                    endAdornment={
                      <InputAdornment position="end">

                        {
                          loadingImage ? <Box sx={{ display: 'flex' }}> <CircularProgress size={25} /> </Box> : <IconButton
                            aria-label="toggle password visibility"
                            edge="end"
                            size="large"
                          >
                            <label htmlFor="icon-button-file">
                              <VisuallyHiddenInput
                                accept="image/*"
                                id="icon-button-file"
                                type="file"
                                onChange={(e) => {
                                  if (e?.target?.files[0]) {
                                    setLoadingImage(true)
                                    uploadImageToCloudinary({ file: e.target.files[0], folder: 'customer' }).then((res) => {
                                      console.log("res", res?.secure_url);
                                      setImage(res?.secure_url)
                                      setLoadingImage(false)
                                      setErrorImage(null)
                                    })
                                  }

                                }}
                              />
                              <CloudUploadIcon sx={{ fontSize: '2rem', color: '#1976d2' }} />
                            </label>
                          </IconButton>
                        }


                      </InputAdornment>
                    }

                  />
                  <FormHelperText 
                    error={errorImage ? true : false} 
                    sx={{m: 0}}
                  > {errorImage} </FormHelperText>
                  <img src={image} style={{ width: '100%', height: 'auto' }} />
                  
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required

                  autoComplete="off"
                  fullWidth
                  name="invoiceName"
                  label="ชื่อร้านและที่ตั้งของลูกค้า"
                  id="invoiceName"
                  sx={{ backgroundColor: "#dbe9ff", }}

                  value={invoiceName}
                  onChange={(e) => {
                    setInvoiceName(e.target.value)
                    setInvoiceNameError(null)
                  }}
                  error={invoiceNameError ? true : false}
                  
                />
                <FormHelperText error={invoiceNameError ? true : false}> {invoiceNameError} </FormHelperText>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  value={phone}
                  onChange={(e) => {
                    setPhone(e.target.value)
                    setErrorPhone(null)
                  }}
                  fullWidth

                  autoComplete="off"
                  name="phone"
                  label="เบอร์โทรศัพท์"
                  id="phone"
                  sx={{ backgroundColor: "#dbe9ff", }}

                  type="number"
                  inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                  error={errorPhone ? true : false}
                  
                />
                <FormHelperText error={errorPhone ? true : false}> {errorPhone} </FormHelperText>
              </Grid>

              <Grid item xs={12}>

                {/* <Button variant="contained" color="primary" fullWidth size='large' sx={{ textTransform: 'none', backgroundColor: "#1976d2", color: '#fff' }}>
                    
                </Button> */}
                <LoadingButton
                  fullWidth
                  size="large"
                  loadingPosition="start"
                  onClick={getLocation}
                  variant="outlined"
                  color={customerShopGeoLocationError ? "error" : "secondary"}
                  startIcon={<LocationOnIcon sx={{ fontSize: "2rem", color: customerShopGeoLocationError ? "error" : "secondary" }} />}
                  sx={{
                    pl: 0,
                    pr: 0,
                  }}
                >


                  {
                    customerShopGeoLocation ? (
                      <Typography variant="h7" component="p" noWrap>
                        {
                          `${customerShopGeoLocation?.lat}, ${customerShopGeoLocation?.lng}`
                        }
                      </Typography>
                    ) : <Typography variant="h7" component="p" sx={{ fontSize: "1rem", color: customerShopGeoLocationError ? "error" : "secondary", textTransform: 'none' }}>
                      ที่ตั้งร้าน
                    </Typography>

                  }
                </LoadingButton>
                <FormHelperText error={customerShopGeoLocationError ? true : false}>
                  {customerShopGeoLocationError ? customerShopGeoLocationError : null}
                </FormHelperText>
              </Grid>


              <Grid item xs={12}>
                <TextField
                  value={road}
                  onChange={(e) => {
                    setRoad(e.target.value)
                    setRoadError(null)
                  }}
                  fullWidth
                  name="road"
                  label="ถนน"

                  autoComplete="off"
                  id="road"
                  sx={{ backgroundColor: "#dbe9ff", }}

                  type="text"
                  error={roadError ? true : false}
                  
                />
                <FormHelperText error={roadError ? true : false}> {roadError} </FormHelperText>
              </Grid>



              <Grid item xs={12} md={6}>
                <FormControl fullWidth focused={Boolean(weekDayOpen)}>
                  <InputLabel id="weekDayOpen">
                    เปิดร้านขาย - จากวัน
                  </InputLabel>
                  <Select
                    labelId="weekDayOpen"
                    id="weekDayOpen-select"
                    value={weekDayOpen}

                    autoComplete="off"
                    label="เปิดร้านขาย - จากวัน"
                    sx={{ backgroundColor: "#dbe9ff", }}

                    onChange={(e) => {
                      setWeekDayOpen(e.target.value)
                      setErrorWeekDayOpen(null)
                    }}
                  >
                    {
                      dayWeekMapping && Object.keys(dayWeekMapping).map((item) => {
                        return <MenuItem value={item}>{dayWeekMapping[item]}</MenuItem>
                      })
                    }

                  </Select>
                </FormControl>
                   <FormHelperText error={errorWeekDayOpen ? true : false}> {errorWeekDayOpen} </FormHelperText>
              </Grid>


              <Grid item xs={12} md={6}>
                <FormControl fullWidth focused={Boolean(weekDayClose)}>
                  <InputLabel id="weekDayClose">
                    เปิดร้านขาย - ถึงวัน
                  </InputLabel>
                  <Select
                    labelId="weekDayClose"
                    id="weekDayClose-select"
                    value={weekDayClose}

                    autoComplete="off"
                    label="เปิดร้านขาย - ถึงวัน"
                    sx={{ backgroundColor: "#dbe9ff", }}

                    onChange={(e) => {
                      setWeekDayClose(e.target.value)
                      setErrorWeekDayClose(null)
                    }}
                  >
                    {
                      dayWeekMapping && Object.keys(dayWeekMapping).map((item) => {
                        return <MenuItem value={item}>{dayWeekMapping[item]}</MenuItem>
                      })
                    }

                  </Select>
                </FormControl>
                <FormHelperText error={errorWeekDayClose ? true : false}> {errorWeekDayClose} </FormHelperText>
              </Grid>



              <Grid item xs={12} sx={{ width: '100%' }}>
                <TimePickerNewListItemOrder label="เวลาเปิดร้าน" time={shopOpeningTime} setTime={setShopOpeningTime} error={shopOpeningTimeError} setError={setShopOpeningTimeError} name="shopOpeningTime" />
              </Grid>

              <Grid item xs={12}>
                <TimePickerNewListItemOrder label="เวลาปิดร้าน" time={shopCloseTime} setTime={setShopCloseTime} error={shopCloseTimeError} setError={setShopCloseTimeError} name="shopCloseTime" />
              </Grid>

              <Grid item xs={12}>
                <SearchableTextField label="ผู้หาลูกค้า (admin)" searchableOptions={adminOptions} value={adminValue} setValue={setAdminValue} error={adminError} setError={setAdminError} setSaleID={setSaleID} type="admin" />
              </Grid>




              <Grid item xs={12}>
                <FormControl variant="outlined" fullWidth focused={Boolean(lineGroupURL)}>
                  <InputLabel htmlFor="outlined-adornment-image"
                  >
                    lineGroupURL
                  </InputLabel>
                  {/* // ถ้ามีข้อมูลให้เอาข้อมูลมาใส่ใน value ให้ Label ลอยไป อยู่บน */}
                  <OutlinedInput
                    required
                    fullWidth
                    name="lineGroupURL"
                    value={lineGroupURL}

                    autoComplete="off"
                    onChange={(e) =>
                      setLineGroupURL(e.target.value)
                    }

                    label="lineGroupURL"
                    placeholder="lineGroupURL"
                    id="lineGroupURL"

                    endAdornment={
                      <InputAdornment position="end">
                        {/* // open new tab */}
                        <IconButton
                          aria-label="toggle password visibility"
                          edge="end"
                          size="large"
                          disabled={!lineGroupURL}
                          onClick={() => {
                            if (lineGroupURL) {
                              // ไปเปิดหน้าใหม่
                              window.open(lineGroupURL, '_blank');
                            }
                          }}

                        >
                          <LaunchIcon sx={{ fontSize: '2rem', color: !lineGroupURL ? '#9e9e9e' : '#1976d2' }} />
                        </IconButton>
                      </InputAdornment>
                    }

                  />


                </FormControl>

                <Button variant="contained" color="primary" fullWidth size='large' sx={{ textTransform: 'none', backgroundColor: "#1976d2", color: '#fff', mt: 3 }} onClick={() => handleSubmit({ status: 'add' })}>
                  บันทึกสร้างลูกค้า และเขียนรายงาน
                </Button>
                

              </Grid>

            </Grid>


          </Box>
        </Box>

      </Container>
    </ThemeProvider>
  )

}

export default Form;