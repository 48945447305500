import React from "react";
import { Router, Switch, Route } from "react-router-dom";
import history from "../history";
import Error_pages from "../pages/ErrorPages";
import ShopDeta from "../pages/Shop/ShopDeta";
import Baskets from "../pages/Shop/Baskets";
import Product from "../pages/Shop/Product";
import CheckoutForSell from "../pages/checkout-for-sell";

import CheckoutForChange from "../pages/Checkout/checkout-for-change";
import Register1 from "../pages/Register/register1";
import RegisterAuto from "../pages/Register/registerAuto";
import Login from "../pages/Login/login";

import Orders from "../pages/Orders";
import OrdersDetails from "../pages/Orders/OrdersDetails";
// import CancelOrder from "../pages/CancelOrder";

import EditOrder from "../pages/Shop/OrderEdit/EditOrder";

import OrderAgain from "../pages/CancelOrder/OrderAgain";
import EditCustomer from "../pages/Edit/customer";
import EditDate from "../pages/Edit/date";
import JobType from "../pages/Edit/job/JobType";
import JobTypeList from "../pages/Edit/job/JobTypeList";
import CustomerLinkToLineGruoup from "../pages/CustomerLinkToLineGroup";
import UrlLinkToLineGroup from "../pages/UrlLinkToLineGroup";
import Status from "../pages/status";
import Pre_checkout from "../pages/pre_checkout";
import OrderAgainShipper from "../pages/CancelOrder/OrderAgain/shipper";
import paymentAmount from "../pages/paymentAmount";
import notEnoughMoney from "../pages/paymentAmount/notEnoughMoney";
import customerNotYetOrder from "../pages/customerNotYetOrder";
import statementOfAccount from "../pages/statementOfAccount";
import dueOrNotDue from "../pages/dueOrNotDue";
import wantToPay from "../pages/statementOfAccount/wantToPay";
import wantToPayNotEnough from "../pages/statementOfAccount/wantToPayNotEnough";
import clearMoney from "../pages/clearMoney";
import orderDetailSlip from "../pages/orderDetailSlip";
import paymentAmountShipper from "../pages/Orders/paymentAmountShipper";
import ShipperUploadNotSlip from "../pages/Orders/paymentAmountShipper/ShipperUploadNotSlip";
import ShipperNotEnoughMoney from "../pages/Orders/paymentAmountShipper/ShipperNotEnoughMoney";
import clearMoneyDetail from "../pages/clearMoneyDetail";
import cashTransfer from "../pages/clearMoneyDetail/cashTransfer";
import checkInvoice from "../pages/checkInvoice";
import OrderAgainUser from "../pages/CancelOrder/OrderAgain/";
import checkPayInCash from "../pages/checkPayInCash";
import cashTransferManualThisPayment from "../pages/cashTransferManualThisPayment";
import cashTransferManualPreviousPayment from "../pages/cashTransferManualPreviousPayment";
import cashTransferPreviousPayment from "../pages/cashTransferPreviousPayment";
import stockRequest from "../pages/Request/stockRequest";
import stockRequest2 from "../pages/Request/stockRequest2";
import statusCorrection from "../pages/statusCorrection";
import logout from "../pages/logout";
import ClearLocalStorage from "../pages/clearLocalStorage";
import CheckoutForSellOrder from "../pages/checkout-for-sell/order";
import CheckoutForGift from "../pages/checkout-for-gift";
import CheckoutForGiftOrder from "../pages/checkout-for-gift/order";
import CheckoutSample from "../pages/checkout-for-sample";
import CheckoutSampleOrder from "../pages/checkout-for-sample/order";
import CostAruneeNoodles from "../pages/costAruneeNoodles";
import TestQrCode from "../pages/paymentAmount/testQrCode";
import CustomerRouteName from "../pages/customerRouteName";
import CreateCustomer from "../pages/createCustomer";
import Test from "../pages/test";
import Test2 from "../pages/test/index";
import CreateNewCustomer from "../pages/createNewCustomer";
 import RecordGeolocation from "../pages/recordGeolocation";
import FollowUpAdmin from "../pages/followUpAdmin";
import FollowupSale from "../pages/followupSale";
import RegisterAuto2 from "../pages/Register/registerAuto2";
import RegisterAutoSuccess from "../pages/Register/registerAutoSuccess";
import UpdateUser from "../pages/UpdateUser";
import MentorMonthlySales from "../pages/MentorMonthlySales";
import StudentWeeklySales from "../pages/StudentWeeklySales";
import AddMentor from "../pages/addMentor";
function Routes() {
  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/" component={ShopDeta} />
        <Route exact path="/Baskets" component={Baskets} />
        <Route exact path="/Product/:id" component={Product} />
        <Route exact path="/checkout-for-sell" component={CheckoutForSell} />
        <Route exact path="/checkout-for-sell/order/:shippingDate/:shipperID/:customerID" component={CheckoutForSellOrder} />
        <Route exact path="/checkout-for-gift" component={CheckoutForGift} />
        <Route exact path="/checkout-for-gift/order/:shippingDate/:shipperID/:customerID" component={CheckoutForGiftOrder} />
        <Route exact path="/checkout-sample" component={CheckoutSample} />
        <Route exact path="/checkout-sample/order/:shippingDate" component={CheckoutSampleOrder} />
        <Route exact path="/checkout-for-change" component={CheckoutForChange} />
        <Route exact path="/register1" component={Register1} />
        <Route exact path="/registerAuto" component={RegisterAuto} />
        <Route exact path="/registerAuto2" component={RegisterAuto2} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/Orders" component={Orders} />
        <Route exact path="/Orders/:employeeID" component={OrdersDetails} />
        <Route exact path="/shipperPaymentAmount/:orderID" component={paymentAmountShipper} />
        <Route exact path="/ShipperUploadNotSlip/:orderID" component={ShipperUploadNotSlip} />
        <Route exact path="/ShipperNotEnoughMoney/:orderID" component={ShipperNotEnoughMoney} />
        <Route exact path="/OrderAgain/shipper" component={OrderAgainShipper} />
        <Route exact path="/OrderAgain/user" component={OrderAgainUser} />
        <Route exact path="/OrderAgain" component={OrderAgain} />
        <Route exact path="/CancelOrder" component={EditOrder} />
        <Route exact path="/EditCustomer" component={EditCustomer} />
        <Route exact path="/EditDate" component={EditDate} />
        <Route exact path="/EditJobType" component={JobType} />
        <Route exact path="/EditJobType/JobTypeList" component={JobTypeList} />
        <Route exact path="/CusLinkGID" component={CustomerLinkToLineGruoup} />
        <Route exact path="/UrlLinkGID" component={UrlLinkToLineGroup} />
        <Route exact path="/Status" component={Status} />
        <Route exact path="/statusCorrection/" component={statusCorrection} />
        <Route exact path="/Pre_checkout" component={Pre_checkout} />
        <Route exact path="/paymentAmount" component={paymentAmount} />
        <Route exact path="/paymentAmount/notEnoughMoney" component={notEnoughMoney} />
        <Route exact path="/customerNotYetOrder" component={customerNotYetOrder} />
        <Route exact path="/statementOfAccount" component={statementOfAccount} />
        <Route exact path="/dueOrNotDue" component={dueOrNotDue} />
        <Route exact path="/statementOfAccount/wantToPay" component={wantToPay} />
        <Route exact path="/statementOfAccount/wantToPayNotEnough" component={wantToPayNotEnough} />
        <Route exact path="/clearMoney" component={clearMoney} />
        <Route exact path="/orderDetailSlip/:customerID/:orderID" component={orderDetailSlip} />
        <Route exact path="/clearMoneyDetail/:shipperID/:date" component={clearMoneyDetail} />
        <Route exact path="/cashTransferThisPayment/:shipperID/:date" component={cashTransfer} />
        <Route exact path="/checkInvoice/:shipperID/:date" component={checkInvoice} />
        <Route exact path="/checkPayInCash/:shipperID/:date" component={checkPayInCash} />
        <Route exact path="/cashTransferManualThisPayment/:shipperID" component={cashTransferManualThisPayment} />
        <Route exact path="/cashTransferManualPreviousPayment" component={cashTransferManualPreviousPayment} />
        <Route exact path="/cashTransferPreviousPayment/:shipperID/:date" component={cashTransferPreviousPayment} />
        <Route exact path="/stockRequest" component={stockRequest} />
        <Route exact path="/stockRequest2/:stockRequestID" component={stockRequest2} />
        <Route exact path="/logout" component={logout} />
        <Route exact path="/clearLocalStorage" component={ClearLocalStorage} />
        <Route exact path="/costAruneeNoodles" component={CostAruneeNoodles} />
        <Route exact path="/testQrCode" component={TestQrCode} />
        <Route exact path="/customerRouteName" component={CustomerRouteName} />
        <Route exact path="/createCustomer" component={CreateCustomer} />
        <Route exact path="/createNewCustomer" component={CreateNewCustomer} />
        <Route exact path="/test" component={Test} />
        <Route exact path="/test2" component={Test2} />
        <Route exact path="/recordGeolocation" component={RecordGeolocation} />
        <Route exact path="/followUpAdmin" component={FollowUpAdmin} />
        <Route exact path="/followupSale" component={FollowupSale} />
        <Route exact path="/registerAutoSuccess" component={RegisterAutoSuccess} />
        <Route exact path="/UpdateUser" component={UpdateUser} />
        <Route exact path="/MentorMonthlySales" component={MentorMonthlySales} />
        <Route exact path="/StudentWeeklySales" component={StudentWeeklySales} />
        <Route exact path="/addMentor" component={AddMentor} />
        <Route component={Error_pages} />
       
      </Switch>
    </Router>
  );
}

export default Routes;
