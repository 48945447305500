import React from "react";
import styled from "styled-components";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useHistory } from "react-router-dom";


const W500 = styled.div`
  padding: 1rem;
  margin: 0 auto;
  margin-top: 1rem;
  width: 500px;
  @media (max-width: 768px) {
    width: 100%;
  }
 
  .detail{
    font-size: 16px;
    font-weight: 600;
    width: 500px;
    margin: 0 auto;
    @media (max-width: 768px) {
      width: 100%;
    }
  }

`;






function Heading() {
  const history = useHistory();



  return (
    <>

      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" sx={{
          backgroundColor: '#ff00a6',
        }}>
          <Toolbar variant="dense">
            <IconButton edge="start" color="inherit" aria-label="menu" onClick={() => {
              history.push('/clearMoney')
            }}>
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" component="div" sx={{ ml: 2 }}>
              เคลียร์ยอด due=null และ overdue
            </Typography>
          </Toolbar>
        </AppBar>
      </Box>

   





    </>
  );
}

export default Heading;
