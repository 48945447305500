import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import thLocale from 'date-fns/locale/th';
import dayjs from 'dayjs';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useHistory } from "react-router-dom";
import StorefrontIcon from '@mui/icons-material/Storefront';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const defaultTheme = createTheme();


export default function Checkout() {

  const [date, setDate] = React.useState(dayjs().add(1, 'day'));
  const [open, setOpen] = React.useState(false);
  const [errMessage, setErrMessage] = React.useState('');
  const history = useHistory();
 

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

 
  const checkForm = () => {
      setOpen(false);

      if (!date) {
        setOpen(true);
        setErrMessage('ข้อมูลไม่ครบถ้วน กรุณาเลือกวันที่')
        return false
      }

      if(date) {
         history.push(`/checkout-sample/order/${date.format('YYYY-MM-DD')}`)
      }
  }
 
 

  return (
    <ThemeProvider theme={defaultTheme}>
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            {errMessage}
        </Alert>
      </Snackbar>
        {
         <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'success.main' }}>
              <StorefrontIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
                  เซลล์เบิกตัวอย่างสินค้า
            </Typography>
            <Box component="form"  noValidate sx={{ mt: 1, width: "100%" }}>

            <FormControl fullWidth required  margin="normal" >
            <LocalizationProvider dateAdapter={AdapterDayjs} locale={thLocale} >
              <DemoContainer components={['DatePicker']}>
                 <DatePicker
                  sx={{ width: "100%" }}
                  label="วันที่ส่งของ" 
                  format="DD MMM YYYY"
                  minDate={dayjs().subtract(-1, 'day')}
                  defaultValue={date || null}
                  onChange={(newValue) => {
                    setDate(newValue);
                  }}
                  
                />

               </DemoContainer>
              </LocalizationProvider>
              </FormControl>

    
              
              <Button
                type="button"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={checkForm}
                color="success"
              >
                 เลือกสินค้า
              </Button>
              
            </Box>
          </Box>
          
        </Container>
        }
    </ThemeProvider>
  );
}