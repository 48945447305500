import React, { useEffect } from 'react'
import './styles/App.css';
import { setCookie } from "./components/cookie";
import Routes from "./routes";
import { setLocalStorage } from "./components/localStorage";

function App() {

  const getLineGroupID = async () => {
    const url = window.location.href;
    
    const setUrl1 = url.split("?liff.state=%3FgroupID%3D")[1];
    const setUrl2 = url.split("?liff.state=%2FgroupID%3D")[1];
    const setUrl3 = url.split("?liff.state=%2F%3FgroupID%3D")[1];
    const setUrl = setUrl1 ? setUrl1 : setUrl2 ? setUrl2 : setUrl3 ? setUrl3 : null;

    const endUrl = setUrl?.split("#")[0] 
    console.log("endUrl", endUrl);
    return endUrl;
  };

    const urlParams = new URLSearchParams(window.location.search);
    const expire_in = urlParams.get("expire_in");
    const token = urlParams.get("token");
 
    if (expire_in && token) {
      setCookie(token, expire_in);
    }

    getLineGroupID().then((e) => {
      
      if (e) {
        setLocalStorage("lineGroupID", e);
      }
    })

  
  return <Routes />  
}

export default App;
