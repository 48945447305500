import { useState,useEffect } from "react"
import apiService from "../api/apiService"
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import PersonIcon from '@mui/icons-material/Person';
import Typography from '@mui/material/Typography';

import liff from "@line/liff/dist/lib"
import CircularProgress from '@mui/material/CircularProgress';
import { useHistory } from "react-router-dom";


function Status(){
    const service = apiService()
    const [userName,setUserName] = useState()
    const [userID,setUserID] = useState()
    const [jobType,setJobType] = useState()  
    const [phone, setPhone] = useState()
    const [loading, setLoading] = useState(true)
    const history = useHistory()

    useEffect(()=>{
        service.get({url:'/api/v1/user/'}).then((res)=>{
            setLoading(false)
            console.log(res.data.result)
            setUserName(res.data.result.employeeNickname)
            setUserID(res.data.result.userID)
            setJobType(res.data.result.employeeJobType)
            setPhone(res.data.result.phone)
        }).catch((err)=>{
            setLoading(false)
            console.log(err)
        })
        



    },[])



    return(
    <div>
   

<Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        backgroundColor: '#f5f5f5',
      }}
    >
      <CssBaseline />
      <Container component="main" sx={{ mt: 8, mb: 2 }} maxWidth="sm">
         
    {
                        loading ? <div style={{
                            textAlign: 'center',
                        }}><CircularProgress /> </div>: <Box
                            className="shadow-sm"
                            sx={{
                                padding: '30px',
                                borderRadius: '10px',
                                backgroundColor: '#fff',
                                border: '1px solid #ffff',
                                paddingBottom: '0px',
                            }}>
                                
                            <Avatar 
                            sizes="large"
                            sx={{
                                m: 1,
                                bgcolor: 'secondary.main',
                                margin: '0 auto',
                                marginBottom: '15px',
                                backgroundColor: '#0167f3',
                            }}>
                                <PersonIcon />
                            </Avatar>


                            <Typography variant="body1" gutterBottom sx={{ fontWeight: '500', }} >
                                ยินดีต้อนรับค่ะ
                            </Typography>
                            <Typography variant="body1" gutterBottom sx={{ fontWeight: '500', }} >
                                คุณ: <span style={{ fontWeight: '300' }}>{userName}</span>
                            </Typography>

                            <Typography variant="body1" gutterBottom sx={{ fontWeight: '500', }} >
                                ขณะนี้ userID ของคุณคือ: <span style={{ fontWeight: '300' }}>{userID}</span>
                            </Typography>

                            <Typography variant="body1" gutterBottom sx={{ fontWeight: '500', }} >
                                เบอร์โทรศัพท์ของคุณคือ: <span style={{ fontWeight: '300' }}>{phone}</span>
                            </Typography>

                            <Typography variant="body1" gutterBottom sx={{ fontWeight: '500', }} >
                                สถานะของคุณคือ: <span style={{ fontWeight: '300' }}>{jobType}</span>
                            </Typography>
                                <Box sx={{

                                    textAlign: 'center',
                                    marginTop: '30px',
                                    marginBottom: '20px',
                                }}>
                                    {/* <Button
                                        onClick={() => { history.push(`/statusCorrection`) }}
                                        variant="contained"
                                    >
                                        ต้องการเพิ่ม/แก้ไขข้อมูล
                                    </Button> */}



                                    {/* ปุ่ม "เริ่มต้นใช้งาน" จะกดได้ก็ต่อเมื่อ
                                    if fullName != null && phone != null ? ไม่โชว์ปุ่มเลย : โชว์ปุ่ม */}

                                    {
                                        userName != null && phone != null ? null : <Box sx={{

                                            textAlign: 'center',
                                            marginTop: '10px',
                                           
                                        }}>
                                            <Button
                                                onClick={() => { liff.closeWindow() }}
                                                variant="contained"
                                            >
                                                เริ่มต้นใช้งาน
                                            </Button>


                                        </Box>
                                    }
                                    
                                 
                                </Box>

                        </Box>
    }


        
      </Container>
      
    </Box>
    </div>
    )
}
export default Status