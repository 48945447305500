import{useEffect,useState} from "react"
import apiService from "../../api/apiService"
import swal from '@sweetalert/with-react';
import liff from "@line/liff/dist/lib";
// import liffIdConfig from '../../constants/liffIdConfig.json' 
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import DoneIcon from '@mui/icons-material/Done';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { getPriceLevelList, postAddCustomer, getEmployeeList } from "./api";
 

const theme = createTheme();

function CreateCustomer() {
    const [invoiceName, setInvoiceName] = useState("")
    const [priceLevelID, setPriceLevelID] = useState("")
    const [priceLevelList, setPriceLevelList] = useState([])
    const [paymentType, setPaymentType] = useState("")
    const [invoiceType, setInvoiceType] = useState("")
    const [employeeList, setEmployeeList] = useState([])
    const [userID, setUserID] = useState("")
    const [error, setError] = useState({
        invoiceName: false,
        priceLevelID: false,
        paymentType: false,
        invoiceType: false,
        userID: false,
    })



    useEffect(() => {
      
        Promise.all([getPriceLevelList(), getEmployeeList()]).then((res) => {
          setPriceLevelList(res[0])
          setEmployeeList(res[1].filter((item) => item.employeeJobType === "Salesperson"))
        })


    }, [])


const addCustomer = () => {
    let isError = false
    let errorObj = {
        invoiceName: false,
        priceLevelID: false,
        paymentType: false,
        invoiceType: false,
        userID: false,
    }

    if (invoiceName === "") {
        isError = true
        errorObj.invoiceName = true
    }
    if (priceLevelID === "") {
        isError = true
        errorObj.priceLevelID = true
    }
    if (paymentType === "") {
        isError = true
        errorObj.paymentType = true
    }
    if (invoiceType === "") {
        isError = true
        errorObj.invoiceType = true
    }
    if (userID === "") {
        isError = true
        errorObj.userID = true
    }

    if (isError) {
        setError(errorObj)
        swal({
            title: "กรุณากรอกข้อมูลให้ครบถ้วน",
            text: "กรุณากรอกข้อมูลให้ครบถ้วน",
            icon: "warning",
            button: "close",
        });
        return
    }

    const data = {
        invoiceName: invoiceName,
        priceLevelID: priceLevelID,
        paymentType: paymentType,
        invoiceType: invoiceType,
        saleID: Number(userID),
    }

    const reset = () =>{
        setInvoiceName("")
        setPriceLevelID("")
        setPaymentType("")
        setInvoiceType("")
        setUserID("")
        setError({
            invoiceName: false,
            priceLevelID: false,
            paymentType: false,
            invoiceType: false,
            userID: false,
        })
    }

    postAddCustomer(data).then((res) => {
        swal({
            title: "สร้างลูกค้าสำเร็จ",
            text: "สร้างลูกค้าสำเร็จ",
            icon: "success",
            button: "close",
            closeOnClickOutside: false,
        }).then(() => {
          liff.closeWindow() 
        })
        reset()
    })
}



  return (
    <ThemeProvider theme={theme}>
    <Container component="main" maxWidth="xs" fullWidth>
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar 
        className="shadow-sm"
        sx={{ 
            m: 1, 
            bgcolor: 'secondary.main',
            marginBottom: 2,

             }}>
          <AddCircleOutlineOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h6">
        กรุณากรอกข้อมูล เบื้องต้นของลูกค้าให้ครบ
        </Typography>
        <Box component="form" noValidate  sx={{ mt: 3, width: "100%" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                
                autoComplete="invoiceName"
                name="invoiceName"
                fullWidth
                id="invoiceName"
                label="ชื่อในบิลของลูกค้า"
                autoFocus
                onChange={(e) => setInvoiceName(e.target.value)}
                value={invoiceName}
                error={error.invoiceName}
              />
            </Grid>
            <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">priceLevelID</InputLabel>
              <Select
                fullWidth
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={priceLevelID}
                label="priceLevelID"
                onChange={(e) => setPriceLevelID(e.target.value)}
                error={error.priceLevelID}
              >
                {
                   priceLevelList.map((item) => {
                          return (
                            <MenuItem value={item.priceLevelID}>{item.priceSymbol}</MenuItem>
                          ) 
                   })
                }
              </Select>
            </FormControl>
            </Grid>
            <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                เซลล์
              </InputLabel>
              <Select
                fullWidth
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={userID}
                label="เซลล์"
                onChange={(e) => setUserID(e.target.value)}
                error={error.userID}
              >
                {
                   employeeList.map((item) => {
                          return (
                            <MenuItem value={item.userID}>{item.employeeNickname}</MenuItem>
                          ) 
                   })
                }
              </Select>
            </FormControl>
            </Grid>
            
             
            <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">paymentType</InputLabel>
              <Select
                fullWidth
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={paymentType}
                label="paymentType"
                onChange={(e) => setPaymentType(e.target.value)}
                error={error.paymentType}
              >
               <MenuItem value={"transferNow"}>ชำระทันที</MenuItem>
                      <MenuItem value={"COD"}>เก็บเงินปลายทาง</MenuItem>
                      <MenuItem value={"COD_Multiple"}>เก็บเงินปลายทาง หลายบิล</MenuItem>

                      <MenuItem value={"1_invoiceOverdue"}>ค้าง 1 บิล</MenuItem>
                      <MenuItem value={"2_invoiceOverdue"}>ค้าง 2 บิล</MenuItem>
                      <MenuItem value={"3_invoiceOverdue"}>ค้าง 3 บิล</MenuItem>
                      <MenuItem value={"15_Day_Defaultable"}>
                        15 วัน ปรกติ
                      </MenuItem>
                      <MenuItem value={"30_Day_Defaultable"}>
                        30 วัน ปรกติ
                      </MenuItem>
                      <MenuItem value={"15_Day_Non_Defaultable"}>
                        15 วัน ยังสั่งได้
                      </MenuItem>
                      <MenuItem value={"30_Day_Non_Defaultable"}>
                        30 วัน ยังสั่งได้
                      </MenuItem>
                      <MenuItem value={"COD_Employee"}>
                        เก็บเงินปลายทาง-พนักงาน
                      </MenuItem>
              </Select>
            </FormControl>
            </Grid>
          
            <Grid item xs={12}>

            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">invoiceType</InputLabel>
                <Select
                    fullWidth
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={invoiceType}
                    label="invoiceType"
                    onChange={(e) => setInvoiceType(e.target.value)}
                    error={error.invoiceType}
                >
                    <MenuItem value={"invoiceNoVat"}>
                    ใบแจ้งหนี้ no VAT
                    </MenuItem>
                    <MenuItem value={"invoiceShort"}>
                    ใบแจ้งหนี้, ใบกำกับภาษีแบบย่อ
                    </MenuItem>
                    <MenuItem value={"invoiceLong"}>
                    ใบแจ้งหนี้, ใบกำกับภาษีแบบเต็ม
                    </MenuItem>
                </Select>
            </FormControl>

            </Grid>

          </Grid>
          <Button
            type="button"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={() => { addCustomer() }}
          >
            สร้างลูกค้า
          </Button>
           
        </Box>
      </Box>
      
    </Container>
  </ThemeProvider>
  )
}

export default CreateCustomer