import React from "react";

 function ErrorModal({distance, updateGeoLocation, setShowModal, showModal}) {
  
  return showModal ? (<div className="swal-overlay swal-overlay--show-modal" tabIndex={-1}>
  <div className="swal-modal sweet-alert-info-custom" role="dialog" aria-modal="true">
 
    <div className="swal-icon swal-icon--error">
    <div className="swal-icon--error__x-mark">
      <span className="swal-icon--error__line swal-icon--error__line--left"></span>
      <span className="swal-icon--error__line swal-icon--error__line--right"></span>
    </div>
  </div>
    <div className="swal-title" style={{}}>
      {"ตําแหน่ง GPS ไม่ถูกต้อง"}
    </div>
    <div className="swal-text" style={{}}>
      {`ท่านต้องอยู่ในระยะ 280 เมตร จากตำแหน่งร้านลูกค้า และตอนนี้ ท่านอยู่ห่างจากร้านลูกค้าประมาณ ${distance} เมตร`}
    </div>
    <div className="swal-footer">
      <div className="swal-button-container">
        <button className="swal-button swal-button--cancel"
        onClick={() => setShowModal(false)}
        >ปิด</button>
        <div className="swal-button__loader">
          <div />
          <div />
          <div />
        </div>
      </div>
     
    </div>
  </div>

</div>) : null;

}

 


export default ErrorModal;
