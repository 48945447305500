import React, { useEffect, useState } from 'react'
import styled from 'styled-components';

import LoadingButton from '@mui/lab/LoadingButton';
import apiService from "../../api/apiService"
import liff from "@line/liff/dist/lib";
import { useHistory } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import swal from "@sweetalert/with-react";


const Counter = styled.div`
  background-color: #f9ecff;
  padding-bottom: 3rem;
  padding-top: 1.5rem;
  width: 100%;
  min-height: 100vh;
  padding-left: 3rem;
  padding-right: 3rem;
 
  .title {
    font-size: 24px;
    font-weight: 600;
    border-bottom: 2px solid #1a1a1a;
    width: 500px;
    text-align: center;
    color: #1a1a1a;
    margin: 0 auto;
     height: auto;
  margin-bottom: 0.5rem;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;



const List = styled.div`
display: flex;
justify-content: center;
  .list {
    width: 500px;
       border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    margin-top: 1rem;
    .list__title {
      font-size: 16px;
       text-align: center;
       color: #1a1a1a;
       background-color: #fff;
       padding: 0.5rem;
       border-bottom: 2px solid #eee;
    }

    .list__detail {
      display: flex;
      justify-content: space-between;
       padding: 0.5rem;
        background-color: #fff;
      .list__detail__title {
        font-size: 16px;
        color: #1a1a1a;
       
      }
    }

    .list__qty {
      display: flex;
      justify-content: flex-end;
      padding: 0.5rem;
      background-color: #f7a4d8;
      border-radius: 0 0 10px 10px;
      .list__qty__title {
        font-size: 16px;
        color: #1a1a1a;
      }
    }



    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;


const Button = styled.div`
  width: 500px;
  margin: 0 auto;
  margin-top: rem;
  .Button {
      width: 500px;
  margin: 0 auto; 
  margin-top: 1.5rem;
  border-radius: 10px;
  font-size: 18px;
  padding: 0.4rem;
  background-color: #2652f9;
  border: 1px solid #fff;
  font-weight: 600;
  transition: all 0.3s ease;
  color: #fff;
  @media (max-width: 768px) {
    width: 100%;
  }
 
    
    &:active {
      background-color: #0a3cfc;
      color: #fff;
      border: 1px solid #2652f9;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;


function StatementOfAccount() {
  const service = apiService();
  const [result, setResult] = useState([]);
  const [totalAll, setTotalAll] = useState(0);
  const history = useHistory();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
    const getUser = () => {
      return service.get({ url: "/api/v1/user/" }).then((res) => {
        return res.data.result.customerID
      });
    }


    getUser().then((customerID) => {
      service.get({ url: `/api/v1/order/list-by-customer?customerID=${customerID}` }).then((res) => {

        const result = res.data.result.filter((item) => {
          return item.paidDate === null || item.orderStatus !== true ? item : null
        })
        console.log(result)

        setTotalAll(result.reduce((acc, item) => {
          return acc + item.totalPrice
        }, 0))

        setResult(result)

        setLoading(false);
      })
    }).catch((err) => {
      console.log(err)
      swal({
        title: "เกิดข้อผิดพลาด",
        text: "กรุณาลองใหม่อีกครั้ง",
        icon: "error",
        button: "ตกลง",
        className: "swal-text-check-user-payment",
      }).then((value) => {
        liff.closeWindow()
      });

    })




    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const confirm = () => {
    swal({
      title: "ขอบพระคุณค่ะ ขณะนี้ท่านไม่มียอดชำระค้างแล้วค่ะ",
      text: "ขอบคุณที่ใช้บริการ",
      icon: "success",
      button: "ตกลง",
      className: "swal-text-check-user-payment",
    }).then((value) => {
      
        liff.closeWindow()
     
    });

  }

  return (
    <Counter style={{
      paddingRight: "2rem",
      paddingLeft: "2rem",

    }} >
         

      
      {result.length > 0 ? <div>
        <div className='title'>
        ยังไม่ถึงกำหนดชำระเงินนะคะ<br/>
        แต่ถ้าคุณลูกค้าจะ <br/>
        ทะยอยชำระก่อนก็ได้นะคะ
        </div>


        <List >
          <div className='list shadow-sm'>
            <div className='list__title' style={{
              fontSize: '17px',
              textAlign: 'left',
            }}>
               ยอดที่ยังไม่ถึงกำหนดชำระเงินทั้งหมด
            </div>



            <div className='list__qty'>
              <div className='list__qty__title'>

                {totalAll.toLocaleString()} บาท
              </div>
            </div>

          </div>
        </List>



        <div className='title' style={{
          marginTop: '50px',
        }}>
          รายละเอียด
        </div>
        {result.map((item, index) => {
          return (
            <List key={index}>
              <div className='list shadow-sm'>
                <div className='list__title'>
                  {
                    new Date(item.orderDate).toLocaleDateString('th-TH', {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                    })

                  }
                </div>

                <div className='list__detail'>
                  <div className='list__detail__title'>
                    เลขที่ใบสั่งซื้อ
                  </div>
                  <div className='list__detail__title'>
                    {item.orderID}
                  </div>
                </div>

                <div className='list__qty'>
                  <div className='list__qty__title'>
                    {item.totalPrice !== null ? item.totalPrice.toLocaleString() : 0} บาท
                  </div>
                </div>

              </div>
            </List>
          )
        })}

        <Button>
          <LoadingButton
            onClick={() => {
              history.push('/statementOfAccount/wantToPay')
            }}
            size="small"
            variant="contained"
            color='success'
            loadingIndicator="กําลังตรวจสอบ..."
            className='shadow-sm'
            sx={{
              width: '500px',
              fontSize: '16px',
              marginTop: '35px',

              '@media (max-width: 768px)': {
                width: '100%',
              }

            }}
          >
            ต้องการชำระเงินเลย
          </LoadingButton>
          <LoadingButton
            onClick={() => {

             
                liff.closeWindow()
             
            }}
            size="small"
            color='error'
            variant="outlined"
            loadingIndicator="กําลังตรวจสอบ..."
            className='shadow-sm'
            sx={{
              width: '500px',
              fontSize: '16px',
              marginTop: '15px',

              '@media (max-width: 768px)': {
                width: '100%',
              }

            }}
          >
            รับทราบ
          </LoadingButton>
        </Button>
      </div> : <div>


        {loading === false ?
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '90vh',
          }}>
            <div style={{
              textAlign: 'center',
              fontSize: '16px',
            }}>
              ขอบพระคุณค่ะ ขณะนี้ท่านไม่มียอดชำระค้างแล้วค่ะ
              {confirm()}
            </div>
          </div>

          :
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '100vh',

          }}>
            <CircularProgress />
          </div>

        }

      </div>}

    </Counter>
  )
}

export default StatementOfAccount