import proj4 from "proj4";
import swal from "sweetalert";

// export const extectLatLongFromGGUrl = (url) => {
  
//   // https://maps.google.com/maps?q=13.731949,100.716256&entry=gps&g_ep=CAESCTExLjg0LjMwMBgAINeCAyoINDcwNjg2MDlCAlRI&shorturl=1
//   const regex = [/!3d(-?\d+\.\d+)!4d(-?\d+\.\d+)/, /q=(-?\d+\.\d+),(-?\d+\.\d+)/]
//   let latLongMatch = url.match(regex[0]) || url.match(regex[1]);
//   console.log("extectLatLongFromGGUrl",{
//     latLongMatch,
//     url
//   });
//   const lat = latLongMatch[1];
//   const long = latLongMatch[2];
//   return { latitude: +lat, longitude: +long };


// }; 

export const extectLatLongFromGGUrl = async (url) => {
  // https://maps.google.com/maps?q=13.731949,100.716256&entry=gps&g_ep=CAESCTExLjg0LjMwMBgAINeCAyoINDcwNjg2MDlCAlRI&shorturl=1
  if(url){
    const regex = [/!3d(-?\d+\.\d+)!4d(-?\d+\.\d+)/, /q=(-?\d+\.\d+),(-?\d+\.\d+)/]
    let latLongMatch = url.match(regex[0]) || url.match(regex[1]);
    console.log("extectLatLongFromGGUrl",{
      latLongMatch,
      url
    });
    const lat = latLongMatch[1];
    const long = latLongMatch[2];
    return { latitude: +lat, longitude: +long
    };
  } else {
    return null
  }
};



export const convertLatLongToUTM = ({ latitude, longitude }) => {
  // Determine the UTM zone based on the longitude
  const zoneNumber = Math.floor((longitude + 180) / 6) + 1;

  // Construct the UTM projection string with the zone
  const utmProjection =
    "+proj=utm +zone=" + zoneNumber + " +datum=WGS84 +units=m +no_defs";
  proj4.defs("EPSG:326" + zoneNumber, utmProjection);
  // Convert the latitude and longitude to UTM
  const utmCoordinates = proj4("EPSG:4326", "EPSG:326" + zoneNumber, [
    longitude,
    latitude,
  ]);
  return { x: utmCoordinates[0], y: utmCoordinates[1] };
};

export const showError = (error) => {
  switch (error.code) {
    case error.PERMISSION_DENIED:
      // alert("User denied the request for Geolocation.");
        // alert("กรุณาเปิดใช้งาน Location ในเครื่อง");
        // swal({
        //   title: "กรุณาเปิดใช้งาน GPS ในเครื่อง",
        //    className: "sweet-alert-info-custom",
        //   //  closeOnClickOutside: false,

        //   icon: "warning",
        //   buttons: ["ปิด", "ตกลง"],

        // })

      break;
    case error.POSITION_UNAVAILABLE:
      // alert("Location information is unavailable.");
      //alert("ไม่สามารถระบุตำแหน่งได้");
      // swal({
      //   title: "ไม่สามารถระบุตำแหน่ง GPS ได้",
      //   className: "sweet-alert-info-custom",
      //   // closeOnClickOutside: false,

      //   icon: "warning",
      //   buttons: ["ปิด", "ตกลง"],

      // })

      break;
    case error.TIMEOUT:
      // alert("The request to get user location timed out.");
      //alert("ไม่สามารถระบุตำแหน่งได้");
      // swal({
      //   title: "ไม่สามารถระบุตำแหน่ง GPS ได้",
      //   className: "sweet-alert-info-custom",
      //   // closeOnClickOutside: false,
        
      //   icon: "warning",
      //   buttons: ["ปิด", "ตกลง"], 
      // })

      break;
    case error.UNKNOWN_ERROR:
      // alert("An unknown error occurred.");
      //alert("ไม่สามารถระบุตำแหน่งได้");
      // swal({
      //   title: "ไม่สามารถระบุตำแหน่ง GPS ได้",
      //   className: "sweet-alert-info-custom",
      //   // closeOnClickOutside: false,
      //   icon: "warning",
      //   buttons: ["ปิด", "ตกลง"],

      // })
      break;
  }
};
