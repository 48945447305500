import React from 'react'

function Footer() {
  return (
    <div className="container m-auto">
  <footer className="d-flex flex-wrap justify-content-between align-items-center py-4 my-4">
     
  </footer>
</div>

  )
}

export default Footer