import React, { useState, useEffect } from 'react'
import {
  Counter,
  Main
} from './style'
import Table1 from './table_1'
import Table2 from './table_2'
import Paper from '@material-ui/core/Paper';
import apiService from "../../api/apiService";
import CircularProgress from '@mui/material/CircularProgress';


function StockRequest() {
  const service = apiService();
  const [loading, setLoading] = useState(true);
  //ใบเบิกที่ยังไม่ได้เบิก
  const [stockRequestListByShipperID, setStockRequestListByShipperID] = useState([]);
  //ใบเบิกที่เบิกแล้ว
  const [stockRequestListByShipperIDRelease, setStockRequestListByShipperIDRelease] = useState([]);

  async function getUser() {
    return await service.get({ url: `/api/v1/user/` }).then((res) => {
      return res.data.result
    })
  }

  async function getStockRequest(shipperID) {
    return await service.get({ url: `/api/v1/stock-request/list-by-shipper-id?shipperID=${shipperID}` }).then((res) => {
      return res.data.result
    })
  }

  useEffect(() => {

    getUser().then((res) => { 
      getStockRequest(res.userID).then((stockRequestListByShipperID) => {
        setStockRequestListByShipperID(stockRequestListByShipperID.filter((item) => item.stockRequestReleaseDate == null));
        setStockRequestListByShipperIDRelease(stockRequestListByShipperID.filter((item) => item.stockRequestReleaseDate?.split("T")[0] === new Date().toISOString().split("T")[0]).filter((item, index, self) => self.findIndex((t) => t.ingStockRequestID === item.ingStockRequestID) === index));
        setLoading(false);

      })
    })

  }, []);

  const NoData = () => {
    return (
      <div style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        fontSize: "1.5rem",

      }}>
        ไม่มีข้อมูล
      </div>
    )
  }


  const renderLoading = () => {
    return (
      <div style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        fontSize: "1.5rem",
        backgroundColor: "#ffecf8"
      }}>
        <CircularProgress />
      </div>
    )
  }


  const TableAll = () => {
    return (
      <>

        {
          stockRequestListByShipperID.length === 0 && stockRequestListByShipperIDRelease.length === 0 ? <NoData /> : <Main className='shadow-sm'>

            {
              stockRequestListByShipperID.length > 0 ? <Table1 stockRequestListByShipperID={stockRequestListByShipperID} /> : null
            }

            {
              stockRequestListByShipperIDRelease.length > 0 ? <Table2 stockRequestListByShipperIDRelease={stockRequestListByShipperIDRelease} /> : null
            }

          </Main>
        }
      </>
    )
  }

  
  return (
    <Paper style={Counter}>
    

       {
        loading ? renderLoading() : TableAll()
       }
     
    </Paper>
  )
}

export default StockRequest