import React, { useEffect } from 'react'
import mentorCustomerList from './mentorCustomerList.json'
import customerList from './customerList.json'
import product from './product.json'
import currentMonthOrderList from './currentMonthOrderList.json'
import lastMonthOrderList from './lastMonthOrderList.json'
import twoMonthsAgoOrderList from './twoMonthsAgoOrderList.json'

function Test() {

  useEffect(() => {

  
    const filteredMentorCustomerList = mentorCustomerList.filter((item) => item.mentorID === 10698);
    const studentIDList = filteredMentorCustomerList.map((item) => item.studentID);

    const filteredCustomerList = customerList.filter((item) => studentIDList.includes(item.customerID));
    
    const a = filteredCustomerList.map((item) => {
      //This month
      const thisMonth = currentMonthOrderList.filter((order) => order.customerID === item.customerID && order.paidDate !== null && order.orderStatus === null);
      //Last month
      const lastMonth = lastMonthOrderList.filter((order) => order.customerID === item.customerID && order.paidDate !== null && order.orderStatus === null);
      // สองเดือนที่แล้ว
      const twoMonthsAgo = twoMonthsAgoOrderList.filter((order) => order.customerID === item.customerID && order.paidDate !== null && order.orderStatus === null);

      return {
        "ชื่อลูกศิษย์": item.invoiceName,
        "เดือนนี้": thisMonth,
        "เดือนที่แล้ว": lastMonth,
        "สองเดือนที่แล้ว": twoMonthsAgo,
        customerID: item.customerID,
        orderID: [...thisMonth, ...lastMonth, ...twoMonthsAgo].map((order) => order.orderID)
      }
    });
    console.log("studentIDList", studentIDList);
    console.log("filteredCustomerList", filteredCustomerList);
    console.log("a", a);
  }, [])

  
  return (
    <div>Test</div>
  )
}

export default Test