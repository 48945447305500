import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import AddBusinessOutlinedIcon from '@mui/icons-material/AddBusinessOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Loading from '../../components/checkout/loading';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import thLocale from 'date-fns/locale/th';
import dayjs from 'dayjs';
import  { getEmployeeList, getShipperCustomers, getOrderListConditionByShipper, getRouteAllWithShipper } from '../../components/checkout/api';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useHistory } from "react-router-dom";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const defaultTheme = createTheme();
const invoiceCondition = [14, 14.6, 16, 16.6, 18, 18.6, 20, 20.6, 24, 24.6, 33, 38, 39]




export default function Checkout() {

  const [shipperAll, setShipperAll] = React.useState([]);
  const [customerAll, setCustomerAll] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [date, setDate] = React.useState(dayjs().add(1, 'day'));
  const [shipperID, setShipperID] = React.useState('');
  const [customerID, setCustomerID] = React.useState('');
  const [shipperIDErro, setShipperIDErro] = React.useState(false);
  const [customerIDErro, setCustomerIDErro] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [errMessage, setErrMessage] = React.useState('');
  const history = useHistory();

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };


  React.useEffect(() => {
    getRouteAllWithShipper().then((res) => {
      setShipperAll(res)
      setLoading(false)
    })
  }, []);


  React.useEffect(() => {

      if (shipperID && date) {
        getShipperCustomers(shipperID).then((resGetShipperCustomers) => {
          getOrderListConditionByShipper(shipperID, date).then((resGetOrderListConditionByShipper) => {
            const filter_customer = resGetOrderListConditionByShipper.filter((e) => invoiceCondition.includes(e.invoiceCondition)).filter((value, index, self) => self.findIndex((t) => t.customerID === value.customerID) === index)
            const  filter_invoiceCondition = resGetShipperCustomers.filter((e) => !filter_customer.map((e) => e.customerID).includes(e.customerID))
            console.log(filter_invoiceCondition)
            //priceLevelID !== null
            setCustomerAll(filter_invoiceCondition.filter((e) => e.priceLevelID !== null))
          })
        })
      }


  }, [shipperID, date]);



  const checkForm = () => {
      setOpen(false);
      setShipperIDErro(false)
      setCustomerIDErro(false)

      if (!shipperID) {
        setShipperIDErro(true)
        setOpen(true);
        setErrMessage('ข้อมูลไม่ครบถ้วน กรุณาเลือกชื่อผู้ส่ง')
        return false
      }
      if (!customerID) {
        setCustomerIDErro(true)
        setOpen(true);
        setErrMessage('ข้อมูลไม่ครบถ้วน กรุณาเลือกชื่อผู้รับ')
        return false
      }
      if (!date) {
        setOpen(true);
        setErrMessage('ข้อมูลไม่ครบถ้วน กรุณาเลือกวันที่')
        return false
      }


      if(shipperID && customerID && date) {
         history.push(`/checkout-for-sell/order/${date.format('YYYY-MM-DD')}/${shipperID}/${customerID}`)
      }
  }
 
 

  return (
    <ThemeProvider theme={defaultTheme}>
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            {errMessage}
        </Alert>
      </Snackbar>
        {
          loading ? <Loading pages={"checkout-for-sell"}/> : <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <AddBusinessOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              เซลล์เบิกเพื่อขาย
            </Typography>
            <Box component="form"  noValidate sx={{ mt: 1, width: "100%" }}>

            <FormControl fullWidth required  margin="normal" >
            <LocalizationProvider dateAdapter={AdapterDayjs} locale={thLocale} >
              <DemoContainer components={['DatePicker']}>
                 <DatePicker
                  sx={{ width: "100%" }}
                  label="วันที่ส่งของ" 
                  format="DD MMM YYYY"
                  minDate={dayjs().subtract(-1, 'day')}
                  defaultValue={date || null}
                  onChange={(newValue) => {
                    setDate(newValue);
                  }}
                  
                />

               </DemoContainer>
              </LocalizationProvider>
              </FormControl>

             
              <FormControl fullWidth required  margin="normal" error={shipperIDErro}>
                <InputLabel id="shipper-select-label"> เลือก routeName</InputLabel>
                <Select
                  labelId="shipper"
                  id="shipper-select"
                  label="เลือก routeName"
                  name="shipper"
                  defaultValue={shipperID || ''}
                  value={shipperID || ''}
                  onChange={(e) => {
                    setShipperID(e.target.value)
                    setCustomerID('')
                    setCustomerIDErro(false)
                    setShipperIDErro(false)
                  }}
                >
 
                  {shipperAll.map((shipper, index) => ( 
                    <MenuItem key={index} value={shipper.userID}>{shipper.routeName}</MenuItem>
                  ))}

                </Select>
              </FormControl>
  
   
           <FormControl fullWidth required  margin="normal" error={customerIDErro}>
            <InputLabel id="customer-select-label">
            {shipperID ? customerAll.length === 0 ? "ไม่มีลูกค้า" : `มีลูกค้า ${customerAll.length} คน` : "ชื่อลูกค้า"} 
            </InputLabel>
            <Select
              
              labelId="customer"
              id="customer-select"
              label={shipperID ? customerAll.length === 0 ? "ไม่มีลูกค้า" : `มีลูกค้า ${customerAll.length} คน` : "ชื่อลูกค้า"}  
              name="customer"
              disabled={customerAll.length === 0}
              onChange={(e) => {
                setCustomerID(e.target.value)
                setCustomerIDErro(false)
                setShipperIDErro(false)
              }}
               defaultValue={customerID || ''}
                
           
            >
              {customerAll.map((customer, index) => (
                <MenuItem key={index} value={customer.customerID}>{customer.invoiceName}</MenuItem>
              ))}

            </Select>
           </FormControl>
              
              <Button
                type="button"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={checkForm}
                color="secondary"
              >
                 เลือกสินค้า
              </Button>
              
            </Box>
          </Box>
          
        </Container>
        }
    </ThemeProvider>
  );
}