import React, { useEffect } from 'react'
import styled from 'styled-components';
import apiService from "../../api/apiService"
import { useHistory } from 'react-router-dom';
import moment from "moment";
import 'moment/locale/th';

const Counter = styled.div`
  background-color: #f9ecff;
  padding-bottom: 3rem;
  padding-top: 1.5rem;
  width: 100%;
  min-height: 100vh;
  padding-left: 3rem;
  padding-right: 3rem;
`;



function DueOrNotDue() {
  const service = apiService();
  const history = useHistory();
  const today = moment().format("YYYY-MM-DD");
  useEffect(() => {

    const getUser = () => {
      return service.get({ url: "/api/v1/user/" }).then((res) => {
        return res.data.result.customerID
      });
    }


    getUser().then((customerID) => {

      service.get({
        url: `/api/v1/order/list-condition-by-customer?customerID=${customerID}&startShippingDate=${today}&endShippingDate=${today}`
      }).then((res) => {
        res.data.result.map((item,index)=>{
          //console.log(item.orderID,item.invoiceCondition)
          if (item.invoiceCondition <14)
          service.post(
            {
              url: `/api/v1/order/delivery-acceptance`,params:{'orderID':item.orderID}
            }
          ).then((res)=>{//console.log(res.data.result)
            
          })
        })
      }).catch((err) => {
        alert(err.message)
      })
    
      service.get({ url: `/api/v1/order/list-by-customer?customerID=${customerID}` }).then((res) => {

        const result = res.data.result.filter((item) => {
          return item.paidDate === null || item.orderStatus !== true ? item.invoiceStatus !== null ? item : null : null
        })

        result.length === 0 ? history.push("/statementOfAccount") : history.push("/paymentAmount")
     
      }).catch((err) => {
        alert(err.message)
      })

    }).catch((err) => {
      alert(err.message)
    })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  return (
    <Counter>
          
    </Counter>
  )
}

export default DueOrNotDue