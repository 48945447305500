import React, { useState, useEffect } from "react";

import Dialog from "@mui/material/Dialog";
import apiService from "../../api/apiService";
import { useDispatch  } from "react-redux";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Link,  useHistory } from "react-router-dom";
 

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AlertDialog = () => {
  const [open, setOpen] = useState(true);
  const service = apiService();
  const [result, setResult] = useState([]);
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [userID, setUserID] = useState(0);
 
  const dispatch = useDispatch();
  function Reset() {
    dispatch({ type: "RESET_ORDER", payload: [] });
    dispatch({ type: "RESET_ORDER_ACC", payload: 0 });
    dispatch({ type: "RESET_ORDER_RESULT", payload: [] });
    dispatch({
      type: "RESET_ORDER_ID",
      payload: 0
    });
     
  }
 

  useEffect(() => {
       
        service.get({url: `/api/v1/user`}).then((res) => {
          if(res.data.result.userState === "USER_NOT_REGISTERED"){
            history.push("/login");
          }
  
         setLoading(false);
          setUserID(res.data.result.userID);
 
       
         if(res.data.result.employeeJobType === "Shipper") {
          history.push({
            pathname: `/Orders/${res.data.result.userID}`,
            
            
          });
         }
  
  
  
  
      }).catch((err) => {
        console.log(err);
      

      });
      
      service.get({url: `/api/v1/route/all-with-shipper`}).then((res) => {
          setResult(res.data.result);
      }).catch((err) => {
        console.log(err);
       

      });
      



    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    setOpen(false);
  };






  return (
    <div>
      {loading ?  <div>Loading...</div> :  <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        scroll="paper"
        fullScreen
        className="dialog-container-orders"
        sx={{
          padding: "10px",
          paddingBottom: "0px",
          backgroundColor: "#ffddf4",
          
        }}
      >
        <DialogTitle
          sx={{
            padding: "1rem",
            backgroundColor: "#fff",
      
          }}
        >
          {"นี่คือลิงค์เพื่อเข้าสู่หน้าใบส่งของ สายส่งทุกๆคนในวันนี้"}
        </DialogTitle>
        <DialogContent
          sx={{
            padding: "1rem",
            backgroundColor: "#fff",
          }}
        >
          <DialogContentText className="pt-4" sx={{
            fontSize: "20px !important",
          }}>
            กรุณากดที่ชื่อของสายส่ง เพื่อเข้าสู่หน้าใบส่งของ
          </DialogContentText>
          <ul className="mt-2">
            
              {
                result.map((item, index) => {
                  return (
                    <li key={index} className="mb-1" onClick={()=> Reset()} >
                      <Link 
                      style={{
                        fontSize: "20px",
   
                       }}
                      to={{
                        pathname: `/Orders/${item.employeeID}`,
                        
                      }}>- {item.routeName}</Link>
                    </li>
                  );
                }
                )
              }

          </ul>

          <DialogContentText
            sx={{
              marginTop: "2rem",
              fontSize: "20px !important",
            }}
          >
            (ถ้าหากว่าตัวเซลล์เองก็มีใบส่งของ ของตนเองด้วย)
          </DialogContentText>

            <Link
            onClick={()=> Reset()}
            style={{
              fontSize: "20px",
            }}
             to={{
              pathname: `/Orders/${userID}`,
              
            }}  className="mt-2">
            - ตรงนี้จะเป็นลิงค์สู่ใบส่งของ ของตนเอง
          </Link>
        </DialogContent>
      </Dialog>}
     
    </div>
  );
};

export default AlertDialog;
