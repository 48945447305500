import apiService from "../../api/apiService";
import swal from '@sweetalert/with-react';

const service = apiService();


 

//customer/list
export async function getCustomerList() {
  return await service.get({ url: `/api/v1/customer/list` }).then((res) => {
    return res.data.result
  }).catch((err) => {
    console.log(err)
    swal({ title: "Error", text: err?.response?.data?.message || err.message, icon: "error", button: "close" });
  })
}
 

///api/v1/mentor-customer/add-student
export async function addStudent({ studentID, mentorID }) {
  return await service.post({ url: `/api/v1/mentor-customer/add-student`, params: { studentID, mentorID } }).then((res) => {
    return res.data.result
  }).catch((err) => {
    console.log(err.message)
    swal({ title: "Error", text: err?.response?.data?.message || err.message, icon: "error", button: "close" });
  })
}

//user/list
export async function getUserList() {
  return await service.get({ url: `/api/v1/user/list` }).then((res) => {
    return res.data.result
  }).catch((err) => {
    console.log(err)
    swal({ title: "Error", text: err?.response?.data?.message || err.message, icon: "error", button: "close" });
  })
}