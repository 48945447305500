import { useEffect } from "react";
import API from "../../constants/serviceAPI.json";


 
 

function Login() {


    const liff = API.serviceAPI.liff
    const serviceAPI = API.serviceAPI.api;
    const baseUrl = serviceAPI + `/api/v1/auth/line/login?success=${liff}${window.location.pathname.split('/')[1]}`

    useEffect(() => {
        window.location.href = baseUrl;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null

}

export default Login;