import CircularProgress from '@mui/material/CircularProgress';
import AddIcon from '@mui/icons-material/Add';
 


const UploadFile = ({ FileUrl, documentClick, fileChange, FileLoading, Upload, FileImage, UploadFileContent }) => {

 

  const LoadImage = (
    <div style={{
      position: 'absolute',
      top: '0',
      right: '0',
      backgroundColor: '#08182878',
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '0px',
      opacity: '0.8'

    }}>  <div style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "absolute",
      zIndex: "1",
      flexDirection: "column",
    }}>
        <CircularProgress size={35} thickness={5} style={{
          color: "white",
          margin: "0px 10px"

        }} />
        <p style={{
          marginTop: "5px",
          color: "white",
        }}>กำลังตรวจสอบ...</p>
      </div>

    </div>
  )


  
  return (
    <UploadFileContent style={{
      marginButtom: "1.5rem",
    }}>
      {FileUrl === null ? <Upload className='shadow-sm' onClick={documentClick} id="upload">
        <div>
          <AddIcon
            id="AddIcon"
           sx={{
            marginBottom: '0.5rem',
          }} />
          <div className='Upload_title'>
            แนบสลิปโอนเงิน
          </div>
        </div>
    
      </Upload>

        : <FileImage>
          <div>
             
            <img src={FileUrl} alt='UploadImage' className='Upload_image shadow-sm' onClick={documentClick} style={{
              cursor: 'pointer',  
            }}/>
            {
              FileLoading === "loading" ? LoadImage : LoadImage === "success" ? null : null
            }
          </div>
        </FileImage>}
       <input type="file" id="paymentAmountFile" onChange={(e) => fileChange(e)} style={{ display: 'none' }} />
    </UploadFileContent>
  )
}




export default UploadFile