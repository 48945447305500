import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

export default function MediaControlCard({ item, productList, setProductList }) {
  

  const handleClick = (productID, type) => {
    const newProductList = [...productList];
    const index = newProductList.findIndex((item) => { return item.productID === productID })
    const quantity = Number(newProductList[index].quantity)
    if (type === "plus") {
      // [/] ให้สินค้า ปลาเส้นทอด (productID = 18) และปลาเส้นขาว (productID = 19) ให้เพิ่มทีละ 5 หน่วย 
      
      if (productID === 18 || productID === 19) {
        newProductList[index].quantity = quantity + 5
      } else {
        newProductList[index].quantity = quantity + 1
      }
    } else {
      
      if (productID === 18 || productID === 19) {
        newProductList[index].quantity = quantity - 5 < 0 ? 0 : quantity - 5
      } else {
        newProductList[index].quantity = quantity - 1 < 0 ? 0 : quantity - 1
      }
    }
    setProductList(newProductList)

  }


  const handleQuantityChange = (productID, quantity) => {
    const newProductList = [...productList];
    const index = newProductList.findIndex((item) => { return item.productID === productID })
    // ถ้าใส่ค่าว่าง ให้เป็น 0 แทน ถ้าน้อยกว่า 0 ให้เป็น 0 แทน
    newProductList[index].quantity = quantity === "" ? 0 : quantity < 0 ? 0 : quantity
    setProductList(newProductList)

  }



  return (
    <Card sx={{ display: 'flex' }}>
         <CardMedia
        component="img"
        sx={{ width: 130 }}
        image={item?.imageURL}
        alt={item?.productNameShort}
      />
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <CardContent sx={{ flex: '1 0 auto' }} >
          <Typography component="div" variant="h6">
            {item?.productNameFull}
          </Typography>
          <Typography variant="subtitle1" color="text.secondary" component="div">
            ราคา {item?.price ? item?.price.toLocaleString() : 0} บาท
          </Typography>
        </CardContent>
        <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
        <div className="num-block skin-2 d-flex ">
          <div className="num-in" style={{ border: "2px solid rgb(204, 204, 204)", }}>
              <span className="minus dis"  onClick={() => handleClick(item?.productID, "minus")} />
                 <input
                            type="number"
                            className="in-num"
                            value={Number(item?.quantity) || 0}
                            
                            onChange={(e) => { 
                              handleQuantityChange(item?.productID, Number(e.target.value)) 
                            }}
                            onClick={(e) => { e.target.select() }}
                            min="0"
                            inputMode='numeric'
                            style={{
                              height: "35px",
                            }}
                           
                          />
                          <span className="plus" onClick={() => handleClick(item?.productID, "plus")} />
                        </div>
                      </div>
        </Box>
      </Box>
     
    </Card>
  );
}
