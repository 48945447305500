import swal from "@sweetalert/with-react";
import liff from "@line/liff/dist/lib";

// แสดงรายการสินค้าที่สั่งซื้อ
const showCart = (quantityProduct, debtInstallment, shippingDate, invoiceName, handleConfirmOrder, pages, discount) => {
  // ค่าสินค้า
  const sumPrice = quantityProduct.reduce((sum, item) => { return sum + (item.price * item.quantity) }, 0);
     // รวมหนี้ที่ชำระงวดนี้ sumPrice + debtInstallment
   const totalDebtPaidThisInstallment = Number(sumPrice) + Number(debtInstallment || 0) < 0 ? 0 : Number(sumPrice) + Number(debtInstallment || 0);
   const totalCheckForGift = totalDebtPaidThisInstallment - Number(discount || 0)  < 0 ? 0 : totalDebtPaidThisInstallment - Number(discount || 0);
 


  return swal({
    className: "swal-custom-checkout",
    // ไม่ไห้ปิดได้
    closeOnClickOutside: false,
    content: (
      <div>
        <div>
          <p
            style={{
              padding: "10px",
              border: " 1px solid #fff",
              backgroundColor: "#ff32a4",
              color: "#fff",
              borderBottom: "0",
              fontSize: "16px",
              fontWeight: "500",
            }}
          >
            ใบสั่งสินค้า
          </p>

          {pages !== "checkout-sample" && <p
            style={{
              padding: "10px",
              border: "1px solid  #fff",
              backgroundColor: "#f1c7e0",

              fontSize: "16px",
              fontWeight: "500",
              color: " #1A1A1A",
            }}
          >
            ลูกค้า: {invoiceName}
          </p>}

          

          <p
            style={{
              padding: "10px",
              border: "1px solid  #fff",
              backgroundColor: "#f1c7e0",

              fontSize: "16px",
              fontWeight: "500",
              color: " #1A1A1A",
            }}
          >
            วันสั่ง:{" "}
            {new Date().toLocaleDateString("th-TH", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
          </p>

          <p
            style={{
              padding: "10px",
              border: "1px solid  #fff",
              backgroundColor: "#f1c7e0",

              fontSize: "16px",
              fontWeight: "500",
              color: " #1A1A1A",
            }}
          >
            วันที่ส่งสินค้า:{" "}
            {new Date(shippingDate).toLocaleDateString("th-TH", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
          </p>
        </div>
        <div
          style={{
            marginTop: "20px",
            height: "40vh",
            overflow: "auto",
          }}
        >

            {
              quantityProduct.map((item, index) => {
                const total = item?.price * item?.quantity;
                return (
                  <div className="mt-4" key={index}>
                  <div className="d-flex">
                    <div>
                      <img
                        src={
                           item?.imageURL
                        }
                        className="img-fluid"
                        alt={"test"}
                        width={120}
                        height={100}
                        style={{ borderRadius: ".25rem" }}
                      />
                    </div>
                    <div
                      style={{
                        width: "100%",
                        textAlign: "left",
                        marginLeft: "10px",
                        display: "flex",
      
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      <p
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                          color: " #1A1A1A",
                        }}
                      >
                        {item?.productNameFull}
                      </p>
      
                      <p
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                          color: " #1A1A1A",
                        }}
                      >
                        จำนวน : {item?.quantity && item?.quantity.toLocaleString()}
                      </p>
                      <p
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                          color: " #1A1A1A",
                        }}
                      >
                        ราคา : {total && total.toLocaleString() + " บาท"}
                      </p>
                    </div>
                  </div>
                </div>
                )
              })
            }

         
        </div>

        <div className="d-flex mt-3">
          <p
            style={{
              padding: "10px",
              border: " 1px solid  #fff",
              borderRight: "0",
              width: "100%",
              fontSize: "16px",
              fontWeight: "500",
              color: " #1A1A1A",
              backgroundColor: "#f1c7e0",
            }}
          >
            ค่าสินค้า
          </p>
          <p
            style={{
              padding: "10px",
              border: "1px solid  #fff",
              width: "150px",
              textAlign: "right",
              fontSize: "16px",
              fontWeight: "500",
              color: " #1A1A1A",
              backgroundColor: "#f1c7e0",
            }}
          >
            {sumPrice.toLocaleString('en-US', { style: 'currency', currency: 'THB' })}
          </p>
        </div>
           {debtInstallment > 0 && pages !== "checkout-for-gift"  && <div className="d-flex mt-0">
          <p
            style={{
              padding: "10px",
              border: " 1px solid  #fff",
              borderRight: "0",
              width: "100%",
              fontSize: "16px",
              fontWeight: "500",
              color: " #1A1A1A",
              backgroundColor: "#f1c7e0",
            }}
          >
            รวมหนี้ที่ชำระงวดนี้
          </p>
          <p
            style={{
              padding: "10px",
              border: "1px solid  #fff",
              width: "150px",
              textAlign: "right",
              fontSize: "16px",
              fontWeight: "500",
              color: " #1A1A1A",
              backgroundColor: "#f1c7e0",
            }}
          >
            {debtInstallment.toLocaleString('en-US', { style: 'currency', currency: 'THB' })}
          </p>
        </div>}


        {
          pages === "checkout-for-gift" &&  <div className="d-flex mt-0">
          <p
            style={{
              padding: "10px",
              border: " 1px solid  #fff",
              borderRight: "0",
              width: "100%",
              fontSize: "16px",
              fontWeight: "500",
              color: " #1A1A1A",
              backgroundColor: "#f1c7e0",
            }}
          >
            ส่วนลด
          </p>
          <p
            style={{
              padding: "10px",
              border: "1px solid  #fff",
              width: "150px",
              textAlign: "right",
              fontSize: "16px",
              fontWeight: "500",
              color: " #1A1A1A",
              backgroundColor: "#f1c7e0",
            }}
          >
              {Number(discount).toLocaleString('en-US', { style: 'currency', currency: 'THB' })}
          </p>
        </div>
        }
        

        <div className="d-flex mt-0">
          <p
            style={{
              padding: "10px",
              border: " 1px solid  #fff",
              borderRight: "0",
              width: "100%",
              fontSize: "16px",
              fontWeight: "500",
              color: " #1A1A1A",
              backgroundColor: "#f1c7e0",
            }}
          >
            รวมเป็นเงิน
          </p>
          <p
            style={{
              padding: "10px",
              border: "1px solid  #fff",
              width: "150px",
              textAlign: "right",
              fontSize: "16px",
              fontWeight: "500",
              color: " #1A1A1A",
              backgroundColor: "#f1c7e0",
            }}
          >
              {
              
                pages === "checkout-for-gift" ?  totalCheckForGift.toLocaleString('en-US', { style: 'currency', currency: 'THB' }) : 
                totalDebtPaidThisInstallment.toLocaleString('en-US', { style: 'currency', currency: 'THB' })
                
              }
          </p>
        </div>
      </div>
    ),
    buttons: [
      {
        text: "ปิด",
        className: "btn btn-danger",
      },
      {
        text: "ปิด",
        className: "btn btn-success",
      },
    ],

    // eslint-disable-next-line no-dupe-keys
    buttons: [
      "ปิด",

      {
        text: "ยืนยันคำสั่งซื้อ",
        className: quantityProduct.length > 0 ? "__btn-success" : "d-none",
        
      },
    ],
  }).then((value) => {
      if(value){
        if (quantityProduct.length > 0) {
          handleConfirmOrder();
        }
      }
  });
};


 

const SweetError = (title, text) => {
    swal({
        title: title,
        text: text,
        className: "swal-text-check-user2",
        icon: "error",
        button: "ตกลง",
     })
    
}


const SweetSuccess = (title, text) => {
    swal({
        title: title,
        text: text,
        className: "swal-text-check-user2",
        icon: "success",
        button: "ตกลง",
     });
};




export  { showCart, SweetError, SweetSuccess }