function getResult(conditions) {
    if (conditions.ingStockRequestID === null && conditions.shippingStatus === "NOT_SHIPPED" && !conditions.orderStatus && conditions.orderType === "ORDER") {
        return "white";
    } else if (conditions.ingStockRequestID === null && conditions.shippingStatus === "NOT_SHIPPED" && conditions.orderStatus && conditions.orderType === "ORDER") {
        return "light orange";
    } else if (conditions.ingStockRequestID !== null && conditions.shippingStatus === "NOT_SHIPPED" && !conditions.orderStatus && conditions.orderType === "ORDER") {
        return "pink";
    } else if (conditions.ingStockRequestID !== null && conditions.shippingStatus === "NOT_SHIPPED" && conditions.orderStatus && conditions.orderType === "ORDER") {
        return "orange";
    } else if (conditions.ingStockRequestID !== null && conditions.shippingStatus === "SHIPPED" && !conditions.orderStatus && conditions.orderType === "ORDER") {
        return "green";
    } else if (conditions.ingStockRequestID !== null && conditions.shippingStatus === "SHIPPED_PAIDED" && !conditions.orderStatus && conditions.orderType === "ORDER") {
        return "greener";
    } else if (conditions.ingStockRequestID === null && conditions.shippingStatus === "NOT_SHIPPED" && !conditions.orderStatus && conditions.orderType === "REORDER") {
        return "pink";
    } else if (conditions.ingStockRequestID === null && conditions.shippingStatus === "SHIPPED" && !conditions.orderStatus && conditions.orderType === "REORDER") {
        return "green";
    } else if (conditions.ingStockRequestID === null && conditions.shippingStatus === "SHIPPED_PAIDED" && !conditions.orderStatus && conditions.orderType === "REORDER") {
        return "greener";
    } else if (conditions.ingStockRequestID === null && conditions.shippingStatus === "SHIPPED" && conditions.orderStatus && conditions.orderType === "REORDER") {
        return "orange";
    } else if (conditions.ingStockRequestID === null && conditions.shippingStatus === "SHIPPED_PAIDED" && conditions.orderStatus && conditions.orderType === "REORDER") {
        return "orange";
    }
}

export { getResult }