import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import swal from '@sweetalert/with-react';
import { useSelector } from "react-redux";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { styled } from "@mui/material/styles";
import "./style.css";
import { useLocation , useHistory } from "react-router-dom";
import apiService from "../../../../api/apiService";

const TypographyOrderList = styled(Typography)({
  fontSize: "16px",
  fontWeight: "400",
})

const PaperOrderList = styled(Paper)({
  padding: "10px",
  backgroundColor: "#f5f5f5",
  borderRadius: "0",
})

function BtnConfirm() {
  const useOrderAgainShippr = useSelector(state => state.useOrderAgainShippr);
  const [disabled, setDisabled] = useState(true);
  const history = useHistory();
  const location = useLocation();
  const service = apiService();

  const quantityAll = useOrderAgainShippr.reduce((acc, item) => { 
    return acc + item.quantity
   }, 0)
   

   
  const Confirm = () => {
       setDisabled(true)
      if(quantityAll > 0){
          
        const data = {
          "orderID": location.state.orderID,
          "shipperID": Number(location.state.shipperID),
          "orderDetail": useOrderAgainShippr.filter((item) => item.quantity > 0).map((item) => {
            return {
              "productID": item.productID,
              "quantity": item.quantity,
              "returnPictureURL": "",
            }})
        }

        console.log(data);

        service.post({
          url: "/api/v1/order/checkout-for-reorder", 
          params: data,
        }).then((res) => {

          swal({
            text: "ขอบคุณสำหรับคำสั่งซื้อของท่าน",
            icon: "success",
            buttons: ["ยกเลิก", "ตกลง"],
            className: "swal-shippr-listOrder",
            content: (
              <Box>

                <Grid container spacing={0}>
                  <Grid item xs={6}>
                    <PaperOrderList>
                      <TypographyOrderList>ยอดเดิม</TypographyOrderList>
                    </PaperOrderList>
                  </Grid>
                  <Grid item xs={6}>
                    <PaperOrderList>
                      <TypographyOrderList> {useOrderAgainShippr.reduce((acc, item) => {
                        return acc + item.totalPrice
                      }, 0).toLocaleString()} บาท</TypographyOrderList>
                    </PaperOrderList>
                  </Grid>
                </Grid>


                <Grid container spacing={0}>
                  <Grid item xs={6}>
                    <PaperOrderList>
                      <TypographyOrderList>ยอดใหม่</TypographyOrderList>
                    </PaperOrderList>
                  </Grid>
                  <Grid item xs={6}>
                    <PaperOrderList>
                      <TypographyOrderList> {useOrderAgainShippr.reduce((acc, item) => {
                        return acc + item.newTotalPrice
                      }, 0).toLocaleString()} บาท</TypographyOrderList>
                    </PaperOrderList>
                  </Grid>
                </Grid>


              </Box>
            ),



          }).then((e) => {
            if (e) {
              history.push({
                pathname: location.state.pathname,
                state: {
                  employeeNickname: location?.state?.employeeNickname,
                }
              });
            }
          })
        }).catch((err) => {
          swal({
            title: `${err.message}`,
            text: "กรุณาลองใหม่อีกครั้ง",
            icon: "error",
            button: "ตกลง",
          });
        })



      
      } else {
        setDisabled(false)
      }
  }


  useEffect(() => {
    if(quantityAll > 0){
      setDisabled(false)
    }else{
      setDisabled(true)
    }
  }, [quantityAll])


  return (
    <Button
   
    onClick={Confirm}
     disabled={disabled}
    variant="contained"
    sx={{
      marginTop: "2.5rem",
      padding: ".5rem",
      width: "100%",
      fontSize: "1.2rem",
    }}
     
  >
    {" "}
    <Typography noWrap>ยืนยัน</Typography>
  </Button>

  
  )
}

export default BtnConfirm