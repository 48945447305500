import React, { useState, useEffect, useRef } from "react";
import Box from "@material-ui/core/Box";
import liff from "@line/liff/dist/lib";
import Grid from "@mui/material/Grid";
import apiService from "../../api/apiService";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import swal from "@sweetalert/with-react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CircularProgress from "@mui/material/CircularProgress";
import SalesReportGeolocation from "./salesReportGeolocation";

function Customer() {

  const service = apiService();
  const [googleMap, setGoogleMap] = useState("");
  const [fileName, setFileName] = useState("ภพ20 / บัตรปชช");
  const [fullName, setFullName] = useState("");
  const [name, setName] = useState("");
  const [taxname, setTaxname] = useState("");
  const [taxID, setTaxID] = useState("");
  const [taxAddress, setTaxAddress] = useState(""); // ที่อยู่ในใบกำกับภาษี
  const [address, setAddress] = useState(""); // ที่อยู่ของร้านลูกค้า
  const [Addresss, setAddresss] = useState(""); //  จุดสังเกตของร้านลูกค้า
  const [tel, setTel] = useState("");
  const [telFax, setTelFax] = useState("");
  const [lineGroupName, setLineGroupName] = useState("");
  const [lineGroupUrl, setLineGroupUrl] = useState("");
 
  const [invoiceType, setInvoiceType] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const [Mon, setMon] = useState("00:00");
  const [Tue, setTue] = useState("00:00");
  const [Wed, setWed] = useState("00:00");
  const [Thu, setThu] = useState("00:00");
  const [Fri, setFri] = useState("00:00");
  const [Sat, setSat] = useState("00:00");
  const [Sun, setSun] = useState("00:00");
  const [customerID, setCustomerID] = useState(0);
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(true);
  const formInput = useRef([]);
  const formTime = useRef([]);
  const [isTimeChange, setIsTimeChange] = useState(false);
  const [isInputChange, setIsInputChange] = useState(false);
  const [loadingFile, setLoadingFile] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [googleMapURLError, setGoogleMapURLError] = useState(false);
 

  const getLineGroupID = async () => {
    const url = window.location.href;
    
    const setUrl1 = url.split("?liff.state=%3FgroupID%3D")[1];
    const setUrl2 = url.split("?liff.state=%2FgroupID%3D")[1];
    const setUrl3 = url.split("?liff.state=%2F%3FgroupID%3D")[1];
    const setUrl = setUrl1 ? setUrl1 : setUrl2 ? setUrl2 : setUrl3 ? setUrl3 : null;

    const endUrl = setUrl?.split("#")[0] 
    console.log("endUrl", endUrl);
    return endUrl;
  };


  const getCustomer = async (gid) => {

 
    return service.get({url: `/api/v1/customer/list`}).then((res) => {

        const result = res.data.result.filter((item) => item.lineGroupID === gid)[0]?.customerID
        console.log("result",{
            customerID: result,
            lineGroupID: gid,
            "/api/v1/customer/list": res.data.result.filter((item) => item.lineGroupID === gid)
        });
        return result 

 
      }).catch((err) => {
        setApiError(true);
        swal({
          title: "เกิดข้อผิดพลาด",
          content: <p>{err.message}</p>,
          icon: "error",
          closeOnClickOutside: false,
          buttons: {
            cancel: "ปิด",
          },
        }).then((value) => {
          switch (value) {
            default:
              
                liff.closeWindow()
             
              console.log("close");
          }
        });
      });
  };

  const getDate = async (e) => {
    setCustomerID(e);
    return service
      .get({ url: `/api/v1/customer/bot-alert-time?customerID=${e}`})
      .then((res) => {
     

        for(let i = 0; i < res.data.result.length; i++){
          if(res.data.result[i].dateID === 1){
            setMon(res.data.result[i].time.length === 5 ? res.data.result[i].time : `0${res.data.result[i].time}` )
          }else if(res.data.result[i].dateID === 2){
            setTue(res.data.result[i].time.length === 5 ? res.data.result[i].time : `0${res.data.result[i].time}`)
          }else if(res.data.result[i].dateID === 3){
            setWed(res.data.result[i].time.length === 5 ? res.data.result[i].time : `0${res.data.result[i].time}`)
          }else if(res.data.result[i].dateID === 4){
            setThu(res.data.result[i].time.length === 5 ? res.data.result[i].time : `0${res.data.result[i].time}`)
          }else if(res.data.result[i].dateID === 5){
            setFri(res.data.result[i].time.length === 5 ? res.data.result[i].time : `0${res.data.result[i].time}`)
          }else if(res.data.result[i].dateID === 6){
            setSat(res.data.result[i].time.length === 5 ? res.data.result[i].time : `0${res.data.result[i].time}`)
          }else if(res.data.result[i].dateID === 7){
            setSun(res.data.result[i].time.length === 5 ? res.data.result[i].time : `0${res.data.result[i].time}`)
          }
        }

        formTime.current = {
          Mon: res.data.result.length >= 1 ? res.data.result[0].time : "00:00",
          Tue: res.data.result.length >= 2 ? res.data.result[1].time : "00:00",
          Wed: res.data.result.length >= 3 ? res.data.result[2].time : "00:00",
          Thu: res.data.result.length >= 4 ? res.data.result[3].time : "00:00",
          Fri: res.data.result.length >= 5 ? res.data.result[4].time : "00:00",
          Sat: res.data.result.length >= 6 ? res.data.result[5].time : "00:00",
          Sun: res.data.result.length >= 7 ? res.data.result[6].time : "00:00",
        };
        
        

      })
      .catch((err) => {
        setApiError(true);

        swal({
          title: "เกิดข้อผิดพลาด",
          content: <p>{err.message}</p>,
          icon: "error",
          closeOnClickOutside: false,
          buttons: {
            cancel: "ปิด",
          },
        }).then((value) => {
          switch (value) {
            default:
         
                liff.closeWindow()
               
              console.log("close");
          }
        });
      });
  };

  const setInput = async (e) => {
    return service.get({ url: `/api/v1/customer/list`}).then((res) => {
    
        setFullName(res.data.result.filter(item => item.customerID === e)[0].customerName === null ? "" : res.data.result.filter(item => item.customerID === e)[0].customerName);
        setName(res.data.result.filter(item => item.customerID === e)[0].invoiceName === null ? "" : res.data.result.filter(item => item.customerID === e)[0].invoiceName);
        setInvoiceType(res.data.result.filter(item => item.customerID === e)[0].invoiceType === null || res.data.result.filter(item => item.customerID === e)[0].invoiceType === "???????????????" || res.data.result.filter(item => item.customerID === e)[0].invoiceType === "NO Tax Invoice" ? "" : res.data.result.filter(item => item.customerID === e)[0].invoiceType);
        setTaxname(res.data.result.filter(item => item.customerID === e)[0].taxInvoiceName === null ? "" : res.data.result.filter(item => item.customerID === e)[0].taxInvoiceName);
        setTaxID(res.data.result.filter(item => item.customerID === e)[0].customerTaxID === null ? "" : res.data.result.filter(item => item.customerID === e)[0].customerTaxID);
        setTaxAddress(res.data.result.filter(item => item.customerID === e)[0].customerTaxAddress === null ? "" : res.data.result.filter(item => item.customerID === e)[0].customerTaxAddress);
        setPaymentType(res.data.result.filter(item => item.customerID === e)[0].paymentType === null || res.data.result.filter(item => item.customerID === e)[0].paymentType === "NO Tax Invoice" ? "" : res.data.result.filter(item => item.customerID === e)[0].paymentType);
        setTel(res.data.result.filter(item => item.customerID === e)[0].phone === null ? "" : res.data.result.filter(item => item.customerID === e)[0].phone);
        setTelFax(res.data.result.filter(item => item.customerID === e)[0].fax === null ? "" : res.data.result.filter(item => item.customerID === e)[0].fax);
        setLineGroupName(res.data.result.filter(item => item.customerID === e)[0].lineGroupName === null ? "" : res.data.result.filter(item => item.customerID === e)[0].lineGroupName);
        setGoogleMap(res.data.result.filter(item => item.customerID === e)[0].deliveryGeoLocation === null ? "" : res.data.result.filter(item => item.customerID === e)[0].deliveryGeoLocation);
        setFileName(res.data.result.filter(item => item.customerID === e)[0].taxDocPic === null ? "ภพ20 / บัตรปชช" : res.data.result.filter(item => item.customerID === e)[0].taxDocPic);
        setLineGroupUrl(res.data.result.filter(item => item.customerID === e)[0].lineGroupURL === null ? "" : res.data.result.filter(item => item.customerID === e)[0].lineGroupURL);
        setAddress(
          res.data.result.filter(item => item.customerID === e)[0].customerAddress === null ? "" : res.data.result.filter(item => item.customerID === e)[0].customerAddress
        ); // ที่อยู่ของร้านลูกค้า
        // setAddresss(item.customerAddress === null ? "" : item.customerAddress); // จุดสังเกตของร้านลูกค้า
        formInput.current = {
          fullName: res.data.result.filter(item => item.customerID === e)[0].customerName === null ? "" : res.data.result.filter(item => item.customerID === e)[0].customerName,
          name: res.data.result.filter(item => item.customerID === e)[0].invoiceName === null ? "" : res.data.result.filter(item => item.customerID === e)[0].invoiceName,
          invoiceType: res.data.result.filter(item => item.customerID === e)[0].invoiceType === null || res.data.result.filter(item => item.customerID === e)[0].invoiceType === "???????????????" || res.data.result.filter(item => item.customerID === e)[0].invoiceType === "NO Tax Invoice" ? "" : res.data.result.filter(item => item.customerID === e)[0].invoiceType,
          taxname: res.data.result.filter(item => item.customerID === e)[0].taxInvoiceName === null ? "" : res.data.result.filter(item => item.customerID === e)[0].taxInvoiceName,
          taxID: res.data.result.filter(item => item.customerID === e)[0].customerTaxID === null ? "" : res.data.result.filter(item => item.customerID === e)[0].customerTaxID,
          taxAddress: res.data.result.filter(item => item.customerID === e)[0].customerTaxAddress === null ? "" : res.data.result.filter(item => item.customerID === e)[0].customerTaxAddress,
          paymentType: res.data.result.filter(item => item.customerID === e)[0].paymentType === null || res.data.result.filter(item => item.customerID === e)[0].paymentType === "NO Tax Invoice" ? "" : res.data.result.filter(item => item.customerID === e)[0].paymentType,
          tel: res.data.result.filter(item => item.customerID === e)[0].phone === null ? "" : res.data.result.filter(item => item.customerID === e)[0].phone,
          telFax: res.data.result.filter(item => item.customerID === e)[0].fax === null ? "" : res.data.result.filter(item => item.customerID === e)[0].fax,
          lineGroupName:  res.data.result.filter(item => item.customerID === e)[0].lineGroupName === null ? "" : res.data.result.filter(item => item.customerID === e)[0].lineGroupName,
          googleMap:  res.data.result.filter(item => item.customerID === e)[0].deliveryGeoLocation === null ? "" : res.data.result.filter(item => item.customerID === e)[0].deliveryGeoLocation,
          fileName:  res.data.result.filter(item => item.customerID === e)[0].taxDocPic === null ? "ภพ20 / บัตรปชช" : res.data.result.filter(item => item.customerID === e)[0].taxDocPic,
          lineGroupUrl:  res.data.result.filter(item => item.customerID === e)[0].lineGroupURL === null ? "" : res.data.result.filter(item => item.customerID === e)[0].lineGroupURL,
          address:  res.data.result.filter(item => item.customerID === e)[0].customerAddress === null ? "" : res.data.result.filter(item => item.customerID === e)[0].customerAddress,
        };

      })
      .catch((err) => {
        setApiError(true);

        swal({
          title: "เกิดข้อผิดพลาด",
          content: <p>{err.message}</p>,
          icon: "error",
          closeOnClickOutside: false,
          buttons: {
            cancel: "ปิด",
          },
        }).then((value) => {
          switch (value) {
            default:
            
                liff.closeWindow()
              
              console.log("close");
          }
        });
      });
  };

  useEffect(() => {
    setLoading(true);
    getLineGroupID().then((lineGroupID) => {
      getCustomer(lineGroupID).then((e) => {
        setLoading(false);
        getDate(e);
        setInput(e);
       
      });
    });
      
 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // เช็ค เวลา หรือ ข้อมูล มีการเปลี่ยนแปลงหรือไม่
  const isChangeTimeAndInput = (input, value) => {
    const isChangeMon =
      input === "Mon"
        ? formTime.current.Mon !== value ||
          formTime.current.Sun !== Sun ||
          formTime.current.Sat !== Sat ||
          formTime.current.Fri !== Fri ||
          formTime.current.Thu !== Thu ||
          formTime.current.Wed !== Wed ||
          formTime.current.Tue !== Tue ||
          formInput.current.fullName !== fullName ||
          formInput.current.name !== name ||
          formInput.current.invoiceType !== invoiceType ||
          formInput.current.taxname !== taxname ||
          formInput.current.taxID !== taxID ||
          formInput.current.taxAddress !== taxAddress ||
          formInput.current.paymentType !== paymentType ||
          formInput.current.tel !== tel ||
          formInput.current.telFax !== telFax ||
          formInput.current.lineGroupName !== lineGroupName ||
          formInput.current.googleMap !== googleMap ||
          formInput.current.fileName !== fileName ||
          formInput.current.lineGroupUrl !== lineGroupUrl ||
          formInput.current.address !== address
          ? true
          : false
        : false;

    const isChangeTue =
      input === "Tue"
        ? formTime.current.Tue !== value ||
          formTime.current.Sun !== Sun ||
          formTime.current.Sat !== Sat ||
          formTime.current.Fri !== Fri ||
          formTime.current.Thu !== Thu ||
          formTime.current.Wed !== Wed ||
          formTime.current.Mon !== Mon ||
          formInput.current.fullName !== fullName ||
          formInput.current.name !== name ||
          formInput.current.invoiceType !== invoiceType ||
          formInput.current.taxname !== taxname ||
          formInput.current.taxID !== taxID ||
          formInput.current.taxAddress !== taxAddress ||
          formInput.current.paymentType !== paymentType ||
          formInput.current.tel !== tel ||
          formInput.current.telFax !== telFax ||
          formInput.current.lineGroupName !== lineGroupName ||
          formInput.current.googleMap !== googleMap ||
          formInput.current.fileName !== fileName ||
          formInput.current.lineGroupUrl !== lineGroupUrl ||
          formInput.current.address !== address
          ? true
          : false
        : false;

    const isChangeWed =
      input === "Wed"
        ? formTime.current.Wed !== value ||
          formTime.current.Sun !== Sun ||
          formTime.current.Sat !== Sat ||
          formTime.current.Fri !== Fri ||
          formTime.current.Thu !== Thu ||
          formTime.current.Tue !== Tue ||
          formTime.current.Mon !== Mon ||
          formInput.current.fullName !== fullName ||
          formInput.current.name !== name ||
          formInput.current.invoiceType !== invoiceType ||
          formInput.current.taxname !== taxname ||
          formInput.current.taxID !== taxID ||
          formInput.current.taxAddress !== taxAddress ||
          formInput.current.paymentType !== paymentType ||
          formInput.current.tel !== tel ||
          formInput.current.telFax !== telFax ||
          formInput.current.lineGroupName !== lineGroupName ||
          formInput.current.googleMap !== googleMap ||
          formInput.current.fileName !== fileName ||
          formInput.current.lineGroupUrl !== lineGroupUrl ||
          formInput.current.address !== address
          ? true
          : false
        : false;

    const isChangeThu =
      input === "Thu"
        ? formTime.current.Thu !== value ||
          formTime.current.Sun !== Sun ||
          formTime.current.Sat !== Sat ||
          formTime.current.Fri !== Fri ||
          formTime.current.Wed !== Wed ||
          formTime.current.Tue !== Tue ||
          formTime.current.Mon !== Mon ||
          formInput.current.fullName !== fullName ||
          formInput.current.name !== name ||
          formInput.current.invoiceType !== invoiceType ||
          formInput.current.taxname !== taxname ||
          formInput.current.taxID !== taxID ||
          formInput.current.taxAddress !== taxAddress ||
          formInput.current.paymentType !== paymentType ||
          formInput.current.tel !== tel ||
          formInput.current.telFax !== telFax ||
          formInput.current.lineGroupName !== lineGroupName ||
          formInput.current.googleMap !== googleMap ||
          formInput.current.fileName !== fileName ||
          formInput.current.lineGroupUrl !== lineGroupUrl ||
          formInput.current.address !== address
          ? true
          : false
        : false;

    const isChangeFri =
      input === "Fri"
        ? formTime.current.Fri !== value ||
          formTime.current.Sun !== Sun ||
          formTime.current.Sat !== Sat ||
          formTime.current.Thu !== Thu ||
          formTime.current.Wed !== Wed ||
          formTime.current.Tue !== Tue ||
          formTime.current.Mon !== Mon ||
          formInput.current.fullName !== fullName ||
          formInput.current.name !== name ||
          formInput.current.invoiceType !== invoiceType ||
          formInput.current.taxname !== taxname ||
          formInput.current.taxID !== taxID ||
          formInput.current.taxAddress !== taxAddress ||
          formInput.current.paymentType !== paymentType ||
          formInput.current.tel !== tel ||
          formInput.current.telFax !== telFax ||
          formInput.current.lineGroupName !== lineGroupName ||
          formInput.current.googleMap !== googleMap ||
          formInput.current.fileName !== fileName ||
          formInput.current.lineGroupUrl !== lineGroupUrl ||
          formInput.current.address !== address
          ? true
          : false
        : false;

    const isChangeSat =
      input === "Sat"
        ? formTime.current.Sat !== value ||
          formTime.current.Sun !== Sun ||
          formTime.current.Fri !== Fri ||
          formTime.current.Thu !== Thu ||
          formTime.current.Wed !== Wed ||
          formTime.current.Tue !== Tue ||
          formTime.current.Mon !== Mon ||
          formInput.current.fullName !== fullName ||
          formInput.current.name !== name ||
          formInput.current.invoiceType !== invoiceType ||
          formInput.current.taxname !== taxname ||
          formInput.current.taxID !== taxID ||
          formInput.current.taxAddress !== taxAddress ||
          formInput.current.paymentType !== paymentType ||
          formInput.current.tel !== tel ||
          formInput.current.telFax !== telFax ||
          formInput.current.lineGroupName !== lineGroupName ||
          formInput.current.googleMap !== googleMap ||
          formInput.current.fileName !== fileName ||
          formInput.current.lineGroupUrl !== lineGroupUrl ||
          formInput.current.address !== address
          ? true
          : false
        : false;

    const isChangeSun =
      input === "Sun"
        ? formTime.current.Sun !== value ||
          formTime.current.Sat !== Sat ||
          formTime.current.Fri !== Fri ||
          formTime.current.Thu !== Thu ||
          formTime.current.Wed !== Wed ||
          formTime.current.Tue !== Tue ||
          formTime.current.Mon !== Mon ||
          formInput.current.fullName !== fullName ||
          formInput.current.name !== name ||
          formInput.current.invoiceType !== invoiceType ||
          formInput.current.taxname !== taxname ||
          formInput.current.taxID !== taxID ||
          formInput.current.taxAddress !== taxAddress ||
          formInput.current.paymentType !== paymentType ||
          formInput.current.tel !== tel ||
          formInput.current.telFax !== telFax ||
          formInput.current.lineGroupName !== lineGroupName ||
          formInput.current.googleMap !== googleMap ||
          formInput.current.fileName !== fileName ||
          formInput.current.lineGroupUrl !== lineGroupUrl ||
          formInput.current.address !== address
          ? true
          : false
        : false;

    const isChangeFullName =
      input === "fullName"
        ? formInput.current.fullName !== value ||
          formTime.current.Sun !== Sun ||
          formTime.current.Sat !== Sat ||
          formTime.current.Fri !== Fri ||
          formTime.current.Thu !== Thu ||
          formTime.current.Wed !== Wed ||
          formTime.current.Tue !== Tue ||
          formTime.current.Mon !== Mon ||
          formInput.current.name !== name ||
          formInput.current.invoiceType !== invoiceType ||
          formInput.current.taxname !== taxname ||
          formInput.current.taxID !== taxID ||
          formInput.current.taxAddress !== taxAddress ||
          formInput.current.paymentType !== paymentType ||
          formInput.current.tel !== tel ||
          formInput.current.telFax !== telFax ||
          formInput.current.lineGroupName !== lineGroupName ||
          formInput.current.googleMap !== googleMap ||
          formInput.current.fileName !== fileName ||
          formInput.current.lineGroupUrl !== lineGroupUrl ||
          formInput.current.address !== address
          ? true
          : false
        : false;

    const isChangeName =
      input === "name"
        ? formInput.current.name !== value ||
          formTime.current.Sun !== Sun ||
          formTime.current.Sat !== Sat ||
          formTime.current.Fri !== Fri ||
          formTime.current.Thu !== Thu ||
          formTime.current.Wed !== Wed ||
          formTime.current.Tue !== Tue ||
          formTime.current.Mon !== Mon ||
          formInput.current.fullName !== fullName ||
          formInput.current.invoiceType !== invoiceType ||
          formInput.current.taxname !== taxname ||
          formInput.current.taxID !== taxID ||
          formInput.current.taxAddress !== taxAddress ||
          formInput.current.paymentType !== paymentType ||
          formInput.current.tel !== tel ||
          formInput.current.telFax !== telFax ||
          formInput.current.lineGroupName !== lineGroupName ||
          formInput.current.googleMap !== googleMap ||
          formInput.current.fileName !== fileName ||
          formInput.current.lineGroupUrl !== lineGroupUrl ||
          formInput.current.address !== address
          ? true
          : false
        : false;

    const isChangeInvoiceType =
      input === "invoiceType"
        ? formInput.current.invoiceType !== value ||
          formTime.current.Sun !== Sun ||
          formTime.current.Sat !== Sat ||
          formTime.current.Fri !== Fri ||
          formTime.current.Thu !== Thu ||
          formTime.current.Wed !== Wed ||
          formTime.current.Tue !== Tue ||
          formTime.current.Mon !== Mon ||
          formInput.current.fullName !== fullName ||
          formInput.current.name !== name ||
          formInput.current.taxname !== taxname ||
          formInput.current.taxID !== taxID ||
          formInput.current.taxAddress !== taxAddress ||
          formInput.current.paymentType !== paymentType ||
          formInput.current.tel !== tel ||
          formInput.current.telFax !== telFax ||
          formInput.current.lineGroupName !== lineGroupName ||
          formInput.current.googleMap !== googleMap ||
          formInput.current.fileName !== fileName ||
          formInput.current.lineGroupUrl !== lineGroupUrl ||
          formInput.current.address !== address
          ? true
          : false
        : false;

    const isChangeTaxname =
      input === "taxname"
        ? formInput.current.taxname !== value ||
          formTime.current.Sun !== Sun ||
          formTime.current.Sat !== Sat ||
          formTime.current.Fri !== Fri ||
          formTime.current.Thu !== Thu ||
          formTime.current.Wed !== Wed ||
          formTime.current.Tue !== Tue ||
          formTime.current.Mon !== Mon ||
          formInput.current.fullName !== fullName ||
          formInput.current.name !== name ||
          formInput.current.invoiceType !== invoiceType ||
          formInput.current.taxID !== taxID ||
          formInput.current.taxAddress !== taxAddress ||
          formInput.current.paymentType !== paymentType ||
          formInput.current.tel !== tel ||
          formInput.current.telFax !== telFax ||
          formInput.current.lineGroupName !== lineGroupName ||
          formInput.current.googleMap !== googleMap ||
          formInput.current.fileName !== fileName ||
          formInput.current.lineGroupUrl !== lineGroupUrl ||
          formInput.current.address !== address
          ? true
          : false
        : false;

    const isChangeTaxID =
      input === "taxID"
        ? formInput.current.taxID !== value ||
          formTime.current.Sun !== Sun ||
          formTime.current.Sat !== Sat ||
          formTime.current.Fri !== Fri ||
          formTime.current.Thu !== Thu ||
          formTime.current.Wed !== Wed ||
          formTime.current.Tue !== Tue ||
          formTime.current.Mon !== Mon ||
          formInput.current.fullName !== fullName ||
          formInput.current.name !== name ||
          formInput.current.invoiceType !== invoiceType ||
          formInput.current.taxname !== taxname ||
          formInput.current.taxAddress !== taxAddress ||
          formInput.current.paymentType !== paymentType ||
          formInput.current.tel !== tel ||
          formInput.current.telFax !== telFax ||
          formInput.current.lineGroupName !== lineGroupName ||
          formInput.current.googleMap !== googleMap ||
          formInput.current.fileName !== fileName ||
          formInput.current.lineGroupUrl !== lineGroupUrl ||
          formInput.current.address !== address
          ? true
          : false
        : false;

    const isChangeTaxAddress =
      input === "taxAddress"
        ? formInput.current.taxAddress !== value ||
          formTime.current.Sun !== Sun ||
          formTime.current.Sat !== Sat ||
          formTime.current.Fri !== Fri ||
          formTime.current.Thu !== Thu ||
          formTime.current.Wed !== Wed ||
          formTime.current.Tue !== Tue ||
          formTime.current.Mon !== Mon ||
          formInput.current.fullName !== fullName ||
          formInput.current.name !== name ||
          formInput.current.invoiceType !== invoiceType ||
          formInput.current.taxname !== taxname ||
          formInput.current.taxID !== taxID ||
          formInput.current.paymentType !== paymentType ||
          formInput.current.tel !== tel ||
          formInput.current.telFax !== telFax ||
          formInput.current.lineGroupName !== lineGroupName ||
          formInput.current.googleMap !== googleMap ||
          formInput.current.fileName !== fileName ||
          formInput.current.lineGroupUrl !== lineGroupUrl ||
          formInput.current.address !== address
          ? true
          : false
        : false;

    const isChangePaymentType =
      input === "paymentType"
        ? formInput.current.paymentType !== value ||
          formTime.current.Sun !== Sun ||
          formTime.current.Sat !== Sat ||
          formTime.current.Fri !== Fri ||
          formTime.current.Thu !== Thu ||
          formTime.current.Wed !== Wed ||
          formTime.current.Tue !== Tue ||
          formTime.current.Mon !== Mon ||
          formInput.current.fullName !== fullName ||
          formInput.current.name !== name ||
          formInput.current.invoiceType !== invoiceType ||
          formInput.current.taxname !== taxname ||
          formInput.current.taxID !== taxID ||
          formInput.current.taxAddress !== taxAddress ||
          formInput.current.tel !== tel ||
          formInput.current.telFax !== telFax ||
          formInput.current.lineGroupName !== lineGroupName ||
          formInput.current.googleMap !== googleMap ||
          formInput.current.fileName !== fileName ||
          formInput.current.lineGroupUrl !== lineGroupUrl ||
          formInput.current.address !== address
          ? true
          : false
        : false;

    const isChangeTel =
      input === "tel"
        ? formInput.current.tel !== value ||
          formTime.current.Sun !== Sun ||
          formTime.current.Sat !== Sat ||
          formTime.current.Fri !== Fri ||
          formTime.current.Thu !== Thu ||
          formTime.current.Wed !== Wed ||
          formTime.current.Tue !== Tue ||
          formTime.current.Mon !== Mon ||
          formInput.current.fullName !== fullName ||
          formInput.current.name !== name ||
          formInput.current.invoiceType !== invoiceType ||
          formInput.current.taxname !== taxname ||
          formInput.current.taxID !== taxID ||
          formInput.current.taxAddress !== taxAddress ||
          formInput.current.paymentType !== paymentType ||
          formInput.current.telFax !== telFax ||
          formInput.current.lineGroupName !== lineGroupName ||
          formInput.current.googleMap !== googleMap ||
          formInput.current.fileName !== fileName ||
          formInput.current.lineGroupUrl !== lineGroupUrl ||
          formInput.current.address !== address
          ? true
          : false
        : false;

    const isChangeTelFax =
      input === "telFax"
        ? formInput.current.telFax !== value ||
          formTime.current.Sun !== Sun ||
          formTime.current.Sat !== Sat ||
          formTime.current.Fri !== Fri ||
          formTime.current.Thu !== Thu ||
          formTime.current.Wed !== Wed ||
          formTime.current.Tue !== Tue ||
          formTime.current.Mon !== Mon ||
          formInput.current.fullName !== fullName ||
          formInput.current.name !== name ||
          formInput.current.invoiceType !== invoiceType ||
          formInput.current.taxname !== taxname ||
          formInput.current.taxID !== taxID ||
          formInput.current.taxAddress !== taxAddress ||
          formInput.current.paymentType !== paymentType ||
          formInput.current.tel !== tel ||
          formInput.current.lineGroupName !== lineGroupName ||
          formInput.current.googleMap !== googleMap ||
          formInput.current.fileName !== fileName ||
          formInput.current.lineGroupUrl !== lineGroupUrl ||
          formInput.current.address !== address
          ? true
          : false
        : false;

    const isChangeLineGroupName =
      input === "lineGroupName"
        ? formInput.current.lineGroupName !== value ||
          formTime.current.Sun !== Sun ||
          formTime.current.Sat !== Sat ||
          formTime.current.Fri !== Fri ||
          formTime.current.Thu !== Thu ||
          formTime.current.Wed !== Wed ||
          formTime.current.Tue !== Tue ||
          formTime.current.Mon !== Mon ||
          formInput.current.fullName !== fullName ||
          formInput.current.name !== name ||
          formInput.current.invoiceType !== invoiceType ||
          formInput.current.taxname !== taxname ||
          formInput.current.taxID !== taxID ||
          formInput.current.taxAddress !== taxAddress ||
          formInput.current.paymentType !== paymentType ||
          formInput.current.tel !== tel ||
          formInput.current.telFax !== telFax ||
          formInput.current.googleMap !== googleMap ||
          formInput.current.fileName !== fileName ||
          formInput.current.lineGroupUrl !== lineGroupUrl ||
          formInput.current.address !== address
          ? true
          : false
        : false;

    const isChangeGoogleMap =
      input === "googleMap"
        ? formInput.current.googleMap !== value ||
          formTime.current.Sun !== Sun ||
          formTime.current.Sat !== Sat ||
          formTime.current.Fri !== Fri ||
          formTime.current.Thu !== Thu ||
          formTime.current.Wed !== Wed ||
          formTime.current.Tue !== Tue ||
          formTime.current.Mon !== Mon ||
          formInput.current.fullName !== fullName ||
          formInput.current.name !== name ||
          formInput.current.invoiceType !== invoiceType ||
          formInput.current.taxname !== taxname ||
          formInput.current.taxID !== taxID ||
          formInput.current.taxAddress !== taxAddress ||
          formInput.current.paymentType !== paymentType ||
          formInput.current.tel !== tel ||
          formInput.current.telFax !== telFax ||
          formInput.current.lineGroupName !== lineGroupName ||
          formInput.current.fileName !== fileName ||
          formInput.current.lineGroupUrl !== lineGroupUrl ||
          formInput.current.address !== address
          ? true
          : false
        : false;

    const isChangeFileName =
      input === "fileName"
        ? formInput.current.fileName !== value ||
          formTime.current.Sun !== Sun ||
          formTime.current.Sat !== Sat ||
          formTime.current.Fri !== Fri ||
          formTime.current.Thu !== Thu ||
          formTime.current.Wed !== Wed ||
          formTime.current.Tue !== Tue ||
          formTime.current.Mon !== Mon ||
          formInput.current.fullName !== fullName ||
          formInput.current.name !== name ||
          formInput.current.invoiceType !== invoiceType ||
          formInput.current.taxname !== taxname ||
          formInput.current.taxID !== taxID ||
          formInput.current.taxAddress !== taxAddress ||
          formInput.current.paymentType !== paymentType ||
          formInput.current.tel !== tel ||
          formInput.current.telFax !== telFax ||
          formInput.current.lineGroupName !== lineGroupName ||
          formInput.current.googleMap !== googleMap ||
          formInput.current.lineGroupUrl !== lineGroupUrl ||
          formInput.current.address !== address
          ? true
          : false
        : false;

    const isChangeLineGroupUrl =
      input === "lineGroupUrl"
        ? formInput.current.lineGroupUrl !== value ||
          formTime.current.Sun !== Sun ||
          formTime.current.Sat !== Sat ||
          formTime.current.Fri !== Fri ||
          formTime.current.Thu !== Thu ||
          formTime.current.Wed !== Wed ||
          formTime.current.Tue !== Tue ||
          formTime.current.Mon !== Mon ||
          formInput.current.fullName !== fullName ||
          formInput.current.name !== name ||
          formInput.current.invoiceType !== invoiceType ||
          formInput.current.taxname !== taxname ||
          formInput.current.taxID !== taxID ||
          formInput.current.taxAddress !== taxAddress ||
          formInput.current.paymentType !== paymentType ||
          formInput.current.tel !== tel ||
          formInput.current.telFax !== telFax ||
          formInput.current.lineGroupName !== lineGroupName ||
          formInput.current.googleMap !== googleMap ||
          formInput.current.fileName !== fileName ||
          formInput.current.address !== address
          ? true
          : false
        : false;

    const isChangeAddress =
      input === "address"
        ? formInput.current.address !== value ||
          formTime.current.Sun !== Sun ||
          formTime.current.Sat !== Sat ||
          formTime.current.Fri !== Fri ||
          formTime.current.Thu !== Thu ||
          formTime.current.Wed !== Wed ||
          formTime.current.Tue !== Tue ||
          formTime.current.Mon !== Mon ||
          formInput.current.fullName !== fullName ||
          formInput.current.name !== name ||
          formInput.current.invoiceType !== invoiceType ||
          formInput.current.taxname !== taxname ||
          formInput.current.taxID !== taxID ||
          formInput.current.taxAddress !== taxAddress ||
          formInput.current.paymentType !== paymentType ||
          formInput.current.tel !== tel ||
          formInput.current.telFax !== telFax ||
          formInput.current.lineGroupName !== lineGroupName ||
          formInput.current.googleMap !== googleMap ||
          formInput.current.fileName !== fileName ||
          formInput.current.lineGroupUrl !== lineGroupUrl
          ? true
          : false
        : false;


    
    setIsTimeChange(
      isChangeMon ||
        isChangeTue ||
        isChangeWed ||
        isChangeThu ||
        isChangeFri ||
        isChangeSat ||
        isChangeSun
        ? true
        : false
    );

    setIsInputChange(
      isChangeFullName ||
        isChangeName ||
        isChangeInvoiceType ||
        isChangeTaxname ||
        isChangeTaxID ||
        isChangeTaxAddress ||
        isChangePaymentType ||
        isChangeTel ||
        isChangeTelFax ||
        isChangeLineGroupName ||
        isChangeGoogleMap ||
        isChangeFileName ||
        isChangeLineGroupUrl ||
        isChangeAddress
        ? true
        : false
    );
  };

  const Update = () => {

 

    if(name !== "" && paymentType !== "" && invoiceType !== "" && tel !== "" && lineGroupName !== "" && googleMap !== "" && lineGroupUrl !== ""){
      const par = {
        customerID: customerID,
        customerName: fullName,
        invoiceName: name,
        invoiceType: invoiceType,
        taxInvoiceName: taxname,
        customerTaxID: taxID,
        customerTaxAddress: taxAddress,
        paymentType: paymentType,
        phone: tel,
        fax: telFax,
        lineGroupName: lineGroupName,
        deliveryGeoLocation: googleMap,
        taxDocPic: fileName,
        lineGroupURL: lineGroupUrl,
        customerAddress: address,
      }
  
      
  
      const PostInput = () => {
        service
          .post({ url: `/api/v1/customer/update`, params: par })
          .then((res) => {
            console.log(res.data.result);
            if (res.status === 200) {
              swal({
                title: "บันทึกสำเร็จ",
                text: "ข้อมูลของคุณถูกบันทึกเรียบร้อยแล้ว",
                icon: "success",
                button: "ตกลง",
              })
                .then(() => {
                  setDisabled(true);
                })
                .catch((err) => {
                  swal({
                    title: "เกิดข้อผิดพลาด",
                    content: <p>{err.message}</p>,
                    icon: "error",
                    closeOnClickOutside: false,
                    buttons: {
                      cancel: "ปิด",
                    },
                  }).then((value) => {
                    switch (value) {
                      default:
                    
                          liff.closeWindow()
                     
                        console.log("close");
                    }
                  });
                });
            }
          })
          .catch((err) => {
            swal({
              title: "เกิดข้อผิดพลาด",
              content: <p>{err.message}</p>,
              icon: "error",
              closeOnClickOutside: false,
              buttons: {
                cancel: "ปิด",
              },
            }).then((value) => {
              switch (value) {
                default:
             
                    liff.closeWindow()
                   
                  console.log("close");
              }
            });
          });
      };
  
      const PostTime = () => {
  
        const Time = {
          customerID: customerID,
            data: [ 
              {
              customerID: customerID,
              dateID: 1,
              date: new Date().toISOString().slice(0, 10),
              time:  Mon,
            },
            {
              customerID: customerID,
              dateID: 2,
              date:  new Date().toISOString().slice(0, 10),
              time:  Tue,
            },
            {
              customerID: customerID,
              dateID: 3,
              date:  new Date().toISOString().slice(0, 10),
              time:  Wed,
            },
            {
              customerID: customerID,
              dateID: 4,
              date:  new Date().toISOString().slice(0, 10),
              time:  Thu,
            },
            {
              customerID: customerID,
              dateID: 5,
              date:  new Date().toISOString().slice(0, 10),
              time:  Fri,
            },
            {
              customerID: customerID,
              dateID: 6,
              date:  new Date().toISOString().slice(0, 10),
              time:  Sat,
            },
            {
              customerID: customerID,
              dateID: 7,
              date:  new Date().toISOString().slice(0, 10),
              time:  Sun,
            },]
        }
  
  
        
    
      
          service.post({ url: `/api/v1/customer/update-bot-alert-time`, params:Time }).then((res) => {
            console.log(res.data.result);
            console.log(Time);
            
            if (res.status === 200) {
          
  
              for(let i = 0; i < res.data.result.length; i++){
                if(res.data.result[i].dateID === 1){
                  setMon(res.data.result[i].time)
                }else if(res.data.result[i].dateID === 2){
                  setTue(res.data.result[i].time)
                }else if(res.data.result[i].dateID === 3){
                  setWed(res.data.result[i].time)
                }else if(res.data.result[i].dateID === 4){
                  setThu(res.data.result[i].time)
                }else if(res.data.result[i].dateID === 5){
                  setFri(res.data.result[i].time)
                }else if(res.data.result[i].dateID === 6){
                  setSat(res.data.result[i].time)
                }else if(res.data.result[i].dateID === 7){
                  setSun(res.data.result[i].time)
                }
              }
             
            
              
              swal({
                title: "บันทึกสำเร็จ",
                text: "ข้อมูลของคุณถูกบันทึกเรียบร้อยแล้ว",
                icon: "success",
                button: "ตกลง",
              }).then(() => {
               setDisabled(true);
              }).catch(err => { throw err });
            }
          }).catch((err) => { 
            swal({
              title: 'เกิดข้อผิดพลาด',
              content: <p>{customerID}</p>,
              icon: 'error',
              closeOnClickOutside: false,
              buttons: {
                cancel: "ปิด",
              },
            }).then((value) => {
              switch (value) {
                default:
              
                    liff.closeWindow()
                  
                  console.log("close");
              }
            });
  
           });
      };
  
      swal({
        title: "คุณต้องการบันทึกข้อมูลหรือไม่?",
        text: "หากบันทึกแล้วข้อมูลจะถูกเก็บไว้ในระบบ!",
        className: "swal-custom-update",
        icon: "info",
        buttons: ["ยกเลิก", "บันทึก"],
      }).then((value) => {
        if (value) {
          PostInput();
          PostTime();
        }
      });
    } else {
     
      swal({
        title: "กรุณากรอกข้อมูลในช่องสีฟ้า และ deliveryGeoLocation ให้ครบถ้วน",
        text: "โปรดตรวจสอบข้อมูลให้ครบถ้วนก่อนบันทึก",
        icon: "warning",
        button: "ตกลง",
      })
      

      
    }
  };

  const handleUploadToCloud = async (imgUpload) => {
    setLoadingFile(true);
    const data = new FormData();
    data.append("file", imgUpload);
    data.append("upload_preset", "jutttjti");

    await fetch(
      "https://api.cloudinary.com/v1_1/suvarnabhumi-food/image/upload",
      {
        method: "POST",
        body: data,
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        setFileName(result.url);
        isChangeTimeAndInput("fileName", result.url);
        setLoadingFile(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return loading ? (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
      }}
    >
      <CircularProgress />
    </Box>
  ) : (
    <Box style={{ display: "flex", justifyContent: "center" }}>
      <div
        className="container"
        id="Editcustomer"
        style={{
          margin: "0 auto",
          paddingTop: ".75rem",
          paddingBottom: ".75rem",
          maxWidth: "500px",
        }}
      >
        <div
          className="row"
          style={{
            margin: "0",
            width: "100%",
          }}
        >
          <div
            className="col-md-6 border p-4 shadow "
            style={{
              width: "100%",
            }}
          >
            <div className="col-12">
              <h3
                className="fw-normal   fs-4  mb-4"
                style={{
                  fontSize: "18px",
                  color: "#333",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                แก้ไขข้อมูลลูกค้า
              </h3>
            </div>

            <div className="row g-3">
              <Paper className="col-md-12 p-0">
                <TextField
                  fullWidth
                  id="FullName"
                  type="text"
                  label="ชื่อนามสกุลจริงของลูกค้า"
                  onChange={(e) => {
                    setFullName(e.target.value);
                    isChangeTimeAndInput("fullName", e.target.value);
                  }}
                  value={fullName}
                  variant="outlined"
                />
              </Paper>

              <Paper className="col-md-12 p-0">
                <TextField
                  fullWidth
                  id="Name"
                  type="text"
                  label=" ชื่อสั้น ๆ ในบิล"
                  value={name}
                  sx={{
                    backgroundColor: "#dbe9ff",
                  }}
                  onChange={(e) => {
                    setName(e.target.value);
                    isChangeTimeAndInput("name", e.target.value);
                  }}
                  variant="outlined"
                />
              </Paper>

              
              <Paper className="col-md-12 p-0">
                <TextField
                  fullWidth
                  id="Taxname"
                  label="ชื่อในใบกำกับภาษี"
                  type="text"
                  value={taxname}
                  onChange={(e) => {
                    setTaxname(e.target.value);
                    isChangeTimeAndInput("taxname", e.target.value);
                  }}
                  variant="outlined"
                />
              </Paper>

              <Paper className="col-md-12 p-0">
                <TextField
                  fullWidth
                  type="number"
                    inputMode="numeric"
                  id="TaxID"
                  label="เลขประจำตัวผู้เสียภาษี"
                  value={taxID}
                  onChange={(e) => {
                    setTaxID(e.target.value);
                    isChangeTimeAndInput("taxID", e.target.value);
                  }}
                  variant="outlined"
                />
              </Paper>

              <Paper className="col-md-12 p-0">
                <TextField
                  fullWidth
                  type="text"
                  id="TaxAddress"
                  label="ที่อยู่ในใบกำกับภาษี"
                  multiline
                  maxRows={4}
                  value={taxAddress}
                  onChange={(e) => {
                    setTaxAddress(e.target.value);
                    isChangeTimeAndInput("taxAddress", e.target.value);
                  }}
                  variant="outlined"
                />
              </Paper>

              <Paper className="col-md-12 p-0">
                <FormControl fullWidth>
                  <InputLabel id="paymentType">เงื่อนไขการชำระเงิน</InputLabel>
                  <Select
                    labelId="paymentType"
                    id="paymentType-select"
                    label="ชนิดของใบแจ้งหนี้"
                    sx={{
                      backgroundColor: "#dbe9ff",
                    }}
                    onChange={(e) => {
                      setPaymentType(e.target.value);
                      isChangeTimeAndInput("paymentType", e.target.value);
                    }}
                    value={paymentType}
                  >
                    <MenuItem value={"transferNow"}>ชำระทันที</MenuItem>
                    <MenuItem value={"COD"}>เก็บเงินปลายทาง</MenuItem>
                    <MenuItem value={"1_invoiceOverdue"}>ค้าง 1 บิล</MenuItem>
                    <MenuItem value={"2_invoiceOverdue"}>ค้าง 2 บิล</MenuItem>
                    <MenuItem value={"3_invoiceOverdue"}>ค้าง 3 บิล</MenuItem>
                    <MenuItem value={"15_Day_Defaultable"}>
                      15 วัน ปรกติ
                    </MenuItem>
                    <MenuItem value={"30_Day_Defaultable"}>
                      30 วัน ปรกติ
                    </MenuItem>
                    <MenuItem value={"15_Day_Non_Defaultable"}>
                      15 วัน ยังสั่งได้
                    </MenuItem>
                    <MenuItem value={"30_Day_Non_Defaultable"}>
                      30 วัน ยังสั่งได้
                    </MenuItem>
                  </Select>
                </FormControl>
              </Paper>

              <Paper className="col-md-12 p-0">
                <FormControl fullWidth>
                  <InputLabel id="InvoiceType">ชนิดของใบแจ้งหนี้</InputLabel>
                  <Select
                      sx={{
                        backgroundColor: "#dbe9ff",
                      }}
                    labelId="InvoiceTypel"
                    id="InvoiceType-select"
                    label="ชนิดของใบแจ้งหนี้"
                    onChange={(e) => {
                      setInvoiceType(e.target.value);
                      isChangeTimeAndInput("invoiceType", e.target.value);
                    }}
                    value={invoiceType}
                  >
                    <MenuItem value={"invoiceFree"}>
                      ใบแจ้งหนี้ ส่งให้ฟรี
                    </MenuItem>
                    <MenuItem value={"invoiceSample"}>
                      ใบแจ้งหนี้ สินค้าตัวอย่าง
                    </MenuItem>
                    <MenuItem value={"invoiceNoVat"}>
                      ใบแจ้งหนี้ no VAT
                    </MenuItem>
                    <MenuItem value={"invoiceShort"}>
                      ใบแจ้งหนี้, ใบกำกับภาษีแบบย่อ
                    </MenuItem>
                    <MenuItem value={"invoiceLong"}>
                      ใบแจ้งหนี้, ใบกำกับภาษีแบบเต็ม
                    </MenuItem>
                  </Select>
                </FormControl>
              </Paper>


              <Paper className="col-md-12 p-0">
                <TextField
                  fullWidth
                  type="text"
                  id="Address"
                  label="ที่อยู่ของร้านลูกค้า"
                  multiline
                  maxRows={4}
                  value={address}
                  onChange={(e) => {
                    setAddress(e.target.value);
                    isChangeTimeAndInput("address", e.target.value);
                  }}
                  variant="outlined"
                />
              </Paper>

              <Paper className="col-md-12 p-0">
                <TextField
                  fullWidth
                  type="text"
                  id="Addresss"
                  label="จุดสังเกตของร้านลูกค้า"
                  multiline
                  maxRows={4}
                  value={Addresss}
                  onChange={(e) => {
                    setAddresss(e.target.value);
                  }}
                  variant="outlined"
                />
              </Paper>

              <Paper className="col-md-12 p-0">
                <TextField
                  fullWidth
                  type="number"
                    inputMode="numeric"
                  id="Tel"
                  label="เบอร์โทรลูกค้า"
                  value={tel}
                  onChange={(e) => {
                    setTel(e.target.value);
                    isChangeTimeAndInput("tel", e.target.value);
                  }}
                  variant="outlined"
                  sx={{
                    backgroundColor: "#dbe9ff",
                  }}
                />
              </Paper>

              <Paper className="col-md-12 p-0">
                <TextField
                  fullWidth
                  type="number"
                    inputMode="numeric"
                  id="Tel"
                  label=" เบอร์ Fax"
                  value={telFax}
                  onChange={(e) => {
                    setTelFax(e.target.value);
                    isChangeTimeAndInput("telFax", e.target.value);
                  }}
                  variant="outlined"
                />
              </Paper>

              <Paper className="col-md-12 p-0">
                <TextField
                  fullWidth
                  id="LineGroupName"
                  label="Line Group Name"
                  type="text"
                  value={lineGroupName}
                  onChange={(e) => {
                    setLineGroupName(e.target.value);
                    isChangeTimeAndInput("lineGroupName", e.target.value);
                  }}
                  variant="outlined"
                  sx={{
                    backgroundColor: "#dbe9ff",
                  }}
                />
              </Paper>

              <Paper className="p-0">
                <Grid container spacing={0}>
                  <Grid
                    item
                    xs={4}
                    sx={{
                      textAlign: "center",
                      padding: "8.5px 14px",
                      border: "1px solid #e4e4e4",
                      borderBottom: "none",
                      borderRight: "none",
                      background: "#f5f5f5",
                      color: "#333",
                      fontSize: "15px",
                    }}
                  >
                    วัน
                  </Grid>
                  <Grid
                    item
                    xs={8}
                    sx={{
                      textAlign: "center",
                      padding: "8.5px 14px",
                      border: "1px solid #e4e4e4",
                      borderBottom: "none",
                      borderRight: "none",
                      background: "#f5f5f5",
                      color: "#333",
                      fontSize: "15px",
                    }}
                  >
                    ชั่วโมง : นาที
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={0}
                  sx={{
                    marginTop: "0px",
                  }}
                >
                  <Grid
                    item
                    xs={4}
                    sx={{
                      textAlign: "center",
                      padding: "8.5px 14px",
                      border: "1px solid #e4e4e4",
                      borderRight: "none",
                      color: "#333",
                      fontSize: "20px",
                    }}
                  >
                    จันทร์
                  </Grid>
                  <Grid
                    item
                    xs={8}
                    sx={{
                      textAlign: "center",
                      border: "1px solid #e4e4e4",
                      borderRight: "none",
                    }}
                  >
                    <input
                      type="time"
                      className="form-control"
                      onChange={(e) => {
                        setMon(e.target.value);
                        isChangeTimeAndInput("Mon", e.target.value);
                      }}
                      value={Mon}
                      style={{
                        padding: "8.5px 14px",
                        border: 0,
                        textAlign: "center",
                        borderRadius: 0,
                        fontSize: "20px",
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={0}
                  sx={{
                    marginTop: "0px",
                  }}
                >
                  <Grid
                    item
                    xs={4}
                    sx={{
                      textAlign: "center",
                      padding: "8.5px 14px",
                      border: "1px solid #e4e4e4",
                      borderRight: "none",
                      color: "#333",
                      fontSize: "20px",
                    }}
                  >
                    อังคาร
                  </Grid>
                  <Grid
                    item
                    xs={8}
                    sx={{
                      textAlign: "center",
                      border: "1px solid #e4e4e4",
                      borderRight: "none",
                    }}
                  >
                    <input
                      type="time"
                      className="form-control"
                      onChange={(e) => {
                        setTue(e.target.value);
                        isChangeTimeAndInput("Tue", e.target.value);
                      }}
                      value={Tue}
                      style={{
                        padding: "8.5px 14px",
                        border: 0,
                        borderRadius: 0,
                        textAlign: "center",
                        fontSize: "20px",
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={0}
                  sx={{
                    marginTop: "0px",
                  }}
                >
                  <Grid
                    item
                    xs={4}
                    sx={{
                      textAlign: "center",
                      padding: "8.5px 14px",
                      border: "1px solid #e4e4e4",
                      borderRight: "none",
                      color: "#333",
                      fontSize: "20px",
                    }}
                  >
                    พุธ
                  </Grid>
                  <Grid
                    item
                    xs={8}
                    sx={{
                      textAlign: "center",
                      border: "1px solid #e4e4e4",
                      borderRight: "none",
                    }}
                  >
                    <input
                      type="time"
                      className="form-control"
                      onChange={(e) => {
                        setWed(e.target.value);
                        isChangeTimeAndInput("Wed", e.target.value);
                      }}
                      value={Wed}
                      style={{
                        padding: "8.5px 14px",
                        border: 0,
                        borderRadius: 0,
                        textAlign: "center",
                        fontSize: "20px",
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={0}
                  sx={{
                    marginTop: "0px",
                  }}
                >
                  <Grid
                    item
                    xs={4}
                    sx={{
                      textAlign: "center",
                      padding: "8.5px 14px",
                      border: "1px solid #e4e4e4",
                      borderRight: "none",
                      color: "#333",
                      fontSize: "20px",
                    }}
                  >
                    พฤหัสบดี
                  </Grid>
                  <Grid
                    item
                    xs={8}
                    sx={{
                      textAlign: "center",
                      border: "1px solid #e4e4e4",
                      borderRight: "none",
                    }}
                  >
                    <input
                      type="time"
                      className="form-control"
                      onChange={(e) => {
                        setThu(e.target.value);
                        isChangeTimeAndInput("Thu", e.target.value);
                      }}
                      value={Thu}
                      style={{
                        padding: "8.5px 14px",
                        border: 0,
                        borderRadius: 0,
                        textAlign: "center",
                        fontSize: "20px",
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={0}
                  sx={{
                    marginTop: "0px",
                  }}
                >
                  <Grid
                    item
                    xs={4}
                    sx={{
                      textAlign: "center",
                      padding: "8.5px 14px",
                      border: "1px solid #e4e4e4",
                      borderRight: "none",
                      color: "#333",
                      fontSize: "20px",
                    }}
                  >
                    ศุกร์
                  </Grid>
                  <Grid
                    item
                    xs={8}
                    sx={{
                      textAlign: "center",
                      border: "1px solid #e4e4e4",
                      borderRight: "none",
                    }}
                  >
                    <input
                      type="time"
                      className="form-control"
                      onChange={(e) => {
                        setFri(e.target.value);
                        isChangeTimeAndInput("Fri", e.target.value);
                      }}
                      value={Fri}
                      style={{
                        padding: "8.5px 14px",
                        border: 0,
                        borderRadius: 0,
                        textAlign: "center",
                        fontSize: "20px",
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={0}
                  sx={{
                    marginTop: "0px",
                  }}
                >
                  <Grid
                    item
                    xs={4}
                    sx={{
                      textAlign: "center",
                      padding: "8.5px 14px",
                      border: "1px solid #e4e4e4",
                      borderRight: "none",
                      color: "#333",
                      fontSize: "20px",
                    }}
                  >
                    เสาร์
                  </Grid>
                  <Grid
                    item
                    xs={8}
                    sx={{
                      textAlign: "center",
                      border: "1px solid #e4e4e4",
                      borderRight: "none",
                    }}
                  >
                    <input
                      type="time"
                      className="form-control"
                      onChange={(e) => {
                        setSat(e.target.value);
                        isChangeTimeAndInput("Sat", e.target.value);
                      }}
                      value={Sat}
                      style={{
                        padding: "8.5px 14px",
                        border: 0,
                        borderRadius: 0,
                        textAlign: "center",
                        fontSize: "20px",
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={0}
                  sx={{
                    marginTop: "0px",
                  }}
                >
                  <Grid
                    item
                    xs={4}
                    sx={{
                      textAlign: "center",
                      padding: "8.5px 14px",
                      border: "1px solid #e4e4e4",
                      borderRight: "none",
                      color: "#333",
                      fontSize: "20px",
                    }}
                  >
                    อาทิตย์
                  </Grid>
                  <Grid
                    item
                    xs={8}
                    sx={{
                      textAlign: "center",
                      border: "1px solid #e4e4e4",
                      borderRight: "none",
                    }}
                  >
                    <input
                      type="time"
                      className="form-control"
                      onChange={(e) => {
                        setSun(e.target.value);
                        isChangeTimeAndInput("Sun", e.target.value);
                      }}
                      value={Sun}
                      style={{
                        padding: "8.5px 14px",
                        border: 0,
                        borderRadius: 0,
                        textAlign: "center",
                        fontSize: "20px",
                      }}
                    />
                  </Grid>
                </Grid>
              </Paper>

              {/* <Paper className="col-md-12 p-0">
                <TextField
                  fullWidth
                  id="GoogleMap"
                  label="ลิงค์ Google Map ร้านลูกค้า"
                  sx={{
                    backgroundColor: "#dbe9ff",
                  }}
                  type="url"
                  value={googleMap}
                  onChange={(e) => {
                    setGoogleMap(e.target.value);
                    isChangeTimeAndInput("googleMap", e.target.value);
                  }}
                  variant="outlined"
                />
              </Paper> */}

<Box className="col-md-12 p-0">
              <SalesReportGeolocation
                    label="deliveryGeoLocation"
                    salesReportGeolocation={googleMap}
                    setSalesReportGeolocation={setGoogleMap}
                    error={googleMapURLError}
                    setError={setGoogleMapURLError}
                  />
  </Box> 
              <Paper
                className="col-md-12 p-0 "
                onClick={() => {
                  document.getElementById("file-20").click();
                }}
              >
                {/* <Button
                  variant="text"
                  sx={{
                    padding: "16.5px 14px",
                    width: "100%",
                    justifyContent: "unset",
                  textTransform: "unset",
                  }}
                >
                  
                </Button> */}
                <TextField
                  fullWidth
                  id="file-TextField"
                  label="กรุณากดแนบภาพของ ภพ.20"
                  type="url"
                  disabled={true}
                  value={loadingFile === true ? "กำลังโหลดรูปภาพ..." : fileName}
                  variant="outlined"
                />
                <input
                  id="file-20"
                  type="file"
                  onChange={(e) => {
                    handleUploadToCloud(e.target.files[0]);
                  }}
                  style={{
                    display: "none",
                  }}
                />
              </Paper>

              <Paper className="col-md-12 p-0">
                <TextField
                  fullWidth
                  id="LineGroupUrl"
                  label="lineGroupUrl (ลิงค์เชิญ)"
                  sx={{
                    backgroundColor: "#dbe9ff",
                  }}
                  type="url"
                  value={lineGroupUrl}
                  onChange={(e) => {
                    setLineGroupUrl(e.target.value);
                    isChangeTimeAndInput("lineGroupUrl", e.target.value);
                  }}
                  variant="outlined"
                />
              </Paper>

              <Paper className="col-md-12 mt-4 p-0">
                <Button
                  
                  onClick={Update}
                  variant="contained"
                  className="btn-endit-conf"
                  size="large"
                >
                  บันทึก
                </Button>
              </Paper>
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
}

export default Customer;
