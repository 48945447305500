import React,{ useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import PlusMinus from "./PlusMinus";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import { useLocation , useHistory } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';



const OrderListTypography = styled(Typography)({
  fontSize: "18px",
   color: "#081828",
   
});


function Order() {
  const location = useLocation();
  const useOrderAgainShippr = useSelector(state => state.useOrderAgainShippr);
  const dispatch = useDispatch();
  const history = useHistory();

  
  useEffect(() => {
   
      dispatch({
        type: "SET_ORDER_AGAIN_SHIPPR",
        payload: location.state.product.map((item) => {
          if(item.imageURL === undefined){
              history.push({ 
                pathname: location.state.pathname,
                state: {
                  employeeNickname: location?.state?.employeeNickname,
                }
              });
             
          }
          
          return {
            productID: item.productID,
            imageURL: item.imageURL,
            productNameFull: item.productNameFull,
            quantity: item.quantity,
            purchasePrice: item.purchasePrice,
            quantityMax: item.quantity,
            totalPrice: item.totalPrice,
            newTotalPrice: 0,

          };
        }
        ),

      });

    
 

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


 

  return (
    <Box
      sx={{
        width: "100%",
        marginTop: "1rem",
      }}
    >

  {useOrderAgainShippr.length > 0 ? useOrderAgainShippr.map((item, index) => {
    return (
      <Paper  elevation={1} key={index} sx={{
        marginTop: index > 0 ? "1rem" : "0px",
      }}>
      <Grid container spacing={0}>
       
        <Grid item xs={4} sx={{
          padding: ".6rem 0 .6rem .6rem",
        }}>
          <img
            src={item.imageURL}
            className="img-fluid rounded shadow "
             style={{
              width: "100%",
              height: "100%",
             }}
            alt={item.productNameFull}
          />
        </Grid>
        <Grid
          item
          xs={8}
          sx={{
            textAlign: "left",
            padding: ".6rem",
          }}
        >
          <OrderListTypography noWrap >
            {item.productNameFull}
          </OrderListTypography>
         
          <OrderListTypography noWrap sx={{
            marginTop: ".2rem",
          }}>
            ราคา: ฿{item.purchasePrice.toLocaleString()}
          </OrderListTypography>
          <Box>
            <PlusMinus  value={item.quantity}  productID={item.productID}  />
          </Box>
        </Grid>
      </Grid>
      </Paper>
    )
  }) : <Box sx={{
     textAlign: "center",
      marginTop: "1rem",
      marginBottom: "1rem",
      height: "300px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",

      }}> <CircularProgress /></Box>} 

    </Box>
  );
}

export default Order;
