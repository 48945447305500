import { useState,useEffect } from "react"
import apiService from "../api/apiService"
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import PersonIcon from '@mui/icons-material/Person';
import Typography from '@mui/material/Typography';
import { Link } from "react-router-dom";


import liff from "@line/liff/dist/lib"
function Pre_checkout(){
    const service = apiService()
    const [userName,setUserName] = useState()
    const [userID,setUserID] = useState()
    const [jobType,setJobType] = useState()  

    useEffect(()=>{
        service.get({url:'/api/v1/user/'}).then((res)=>{
            console.log(res.data.result)
            setUserName(res.data.result.employeeNickname)
            setUserID(res.data.result.userID)
            setJobType(res.data.result.employeeJobType)
        })
    },[])
    if(jobType==='Salesperson' || jobType==='Accountant' || jobType==='Admin')
    return(
    <div>
        {/* <div className="pt-5 text-center pb-5">
            <p>ยินดีต้อนรับคุณ {userName}</p>
            <p>userID: {userID}</p>
            <p>เข้าสู่ครอบครัวอรุณี</p>
            <p>สถานะของคุณคือ</p>
            <p>{jobType}</p>
            <p>คุณสามารถเริ่มต้นใช้งานได้เลยค่ะ</p>
        </div> */}

<Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        backgroundColor: '#f5f5f5',
      }}
    >
      <CssBaseline />
      <Container component="main" sx={{ mt: 8, mb: 2 }} maxWidth="sm">
         
       <Box
       className="shadow-sm"
        sx={{
            padding: '30px',
            borderRadius: '10px',
            backgroundColor: '#fff',
            border: '1px solid #ffff',
            paddingBottom: '0px',
        }}>
             <Avatar sx={{ 
                m: 1, 
                bgcolor: 'secondary.main',
                margin: '0 auto',
                marginBottom: '15px',
                backgroundColor: '#0167f3',
             }}>
              <PersonIcon />
            </Avatar>
       <Typography 
        className="text-center "
        sx={{
            textAlign: 'center',
            fontSize: '20px',
        }}
        variant="h5" component="h5" gutterBottom >
             ท่านต้องการเบิกสินค้าเพื่อ
        </Typography>
        
            <Box sx={{

                textAlign: 'center',
                 marginTop: '25px',
                 marginBottom: '20px',
            }}>
                <Link
                      to={{
                        pathname: "/checkout-for-sell",
                      }}
                    >
                <Button 
                
                variant="contained"
                >
                    checkout-for-sell
                </Button>
                </Link>
            </Box>
            <Box sx={{

                textAlign: 'center',
                marginTop: '25px',
                marginBottom: '20px',
                }}>
                <Link
                      to={{
                        pathname: "/checkout-for-change",
                      }}
                    >
                <Button 
                
                variant="contained"
                >
                    checkout-for-change
                </Button>
                </Link>
            </Box>
            <Box sx={{

                textAlign: 'center',
                marginTop: '25px',
                marginBottom: '20px',
                }}>
                <Link
                      to={{
                        pathname: "/checkout-sample",
                      }}
                    >    
                <Button 
                
                variant="contained"
                >
                    checkout-sample
                </Button>
                </Link>
            </Box>
                
       </Box>


        
      </Container>
      
    </Box>
    </div>
    )
    else if(jobType==='Executive')
    return(
        <div>
            {/* <div className="pt-5 text-center pb-5">
                <p>ยินดีต้อนรับคุณ {userName}</p>
                <p>userID: {userID}</p>
                <p>เข้าสู่ครอบครัวอรุณี</p>
                <p>สถานะของคุณคือ</p>
                <p>{jobType}</p>
                <p>คุณสามารถเริ่มต้นใช้งานได้เลยค่ะ</p>
            </div> */}
    
    <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
            backgroundColor: '#f5f5f5',
          }}
        >
          <CssBaseline />
          <Container component="main" sx={{ mt: 8, mb: 2 }} maxWidth="sm">
             
           <Box
           className="shadow-sm"
            sx={{
                padding: '30px',
                borderRadius: '10px',
                backgroundColor: '#fff',
                border: '1px solid #ffff',
                paddingBottom: '0px',
            }}>
                 <Avatar sx={{ 
                    m: 1, 
                    bgcolor: 'secondary.main',
                    margin: '0 auto',
                    marginBottom: '15px',
                    backgroundColor: '#0167f3',
                 }}>
                  <PersonIcon />
                </Avatar>
           <Typography 
            className="text-center "
            sx={{
                textAlign: 'center',
                fontSize: '20px',
            }}
            variant="h5" component="h5" gutterBottom >
                 ท่านต้องการเบิกสินค้าเพื่อ
            </Typography>
            
                <Box sx={{
    
                    textAlign: 'center',
                     marginTop: '25px',
                     marginBottom: '20px',
                }}>
                    <Link
                      to={{
                        pathname: "/checkout-for-sell",
                      }}
                    >
                    <Button 
                    
                    variant="contained"
                    >
                        checkout-for-sell
                    </Button>
                    </Link>
                </Box>
                <Box sx={{
    
                    textAlign: 'center',
                    marginTop: '25px',
                    marginBottom: '20px',
                    }}>
                        <Link
                      to={{
                        pathname: "/checkout-for-change",
                      }}
                    >
                    <Button 
                    
                    variant="contained"
                    >
                        checkout-for-change
                    </Button>
                    </Link>
                </Box>
                <Box sx={{
    
                    textAlign: 'center',
                    marginTop: '25px',
                    marginBottom: '20px',
                    }}>
                        <Link
                      to={{
                        pathname: "/checkout-sample",
                      }}
                    >
                    <Button 
                    
                    variant="contained"
                    >
                        checkout-sample
                    </Button>
                    </Link>
                </Box>
                <Box sx={{
    
                    textAlign: 'center',
                    marginTop: '25px',
                    marginBottom: '20px',
                    }}>
                        <Link
                      to={{
                        pathname: "/checkout-for-gift",
                      }}
                    >
                    <Button 
                    
                    variant="contained"
                    >
                        checkout-for-gift
                    </Button>
                    </Link>
                </Box>
                    
           </Box>
    
    
            
          </Container>
          
        </Box>
        </div>
        )

        if(jobType==='Shipper')
        return(
        <div>
            {/* <div className="pt-5 text-center pb-5">
                <p>ยินดีต้อนรับคุณ {userName}</p>
                <p>userID: {userID}</p>
                <p>เข้าสู่ครอบครัวอรุณี</p>
                <p>สถานะของคุณคือ</p>
                <p>{jobType}</p>
                <p>คุณสามารถเริ่มต้นใช้งานได้เลยค่ะ</p>
            </div> */}
    
    <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
            backgroundColor: '#f5f5f5',
          }}
        >
          <CssBaseline />
          <Container component="main" sx={{ mt: 8, mb: 2 }} maxWidth="sm">
             
           <Box
           className="shadow-sm"
            sx={{
                padding: '30px',
                borderRadius: '10px',
                backgroundColor: '#fff',
                border: '1px solid #ffff',
                paddingBottom: '0px',
            }}>
                 <Avatar sx={{ 
                    m: 1, 
                    bgcolor: 'secondary.main',
                    margin: '0 auto',
                    marginBottom: '15px',
                    backgroundColor: '#0167f3',
                 }}>
                  <PersonIcon />
                </Avatar>
           <Typography 
            className="text-center "
            sx={{
                textAlign: 'center',
                fontSize: '20px',
            }}
            variant="h5" component="h5" gutterBottom >
                 ท่านต้องการเบิกสินค้าเพื่อ
            </Typography>
            
             
                <Box sx={{
    
                    textAlign: 'center',
                    marginTop: '25px',
                    marginBottom: '20px',
                    }}>
                    <Link
                          to={{
                            pathname: "/checkout-sample",
                          }}
                        >    
                    <Button 
                    
                    variant="contained"
                    >
                        checkout-sample
                    </Button>
                    </Link>
                </Box>
                    
           </Box>
    
    
            
          </Container>
          
        </Box>
        </div>
        )

        else
        return(<div></div>)

}
export default Pre_checkout