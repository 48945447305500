import React, { useEffect, useState }  from 'react'
 
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { ImageViewer } from "react-image-viewer-dv"
import Navbar from '../Navbar';
import ReactMarkdown from 'react-markdown'
function Products() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const useShopReducer = useSelector((state) => state.useShopReducer);
 
 
 
  // const [QuantityProduct, setQuantityProduct] = useState("");
 
 
  const useCartReducer = useSelector((state) => state.useCartReducer);
  const useNumberOfHeartsReducer = useSelector((state) => state.useNumberOfHeartsReducer);
  const [item, setItem] = useState([]);
   
  
  useEffect(() => {
    
    useShopReducer.map((e) => {
      if (Number(e.id) === Number(id)){
        setItem(e)
      }
      return e;
    });
    if (useShopReducer.length <= 0) {
        window.location.href = "/";
       }

  }, [id, useShopReducer]);


  const setheart3 = (e) => {
    
    const getlocalStorage = localStorage.getItem("heart");
    getlocalStorage === null ? localStorage.setItem("heart", JSON.stringify([{
      id: e.รหัสสินค้า,

    }])) : localStorage.setItem("heart", JSON.stringify([...JSON.parse(getlocalStorage), {
      id: e.รหัสสินค้า,
    }]));



    dispatch({
      type: "SET_NUMBER_OF_HEARTS",
      payload: useNumberOfHeartsReducer + 1,
    });
    const heart = document.querySelector(`#heart${e.id}`);
    if (heart.classList.contains("lni-heart")) {
               
      heart.classList.remove("lni-heart");
      heart.classList.add("lni-heart-filled");
      dispatch({
        type: "UPDATE_SHOP_DATA", payload: {
          
          id: e.id,
          รหัสสินค้า: e.รหัสสินค้า,
          สินค้า: e.สินค้า,
          ราคา: e.ราคา,
          รูปภาพ: e.รูปภาพ,
          หมวด: e.หมวด,
          จำนวน: e.จำนวน,
          รายละเอียด: e.รายละเอียด,
          heart: true
          
        }
      });

    } else {
      const getlocalStorage = localStorage.getItem("heart");
      const newheart = JSON.parse(getlocalStorage).filter(item => item.id !== e.id);
      localStorage.setItem("heart", JSON.stringify(newheart));

      dispatch({
        type: "SET_NUMBER_OF_HEARTS",
        payload: useNumberOfHeartsReducer - 1,
      });
      heart.classList.remove("lni-heart-filled");
      heart.classList.add("lni-heart");
      dispatch({
        type: "UPDATE_SHOP_DATA", payload: {
          
          id: e.id,
          รหัสสินค้า: e.id,
          สินค้า: e.สินค้า,
          ราคา: e.ราคา,
          รูปภาพ: e.รูปภาพ,
          หมวด: e.หมวด,
          จำนวน: e.จำนวน,
          รายละเอียด: e.รายละเอียด,
          heart: false
        }
      });
    }

  } 

  const addtocart = (e) => {
    
    const id = e.รหัสสินค้า;
    const name = e.สินค้า;
    const price = e.ราคา;
    const image = e.รูปภาพ;
    const amount = e.จำนวน;
    const heart = e.heart;
    const details = e.รายละเอียด;
    const Category = e.หมวด;
 

    if(id === 18 || id === 19){
      //  ให้สินค้า ปลาเส้นทอด (productID = 18) และปลาเส้นขาว (productID = 19) ให้เพิ่มทีละ 5 หน่วย
      if (useCartReducer.length > 0) {

        Promise.all(
          useCartReducer.map((item) => {
            if (item.id === id) {
              dispatch({
                type: "UPDATE_CART_DATA",
                payload: {
                  id: id,
                  รหัสสินค้า: id,
                  สินค้า: name,
                  ราคา: price,
                  รูปภาพ: image,
                  จำนวน: item.จำนวน + amount,
                  ราคารวม: (item.จำนวน + 5) * price,
                  หมวด: Category,
                  heart: heart,
  
                  รายละเอียด: details,
                },
              });
            } else if (item.id !== id) {
              dispatch({
                type: "ADD_CART_DATA",
                payload: {
                  id: id,
                  รหัสสินค้า: id,
                  สินค้า: name,
                  ราคา: price,
                  รูปภาพ: image,
                  จำนวน: 5,
                  ราคารวม: price * 5,
                  หมวด: Category,
                  heart: false,
  
                  รายละเอียด: details,
                },
              });
            }
          })
        );
      } else {
        dispatch({
          type: "ADD_CART_DATA",
          payload: {
            id: id,
            รหัสสินค้า: id,
            สินค้า: name,
            ราคา: price,
            รูปภาพ: image,
            จำนวน: 5,
            ราคารวม: price * 5,
            หมวด: Category,
            heart: false,
            รายละเอียด: details,
          },
        });
      }
    } else {
      if (useCartReducer.length > 0) {

        Promise.all(
          useCartReducer.map((item) => {
            if (item.id === id) {
              dispatch({
                type: "UPDATE_CART_DATA",
                payload: {
                  id: id,
                  รหัสสินค้า: id,
                  สินค้า: name,
                  ราคา: price,
                  รูปภาพ: image,
                  จำนวน: item.จำนวน + amount,
                  ราคารวม: item.ราคารวม + price,
                  หมวด: Category,
                  heart: heart,
  
                  รายละเอียด: details,
                },
              });
            } else if (item.id !== id) {
              dispatch({
                type: "ADD_CART_DATA",
                payload: {
                  id: id,
                  รหัสสินค้า: id,
                  สินค้า: name,
                  ราคา: price,
                  รูปภาพ: image,
                  จำนวน: 1,
                  ราคารวม: price,
                  หมวด: Category,
                  heart: false,
  
                  รายละเอียด: details,
                },
              });
            }
          })
        );
      } else {
        dispatch({
          type: "ADD_CART_DATA",
          payload: {
            id: id,
            รหัสสินค้า: id,
            สินค้า: name,
            ราคา: price,
            รูปภาพ: image,
            จำนวน: 1,
            ราคารวม: price,
            หมวด: Category,
            heart: false,
  
            รายละเอียด: details,
          },
        });
      }
    }

  }

  const localStorageHeart = JSON.stringify(localStorage.getItem("heart"))

  return (
    <div className='fade-in'>
      <Navbar />
    
    <section className="item-details section" style={{
      paddingTop:"100px",
      backgroundColor: "#FFE4E1"
    }}>
      <div className="container" style={{
        margin: "auto",
      }}>
        <div className="top-area">
            <div className="row align-items-center justify-content-center">
            <div className="col-lg-4 col-md-12 col-12 ">
              <div className="product-images">
                <main id="gallery">
                  <div className="main-img" style={{
                      border: "1px solid #eee"
                  }}>
                     <ImageViewer>
                        <img src={item.รูปภาพ} id="current" alt={item.สินค้า} onError={
                          (e) => {
                            e.target.onerror = null;
                            e.target.src = process.env.REACT_APP_NO_IMAGE
                          }
                        }/>
                      </ImageViewer>

                  </div>

                </main>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-12">
              <div className="product-info">
                  <h2 className="title">{item.สินค้า}</h2>
                 
                  <h3 className="price">฿{item.ราคา}</h3>
                  <ReactMarkdown>{item.รายละเอียด}</ReactMarkdown>
          
                <div className="bottom-content">
                  <div className="row align-items-end">
                    <div className="col-lg-6 col-md-6 col-12">
                      <div className="button cart-button">
                          <button
                            onClick={() => addtocart(item)}
                          className="btn" 
                          style={{ width: '100%' }}>หยิบใส่ตะกร้า</button>
                      </div>
                    </div>
                     
                    <div className="col-lg-6 col-md-6 col-12">
                      <div className="wish-button">
                          {/* <button className="btn" onClick={() => setheart3(item)}> 
                           <i
                            style={{
                              fontSize: "18px",
                              marginTop: "1.5px"
                            }}
                            className="lni lni-heart"
                            
                            id={`heart${id}`} /> กดหัวใจ</button> */}
                          {localStorageHeart !== null ? localStorageHeart.includes(item.รหัสสินค้า) ? <button className="btn" onClick={() => setheart3(item)}> <i className="lni lni-heart-filled"  id={`heart${item.id}`} style={{
                            fontSize: "18px",
                            marginTop: "1.5px",
                            color: "#f50057"
                          }} />ลบหัวใจ</button> : <button className="btn" onClick={() => setheart3(item)} ><i className="lni lni-heart"  id={`heart${item.id}`} style={{
                            fontSize: "18px",
                            marginTop: "1.5px"
                            }} />เพิ่มหัวใจ</button> : <button className="btn" onClick={() => setheart3(item)} ><i className="lni lni-heart"  id={`heart${item.id}`} style={{
                            fontSize: "18px",
                            marginTop: "1.5px"
                            }} />เพิ่มหัวใจ</button> }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </section>
    </div>
  )
}

export default Products