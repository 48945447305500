import apiService from "../../api/apiService"
import { SweetError } from "../../components/checkout/modal"

const service = apiService()

// https://script.google.com/macros/s/AKfycbw7MI4-gfJ3TIMH0-pgiFb2mPmKXHA83cS5h4GI-3ctQMC9-5MJxLcSdiWUW7oR5jNc/exec
async function getSpreadsheetApp (){
    // รหัสการใช้งานของ Google Sheet
    return await fetch("https://script.google.com/macros/s/AKfycbw7MI4-gfJ3TIMH0-pgiFb2mPmKXHA83cS5h4GI-3ctQMC9-5MJxLcSdiWUW7oR5jNc/exec", { 
        method: "GET",
      }).then((res) => {
        return res.json()
      }).catch((err) => {
        console.log(err)
        SweetError("/api/v1/product/list", err.message)
    })

}

 

//  /api/v1/selling-price-level/list
async function getSellingPriceLevelList (){
    return await service.get({ url: '/api/v1/selling-price-level/list' }).then((res) => {
        return res.data.result
    }).catch((err) => {
        console.log(err)
        SweetError("/api/v1/selling-price-level/list", err.message)
    })
}


// /api/v1/user/
async function getUser (){
    return await service.get({ url: '/api/v1/user/' }).then((res) => {
        return res.data.result
    }).catch((err) => {
        console.log(err)
        SweetError("/api/v1/user/", err.message)
    })
}


export { getSpreadsheetApp, getSellingPriceLevelList, getUser }