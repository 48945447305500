import React, { useEffect } from 'react'
 import { ScroolTop } from '../../styles/themes/GoTop.module';
 
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useLocation } from "react-router-dom";


 const style = {
  position: "fixed",
  bottom: "30px",
  right: "10px",
  zIndex: "9",
  padding: "0 10px 0 10px",
  fontSize: "16px",
  height: "50px",
  background: "rgb(255, 0, 119)",
  borderRadius: "10px",
  color: "rgb(255, 255, 255)",
  cursor: "pointer",

  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  transition: "all 0.3s ease-in-out 0s",

}


function GoTop() {
  const location = useLocation();
  const useCartReducer = useSelector((state) => state.useCartReducer)
 
  
 
  return (
    <div>
<ScroolTop  onClick={
      () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      }
      
    } style={{
      display: "flex",
    }}>
      <i className="lni lni-chevron-up"></i>
    </ScroolTop>

      {location.pathname === "/CancelOrder" || location.pathname === "/OrderAgain/user" || location.pathname === "/Baskets" ? null : <Link 
      to={{
        pathname: "/Baskets",
        state: useCartReducer.map((item) => {
          return {
            ...item,
            orderID: location?.state && location?.state[0]?.orderID ? location?.state[0]?.orderID : null,
            productID: item.id,
            quantity: item.จำนวน,
            purchasePrice: item.ราคา,
          }
        }),
      }}
      style={style}
      >
        

        {
          location?.state && location?.state[0]?.orderID ? "แก้ไขออเดอร์" : <>เพิ่มไปยังรถเข็น
          <i className={ useCartReducer.reduce((acc, item) => { return item.จำนวน + acc; }, 0) === 0 ? 'lni lni-cart ml-1' : 'lni lni-cart-full'} style={{
            marginLeft: "3px",
          }}></i></>
        }
      </Link> }
      
     
        

    </div>
  )
}
export default GoTop