import apiService from "../../api/apiService"
import { SweetError } from "../../components/checkout/modal"

const service = apiService()
 
 

///api/v1/customer/
async function getCustomer (){
    return await service.get({ url: '/api/v1/customer/' }).then((res) => {
        return res.data.result
    }).catch((err) => {
        console.log(err)
        SweetError("/api/v1/customer/", err.message)
    })
}


// /api/v1/customer/update 
async function updateCustomer (data){
    return await service.post({ url: '/api/v1/customer/update', params: data }).then((res) => {
        return res.data.result
    }).catch((err) => {
        console.log(err)
        SweetError("/api/v1/customer/update", err.message)
    }
    )
}


///api/v1/customer/list
async function getCustomerList (){
    return await service.get({ url: '/api/v1/customer/list' }).then((res) => {
        return res.data.result
    }).catch((err) => {
        console.log(err)
        SweetError("/api/v1/customer/list", err.message)
    })
}
        

export {  
 
    getCustomer,
    updateCustomer,
    getCustomerList

}