import React from 'react'
import { WatDatePicker } from "thaidatepicker-react";
import Box from '@mui/material/Box';
function DPicker({
  onChange
}) {
  return (
    <Box className='DPicker' sx={{ ml: 0.5}}>
    <WatDatePicker
      placeholder="วันที่"
      onChange={onChange}
      dateFormat={"yyyy-MM-dd"}
      displayFormat={"dd, DD MMMM YY"}
      inputStyle={{
        color: "#081828",
        border: "1px solid #dfdfdf",
        borderRadius: "4px",
        padding: "6px",
        textAlign: "left",
        width: "150px",
      
      }}
    />
    </Box>
  )
}

export default DPicker