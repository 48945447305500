import { combineReducers } from "redux";
import { useShopReducer } from "./useShopReducer";
import { useCartReducer } from "./useCartReducer";
import { useCustomerReducer } from "./useCustomerReducer";
import { useLoadersReducer } from "./useLoadersReducer";
import { useNumberOfHeartsReducer } from "./useHearts";
import { useRegister } from "./useRegister";
import {
  useOrderReult,
  useOrder,
  useOrderAcc,
  useInfoOrder,
  useOrderID,
  useOrderSelectedDate,
  useGetCash,
  useOnActive,
  useGetSlip,
  
} from "./useOrderReult";
import { useOrderDetail, useOrderDetailLoading, findIndex } from "./useOrderDetail";
import { useOrderAgainShippr } from "./useOrderAgainShippr";
import { user, customerID, closeOrder, customerInfo } from "./user";
import { useBuyNew } from "./useBuyNew";
import { useAmountToBePaid } from "./useAmountToBePaid";
const rootReducer = combineReducers({
  useNumberOfHeartsReducer,
 
  useShopReducer,
  useCartReducer,
  useRegister,
  useCustomerReducer,
  useOrderReult,
  useLoadersReducer,
  useOrder,
  useOrderAcc,
  useInfoOrder,
  useOrderID,
  useOrderSelectedDate,
  useGetCash,
  useOnActive,
  useGetSlip,
  useOrderDetail,
  useOrderAgainShippr,
  user,
  useBuyNew,
  customerID,
  useAmountToBePaid,
  useOrderDetailLoading,
  findIndex,
  closeOrder,
  customerInfo,

});

export default rootReducer;
