import React, { useState, useEffect } from "react";
import styled from 'styled-components';
import LoadingButton from "@mui/lab/LoadingButton";
import { useLocation, useHistory, useParams } from "react-router-dom";
import Toastify from 'toastify-js'
import UploadFile from "./uploadFile";
import apiService from "../../api/apiService";
import swal from '@sweetalert/with-react';
import imageCompression from 'browser-image-compression';
 
const Counter = styled.div`
  background-color: #f9ecff;
  padding-bottom: 3rem;
  padding-top: 1.5rem;
  width: 100%;
  min-height: 100vh;
  padding-left: 2rem;
  padding-right: 2rem;

  .title {
    font-size: 20px;
    font-weight: 600;
    border-bottom: 2px solid #1a1a1a;
    width: 500px;
    text-align: center;
    color: #1a1a1a;
    margin: 0 auto;
     height: auto;
    margin-bottom: 0.5rem;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;

const CounterInput = styled.div`
  width: 500px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 1.5rem;

  .dropdown {
    width: 100%;
    display: flex;
    margin-bottom: 0.5rem;
    padding: 10px;
    font-size: 15px;
    color: #1a1a1a;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    .dropdown__content .form-control {
      width: 160px;
    }
  }

  .datePicker {
    width: 100%;
    display: flex;
    padding: 10px;
    margin-bottom: 0.5rem;
    font-size: 15px;
    color: #1a1a1a;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    .datePicker__content .form-control {
      width: 160px;
    }
  }

  .timePicker {
    width: 100%;
    display: flex;
    padding: 10px;
    margin-bottom: 0.5rem;
    font-size: 15px;
    color: #1a1a1a;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    .timePicker__content .form-control {
      width: 160px;
    }
  }

  .inputTypeNumber {
    width: 100%;
    display: flex;
    padding: 10px;
    margin-bottom: 0.5rem;
    font-size: 15px;
    color: #1a1a1a;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    .inputTypeNumber__content .form-control {
      width: 160px;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;
const Upload = styled.div`
  width: 250px;
  height: 350px;
  margin: 0 auto;
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0px;
  background-color: #fff;
  border: 1px solid #9f98a3;
  cursor: pointer;
   
  div{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  &:hover {
    div .Upload_title{
      color: #2652f9;
    }
    div svg{
      color: #2652f9;
    }
  border: 1px solid #2652f9;

  }

  &:active {
     div .Upload_title{
      color: #2652f9;
    }
    div svg{
      color: #2652f9;
    }
  border: 1px solid #2652f9;
  }

  @media (max-width: 768px) {
     width: 200px;
    height: 300px;
  }

 
  }
`;

const Button = styled.div`
  width: 500px;
  margin: 0 auto;
  .Button {
      width: 500px;
  margin: 0 auto; 
  margin-top: 1.5rem;
  border-radius: 10px;
  font-size: 18px;
  padding: 0.4rem;
  background-color: #2652f9;
  border: 1px solid #fff;
  font-weight: 600;
  transition: all 0.3s ease;
  color: #fff;
  @media (max-width: 768px) {
    width: 100%;
  }
 
    
    &:active {
      background-color: #0a3cfc;
      color: #fff;
      border: 1px solid #2652f9;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;


const FileImage = styled.div`
  width: 250px;
  height: 100%;
  margin: 0 auto;
  position: relative;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
    @media (max-width: 768px) {
     width: 200px;
    
    margin-bottom: 1rem;
  }
`

const UploadFileContent = styled.div`
  width: 100%;
  height: 100%;

`;

const ToastifyError = (message) => {
  Toastify({
    text: message,
    duration: 3000,
    close: false,
    gravity: "top",
    position: "center",
    backgroundColor: "linear-gradient(to right, #ff416c, #ff4b2b)",
    stopOnFocus: false,
  }).showToast();
}


function CashTransferManualThisPayment() {
  const service = apiService()
  const [FileUrl, setFileUrl] = useState(null);
  const [FileLoading, setFileLoading] = useState("");
  const [FilePost, setFilePost] = useState(null);
  const location = useLocation();
  const history = useHistory();
  const [dropdown, setDropdown] = useState("");
  const [datePicker, setDatePicker] = useState("");
  const [timePicker, setTimePicker] = useState("");
  const [inputTypeNumber, setInputTypeNumber] = useState("");
  const [selectError, setSelectError] = useState(false);
  const [datePickerError, setDatePickerError] = useState(false);
  const [timePickerError, setTimePickerError] = useState(false);
  const [inputTypeNumberError, setInputTypeNumberError] = useState(false);
  const [loading, setLoading] = useState(false);
  const { shipperID } = useParams();

  useEffect(() => {
    if (location.state.slipUrl) {
      setFilePost(location.state.slipUrl);
      setFileUrl(URL.createObjectURL(location.state.slipUrl));
    }
   
   
  }, []);


  const documentClick = () => {
    document.getElementById("paymentAmountFile").click();
  };

  const fileChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    // เช็คขนาดไฟล์ มากกว่า 1 MB ให้บีบอัด
    if (file.size > 1000000) {
      const options = {
        maxSizeMB: 0.5,
        maxWidthOrHeight: 1920,
        useWebWorker: true
      }
      imageCompression(file, options)
        .then(function (compressedFile) {
          // size in KB
   
          setFilePost(compressedFile);
        })
        .catch(function (error) {
          console.log(error.message);
        });
      setFileUrl(URL.createObjectURL(file));
    } else {
      setFilePost(file);
      setFileUrl(URL.createObjectURL(file));
    }
    
  }

  const postApi = () => {
    setLoading(true);

    if (shipperID === null) {
      setLoading(false);
      ToastifyError("มีข้อผิดพลาด");
    } else {
      if (dropdown === "" || datePicker === "" || timePicker === "" || inputTypeNumber === "") {
        setLoading(false);
        ToastifyError("กรุณากรอกข้อมูลให้ครบถ้วน");
        // ถ้าไม่กรอกข้อมูลให้เปลี่ยนสีเป็นแดง
        dropdown === "" ? setSelectError(true) : setSelectError(false);
        datePicker === "" ? setDatePickerError(true) : setDatePickerError(false);
        timePicker === "" ? setTimePickerError(true) : setTimePickerError(false);
        inputTypeNumber === "" ? setInputTypeNumberError(true) : setInputTypeNumberError(false);
      } else {
        // ถ้ากรอกข้อมูลครบให้เปลี่ยนสีเป็นเทา
        setSelectError(false);
        setDatePickerError(false);
        setTimePickerError(false);
        setInputTypeNumberError(false);



        //api/v1/upload-image
        const formData = new FormData();
        formData.append("image", FilePost);

        service.post({ url: "api/v1/upload-image", params: formData, headers: { "Content-Type": "multipart/form-data" } }).then((res) => {


          const data = {
            shipperID: Number(shipperID),
            slipUrl: res.data.result.url,
            data: "CashTransfer",
            receiptDate: new Date(datePicker + " " + timePicker).toISOString(),
            receiptAmount: inputTypeNumber,
            paymentMethod: dropdown
          }
          console.log(data);
          service.post({ url: "/api/v1/financial/upload-slip", params: data }).then((res) => {
            setLoading(false);

            swal({
              title: "สำเร็จ",
              text: "อัพโหลดสลิปเรียบร้อยแล้ว",
              icon: "success",
              button: "ตกลง",
            }).then((value) => {
              setInputTypeNumber("");
              setDatePicker("");
              setTimePicker("");
              setDropdown("");
              setFileUrl(null);
              setFilePost(null);
            });


          }).catch((err) => {
            console.log(err)
            setLoading(false);
            ToastifyError("เกิดข้อผิดพลาด " + err.message)
          })

        }).catch((err) => {
          console.log(err)
          setLoading(false);
          ToastifyError("เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง")
        })




      }
    }


  };



  return (
    <Counter>
      <div
        className="title"
        style={{
          marginBottom: "1.5rem",
        }}
      >
        กรุณากรอกรายละเอียด หลักฐานการโอน
      </div>

      <UploadFile
        style={{
          marginTop: "1rem",
        }}
        File={File}
        FileUrl={FileUrl}
        documentClick={documentClick}
        fileChange={fileChange}
        FileLoading={FileLoading}
        Upload={Upload}
        FileImage={FileImage}
        UploadFileContent={UploadFileContent}
      />

      <div
        className="title"
        style={{
          marginTop: "3.5rem",
        }}
      >
        กรอกรายละเอียด
      </div>

      <CounterInput>




        <div className="dropdown shadow-sm">
          <div className="dropdown__title">paymentMethod</div>
          <div className="dropdown__content">
            <select
              className={
                selectError ? "form-control is-invalid" : "form-control"
              }
              value={dropdown}
              onChange={(e) => {
                setDropdown(e.target.value);
              }}
            >

              <option value="">เลือกวิธีชำระเงิน</option>



              <option value="cash">เงินสด</option>
              <option value="transfer">สลิปเงินโอน</option>
              <option value="ATM">ใบฝากเอทีเอ็ม</option>
              <option value="depositSlip">ใบนำฝากธนาคาร</option>
              <option value="cheque">เช็ค</option>
              <option value="otherTransfer">เงินโอนประเภทอื่น</option>



            </select>
          </div>
        </div>




        <div className="datePicker shadow-sm">
          <div className="datePicker__title">วันโอน</div>
          <div className="datePicker__content">
            <input
              type="date"
              className={
                datePickerError ? "form-control is-invalid" : "form-control"
              }
              value={datePicker}
              onChange={(e) => {
                setDatePicker(e.target.value);
              }}
            />
          </div>
        </div>

        <div className="timePicker shadow-sm">
          <div className="timePicker__title">เวลาโอน</div>
          <div className="timePicker__content">
            <input
              type="time"
              className={
                timePickerError ? "form-control is-invalid" : "form-control"
              }
              value={timePicker}
              onChange={(e) => {
                setTimePicker(e.target.value);
              }}
            />
          </div>
        </div>

        <div className="inputTypeNumber shadow-sm">
          <div className="inputTypeNumber__title">จำนวนเงิน</div>
          <div className="inputTypeNumber__content">
            <input
              type="number"
              inputMode="numeric"
              className={
                inputTypeNumberError ? "form-control is-invalid" : "form-control"
              }
              placeholder="จำนวนเงิน"
              value={inputTypeNumber}
              onChange={(e) => {
                setInputTypeNumber(e.target.value);
              }}
            />
          </div>
        </div>
      </CounterInput>

      <Button
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "2rem",
        }}
      >
        <LoadingButton
          onClick={postApi}
          size="small"
          variant="contained"
          loading={loading}
          loadingIndicator="กําลังตรวจสอบ..."
          className="shadow-sm"
          sx={{
            width: "80%",
            fontSize: "16px",
            marginTop: "10px",

            "@media (max-width: 768px)": {
              width: "80%",
            },
          }}
        >
          ยืนยันถูกต้อง
        </LoadingButton>
      </Button>
    </Counter>
  )
}

export default CashTransferManualThisPayment