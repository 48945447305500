import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

const DiaLogs = (props) => {
    const{ openDialog, setOpenDialog, discount, setDiscount, postApi } = props;
  
    return(
        <div>
        <Dialog 
      open={openDialog} 
      onClose={() => setOpenDialog(false)} 
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
           
      >
      <DialogTitle>ส่วนลด</DialogTitle>
      <DialogContent >
        <DialogContentText sx={{
          width: "500px",
          '@media (max-width: 616px)': {
            width: "300px",
          }
        }}>
          
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="discount"
          label="กรอกส่วนลด"
          type="number"
          inputMode="numeric"
          onClick={(e) => { e.target.select() } }
          fullWidth
          onChange={(e) => {
            setDiscount(e.target.value)
          }}
          value={discount || ""}
    
          variant="standard"

        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() =>  setOpenDialog(false)}>
          ยกเลิก
        </Button>
        <Button onClick={() => { 
          setOpenDialog(false);
          postApi()
        }}>
          
          ตกลง
        </Button>
      </DialogActions>
    </Dialog>

 
        </div>
    )
}


export default DiaLogs;