import React,{ useEffect, useState } from "react";
import Navbar from "../../../components/Navbar";
import GoTop from "../../../components/Button/GoTop";
import { useSelector, useDispatch } from "react-redux";
import swal from "@sweetalert/with-react";
import Order from "./Order";
import'./style.css';
 import CircularProgress from "@material-ui/core/CircularProgress";
 import Box from "@material-ui/core/Box";
 import Backdrop from '@mui/material/Backdrop';

import apiService from "../../../api/apiService";
import { useHistory , Link} from "react-router-dom";
import styled from 'styled-components';
import liff from "@line/liff/dist/lib";
// button สีเขียว
const ButtonGreen = styled.button`
  background-color: #4CAF50 !important;
  &:hover {
    background-color: #46a049;
  }
`;

const SweetError = (title, text) => {
  return swal({
    title: title,
    text: text,
    icon: "error",
    button: "ตกลง",
  });
};

 
 

function OrderList() {
  const useOrderDetail = useSelector((state) => state.useOrderDetail);
  const history = useHistory();
  const useOrderDetailLoading = useSelector((state) => state.useOrderDetailLoading);
  const service = apiService();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  async function getUser() {
    const user = await service.get({ url: '/api/v1/user' });
    return user.data.result;
  }

 
  // ///api/v1/order/delete
 async function postDeleteOrder(orderID) {
  return await service.post({ url: `/api/v1/order/delete`, params: { orderID } }).then((res) => {
      return res.data.result
  }).catch((err) => {
      console.log(err)
      SweetError("api/v1/order/delete", err.message)
    })
}


  useEffect(() => {

    getUser().then((res) => {
      if(['Disabled'].includes(res?.customerJobType)){
        swal({
          title: "ขออภัยค่ะ",
          text: "คุณไม่สามารถเข้าใช้งานระบบได้",
          icon: "warning",
          button: "ตกลง",
          // ให้ปิดไม่ให้กดถ้าไม่กดตกลง
          closeOnClickOutside: false,
        }).then(() => {
          liff.closeWindow();
        });
      } 
    }).catch((err) => {
      console.log(err);
    });

  }, []);

  

      const ThDate = (date) => {
          console.log(date);
          const d = new Date(date);
          d.setHours(d.getHours() + 7);
          const hours = d.getHours();
          const minutes = d.getMinutes() < 10 ? `0${d.getMinutes()}` : d.getMinutes();
          const seconds = d.getSeconds();
          

   
        // 4 ก.ย. 2566 16:30:00
        const setD = d.toLocaleDateString('th-TH', { 
          day: 'numeric',
          month: 'short',
          year: 'numeric',
         
      })
 
      return `${setD} ${hours}:${minutes}`;
 
 }



const Load = () => {
   

  return useOrderDetailLoading ? (<Box sx={{ 
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh', 
    backgroundColor: '#fae0dd',
     }}>
    <CircularProgress />
  </Box>) : (
    
  <div style={{
    minHeight: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    paddingTop: "34px",
    backgroundColor: "#ffe4e1",
  }}>
    ไม่พบรายการสั่งซื้อ
     
  </div>
  );

}


const warning = () => {
  swal({
    title: "แก้ไขรายการสั่งซื้อ",
    text: "ขณะนี้คำสั่งซื้อของท่าน อยู่ระหว่างการดำเนินการ ท่านจะสามารถแก้ไขคำสั่งซื้อ ได้ใหม่ภายในอีก 1-2 ชั่วโมง",
    icon: "warning",
    button: "ตกลง",
    
  }).then((willDelete) => {
    if (willDelete) {
      liff.closeWindow()
    }
  })
}

// ยกเลิกคำสั่งซื้อนี้ใช่ไหม?
const CancelOrder = (item) => {


  if(item.stage !== 2){
  

    console.log(item.orderDetailRecords);
    swal({
      title: "ต้องการยกเลิกคำสั่งซื้อนี้ใช่ไหม?",
      className: "swal-custom-editOrder",
      text: "กดใช่เพื่อยกเลิกคำสั่งซื้อ",
      icon: "warning",
      buttons: ["ไม่ใช่", "ใช่"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
         
        setOpen(true)
      postDeleteOrder(item.orderDetailRecords[0].orderID).then((res) => {
          setOpen(false)
          dispatch({
            type: "DELETE_ORDER_DETAIL",
            payload: item.key,
          });
          swal({
            title: "คำสั่งซื้อท่านถูกยกเลิกแล้ว",
            className: "swal-custom-editOrder-2",
            text: `รายการสั่งซื้อที่ท่านเลือกถูกยกเลิกแล้ว`,
            icon: "success",
            buttons: ["ไม่ใช่", "ตกลง"],
          })
        }) 
    
      }
    });
  } else {
    warning()
  }  

}

//แก้ไขรายการสั่งซื้อ
const EditOrder = (item) => {
   
  if(item.stage === 1){
    history.push({
      pathname: "/Baskets",
      state: item.orderDetailRecords,
    }); 
  } else  if(item.stage === 2){
    warning()
  }  if(item.stage === 3){
    history.push({
      pathname: "/OrderAgain/user",
      state: {
        order: item.orderDetailRecords.filter((item) => item.receivedQuantity !== null),
        orderID: item.orderDetailRecords[0].orderID,
        CancelOrder: true,
        ยอดเดิม: item.orderDetailRecords.reduce((acc, cur) => {
          return acc + cur.totalPrice;
        }, 0).toLocaleString()

      }
    });
  }
  
  
}

 

  return (
    < >
      <Navbar />

      {useOrderDetail.filter((item) => item.orderStatus !== true && item.shippedDate === null).length > 0 ? ( <div
        className="shopping-cart-b section fade-in"
        style={{ 
          padding: "100px 10px 10px 10px",
            backgroundColor: "#ffe4e1",
          minHeight: "100vh",
          paddingBottom: "150px",
          }}
      >


        {useOrderDetail.filter((item) => item.orderStatus !== true && item.shippedDate === null)
         .sort((a, b) => b.orderID - a.orderID)
         .map((item, index) => {
          
          return (
            
      <Box
       key={index}
        
        sx={{
            margin: "0px auto",
            // ล้างสุดท้าย marginBottom: "0px",
            marginBottom: useOrderDetail.length - 1 === index ? "0px" : "50px",
            boxShadow: "0px 0px 20px #00000012",
            backgroundColor: "#fcfcfc",
            width: '500px',
            borderRadius: "10px",
            '@media (max-width: 600px)': {
              width: '100%',
            },

          }}
      >
       
   
        <div className="cart-list-head" style={{ 
          display: "block",
          backgroundColor: "#fff",
          borderRadius: "10px",
          }}>
         
          <div style={{
            padding: "10px",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            paddingTop: "2rem",

          }}>
           <p style={{
            fontSize: "16px",
            color: "#1A1A1A",
            fontWeight: "500",
           }}>
                    {item.orderDetailRecords.length > 0 ? `orderID: ${item.orderDetailRecords[0].orderID}` : "-"}
           </p>
           <p style={{
            fontSize: "14px",
           }}>
           <span><em style={{
            fontStyle: "normal",
            color: "#555",
           }}>วันสั่ง:</em> {item.orderDate === null ? "" : ThDate(item.orderDate)}</span>
            
           </p>
          </div>
          
           <div style={{
            display: "flex",
        
            alignItems: "center",
            flexWrap: "wrap",
           }}>

                <Order orderDetailRecords={item.orderDetailRecords}/>
            
           </div>

           <div className="row">
          <div className="col-12">
            <div className="total-amount">
              <div className="row">
                <div
                  className="col-lg-4 col-md-6 col-12"
                  style={{ 
                    width: "100%",
                    
                   }}
                >
                  <div className="right" style={{
                    marginTop: "0px",
                  }}>
                    <ul style={{ listStyleType: "none" }}>
                      
                      <li>
                        ราคารวมทั้งหมด<span>{
                          item.orderDetailRecords.length > 0 ?  item.orderDetailRecords.reduce((acc, cur) => {
                            return acc + cur.totalPrice;
                          }
                          , 0).toLocaleString() : 0 } บาท</span>
                      </li>
                    </ul>
                    <div className="button d-flex justify-content-center align-items-center">
                      <ButtonGreen className="btn btn-alt mb-0" onClick={ () => { EditOrder(item) }}> แก้ไขออเดอร์ </ButtonGreen>
                      <button className="btn btn-alt" onClick={ () => { CancelOrder(item) }} style={{ backgroundColor: "#dc3545", }}>ยกเลิกออเดอร์</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
       
      </Box>
          );
        })} 





      </div>) : <Load/>}
      
     
    
      <GoTop />
     
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={()=> setOpen(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

export default OrderList;
