import * as React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
 
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import TextField from '@mui/material/TextField';
import 'dayjs/locale/th'; // Import the Thai locale for Day.js
import dayjs from 'dayjs'; // Use dayjs instead of moment
 
export default function TimeNewListItemOrder({time, setTime, label, error, setError, name}) {
 
 
  
  return (
     <TextField
        error={error}
        helperText={error}
        fullWidth
        id="time"
        label={label}
        name={name}
         value={time}
          type="time"
         ampm={false}
          
      sx={{
        backgroundColor: '#dbe9ff',
      }}
        onChange={(e) => {
          setTime(e.target.value);
          setError(false);
        }}
      
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          step: 300, // 5 min
          // click showPicker
          onFocus: (e) => {
            e.target.showPicker();
          },
        }}
      />
  );
}
