
function setLocalStorage(key, value) {
 
    if (localStorage.getItem(key) === null) {
        localStorage.setItem(key, value)
      } else {

        localStorage.removeItem(key)
        localStorage.setItem(key, value)
      }
}   

export { setLocalStorage }