import React from "react";

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useHistory } from "react-router-dom";

 

function Heading() {
  const history = useHistory();



  return (
    <>

      <Box sx={{
         flexGrow: 1,
         position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          width: '100%',
          
       }}>
        <AppBar position="static" sx={{
          backgroundColor: '#ff00a6',
        }}>
          <Toolbar variant="dense">
            <IconButton edge="start" color="inherit" aria-label="menu" onClick={() => {
              history.push('/clearMoney')
            }}>
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" component="div" sx={{ ml: 2 }} >
              เคลียร์การชำระเงินทั้งหมด
            </Typography>
          </Toolbar>
        </AppBar>
      </Box>
 




    </>
  );
}

export default Heading;
