import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { ImageViewer } from "react-image-viewer-dv";
import Toastify from "toastify-js";
import UploadFile from "./uploadFile";
import LoadingButton from "@mui/lab/LoadingButton";
import { useHistory, useParams } from "react-router-dom";
import imageCompression from 'browser-image-compression';
import apiService from "../../../api/apiService"
import CircularProgress from '@mui/material/CircularProgress';
import { ToastifyError, copyToClipboard, swalChange, swalSuccess } from './tools';
import swal from "@sweetalert/with-react";
import liff from "@line/liff/dist/lib";




const Button = styled.div`
  width: 500px;
  margin: 0 auto;
  .Button {
    width: 500px;
    margin: 0 auto;
    font-size: 16px;
    padding: 4px 10px;
    background-color: #1976d2;
    font-weight: 600;
    transition: all 0.3s ease;
    color: #fff;
    @media (max-width: 768px) {
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Counter = styled.div`
  background-color: #f9ecff;
  padding-bottom: 3rem;
  padding-top: 1.5rem;
  width: 100%;
  min-height: 100vh;
  padding-left: 2rem;
  padding-right: 2rem;

  .title {
    font-size: 24px;
    font-weight: 600;
    border-bottom: 2px solid #1a1a1a;
    width: 500px;
    text-align: center;
    color: #1a1a1a;
    margin: 0 auto;
     height: auto;
    margin-bottom: 0.5rem;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;
const List = styled.div`
  display: flex;
  justify-content: center;
  .list {
    width: 500px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    margin-top: 1rem;

    .list__detail {
      display: flex;
      justify-content: space-between;
      padding: 0.5rem;
      background-color: #fff;
      .list__detail__title {
        font-size: 16px;
        color: #1a1a1a;
      }
    }

    .list__detail_qrt {
      display: flex;
      justify-content: space-between;
      padding: 0.5rem;
      background-color: #f7a4d8;
      border-radius: 0 0 10px 10px;
      .list__detail_qrt__title {
        font-size: 16px;
        color: #1a1a1a;
      }
    }

    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;

const SlipInProofOfPayment = styled.div`
  width: 500px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  img {
    width: 350px;
    height: auto;
    @media (max-width: 768px) {
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const PaymentText = styled.div`
  width: 500px;
  margin: 0 auto;
  margin-top: 2.5rem;
  border-radius: 0px;
  padding: 1rem;
  text-align: center;
  border: 1px solid #9f98a3;
  cursor: pointer;
  background-color: #f9ecff;
  

  .Payment {
    .Payment__title {
      font-size: 16px;
      font-weight: unset;
      color: #1a1a1a;
    }
    .Payment__detail {
      font-size: 16px;
      font-weight: unset;
      color: #1a1a1a;
    }
    .Payment__detail_dank {
      font-size: 16px;
      font-weight: unset;
      color: #1a1a1a;
    }
    .Payment__detail_dank_number {
      font-size: 20px;
     
      margin-bottom: 0.3rem;
      color: #15a251;
    }
    .Payment__detail_coppy {
      font-size: 16px;
      font-weight: unset;
      color: #1a1a1a;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Upload = styled.div`
  width: 250px;
  height: 350px;
  margin: 0 auto;
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0px;
  background-color: #fff;
  border: 1px solid #9f98a3;
  cursor: pointer;
   
  div{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  &:hover {
    div .Upload_title{
      color: #2652f9;
    }
    div svg{
      color: #2652f9;
    }
  border: 1px solid #2652f9;

  }

  &:active {
     div .Upload_title{
      color: #2652f9;
    }
    div svg{
      color: #2652f9;
    }
  border: 1px solid #2652f9;
  }

  @media (max-width: 768px) {
     width: 200px;
    height: 300px;
  }

 
  }
`;
const FileImage = styled.div`
  width: 250px;
  height: 100%;
  margin: 0 auto;
  position: relative;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  @media (max-width: 768px) {
    width: 200px;

    margin-bottom: 1rem;
  }
`;
const UploadFileContent = styled.div`
  width: 100%;
  height: 100%;
`;

const ToastifyGreen = (message) => {
  Toastify({
    text: message,
    duration: 3000,
    close: false,
    gravity: "top",
    position: "center",
    backgroundColor: "linear-gradient(to right, #00b09b, #96c93d)",
    stopOnFocus: false,
  }).showToast();
};

// four purple
const ToastifyPurple = (message) => {
  Toastify({
    text: message,
    duration: 3000,
    close: false,
    gravity: "top",
    position: "center",
    backgroundColor: "linear-gradient(to right, #6a3093, #a044ff)",
    stopOnFocus: false,
  }).showToast();
};





function CashTransfer() {
  

  const [FileUrl, setFileUrl] = useState(null);
  const [FileLoading, setFileLoading] = useState("");
  const [FilePost, setFilePost] = useState(null);
  const [LoadingButtonCheck, setLoadingButtonCheck] = useState(false);
  const history = useHistory();
  const { shipperID, date } = useParams();
  const [list_cashtransfer, setListCashtransfer] = useState([]);
  const service = apiService();
  const [loading, setLoading] = useState(true);
  const [employeeNickname, setEmployeeNickname] = useState("");
  // /api/v1/financial/list-cashtransfer
  async function getListCashtransfer() {
    return await service.get({ url: `/api/v1/financial/list-cashtransfer?shipperID=${shipperID}&shippingDate=${date}` }).then((res) => {
      return res.data.result;
    })
  }

  // /api/v1/employee/list
  async function getEmployee() {
    return await service.get({ url: `/api/v1/employee/list` }).then((res) => {
      return res.data.result;
    })

  }



  const getApi = () => {
    setLoading(true);
    Promise.all([getListCashtransfer(), getEmployee()]).then((res) => {
      setEmployeeNickname(res[1].filter((item) => { return item.userID === Number(shipperID) })[0].employeeNickname);
      //ThisPayment ให้ filter paymentMethod ชนิด[cash_ThisPayment, transfer_ThisPayment, ATM_ThisPayment, ATM_ThisPayment, cheque_ThisPayment, otherTransfer_ThisPayment] เท่านั้น จึงจะเอา response มาใช้ได้
      setListCashtransfer(res[0].filter((item) =>  ["cash_ThisPayment", "transfer_ThisPayment", "ATM_ThisPayment", "cheque_ThisPayment", "otherTransfer_ThisPayment"].includes(item.paymentMethod) ));
      
      console.log(res[0]);
      setLoading(false);
    }).catch((err) => {
      setLoading(false);
      ToastifyError(err.message);
    })

  }

  useEffect(() => {
    getApi();


  }, []);

  const confirm = () => {
    getApi();
    swal({
      title: "คำสั่งซื้อของคุณลูกค้าเรียบร้อยแล้วค่ะ",
      text: "ขอบคุณที่ใช้บริการ",
      icon: "success",
      button: "ตกลง",
      className: "swal-text-check-user-payment",
    }).then((value) => {
          if (value) {
             window.location.reload();
          }
    });

  }

  async function copyToClipboard(bank) {
    await navigator.clipboard.writeText(bank);
    if (bank === "347-404461-8"){
       ToastifyPurple( "คัดลอกเลขบัญชีธนาคารสำเร็จ");
      document.querySelector(".PaymentText").style.border = "1px solid #6a3093";
      setTimeout(() => {
        document.querySelector(".PaymentText").style.border = "1px solid #9f98a3";
      }, 500);

     }else{
      ToastifyGreen( "คัดลอกเลขบัญชีธนาคารสำเร็จ");
      document.querySelector(".PaymentText").style.border = "1px solid #15a251";
      setTimeout(() => {
        document.querySelector(".PaymentText").style.border = "1px solid #9f98a3";
      }, 500);

     }
  }
  const documentClick = () => {
    document.getElementById("paymentAmountFile").click();
  };
  const fileChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    // เช็คขนาดไฟล์ มากกว่า 1 MB ให้บีบอัด
    if (file.size > 1000000) {
      const options = {
        maxSizeMB: 0.5,
        maxWidthOrHeight: 1920,
        useWebWorker: true
      }
      imageCompression(file, options)
        .then(function (compressedFile) {
          // size in KB
   
          setFilePost(compressedFile);
        })
        .catch(function (error) {
          console.log(error.message);
        });
      setFileUrl(URL.createObjectURL(file));
    } else {
      setFilePost(file);
      setFileUrl(URL.createObjectURL(file));
    }
    
  }

  function fetchRequest() {

    const reset = () => {
      setFileUrl(null);
      setFileLoading("");
      setFilePost(null);
      document.getElementById('paymentAmountFile').value = null;
      setLoadingButtonCheck(false);
    }


    // /api/v1/financial/upload-slip
    async function uploadSlip(body) {
      return await service.post({ url: "/api/v1/financial/upload-slip", params: body }).then((res) => {
        return res.data.result;
      }).catch((err) => {
        reset();

        swal({
          title: "ระบบไม่สามารถ ตรวจสอบ QR code ได้",
          text: "ท่านยืนยันว่าภาพดังกล่าว เป็นหลักฐานการโอนเงิน ใช่หรือไม่?",
          className: "swal-text-check-user-cashTransferPreviousPayment",
          buttons: { cancel: "ไม่ใช่", confirm: "ใช่" },

        }).then((value) => {
          if (value) {
            history.push({
              pathname: `/cashTransferManualThisPayment/${shipperID}`,
              state: { slipUrl: FilePost }
            })
          }
        })
        ToastifyError(err.message)
        reset();
      })

    }


    function uploadImage(resultQR) {
      const formData = new FormData();
      formData.append("image", FilePost);
      service.post({ url: "api/v1/upload-image", params: formData, headers: { "Content-Type": "multipart/form-data" } }).then((img) => {
        const slipUrl = img.data.result.url
        const body = {
          "slipUrl": slipUrl,
          "data": resultQR,
          "shipperID": Number(shipperID),
          "paymentMethod": "transfer"
        }

        uploadSlip(body).then((res) => {
          // success === false
          if (res.success === false) {
            ToastifyError(res.message)
            reset();

            swal({
              title: "ระบบไม่สามารถ ตรวจสอบ QR code ได้",
              text: "ท่านยืนยันว่าภาพดังกล่าว เป็นหลักฐานการโอนเงิน ใช่หรือไม่?",
              className: "swal-text-check-user-cashTransferPreviousPayment",
              buttons: { cancel: "ไม่ใช่", confirm: "ใช่" },

            }).then((value) => {
              if (value) {
                history.push({
                  pathname: `/cashTransferManualThisPayment/${shipperID}`,
                  state: { slipUrl: FilePost }
                })
              }
            })
          } else {
            // จำนวนเงินที่ท่านต้องชําระ
            // const amount = Number(list_cashtransfer.reduce((a, b) => a + (b.receiptAmount || 0), 0))

            // res.receiptAmount >= amount ? confirm() : history.push({
            //   pathname: "/paymentAmount/notEnoughMoney",
            //   state: {
            //     receiptAmount: res.receiptAmount,
            //   }

            // })
            confirm()
          }
        })

      }).catch((err) => {
        ToastifyError(`${err.message}`)

        reset();

        swal({
          title: "ระบบไม่สามารถ ตรวจสอบ QR code ได้",
          text: "ท่านยืนยันว่าภาพดังกล่าว เป็นหลักฐานการโอนเงิน ใช่หรือไม่?",
          className: "swal-text-check-user-cashTransferPreviousPayment",
          buttons: { cancel: "ไม่ใช่", confirm: "ใช่" },

        }).then((value) => {
          if (value) {
            history.push({
              pathname: `/cashTransferManualThisPayment/${shipperID}`,
              state: { slipUrl: FilePost }
            })
          }
        })
        console.log(err);
      })
    }




    const formData = new FormData();
    formData.append("file", FilePost);


    setFileLoading("loading");
    setLoadingButtonCheck(true);

    fetch("https://api.qrserver.com/v1/read-qr-code/", {
      method: "POST",
      body: formData,
    }).then((res) => res.json()).then((r) => {
      const resultQR = r[0].symbol[0].data;
      console.log(resultQR);
      
      if (resultQR) {
        setFileLoading("success");
        setLoadingButtonCheck(true);
        uploadImage(resultQR);
      } else {
        reset();

        swal({
          title: "ระบบไม่สามารถ ตรวจสอบ QR code ได้",
          text: "ท่านยืนยันว่าภาพดังกล่าว เป็นหลักฐานการโอนเงิน ใช่หรือไม่?",
          className: "swal-text-check-user-cashTransferPreviousPayment",
          buttons: { cancel: "ไม่ใช่", confirm: "ใช่" },

        }).then((value) => {
          if (value) {
            history.push({
              pathname: `/cashTransferManualThisPayment/${shipperID}`,
              state: { slipUrl: FilePost }
            })
          }
        })
      }


    }).catch((e) => {

      reset();

      swal({
        title: "ระบบไม่สามารถ ตรวจสอบ QR code ได้",
        text: "ท่านยืนยันว่าภาพดังกล่าว เป็นหลักฐานการโอนเงิน ใช่หรือไม่?",
        className: "swal-text-check-user-cashTransferPreviousPayment",
        buttons: { cancel: "ไม่ใช่", confirm: "ใช่" },

      }).then((value) => {
        if (value) {
          history.push({
            pathname: `/cashTransferManualThisPayment/${shipperID}`,
            state: { slipUrl: FilePost }
          })
        }
      })
    })
  }



  return (
    <Counter>

      {
        loading === true ? <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '2rem'
        }}>
          <CircularProgress />
        </div> : <> <div className="title">สรุปยอดที่ พนักงาน โอนเข้าบริษัท งวดนี้ ({employeeNickname})</div>

          <List>
            <div className="list shadow-sm">


              {list_cashtransfer.map((item, index) => {
                return (
                  <div className="list__detail" style={{ borderBottom: "1px solid #dfdfdf", }} key={index}>
                    <div className="list__detail__title">{index + 1}</div>
                    <div className="list__detail__title">{item.receiptAmount ? item.receiptAmount.toLocaleString() : 0}</div>
                  </div>
                )
              })}


              <div className="list__detail_qrt">
                <div className="list__detail_qrt__title">รวมทั้งหมด</div>
                <div className="list__detail_qrt__title">
                  {list_cashtransfer.reduce((a, b) => a + (b.receiptAmount || 0), 0).toLocaleString()}
                </div>
              </div>
            </div>
          </List>

          <div
            className="title"
            style={{
              marginTop: "3rem",
            }}
          >
            ภาพสลิปที่แนบแล้ว
          </div>

          <SlipInProofOfPayment className="mb-2">
            {
              list_cashtransfer.map((item, index) => {
                return (
                  <ImageViewer key={index}>
                    <img src={item?.receiptPictureURL} alt={item?.receiptPictureURL} className="mt-3" />
                  </ImageViewer>
                )
              })
            }

          </SlipInProofOfPayment>

          <div
            className="title"
            style={{
              marginTop: "3rem",
            }}
          >
            แนบสลิปใหม่
          </div>

            <PaymentText className="shadow-sm PaymentText" >
              <div className="Payment">
                <div className="Payment__title">หมายเลขบัญชีธนาคาร</div>
                <div className="Payment__detail">
                  ของ บริษัท สุวรรณภูมิ ฟู้ด จำกัด
                </div>

                <div style={{ margin: "15px 0" }}>
                  <div className="Payment__detail_dank">ธนาคารไทยพาณิชย์ เลขที่บัญชี</div>
                  <div className="Payment__detail_dank_number" style={{ color: "#8A2BE2" }} onClick={() => copyToClipboard("347-404461-8")}>{"347-404461-8"}</div>
                </div>

                <div>
                  <div className="Payment__detail_dank">ธนาคารกสิกรไทย เลขที่บัญชี</div>
                  <div className="Payment__detail_dank_number " onClick={() => copyToClipboard("065-1-90826-4")}>{"065-1-90826-4"}</div>
                </div>

                <div className="Payment__detail_coppy mt-2">กดที่ตัวเลขเพื่อคัดลอก</div>
              </div>
            </PaymentText>

          <UploadFile
            File={File}
            FileUrl={FileUrl}
            documentClick={documentClick}
            fileChange={fileChange}
            FileLoading={FileLoading}
            Upload={Upload}
            FileImage={FileImage}
            UploadFileContent={UploadFileContent}
          />

          <Button>
            <LoadingButton
              onClick={() => {
                if (FileUrl === null) {
                  ToastifyError("กรุณาอัพโหลดหลักฐานการชำระเงิน");

                  document.getElementById("upload").style.border = "1px solid red";
                  document.querySelector(".Upload_title").style.color = "red";
                  document.getElementById("AddIcon").style.color = "red";
                  document
                    .getElementById("upload")
                    .classList.add("wobble-hor-bottom");
                  setTimeout(() => {
                    document
                      .getElementById("upload")
                      .classList.remove("wobble-hor-bottom");
                  }, 500);
                } else {
                  fetchRequest();
                }
              }}
              size="small"
              variant="contained"
              loading={LoadingButtonCheck}
              loadingIndicator="กําลังตรวจสอบ..."
              className="shadow-sm Button"
              sx={{
                width: "500px",
                fontSize: "16px",
                marginTop: "1.5rem !important",

                "@media (max-width: 768px)": {
                  width: "100%",
                },
              }}
            >
              ส่งสลิป
            </LoadingButton>

            <LoadingButton
              size="small"
              variant="contained"
              className="shadow-sm Button"
              sx={{
                width: "500px",
                fontSize: "16px",
                marginTop: "10px !important",

                "@media (max-width: 768px)": {
                  width: "100%",
                },
              }}
              onClick={() => history.push(`/clearMoneyDetail/${shipperID}/${date}`)}
            >
              กลับหน้าเคลียร์เงิน
            </LoadingButton>
          </Button></>
      }

    </Counter>
  );
}


export default CashTransfer;
