import React, { useEffect , useState} from 'react'
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
 
import apiService from "../api/apiService"
import Avatar from '@mui/material/Avatar';
import PersonIcon from '@mui/icons-material/Person';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import swal from '@sweetalert/with-react';

function StatusCorrection() {


  const service = apiService()
  const [fullName, setFullName] = useState("")
  const [phone, setPhone] = useState("")
  const [loading, setLoading] = useState(true)
  const [userID, setUserID] = useState()
  const [fullNameDisable, setFullNameDisable] = useState("")
  const [phoneDisable, setPhoneDisable] = useState("")



  const getApi = () => {
    setLoading(true)
    service.get({ url: '/api/v1/user/' }).then((res) => {
      console.log(res.data.result)
      setFullName(res.data.result.employeeNickname)
      setPhone(res.data.result.phone)
      setUserID(res.data.result.userID)
      setLoading(false)

      setFullNameDisable(res.data.result.employeeNickname)
      setPhoneDisable(res.data.result.phone)

    }
    ).catch((err) => {
      setLoading(false)
      console.log(err)
    })
  }


  useEffect(() => {
    getApi()
  }, [])








  
  const postApi = () => {
    service.post({ url: '/api/v1/employee/update', params: { "fullName": fullName, "phone": phone, "userID": userID } }).then((res) => { 
      console.log(res.data)
      swal({
        title: "สำเร็จ",
        text: "บันทึกข้อมูลสำเร็จ",
        icon: "success",
        button: "ตกลง",
      }).then((value) => {
        getApi()
      });

    }).catch((err) => {
      console.log(err)
      swal({
        title: "ผิดพลาด",
        text: `${err.message}`,
        icon: "error",
        button: "ตกลง",
      });


     })

  }


  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        backgroundColor: '#f5f5f5',
      }}
    >
      <CssBaseline />
      <Container component="main" sx={{ mt: 8, mb: 2 }} maxWidth="sm">
        {
          loading ? <div style={{
            textAlign: 'center',
          }}><CircularProgress /> </div> : <Box
            className="shadow-sm"
            sx={{
              padding: '30px',
              borderRadius: '10px',
              backgroundColor: '#fff',
              border: '1px solid #ffff',
              paddingBottom: '0px',
            }}>

            <Avatar
              sizes="large"
              sx={{
                m: 1,
                bgcolor: 'secondary.main',
                margin: '0 auto',
                marginBottom: '15px',
                backgroundColor: '#0167f3',
              }}>
              <PersonIcon />
            </Avatar>

              <Typography variant="body1" gutterBottom sx={{ fontWeight: '500', }} >
                ขณะนี้ userID ของท่านคือ : <span style={{ fontWeight: '300' }}>{userID}</span> <br />
                กรุณากรอกข้อมูลดังนี้:
              </Typography>

              <div>
             <div className="input-group  mt-3">
                  <span className="input-group-text">ชื่อ-นามสกุล</span>
                <input type="text" className="form-control" value={fullName}  onChange={(e)=>setFullName(e.target.value)} />
                </div>

                <div className="input-group mb-3 mt-2">
                  <span className="input-group-text">เบอร์โทรศัพท์</span>
                  <input className="form-control" type="number" inputMode="numeric" value={phone} onChange={(e)=>setPhone(e.target.value)} />
                </div>

              </div>


              


              <Box sx={{

                textAlign: 'center',
                marginTop: '30px',
                marginBottom: '20px',
              }}>
                <Button
                  disabled={fullName === fullNameDisable && phone === phoneDisable}
                  variant="contained"
                  onClick={postApi}

                >
                  กรอกข้อมูลเรียบร้อย
                </Button>



              </Box>

            </Box>


        }



        </Container>
    </Box>


  )
}

export default StatusCorrection