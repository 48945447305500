import apiService from "../../api/apiService";
 
import { SweetError } from "../../components/checkout/modal";
const service = apiService();
 

// /api/v1/customer/list
async function getCusList() {
    return await service.get({ url: `/api/v1/customer/list` }).then((res) => {
        return res.data.result
    }).catch((err) => {
        console.log(err)
        SweetError("api/v1/customer/list", err.message)
        }
    )
}

// /api/v1/route/all-with-shipper
async function getAllWithShipper() {
    return await service.get({ url: `/api/v1/route/all-with-shipper` }).then((res) => {
        return res.data.result
    }).catch((err) => {
        console.log(err)
        SweetError("api/v1/route/all-with-shipper", err.message)
        }
    )
}




export { getCusList, getAllWithShipper  };