import * as React from 'react';
import Box from '@mui/material/Box';
import { SectionShopDeta } from "../../styles/themes/ShopDeta.module";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import { IOSSwitch } from "./MaterialUISwitch"
import DatePickers from './DPicker';
import Link from '@mui/material/Link';
const style = {
  p: 0,
  width: '100%',
 
  borderRadius: 2,
  border: '1px solid',
  borderColor: 'divider',
  backgroundColor: 'background.paper',
};

function FollowUpAdmin() {
  return (
    <SectionShopDeta style={{ minHeight: "100vh", paddingTop:10 }}>
    <Box className="container m-auto fade-in" sx={{ width: '500px', '@media screen and (max-width: 600px)': { width: '100%', }, }} >
      <div className="row " id="haed_false">
          <div className="mt-1 mb-1">
            <List sx={style} aria-label="mailbox folders" className='cart-list-head p-0 shadow-sm'>
              <ListItem>
                <Typography variant="subtitle1" gutterBottom sx={{m:0}}>ชื่อลูกค้า: นายชัย ราชวัตร</Typography>
              </ListItem>
              <Divider component="li" light />
              <ListItem>
                <Typography variant="subtitle1" gutterBottom sx={{ m: 0 }}>lineGroupURL: <Link href="#">Link</Link>
                  </Typography>
              </ListItem>
              <Divider component="li" light />
              <ListItem>
                <Typography variant="subtitle1" gutterBottom sx={{ m: 0 }}>แอดมิน: พี่จ๊ะ</Typography>
              </ListItem>
              <Divider component="li" light />
              <ListItem>
                <Typography variant="subtitle1" gutterBottom sx={{ m: 0 }}>เซลล์: พี่จ๊ะ</Typography>

              </ListItem>
              <Divider component="li" light />
              <ListItem>
                <Typography variant="subtitle1" gutterBottom sx={{ m: 0 }}>ชื่อคอร์ส: ก๋วยเตี๋ยวลูกชิ้นปลา</Typography>
              </ListItem>
              <Divider component="li" light />
              <ListItem>
                <Typography variant="subtitle1" gutterBottom sx={{ m: 0 }}>ผู้รับผิดชอบส่งวัตถุดิบ: ไข่ พิธีวัฒน์</Typography>
              </ListItem>
              <Divider component="li" light />
              
              <ListItem>
                  <Box sx={{ display: "flex" }}>
                  <Typography variant="subtitle1" gutterBottom sx={{ m: 0 }}>ส่งวัตถุดิบครบถ้วน:</Typography>
                    <FormControlLabel
                      control={<IOSSwitch defaultChecked sx={{ ml: 2 }} />}
                      label=""
                    />
                  </Box>
            
              </ListItem>
              <Divider component="li" light />

              <ListItem>
                <Box sx={{ display: "flex" }}>
                  <Typography variant="subtitle1" gutterBottom sx={{ m: 0 }}>อธิบายให้ลูกค้าเตรียมตัว:</Typography>
                  <FormControlLabel
                    control={<IOSSwitch defaultChecked sx={{ ml: 2 }} />}
                    label=""
                  />
                </Box>
              </ListItem>
              <Divider component="li" light />

            

              <ListItem>
               
                  <Box sx={{ display: "flex" }}>
                  <Typography variant="subtitle1" gutterBottom sx={{ m: 0 }}>ระบุวันเรียนของลูกค้า:</Typography>
                    <DatePickers />
                  </Box>
            
              </ListItem>
              <Divider component="li" light />


             
              <ListItem>
                <Box sx={{ display: "flex" }}>
                  <Typography variant="subtitle1" gutterBottom sx={{ m: 0 }}>แอดมินทำงานครบถ้วน:</Typography>
                  <FormControlLabel
                    control={<IOSSwitch defaultChecked sx={{ ml: 2 }} />}
                    label=""
                  />
                </Box>
              </ListItem>

            </List>
            
          </div>
      </div>
    </Box>
    </SectionShopDeta>
  )
}

export default FollowUpAdmin
