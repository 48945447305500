import React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgb(218, 24, 132)",
    color: theme.palette.common.white,
    border: '1px solid rgba(224, 224, 224, 1)',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    border: '1px solid rgba(224, 224, 224, 1)',
    
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: '1px solid rgba(224, 224, 224, 1)',
  },
}));

 

export default function CustomizedTables({
  data,
  loading
}) {
  return (
    <TableContainer component={Paper}  style={{ marginTop: '20px' }}>
      <Table  aria-label="customized table">
        <TableHead sx={{overflowX: 'auto'}}>
          <TableRow>
            <StyledTableCell>
          
              <Typography noWrap sx={{ fontSize: "0.875rem" }}>
                ชื่อสินค้า
              </Typography>
            </StyledTableCell>
            <StyledTableCell align="right">
              <Typography noWrap sx={{ fontSize: "0.875rem" }}>
                สัปดาห์นี้
                </Typography>
            </StyledTableCell>
            <StyledTableCell align="right">
              
              <Typography noWrap sx={{ fontSize: "0.875rem" }}>
                สัปดาห์ที่แล้ว
              </Typography>
              </StyledTableCell>
            <StyledTableCell align="right">
              <Typography noWrap sx={{ fontSize: "0.875rem" }}>
                สองสัปดาห์ที่แล้ว
              </Typography>
            </StyledTableCell>
            <StyledTableCell align="right">
              <Typography noWrap sx={{ fontSize: "0.875rem" }}>
                สามสัปดาห์ที่แล้ว
              </Typography>
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.length > 0 &&
            data.map((item, index) => {
              return (
                <StyledTableRow key={index}>
                  <StyledTableCell component="th" scope="row" >

                    <div className='MentorMonthlySales-StyledTableCell'>
                      <Typography noWrap sx={{ fontSize: "0.875rem" }}>
                        {item.productNameFull}
                      </Typography>
                    </div>
                  </StyledTableCell>

                  <StyledTableCell align="right">
                    <Typography noWrap sx={{ fontSize: "0.875rem" }}>
                      {item.table[0]?.receivedQuantity || 0}
                    </Typography>
                  </StyledTableCell>

                  <StyledTableCell align="right">
                    <Typography noWrap sx={{ fontSize: "0.875rem" }}>
                      {item.table[1]?.receivedQuantity || 0}
                    </Typography>
                  </StyledTableCell>

                  <StyledTableCell align="right">
                    <Typography noWrap sx={{ fontSize: "0.875rem" }}>
                      {item.table[2]?.receivedQuantity || 0}
                    </Typography>
                  </StyledTableCell>

                  <StyledTableCell align="right">
                    <Typography noWrap sx={{ fontSize: "0.875rem" }}>
                      {item.table[3]?.receivedQuantity || 0}
                    </Typography>
                  </StyledTableCell>
                </StyledTableRow>
              )
            })}
        </TableBody>
        {data.length === 0 && <TableBody> 
          <TableRow>
          <StyledTableCell colSpan={5} align="center">
          <Typography noWrap sx={{ marginTop: '20px', marginBottom: '20px' }}>
            {
              loading ? 'กำลังโหลดข้อมูล...' : 'ไม่พบข้อมูล'
            }
          </Typography>
        </StyledTableCell></TableRow></TableBody>}
      </Table>
    </TableContainer>
  );
}
