import * as React from 'react';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import ButtonGroup from '@mui/material/ButtonGroup';
 
import LaunchIcon from '@mui/icons-material/Launch';
import swal from '@sweetalert/with-react';
import { updateCustomer } from "./api"
import Toastify from 'toastify-js'
import 'toastify-js/src/toastify.css'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';

export default function SplitButton({
    label,
    color,
    customer,
    type
}) {
   
 
  const anchorRef = React.useRef(null);

    const [error, setError] = React.useState(null);
    const [salesReportGeolocation, setSalesReportGeolocation] = React.useState(null);


    React.useEffect(() => {
        setSalesReportGeolocation(customer?.[type])
    }, [customer]);

  const showError = (error) => {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        setError("ผู้ใช้ปฏิเสธคำขอสำหรับตำแหน่งที่ตั้ง.");
  
        break;
      case error.POSITION_UNAVAILABLE:
        setError("ข้อมูลตำแหน่งไม่สามารถให้ได้.");
  
        break;
      case error.TIMEOUT:
        setError("คำขอเพื่อรับตำแหน่งของผู้ใช้หมดเวลา.");
        break;
      case error.UNKNOWN_ERROR:
        setError("เกิดข้อผิดพลาดที่ไม่รู้จัก.");
        break;
    }
  };
  
  function getLocation() { 
   
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          console.log("position", position);
          setError(null);
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;
          setSalesReportGeolocation(lat + "," + lng);
          const data = {
            customerID: customer?.customerID,
            [type]: lat + "," + lng
          }
          console.log("data", data);
            updateCustomer(data).then((res) => {
             
                if(res){
                 
                    Toastify({
                        text: "บันทึกตำแหน่งสำเร็จ",
                        duration: 3000,
                        gravity: "top",
                        position: "center",
                        backgroundColor: "linear-gradient(to right, #00b09b, #96c93d)",
                        stopOnFocus: true, // Prevents dismissing of toast on hover
                        onClick: function(){} // Callback after click
                      }).showToast();
                }
            }) 
        },
        showError,
        {
          enableHighAccuracy: true,
        }
      );
    } else {
      setError("เบราว์เซอร์นี้ไม่รองรับการระบุตำแหน่ง.");
    }
  }



  React.useEffect(() => {
    if(error){
        swal({
            title: "ผิดพลาด!",
            text: error,
            icon: "error",
            button: "ปิด",
          });
    }
  }, [error]);




  return (
    <React.Fragment>
      {/* title */}
      <Box
        sx={{
      
          mb: .2,
        
     
        }}
      >
        <Typography variant="h6" component="div">
          {label}
        </Typography>
      </Box>

      <ButtonGroup variant="contained" ref={anchorRef} aria-label="split button" sx={{  mb: 2, ml: 1, mr: 1, }} color={color} >
        <Button  size="large" variant="contained" color={color} onClick={getLocation} sx={{ width: 300, }}  startIcon={<AddLocationAltIcon />}>
        <Typography variant="h7" component="div" noWrap>
        {
            salesReportGeolocation ? salesReportGeolocation : label
        }
        </Typography>
        </Button>
        <Button
         size="large"
          variant="contained"
          aria-label={label}
          
            color={color}
            onClick={()=>{
                if(salesReportGeolocation){
                  window.open("https://www.google.com/maps/search/?api=1&query=" + salesReportGeolocation, "_blank")
                } else {
                  Toastify({
                    text: "ไม่พบตำแหน่ง",
                    duration: 3000,
                    gravity: "top",
                    position: "center",
                    // error color
                    backgroundColor: "linear-gradient(to right, #ff416c, #ff4b2b)",
                    stopOnFocus: true, // Prevents dismissing of toast on hover
                    onClick: function(){} // Callback after click
                  }).showToast();
                }
            }}
        >
          <LaunchIcon  />
        </Button>
      </ButtonGroup>

      
    
    </React.Fragment>
  );
}
