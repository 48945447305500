import styled from 'styled-components';

const Counter = styled.div`
  background-color:  #e2e3f0;
 
  width: 100%;
  min-height: 100vh;
  
  @media (max-width: 768px) {
   background-color: #11103f;

  }
 
  .title {
    font-size: 1.5rem;
 
    border-color: ;
    border: 1px solid #6c757d !important;
    padding: 0.5rem;
    border-radius: 10px;
    background-color: #ffecf8;
    text-align: center;
    color: #1a1a1a;
    margin: 0 auto;
     height: auto;
 
   
  }

  .main{
    width: 500px;
    margin: 0 auto;

    @media (max-width: 768px) {
        width: 100%;
      }
  }

  .Table-costAruneeNoodles {
   background-color: #11103f;
    @media (max-width: 768px) {
   
      border-radius: 0;
      
    }
  }
`;

export { Counter }