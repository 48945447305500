import React from "react";
 
function Order({ orderDetailRecords }) {
  console.log(orderDetailRecords);
  return (
    <>
      {orderDetailRecords.map((item, index) => {
        
        return ( 
        <div key={index} style={{
          display: "flex",
          padding: "20px",
          backgroundColor: "#fff",
          width:"400px",
        
          alignItems: "center",
         

        }}>


            <div style={{
              width: "150px",
              paddingRight: "1rem",

            }}>
              
               <img className="img-thumbnail" src={item.imageURL} alt={item.productNameFull} style={{maxWidth: '100%'}} />

            </div>
            <div style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
              
            }}>
             <h5 style={{
                fontSize: "15px",
                color: "#333",
                fontWeight: "500",
                lineHeight: "25px",
              }}>
                {item.productNameFull}
              </h5>


              <p style={{
          fontSize: "13px",
          padding: "0px",
         }}>
         <span><em style={{
          fontStyle: "normal",
          color: "#555",
         }}>ราคา:</em> {item.purchasePrice ? item.purchasePrice.toLocaleString() : 0} บาท</span>
        
         </p>
         <p style={{
          fontSize: "13px",
          padding: "0px",

         }}>
         <span><em style={{
          fontStyle: "normal",
          color: "#555",
         }}>จํานวน:</em> {
          // ถ้ามี receivedQuantity ให้ใช้ receivedQuantity ถ้าไม่มี ให้ใช้ quantity
          item.receivedQuantity ? item.receivedQuantity.toLocaleString() : item.quantity ? item.quantity.toLocaleString() : 0

         } ชิ้น</span>
        
         </p>
         <p style={{
          fontSize: "13px",
          padding: "0px",

         }}>
          
         <span><em style={{
          fontStyle: "normal",
          color: "#555",
         }}>ราคารวม:</em> {item.totalPrice ? item.totalPrice.toLocaleString() : 0} บาท</span>
        
         </p>

             
            </div>

        </div>)
      })}
   


          
    </>
  );
}

export default Order;
